import React, {useEffect, useState} from 'react'
import BeaconRequest from '../../BeaconRequest'

import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

import LoaderAlt from '../../components/general/LoaderAlt';

import {withWidgetContainer} from '../withWidgetContainer'
import FormatNumber from '../../components/general/FormatNumber'
import * as colours from '../../colours'
import DropdownList from '../../components/general/DropdownList';

import {setAnalyticsListResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '../../components/general/Modal'
import moment from 'moment-timezone'
import { redirectRouter } from '../../organisms/WidgetContainer/DropdownContainer';
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'
import Loader from '../../components/general/Loader';

const CampaignsList = (props) => {
  const resource = useSelector(({analyticsList}) => analyticsList.data)
  const isFetching = useSelector(({analyticsList}) => analyticsList.fetching)
  const prevDatetime = usePrevious(props.datetime)
  const prevFilter = usePrevious(props.filter)
  const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
          dispatch(setAnalyticsListResource({filter: props.filter, timePeriod: props.datetime}))
        }
    }, [props.filter, props.datetime])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Skeleton variant="rect" width='100%' height={362} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
        >
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
               <CampaignsListChild 
                  {...props} 
                  resource={resource}
                  createResource={(limit, start) => dispatch(setAnalyticsListResource({filter: props.filter, timePeriod: props.datetime, paging: {start, limit} }))}
                />
            </div>
        </Card>
    )
     
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: 15
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableCell: {
      padding: '12px'
    },
  }));
  

const CampaignsListChild = (props) => {
    const classes = useStyles();
    const [campaigns_res, setCampaigns_res] = useState([])
    const [linksPaging, setLinksPaging] = useState(null)
    const [type, setType] = useState("campaigns")
    const [view, setView] = useState("table")
    const [option, setOption] = useState({id: "active", title: 'Active ' + type})
    const [linkOption, setLinkOption] = useState({id: "active", title: 'Visited'})
    const [links, setLinks] = useState([])
    const [loadingLinks, setLoadingLinks] = useState(false)
  
    useEffect(() => {
      setCampaigns_res([])
      if (props.filter.type === "website") {
        setType("campaigns")
      } else if (props.filter.type === "campaign") {
        setType("channels")
      } else if (props.filter.type === "channel") {
        setType("links")
      } 
    }, [props.resource])

    useEffect(() => {
      const tmp_res = props.resource.campaigns
      const tmp = tmp_res.response

      if (props.filter.type === "website") {
        if (tmp == null) setCampaigns_res([])
        else {
          setCampaigns_res(tmp)
          
          
        }
      } else if (props.filter.type === "campaign") {
        if (tmp == null) setCampaigns_res([])
        else {
          setCampaigns_res(tmp)

          
        }
      } else if (props.filter.type === "channel") {
        if (tmp == null) setCampaigns_res([])
        else {
          setCampaigns_res(tmp)
         
         
        }
      } 
      
    }, [type, props.resource])


    const [page, setPage] = useState(0)

    const rowsPerPage = 10
    const [limit, setLimit] = useState(rowsPerPage)
    const [start, setStart] = useState(page*limit)

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('visits');

    const [exportModal, setExportModal] = useState(false)
    const [collation, setCollation] = useState({id: "summary", title: 'Summary'})
    const [exportFormat, setExportFormat] = useState({id: "csv", title: 'CSV'})
    const [exportName, setExportName] = useState("")

    const [loadingDaily, setLoadingDaily] = useState(false)
    const [daily, setDaily] = useState([])

    useEffect(() => {
      setPage(0)
      setLimit(rowsPerPage)
      setStart(0)
    }, [props.filter, props.datetime]) 

    useEffect(() => { 
      if (option.id != "active") {
        setLoadingLinks(true)
        BeaconRequest.get(
          `${props.filter.type}/${props.filter.payload.id}/ad/${option.id}/${props.datetime}`
          )
         .then(e => {
           setLinks(e.response)
         })
         .catch(e => {
           setLinks([])
           
         })
         .finally(e => {setLoadingLinks(false)})
      }
    }, [option, linkOption, props.filter, props.datetime, start, limit])

    const collationType = () => {
      if (option.id == "links") {
        return 'link'
      } else if (option.id == "channels") {
        return 'channel'
      } else {
        return type.substring(0, type.length - 1)
      }
    }

    useEffect(() => { 
      setLoadingDaily(true)
      let query = `${props.filter.type}/${props.filter.payload.id}/export/analytics/${collationType()}/${props.datetime}`
      BeaconRequest.get(query)
       .then(e => {
         setDaily(e.response)
       })
       .finally(e => setLoadingDaily(false))
    }, [option, linkOption, props.filter, props.datetime])
  
    const getTable = (res) => res.map(item => {
      const id = () => {
        if (props.filter.type === "website") return "campaign_id"
        else if (props.filter.type === "campaign") return "channel_id"
        else return "link_id"
      }
  
      const idForTable = () => {
        if (option.id == "links") {
          return item["link_id"] 
        } else if (option.id == "channels") {
          return item["channel_id"] 
        } else {
          return item[`${id()}`]
        }
      }
  
        const name = () => {
          if (props.filter.type === "website") return "campaign_name"
          else if (props.filter.type === "campaign") return "channel_name"
          else return "link_name"
        }
        
        const getColour = () => {
          if (option.id == "links" || type == "links") {
            return null
          } else if (option.id == "channels"|| type == "channels") {
            return item.channel_colour
          } else {
            return item.campaign_colour
          }
        }
        const getIcon = () => {
          if (option.id == "links"|| type == "links") {
            return null
          } else if (option.id == "channels"|| type == "channels") {
            return item.channel_icon
          } else {
            return null
          }
        }
        const getName = () => {
          if (option.id == "links"|| type == "links") {
            return item.link_notes || "No description"
          } else if (option.id == "channels"|| type == "channels") {
            return item.channel_name
          } else {
            return item[name()]
          }
        }
        return {
            id: idForTable(),
            name: getName(),
            icon: getIcon(),
            ad_channels: item.campaign_ad_channels,

            impressions: item.impressions,
            visits: item.visits || item.visitors,
            clicks: item.clicks,
            bot: item.bots,
            bot_percent: item.bot_percentage,
            leads: item.leads,
            leads_percentage: item.leads_percentage,
            visits_percent: item.visits/item.clicks,
            clicks_percent: item.clicks/item.impressions,
            engaged_visitors: item.engaged_visitors,
            engaged_visitors_percentage: item.engaged_visitors_percentage,
            last_visit: item.last_visit,
            colour: getColour(),
            spend: item.spend,

            cpc: item.clicks == 0 ? 0 : (item.spend/item.clicks).toFixed(2) ,
            cpv: item.visits == 0 ? 0 : (item.spend/item.visits).toFixed(2),
            cpl: item.leads == 0 || item.leads == null ? 0 : (item.spend/item.leads).toFixed(2),
            ...(collation.id == "daily" ? {start_date: item.start_date, end_date: item.end_date} : {})
        }
    })

    const campaigns = getTable(campaigns_res)

    const mappedLinks = getTable(links)

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }
      
      function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }

      function capitalize(text) {
          return text.charAt(0).toUpperCase() + text.slice(1);
      }
      
      const headCells = [
        { id: 'name', numeric: false, label: 'Title', minWidth: 1240*0.35},
        { id: 'traffic', numeric: false, label: 'Last visit', minWidth: 1240*0.15 },
        { id: 'visits', numeric: true, label: 'Visits', minWidth: 1240*0.1 },
        { id: 'engaged_visitors', numeric: true,  label: 'Engaged visits', minWidth: 1240*0.1 },
        { id: 'engaged_visitors_percentage', numeric: true,  label: '%', minWidth: 1240*0.1 },
        { id: 'leads', numeric: true,  label: props.meta.lead_text, minWidth: 1240*0.1},
        { id: 'leads_percentage', numeric: true, label: '%', minWidth: 1240*0.1},
      ];


      function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };
      
        return (
          <div  className="table__row">
              {headCells.map((headCell, index) => (
                <div
                  className="table__heading is-fixed"
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{textAlign: headCell.numeric && headCell.label != "%"  ? 'right' : 'left', }}
                >
                  {headCell.sort == null ? <>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        style={{color:'rgb(76 76 76)'}}
                        hideSortIcon={true}
                      >
                        
                        {headCell.label}
                      </TableSortLabel>
                    </>
                    :
                    <>{headCell.label}</>
                  }
                </div>
              ))}
           </div>
        );
      }

    const NoFilterHead = (props) => {
        return <div className="table__row">
            {headCells.map((headCell, index) => (
              <div
                className="table__heading"
              >
                  <>{headCell.label}</>
              </div>
            ))}
          </div>
          
    }



    const tableRow = (row, i,  isLinks,isFixed) => {
      
      return <div 
          hover
          className="table__row table__row--link"
          style={{backgroundColor: isFixed ? 'white' : null, cursor: 'pointer'}}
          onClick={event => {
              !isFixed && props.redirect(collationType(), row.id)
          }}
      >
        <div 
            className={`table__cell ${isFixed && "is-fixed-bottom"}`}
        >
          <div style={{display: 'grid', gridTemplateColumns: 'max-content auto max-content', overflow: 'visible'}}>
            <div 
              style={{
                maxWidth: '250px',
              }} 
            >
                {(row.colour || row.icon) && 
                  <div className="channels__icon channels__icon--inline">
                      <span className="key-dot key-dot--over-icon" style={{backgroundColor:row.colour}}></span>
                      { row.icon ? <i className={row.icon}></i> : <i>{row.name && row.name.charAt(0)}</i> }
                  </div>
                }
            </div>
            <div 
              style={{
                whiteSpace: 'nowrap', 
                textOverflow: 'ellipsis', 
                overflow: 'hidden',
                maxWidth: '250px',
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center'
              }} 
            >
              <span title={row.name}>{row.name}</span>
            </div>
            <div style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                  {row.ad_channels && row.ad_channels.map(channel => {
                    return <>
                        {channel == "Google Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-google" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {channel == "Facebook Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-facebook-f" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {channel == "LinkedIn Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-linkedin-in" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {channel == "Microsoft Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-microsoft" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {(channel != "Google Ads" && channel != "Facebook Ads" && channel != "LinkedIn Ads" && channel != "Microsoft Ads") && 
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fas fa-question" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                    </>
                  })}
            </div>
          </div>
        </div>
        <div className={`table__cell ${isFixed && "is-fixed-bottom"}`}>
          
            {row.last_visit ? moment(moment(row.last_visit), "YYYYMMDD").fromNow() : '-'}
            
        </div>

        <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
          <FormatNumber dashZero val={row.visits} />
        </div> 

        <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
          <FormatNumber dashZero val={row.engaged_visitors} />
          
        </div> 
        <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
            <span className="table__cell--with-tag">
                <span className="tag"  style={{backgroundColor: getTagColour(parseInt(row.engaged_visitors_percentage)/100, 'engaged_visitors'), color: 'white'}}> <FormatNumber val={parseInt(row.engaged_visitors_percentage)/100} percent/></span>
            </span>
        </div> 

        <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
            <FormatNumber dashZero val={row.leads} />
        </div>
        <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
            <span className="table__cell--with-tag">
                <span className="tag"  style={{backgroundColor: getTagColour(row.visits == 0  ? 0 : row.leads/row.visits, 'leads'), color: 'white'}}> <FormatNumber val={row.visits == 0  ? 0 : row.leads/row.visits} percent/></span>
              </span>
        </div>
      </div>
    }


    const domain = {
      clicks: [0, 0.45, 0.6],
      bots: [0, .1, .25],
      visitor: [0, 0.6, 0.85],
      engaged_visitors: [0, 0.45, 0.6],
      leads: [0, .01, .02]
    }

    
    const getTagColour = (percent, key) => {
        if (percent >= domain[key][2]) {
          return colours.green;
        } else if (percent >= domain[key][1] && percent < domain[key][2]) {
          return colours.yellow;
        } else if (percent >= domain[key][0] && percent < domain[key][1]) {
          return colours.red;
        } else {
          return colours.red;
        }
    }


    let result =   campaigns.reduce((acc, row) => {
      return {
        'name': 'Total',
        'traffic': '-',
        'impressions': acc.impressions+row.impressions,
        'clicks': acc.clicks+row.clicks,
        'visits': acc.visits+(row.visits||row.visitors||0),
        'visits_percent': 0,
        'bot': acc.bot+row.bot,
        'bot_percent': 0,
        // 'leads': acc.leads+row.leads,
        // 'leads_percent': 0,
        'engaged_visitors': acc.engaged_visitors+row.engaged_visitors,
        'engaged_visitors_percentage':0, 
        'leads': acc.leads+(row.leads || 0),
        'spend': acc.spend+row.spend,
        
        'cpv': 0,
        'cpl': 0,
        'cpc': 0,

      }
    }, {
      'name': 'Total',
      'traffic': '-',
      'impressions': 0,
      'clicks': 0,
      'visits': 0,
      'visits_percent': 0,
      'bot': 0,
      'bot_percent': 0,
      'leads': 0,
      'leads_percentage': 0,
      'engaged_visitors': 0,
      'engaged_visitors_percentage':0, 
      'spend': 0,
      'cpv': 0,
      'cpl': 0,
      'cpc': 0,
      'last_visit': null
    })
    result.visits_percent = result.clicks == 0 ? 0 : result.visits/result.clicks
    result.bot_percent = 50;//result.clicks == 0 ? 0 : (result.bot/result.clicks)*100 
    result.cpv = result.visits == 0 ? 0 : (result.spend /result.visits).toFixed(2)
    result.cpc = result.clicks == 0 ? 0 : (result.spend /result.clicks).toFixed(2)
    result.cpl = result.leads == 0 ? 0 : (result.spend /result.leads).toFixed(2)
    result.engaged_visitors_percentage = result.visits == 0 ? 0 : ((result.engaged_visitors/result.visits)*100).toFixed(2)
    result.leads_percentage = result.visits == 0 ? 0 : ((result.leads /result.visits)*100).toFixed(2)
    
    const downloadToFile = (json, timezone, total, exportType, title) => {
        const process = () => {
            switch (exportType) {
                case "json":
                    return JSON.stringify(json, undefined, 2);
                case "csv":
                        const totalToRow = (obj) => {
                            return [
                                "Total",
                                null,
                                obj.visits,
                                obj.engaged_visitors,
                                obj.engaged_visitors_percentage,
                                obj.leads,
                                obj.leads_percentage,
                            ]
                        }
                        const childType = type.substring(0, type.length - 1)


                        const objToArray = (obj) => {
                            if (collation.id == "daily") {
                                return [
                                  '\"' + obj.name  + '\"',
                                  obj.id,
                                  obj.start_date,
                                  obj.end_date,
                                  obj.visits,
                                  obj.engaged_visitors,
                                  obj.engaged_visitors_percentage,
                                  obj.leads,
                                  obj.leads_percentage,
                              ]
                            } else {
                              return [
                                  '\"' + obj.name  + '\"',
                                  obj.id,
                                  obj.visits,
                                  obj.engaged_visitors,
                                  obj.engaged_visitors_percentage,
                                  obj.leads,
                                  obj.leads_percentage,
                              ]
                            }
                            
                        }
                        const wrapValue = (value) => `${value ?? ""}`;
                        const capType =  capitalize(collationType())
                        return [
                          collation.id == "daily" ?
                            `"${capType} Name","${capType} ID","Start date","End date",Visits,"Engaged visits","Engaged visits percentage",${props.meta.lead_text},"Leads percentage"`
                            : `"${capType} Name","${capType} ID",Visits,"Engaged visits","Engaged visits percentage",${props.meta.lead_text},"Leads percentage"`
                            ,
                            ...Object.entries(json).map(([key, value]) => {
                                return [objToArray(value)]
                            })
                            .concat(total != null ? [totalToRow(total)] : [])
                            .map(row => row.map(wrapValue).join(","))
                        ].join("\n")
                default:
                    return null;
            }
        }
        
        const result = process()
        const name = title
        const blob = new Blob([result], {type: exportType === "json" ? "application/json" :  "text/csv"});
        const link = document.createElement("a");
        link.setAttribute("download",`${name}.${exportType}`);
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.click();
    }

    useEffect(() => { 
      if (exportModal) {
        const unixstr = BeaconRequest.tz_conversion(props.datetime).split(":")
        const name = props.resource.onlyCore.response.name || props.resource.onlyCore.response.title || props.resource.onlyCore.response.notes
        const childType = capitalize(collationType() + "s")
        // option.id == "links" ? "Links" : type.charAt(0).toUpperCase() + type.slice(1)
        // ${childType}
        setExportName(`${name} ${collation.id} analytics export covering ${moment.unix(unixstr[0]).format('DD-MM-YY')} to ${moment.unix(unixstr[1]).format('DD-MM-YY')}`)
      }
    }, [exportModal,collation])

    const closeModal = () => {
      setExportModal(false)
      setCollation({id: "summary", title: 'Summary'})
      setExportFormat({id: "csv", title: 'CSV'})
    }


    

    return <>

        {exportModal && 
          <Modal
            title="Export"
            closeAction={() => closeModal()}
            saveLabel="Export"
            saveAction={() => {
                if (option.id == "links" || option.id == "channels") {
                  if (collation.id == 'daily')
                    downloadToFile(exportFormat.id == "json" ? daily : getTable(daily), null, null, exportFormat.id, exportName)
                  else 
                    downloadToFile(exportFormat.id == "json" ? props.resource.campaigns.response : mappedLinks, null, result, exportFormat.id, exportName)
                } 
                else {
                  if (collation.id == 'daily') {
                    downloadToFile(exportFormat.id == "json" ? daily : getTable(daily), null, null, exportFormat.id, exportName)
                  } else 
                    downloadToFile(exportFormat.id == "json" ? props.resource.campaigns.response : campaigns, null, result, exportFormat.id, exportName)
                }
                closeModal()
            }}
            disableSave={collation.id == "daily" && loadingDaily}
            >
                <ul>
                  <li className="form__item">
                    <label className="form__label">Export name</label>
                    <input 
                      type="text"
                      className="form__input" 
                      value={exportName} 
                      onChange={e => setExportName(e.target.value)} 
                      autoFocus 
                      required 
                    />
                  </li>

                  <li className="form__two-column">
                    <div className="form__item">
                      <label className="form__label">Collation mode</label>
                      <DropdownList 
                          selectItem={selected => {setCollation(selected)}} 
                          menu={{
                            title: collation.title,
                            items: [
                              {id: "summary", title: 'Summary'},
                              {id: "daily", title: 'Daily'},
                            ]
                          }}
                        >
                      </DropdownList>
                    </div>
                    <div className="form__item">
                      <label className="form__label">Export format</label>
                      <DropdownList 
                          selectItem={selected => {setExportFormat(selected)}} 
                          menu={{
                            title: exportFormat.title,
                            items: [
                              {id: "csv", title: 'CSV'},
                              {id: "json", title: 'JSON'},
                            ]
                          }}
                        >
                      </DropdownList>
                    </div>
                  </li>
                </ul>
          </Modal>
        }
        

        <div className="card" style={{padding: '10px'}}>
          {props.filter.type != 'link' &&
            <div style={{zIndex: 11, margin: '10px 0', display: 'grid', gridTemplateColumns: 'max-content auto', borderBottom: '1px solid #E6EBED', backgroundColor: 'white', position:'sticky', top:'99px'}}>
               
                {props.filter.type != 'channel' ?
                  <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                    <span 
                      style={{padding: '10px', borderBottom: option.id == "active" ? '3px solid #ffd200' : null, marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                      onClick={e => setOption({id: "active", title: 'Active ' + type})}
                    >
                      Active {type}
                    </span>

                    {props.filter.type != 'campaign' && 
                      <span 
                        style={{padding: '10px', borderBottom: option.id == "channels" ? '3px solid #ffd200' : null, marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                        onClick={e => setOption({id: "channels", title: 'Channels'})}
                      >
                        Active channels
                      </span>
                    }

                    <span 
                      style={{padding: '10px', borderBottom: option.id == "links" ? '3px solid #ffd200' : null, marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                      onClick={e => setOption({id: "links", title: 'Links'})}
                    >
                      Active links
                    </span>
              
                    
                  </div>
                  :
                  <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                    <span 
                      style={{padding: '10px', borderBottom: '3px solid #ffd200', marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                    >
                      Active links
                    </span>
                  </div>
                }
                  <div style={{marginLeft: 'auto', marginTop: '7px'}}>
                    <button className="button button--green" 
                     onClick={e => {
                      setExportModal(true)
                    }} ><i class="fas fa-download" style={{marginRight: '5px'}}></i> Export this table</button>

                  </div>
            </div>
          }

          <div>
            <div className="table">
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(event, property) => {
                      if (property == orderBy) {
                        const isAsc = orderBy === property && order === 'asc';
                        setOrder(isAsc ? 'desc' : 'asc');
                      } else {
                        setOrder('desc');
                      }
                      setOrderBy(property);
                    }}
                    
                    rowCount={option.id == "active" ? campaigns.length : mappedLinks.length}
                  />
                
                    {option.id == "active" ? 
                      stableSort(campaigns, getComparator(order, orderBy))
                        .map(tableRow)
                      : 
                      stableSort(mappedLinks, getComparator(order, orderBy))
                        .map(tableRow)
                    }
                  
                  {tableRow(result, campaigns.length + 1, null, true)}
            </div>
          </div>
        </div>
      
    </>
}

export default redirectRouter(CampaignsList)


