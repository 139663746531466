import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setFirstLogin } from '../../actions/customers'
import { setFirstDashboard } from '../../actions/login'

class AddCode extends Component {
	constructor(){
		super();
		this.state = {
			isOpen: false
		}
		this.handleToggleModal = this.handleToggleModal.bind(this)
		this.handleFirstLogin = this.handleFirstLogin.bind(this)
	}

	handleToggleModal(){
		this.setState({ 
			isOpen: !this.state.isOpen
		})
	}

	handleFirstLogin(){
		this.props.setFirstLogin(1)
		this.props.setFirstDashboard(1)
	}

	render() {
		return (
			<div className="first-login__slide">
				<div className="first-login__image">
					<img 
						src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/newOnboarding/Onboarding_4.png" 
						style={{objectFit: 'contain', width: '100%', height: '100%'}}
					/>
				</div>
				<div className="first-login__content">
					<h1 className="first-login__title">Install the tag</h1>
					<p className="margin-large">When you drive visitors to the website with the Beacon insights tag installed something pretty nifty happens – we analyse and report all visitor behaviour on your website, giving you the knowledge to attribute website actions to campaigns.</p>
					<a href="https://go.veracitytrustnetwork.com/resource/add-afp-detection-tag/" target="_blank" rel="noopener noreferrer">Add your Ad Fraud Protection tag</a>
					<div className="first-login__actions">
						<button className="button button--transparent" onClick={this.handleFirstLogin}>I'll do this later</button>
						<button className="button button--yellow" onClick={this.handleFirstLogin}>I've added my code</button>
					</div>
				</div>
			</div>
		)
	}
}


const mapStateToProps = state => {
	return {
		website: state.websites.selectedWebsiteName,
		userId: state.login.currentUser.user_id,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setFirstDashboard: (flag) => dispatch(setFirstDashboard(flag)),
		setFirstLogin: (flag) => dispatch(setFirstLogin(flag))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCode);