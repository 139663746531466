  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

const createAnalyticsListResource = ({filter, timePeriod, paging, disableCache, cancelToken}) => {
  
    let query
    let rating_query
    let qs
      switch (filter.type) {
          case "website":
          default: 
              query = `website/${filter.payload.id}/ad/campaigns/${timePeriod}`
              break;
          case "campaign":
              query = `campaign/${filter.payload.id}/ad/channels/${timePeriod}`
              break;
          case "channel":
              query = `channel/${filter.payload.id}/ad/links/${timePeriod}`
              break
    }

    return {
        campaigns: [query, null, {cancelToken: cancelToken.token}],
        onlyCore: [`${filter.type}/${filter.payload.id}`, {__onlyCore: 1}, {cancelToken: cancelToken.token}]
    }
}

export const setAnalyticsListResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_ANALYTICS_LIST_FETCHING,
            fetching: true
        })

        const previousToken = getState().analyticsList.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("media spend request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_ANALYTICS_LIST_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createAnalyticsListResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_ANALYTICS_LIST_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_ANALYTICS_LIST_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_ANALYTICS_LIST_FETCHING,
                        fetching: false
                    })
                }
            })
    }
}