import React from 'react'
import HelpContainer from '../../containers/general/HelpContainer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Toggle from '../../components/general/Toggle'
import moment from 'moment-timezone'

const CreateWebsite = props => {
	const currencies = props.supportedCurrencies.map(currency => {
		return (
			<option 
			key={currency.currency_id} 
			value={currency.code}
			dangerouslySetInnerHTML={ { __html: `${currency.symbol} (${currency.code})` } }>
			</option>
		)
	})
	return (
		<ul>
			<li className="form__item">
				<label className="form__label">Website URL</label>
				<input type="text" className="form__input" value={props.websiteUrl} placeholder="https://" onChange={props.handleChangeWebsiteUrl} autoFocus required />
			</li>
			<div style={{display:"grid",gridTemplateColumns:"auto max-content", gridGap:10}}>
				<li className="form__item">
					<label className="form__label">Website Name</label>
					<input type="text" className="form__input" value={props.websiteName} onChange={props.handleChangeWebsiteName} />
				</li>
				<li className="form__item">
					<div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
						<label className="form__label">Website Currency</label>
						<div><HelpContainer id={19} /></div>
					</div>
					<div className="select-container" style={{width:"100%"}}>
						<select className="select" onChange={props.handleChangeWebsiteCurrency}>{currencies}</select>
					</div>
				</li>
			</div>
			<div style={{display:"grid",gridTemplateColumns:"auto auto", gridGap:10}}>
				<li className="form__item">
					<div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
						<label className="form__label">Leads/Other Outcomes name</label>
						<div><HelpContainer id={21} /></div>
					</div>
					
					<input type="text" className="form__input" value={props.leadsText} placeholder="Leads" onChange={props.handleChangeLeadsText} autoFocus required />
				</li>
				<li className="form__item">
					<div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
						<label className="form__label">Time zone</label>
						<div><HelpContainer id={22} /></div>
					</div>
					<Autocomplete
						id="combo-box-demo"
						options={moment.tz.names()}
						getOptionLabel={(option) => option}
						defaultValue={props.timezone}
						inputValue={props.timezone}
						onInputChange={(event, newInputValue) => {
							props.handleTimezoneChange(newInputValue)
						}}
						renderInput={(params) => (
							<div style={{width: '100%'}} ref={params.InputProps.ref}>
								<input {...params.inputProps} type="text" className="form__input"  />
							</div>
						)}
					/>
					
					
				</li>
			</div>
		</ul>
	)
}

export default CreateWebsite