import BeaconRequest from '../BeaconRequest';

export const fetchLeadsByCampaign = (campaign_id, start, end) => {
	return (dispatch) => {
		return BeaconRequest.get('campaign/' + campaign_id + `/leads/${start}:${end}`, {})
		.then(responseObj => {
            return responseObj
        })
        .catch(error => { console.log(error) })
	}
}

export const fetchLeadsByChannel = (channel_id, start, end) => {
	return (dispatch) => {
		return BeaconRequest.get('channel/' + channel_id + `/leads/${start}:${end}`, {})
		.then(responseObj => {
            return responseObj
        })
        .catch(error => { console.log(error) })
	}
}

export const fetchLeadsByLink = (link_id, start, end) => {
	return (dispatch) => {
		return BeaconRequest.get('link/' + link_id + `/leads/${start}:${end}`, {})
		.then(responseObj => {
            return responseObj
        })
        .catch(error => { console.log(error) })
	}
}

export const fetchLeadsByWebsite = (website_id) => {
	return (dispatch, getState) => {
		return BeaconRequest.get('website/' + website_id + '/leads/' + getState().dates.selectedDateValue, {})
		.then(responseObj => {
            return responseObj
        })
        .catch(error => { console.log(error) })
	}
}