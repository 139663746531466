import React, {useState, useEffect, useContext, useCallback} from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import moment from 'moment-timezone'
import BeaconRequest from '../../BeaconRequest'
import LoaderAlt from '../general/LoaderAlt'
import { connect } from 'react-redux';
import { addNotification } from '../../actions/ui'
import Toggle from '../general/Toggle'
import {Beacon_reporting} from '../../API'
import Modal from '../general/Modal'
import ScheduledReport from './ScheduledReport'
import DateFilter from "../date/DateFilter";
import StandaloneDateRangeSelect from "../date/StandaloneDateRangeSelect";
import DropdownList from "../general/DropdownList";
import { fetchCampaignChannels } from '../../actions/channels';
import Base64Binary from '../../utils/base64binary'
import { redirectRouter } from '../../organisms/WidgetContainer/DropdownContainer';

const Paging = (props) => {
	return  <div className="filters__content" style={{gridTemplateColumns: 'auto max-content', marginTop: '10px', marginBottom: '10px'}}>
        <div>
            {props.title && props.title}
        </div>
        <ul className="paging">
            <li>Showing <strong>{props.paging.showingFrom} - {props.paging.showingTo}</strong> of <strong>{props.paging.totalResults}</strong> records</li>
            { 
                props.paging.previous && 
                <li 
                className="button button--dark-grey hollow" 
                onClick={() => props.handlePaging(props.paging.previous)}>
                <i className="fas fa-caret-left"></i>
                </li> 
            }
            {
                props.paging.next && 
                <li className="button button--dark-grey hollow" 
                onClick={() => props.handlePaging(props.paging.next)}>
                <i className="fas fa-caret-right"></i>
                </li> 
            }
	</ul></div>
}


const Table = ({title, paging, refresh, headings, body, fetch_paged}) => {
    return <>
        <Paging handlePaging={fetch_paged} paging={paging}
        title={
            <h2 className="section-title">
                {/* {title} */}
                <button 
                aria-label="Refresh report list"
                className="button button--small button--light-grey"
                onClick={refresh}>
                    <i title="Refresh list" className="fas fa-sync-alt"></i>
                </button>
            </h2>
        }
        />
        {body.length == 0 ?
            <div className="message message--notice">
                <p>There are no results</p>
            </div>
        :
        <div className="table">
            <div className="table__row">
                {headings.map(key => {
                    return <div className="table__heading">{key.label}</div>
                })}
            </div>
            {body.map((row, i) => {
                return <div key={i} className="table__row">
                    {headings.map(key => {
                        return <div className="table__cell">
                            {row[key.id]}
                        </div>  
                    })}
                </div>
            })}
        </div>
        }
        
        <Paging handlePaging={fetch_paged} paging={paging}/>
    </>
}

const useTableFunction = (props) => {
    const [response, setResponse] = useState([])
    const [paging, setPaging] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetch_report = () => {
        setLoading(true);
        setError(false)
        BeaconRequest.getNoChache(props.query, {limit: props.limit, ...props.querystring}).then(e => {
            setLoading(false); setResponse(e.response); setPaging(e.paging)
        })
        .catch(e => setError(true))
    }
    
    const fetch_paged = (query) => {
        setLoading(true);
        setError(false)
        BeaconRequest.paging(query).then(e => {
            setResponse(e.response); setPaging(e.paging); setLoading(false); 
        })
        .catch(e => setError(true))
    }
    
    useEffect(() => {
        fetch_report(props.limit)
    }, [props.query])

    return {
        loading, 
        paging,
        response, 
        fetch_report,
        fetch_paged,
        error,
    }
}


const mapStateToPropsTables = (state) => {
	return {
        tables: state.tables
	}
}

const mapDispatchToPropsTables = (dispatch) => {
    return {
        init: (title, payload) => dispatch({
            type: 'SET_TABLE',
            title,
            payload,
        })
    }
}

const TableWrapper = connect(mapStateToPropsTables, mapDispatchToPropsTables)((props) => {
    const limit = 12, start = 0

    const tableFunctions = useTableFunction({limit, website: props.websiteId, query: props.query, querystring: props.querystring})
    const {
        loading, 
        paging,
        response, 
        fetch_report,
        fetch_paged,
        error
    } = tableFunctions

    const refresh = () => fetch_report(limit)

    useEffect(() => {
        props.init(props.title,{refresh}) //need to redo
    }, [])

    if (error) return <ul>
        <li className="form__item">
            <div className="message message--warning margin-large">
                <p class="margin-small">There was a problem with fetching your reports, if the error persists please <a href="mailto:support@thisisbeacon.com">contact support</a>.</p>
            </div>
        </li>
    </ul>

    if (loading) return <LoaderAlt />

    // if (response.length === 0) {
    //     return <div className="message message--notice">
    //         <p>There are no reports available, to schedule or create a report click the buttons above.</p>
    //     </div>
    // } else {
        const properties = {
            title: props.title, 
            body: props.body(response, refresh, tableFunctions), 
            refresh: refresh, 
            headings: props.headings, 
            paging, 
            fetch_paged
        }

        return <div>
            <Table  {...properties}/>
        </div>
    // }
})


connect(mapStateToPropsTables, null)(TableWrapper)


const Actions = (props) => {
    return props.completed ? <div
        style={{
            display:"grid", 
            gridTemplateColumns:"max-content max-content max-content max-content max-content", 
            gridGap:8, 
            alignItems:"center"
        }}
    >
        {
            props.actions.map(action => {
                return <>
                {
                    action.display && <button
                        type="button"
                        title={action.title}
                        className={`button button--${action.colour}`}
                        onClick={action.onClick}
                    >
                        <i className={action.icon}/>
                        {action.name && <span style={{marginLeft: '10px', fontWeight: '700'}}>{action.name}</span>}
                    </button>
                }
                </>
            })
        }
    </div> : <em>Report is being created</em>
}


const ChangePassword = (props) => {
    const [password, setPassword] = useState("")

    return <>{ props.report && <Modal
                title={'Reset Password'}
                closeAction={() => { 
                    props.setEditModal(null)
                }}
                saveAction={e => {
                    BeaconRequest.post('reporting/' + props.website + '/' + props.report.report_id + '/access', {} , BeaconRequest.encryptPayload({
                        "report_id": props.report.report_id,
                        "password": password,
                        "note": ""
                    }))
                    .then(responseObj => {
                        props.addNotification({
                            title:"Password reset for report  '" + props.report.name + "'",
                            theme:"positive"
                        })

                        setPassword("")
                        props.setEditModal(null)
                    })
                    .catch(error => {
                        console.log(error)
                        alert("Unable to reset password, please try again. If the error persists please contact support.")
                    })
                }}
            >
                <h4 className="text-medium margin-small" >Enter a new password</h4>
                <div className="login__content">
                    <div className="form__item">
                        <input 
                            onChange={e => setPassword(e.target.value)}
                            autoFocus
                            type="password" 
                            className="form__input" 
                        />
                    </div>
                </div>
            </Modal>
    }</>
}

const CampaignReport = (props) => {
    const [editModal, setEditModal] = useState(null)

    const headings = [
        {id: 'name', label: 'Name'},
        {id: 'created', label: 'Date Created'},
        {id: 'type', label: 'Source'},
        {id: 'actions', label: 'Actions'}
    ]

    const actions = (row, refresh) => [
        {
            title: "Download Report",
            colour: "green",
            onClick: e => {
                const error_payload = {
                    title: `Unable to download report ${row.name}`,
                    theme: "negative"
                }
                props.addNotification({
                    title: `Downloading ${row.name}`,
                    theme: "positive"
                })

                BeaconRequest.post('reporting/' + row.report_id + '/getpdf', {}, BeaconRequest.encryptPayload({
                    report_id: parseInt(row.report_id),
                    password: ''
                }), {}, false, false)
                .then(tmp => {
                    let dl = document.createElement("a");
                    dl.setAttribute("download", true);
                    dl.setAttribute("target", "_blank");
                    dl.setAttribute("href", tmp.response)
                    dl.click();
                })
                .catch(error => {
                    console.log(error)
                    props.addNotification(error_payload)
                });

            },
            icon: "fas fa-download",
            display: true,
        },
        {
            title: "View report",
            colour: "green",
            onClick: e => {window.open(Beacon_reporting + "/report/" + row.report_id)},
            icon: "fas fa-external-link-alt",
            display: true,
        },
        {
            title: "Copy Link to clipboard",
            colour: "light-grey",
            onClick: e => {
                var text = Beacon_reporting + "/report/" + row.report_id;
                navigator.clipboard.writeText(text).then(function() {
                    props.addNotification({
                        title: `Copied link to clipboard`,
                        theme: "positive"
                    })
                }, function(err) {
                    props.addNotification({
                        title: `Unable to copy link`,
                        theme: "negative"
                    })
                });
            },
            icon: "fas fa-copy",
            display: true,
        },
        {
            title: "Change password",
            colour: "light-grey",
            onClick: e => {
                setEditModal(row)
            },
            icon: "fas fa-key",
            display: props.role_id !== 4,
        },
        {
            title: "Delete report",
            colour: "red",
            onClick: e => {
                let confirm = window.confirm("Are you sure you want to delete this report? This cannot be undone.")
                if(confirm){
                    BeaconRequest.delete('reporting/' + props.websiteId + '/' + row.report_id)
                    .then(responseObj => {
                        props.addNotification({
                            title:"Report successfully deleted - " + row.name,
                            theme:"positive"
                        })

                        refresh()
                    })
                    .catch(error => {
                        props.addNotification({
                            title:"Unable to delete report " + row.name,
                            theme:"negative"
                        })
                    })
                }
            },
            icon: "fas fa-times",
            display: props.role_id !== 4,
        },
    ]

    const body = (response, refresh) => response.map(row => {
        return {
            name: row.name, 
            created: moment(row.created).format('DD MMM YYYY, h:mma'),
            type: row.reportType,
            actions: <Actions actions={actions(row, refresh)} completed={row.completed}/>
        }
    })

    
    return  <>
        <ChangePassword report={editModal} setEditModal={setEditModal} website={props.websiteId} addNotification={props.addNotification}/>
        <TableWrapper query={`reporting/${props.websiteId}`} headings={headings} body={body} title={'Campaign Reports'} />
    </>
}

const ScheduledReportList = redirectRouter((props) => {
    const [selectedEdit, setSelectedEdit] = useState(null)

    const headings = [
        {id: 'campaign', label: 'Campaign'},
        {id: 'type', label: 'Frequency'},
        {id: 'status', label: 'Status'},
        {id: 'due', label: 'Next due date'},
        {id: 'actions', label: 'Actions'},
    ]


    const actions = (row, refresh) => [
        {
            title: "Edit",
            colour: "light-grey",
            onClick: e => {
                setSelectedEdit(row)
            },
            icon: "fas fa-edit",
            display: props.role_id !== 4,
        },
        {
            title: row.running == 1 ? "Pause" : "Start",
            colour: "light-grey",
            onClick: e => {
                BeaconRequest.post(`reportscheduler/${row.schedule_id}/${row.running == 1 ? "pause" : "resume"}`, {}, {})
                .then(responseObj => {
                    props.addNotification({
                        title: (row.running == 1 ? "Paused scheduled report for campaign " : "Resumed scheduled report for campaign ") + row.campaign_id,
                        theme:"positive"
                    })

                    refresh()
                })
                .catch(error => {
                    props.addNotification({
                        title:"Unable to pause/resume the scheduled report for campaign " + row.campaign_id,
                        theme:"negative"
                    })
                })
            },
            icon: row.running == 1 ? "fas fa-pause" : "fas fa-play",
            display: props.role_id !== 4,
        },
        {
            title: "Delete report",
            colour: "red",
            onClick: e => {
                let confirm = window.confirm("Are you sure you want to cancel this scheduled report?")
                if(confirm){
                    BeaconRequest.delete('reportscheduler/' + row.schedule_id)
                    .then(responseObj => {
                        props.addNotification({
                            title:"Successfully cancelled scheduled report for campaign " + row.campaign_id,
                            theme:"positive"
                        })

                        refresh()
                    })
                    .catch(error => {
                        props.addNotification({
                            title:"Unable to cancel schedule report for campaign " + row.campaign_id,
                            theme:"negative"
                        })
                    })
                }
            },
            icon: "fas fa-times",
            display: props.role_id !== 4,
        },
    ]

    const format_type = (frequency) => {
        switch (frequency) {
            case "CampaignEnd":
                return "On campaign end date"
            case "Single":
                return "Once"
            case "Week": 
                return "Every week"
            case "Day":
                return 'Every day'
            case "Fortnight":
                return 'Every fortnight'
            case "4Weekly":
                return "Every 4 weeks"
            case "Monthly":
                return "Last calendar month" 
            default:
                return ""
        }
    }

    const body = (response, refresh) => response.map(row => {
        return {
            campaign: <a onClick={e => props.redirect('campaign', row.__campaign.campaign_id, null, '/')} style={{textDecoration: 'underline', cursor: 'pointer'}}>{row.__campaign.title}</a>,
            type: format_type(row.frequency),
            status: row.running == 1 ? 'running' : 'stopped',
            due: row.nextDue ? moment(row.nextDue).tz(row.timezone).format('DD MMM YYYY, h:mma (z)') : moment.unix(row.__campaign.endDate).format("DD MMM YYYY, h:mma  (z)"),
            actions: <Actions actions={actions(row, refresh)} completed={true}/>
        }
    })


    return  <>
        {selectedEdit &&
            <Modal
                title={"Edit: Schedule a report"}
                closeAction={() => setSelectedEdit(null)}
                disableSave="true"
            >
                <ScheduledReport  edit={selectedEdit} close={() => setSelectedEdit(null)}/>
            </Modal>
        }
        <TableWrapper 
            query={`reportscheduler/website/${props.websiteId}`} 
            querystring={{order: "nextDue:asc"}} 
            headings={headings} 
            body={body}
            title={'Scheduled Reports'} 
            refresh={props.refresh}
        />
    </>

})

export const customPeriods = [
    // { label: "Yesterday", value: 'yesterday', days: 1 },
    { label: "Previous 2 days", value: 'previous2days', days: 2 },
    // { label: "Previous 3 days", value: 'previous3days', days: 3 },
    { label: "Previous 7 days", value: 'previous7days', days: 7 },
    { label: "Previous 14 days", value: 'previous14days', days: 14 },
    { label: "Previous 30 days", value: 'previous30days', days: 30 },
    { label: "Custom...", value: 'custom' },
];
const filterModes = [
    { title: "Website", id: "website" },
    { title: "Campaign", id: "campaign" },
    { title: "Channel", id: "channel" },
];
const groupByModes = [
    { title: "Campaign", id: "campaign" },
    { title: "Channel", id: "channel" },
    { title: "Link", id: "link" },
]
const collationModes = [
    { title: "Daily", id: "daily" },
    { title: "3 Daily", id: "3day" },
    { title: "Weekly", id: "7day" },
    { title: "Monthly", id: "monthly" },
    { title: "Summary", id: "summary" },
]
const outputModes = [
    { title: "CSV", id: "csv"},
    { title: "JSON", id: "json"},
];

const getTimePeriod = (timezone, selectedDate) => {
    // if (!selectedDate.includes(":")) {
        return [
            moment.unix(selectedDate.split(":")[0]).tz(timezone, true),
            moment.unix(selectedDate.split(":")[1]).tz(timezone, true),
        ]
    // } else {
    //     return [
    //         moment().startOf("day")
    //             .subtract(customPeriods.find(p => p.value === selectedDate.id).days, "days")
    //             .tz(timezone, true),
    //         moment().subtract(1, "day").endOf("day").tz(timezone, true),
    //     ];
    // }
}


const getTimePeriodCreate = (timezone, selectedDate) => {
    if ("from" in selectedDate && "to" in selectedDate) {
        return [
            selectedDate.from.clone().tz(timezone, true),
            selectedDate.to.clone().tz(timezone, true),
        ]
    } else {
        return [
            moment().startOf("day")
                .subtract(customPeriods.find(p => p.value === selectedDate.id).days, "days")
                .tz(timezone, true),
            moment().subtract(1, "day").endOf("day").tz(timezone, true),
        ];
    }
}

const timeAndRowToArray = (startTimeString, endTimeString, hasDescriptions) => ({details, results}) => ([
    details.name,
    details.id,
    ...(hasDescriptions ? [details.description] : []),
    startTimeString,
    endTimeString,
    results.impressions,
    results.clicks,
    results.visitors,
    results.leads,
    results.botsNumber,
    results.botsPercentage,
    results.totalSpend,
    results.cpc,
    results.cpv,
    results.cpm,
    results.cpl,
])

const wrapValue = (value) => `"${value ?? ""}"`;

const rowToArrayAndRowToString = (rowToArray) => (row) => rowToArray(row).map(wrapValue).join(",");

const downloadFile = (json, timezone, row, exportType) => {
    const [startTime, endTime] = getTimePeriod(timezone, row.timePeriod);
    const timePeriodString = startTime.clone().utc().unix() + ":" + endTime.clone().utc().unix();
    const startTimeString = startTime.format("D MMM YYYY");
    const endTimeString = endTime.format("D MMM YYYY");
    
    const process = () => {
        switch (exportType) {
            case "json":
                return JSON.stringify(json, undefined, 2);
            case "csv":
                    const hasDescriptions = typeof Object.values(json.result.divisions)[0].details?.description === "string";

                    const totalToRow = (results) => {
                        return [
                            "Total",
                            null,
                            ...(hasDescriptions ? [null] : []),
                            startTimeString,
                            endTimeString,
                            results.impressions,
                            results.clicks,
                            results.visitors,
                            results.leads,
                            results.botsNumber,
                            results.botsPercentage,
                            results.totalSpend,
                            results.cpc,
                            results.cpv,
                            results.cpm,
                            results.cpl,
                        ]
                    }
    
                    return [
                        `"${row.groupBy} Name","${row.groupBy} ID",${hasDescriptions ? "Description," : ""}"Start Date","End Date",Impressions,Clicks,Visitors,Leads,Bots,"Bot Percentage",Spend,CPC,CPV,CPM,CPL`,
                        ...Object.entries(json.result.divisions).reduce((acc, [date, res]) => {
                            
                            let rowToArray;
                            let map = res
                            if (row.collation == "summary") {
                                rowToArray = timeAndRowToArray(startTimeString, endTimeString, hasDescriptions)
                                map = [res]
                            }
                            else {
                                rowToArray = timeAndRowToArray(...date.split(" - "), hasDescriptions)
                            }
                            return [...acc, ...[...map].map(rowToArray)]
                        }, [])
                        .concat([totalToRow(json.result.total)])
                        .sort((a, b) => (a[1] ?? 1000000) - (b[1] ?? 1000000)).map(row => row.map(wrapValue).join(","))
                    ].join("\n")
            default:
                return null;
        }
    }
    
    const result = process()
    const startTimeStr = startTimeString;
    const endTimeStr = endTimeString;
    // typeof selectedCampaign.id === "undefined" ? websiteName : selectedCampaign.title
    const name = (row.name)
        + ` Export ${startTimeStr}`
        + (startTimeStr === endTimeStr ? "" : ` -  ${endTimeStr}`);
        // selectedOutput.id === "json" ? "application/json" : 
    const blob = new Blob([result], {type: exportType === "json" ? "application/json" :  "text/csv"});
    const link = document.createElement("a");
    // selectedOutput.id
    link.setAttribute("download",`${name}.${exportType}`);
    link.setAttribute("href", window.URL.createObjectURL(blob));
    link.click();
}

export const createExport = (websiteId, websiteName, timezone, selectedDate, selectedFilter, selectedCampaign, selectedChannel, selectedOutput, selectedGroup, selectedCollation, setLoading, callback) => {
    
    // function getModelString() {
    //     switch (selectedFilter.id) {
    //         case "website":
    //             return `website/${websiteId}`
    //         case "campaign":
    //             return `campaign/${selectedCampaign.id}`
    //         case "channel":
    //             return `channel/${selectedChannel.id}`
    //         default:
    //             throw new Error("Unrecognized model.")
    //     }
    // }
    // setLoading(true);
    
    const [startTime, endTime] = getTimePeriodCreate(timezone, selectedDate);
    const timePeriodString = startTime.clone().utc().unix() + ":" + endTime.clone().utc().unix();
    // const startTimeString = startTime.format("D MMM YYYY");
    // const endTimeString = endTime.format("D MMM YYYY");


    function getFilterID() {
        switch (selectedFilter.id) {
            case "website":
                return websiteId
            case "campaign":
                return selectedCampaign.id
            case "channel":
                return selectedChannel.id
            default:
                throw new Error("Unrecognized model.")
        }
    }

    BeaconRequest.post(`export/new`, {}, {
        user_id: 550,
        website_id: websiteId,
        modelType: selectedFilter.id,
        model_id: getFilterID(),
        timePeriod: timePeriodString,
        groupBy: selectedGroup.id,
        collation: selectedCollation.id
    }).then(res => {
        callback()
    })
    .catch(err => {
        callback(err)
    })
    .finally(() => {
        setLoading(false);
    });
}

export const PerformanceExport = (props) => {
    const [selectedFilter, setSelectedFilter] = useState(filterModes[0]);
    const [selectedGroup, setSelectedGroup] = useState(groupByModes[0]);
    const [selectedCollation, setSelectedCollation] = useState(collationModes[0]);
    const [selectedOutput, setSelectedOutput] = useState(outputModes[0]);
    const [selectedDate, setSelectedDate] = useState({title: customPeriods[0].label, id: customPeriods[0].value});
    const [rangeSelectorOpen, setRangeSelectorOpen] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const [campaignChannels, setCampaignChannels] = useState([]);
    const [activeCampaigns, setActiveCampaigns] = useState([])

    useEffect(() => {
        BeaconRequest.get('website/'+ props.websiteId + '/campaigns/withTraffic/1:' + moment().unix(), {__onlyCore: 1})
            .then(e => setActiveCampaigns(e.response))

        props.selectedFilter && setSelectedFilter(props.selectedFilter)
        props.selectedGroup && setSelectedGroup(props.selectedGroup)
        props.selectedDate && setSelectedDate(props.selectedDate)
        props.selectedCampaign && setSelectedCampaign({id: props.selectedCampaign.campaign_id, title: props.selectedCampaign.title})
        props.selectedChannel && setSelectedChannel({id: props.selectedChannel.channel_id, title: props.selectedChannel.title})
    }, [])

    useEffect(() => {
        fetchChannels(selectedCampaign)
    }, [props.timezone, selectedCampaign, selectedDate])
  
    const fetchChannels = (selectedCampaign) => {
        if (selectedCampaign && selectedCampaign.id) {
            const [startTime, endTime] = getTimePeriodCreate(props.timezone, selectedDate);
            const timePeriodString = startTime.clone().utc().unix() + ":" + endTime.clone().utc().unix();

            BeaconRequest.get(
                `campaign/${selectedCampaign.id}/channel/analytics/${timePeriodString}`
            ).then(responseObj => {
                setCampaignChannels(responseObj.response.__channels)
            }).catch(error => { 
                console.log(error) 
            })
        }
    }

    useEffect(() => {
        setSelectedGroup(groupByModes[selectedFilter.id === "channel" ? 1 : 0])
    }, [selectedFilter.id])

    return (
        <div className={props.modal ? null : "card"} style={{width: props.modal ? "100%" :"50%", }}>
            <div className="card__content" style={{display: "flex", flexDirection: "column"}}>
                <StandaloneDateRangeSelect
                    isOpen={rangeSelectorOpen}
                    toggleDateRangeSelect={() => setRangeSelectorOpen(false)}
                    setSelectedDateRange={dateRange => {
                        setSelectedDate(dateRange);
                    }}
                    dateRange={selectedDate}
                    maxDate={moment().subtract(1, "day").toDate()}
                />
                <label>Export For</label>
                <DropdownList
                    selectedItem={selectedFilter}
                    selectItem={setSelectedFilter}
                    menu={{title: selectedFilter.title, items: filterModes}}
                />
                <div style={{height: "1rem"}} />
                {(selectedFilter.id === "campaign" || selectedFilter.id === "channel") && (
                    <>
                        <label>Campaign</label>
                        <DropdownList
                            selectedItem={selectedCampaign != null ? selectedCampaign.id : ""}
                            selectItem={(campaign) => {
                                setCampaignChannels([])
                                setSelectedChannel(null)
                                setSelectedCampaign(campaign)
                            }}
                            clearSelectedItem={() => {
                                setSelectedCampaign(null)
                            }}
                            menu={{title: selectedCampaign && selectedCampaign.title ? selectedCampaign.title : "Select a campaign", items: activeCampaigns.map(c => {return {id: c.campaign_id, title: c.title, colour: c.colour}})}}
                            isClearable={true}
                        />
                        <div style={{height: "1rem"}} />
                        {(selectedFilter.id === "channel") && (
                            <>
                                <label>Channel</label>
                                <DropdownList
                                    selectedItem={selectedChannel != null ? selectedChannel.id : ""}
                                    selectItem={setSelectedChannel}
                                    clearSelectedItem={() => setSelectedChannel(null)}
                                    menu={{title: selectedChannel && selectedChannel.title ? selectedChannel.title : "Select a channel", items: campaignChannels.map(c => {return {id: c.campaign_id, title: c.title, channel_icon: c.icon, colour: c.colour}})}}
                                    isClearable={true}
                                    style={{width: "100%"}}
                                    // isDisabled={campaignChannels.length === 0}
                                />

                                <div style={{height: "1rem"}} />
                            </>
                        )}
                        <label>Group By</label>
                        <DropdownList
                            selectedItem={selectedGroup}
                            selectItem={setSelectedGroup}
                            menu={{title: selectedGroup.title, items: groupByModes.slice(selectedFilter.id === "campaign" ? 0 : 1)}}
                        />
                        <div style={{height: "1rem"}} />
                    </>
                )}
                <label>Date Range</label>
                <DateFilter
                    selectedDateValue={selectedDate.id}
                    selectedDateName={selectedDate.title}
                    clearSelectedDate={() => setSelectedDate(null)}
                    periods={customPeriods}
                    setSelectedDate={(selected) => {
                        
                        if(selected.id === "custom") {
                            setRangeSelectorOpen(true);
                        } else {
                            setSelectedDate(selected);
                        }
                    }}
                />
                <div style={{height: "1rem"}} />
                <label>Collation Mode</label>
                <DropdownList
                    selectedItem={selectedCollation}
                    selectItem={setSelectedCollation}
                    menu={{title: selectedCollation.title, items: collationModes}}
                />
                <div style={{height: "1rem"}} />
                {/* <label>Output Format</label>
                <DropdownList
                    selectedItem={selectedOutput}
                    selectItem={setSelectedOutput}
                    menu={{title: selectedOutput.title, items: outputModes}}
                />
                <div style={{height: "1rem"}} />  */}

                {error && <div className="message message--warning margin-small">Unable to create export, please try again. If the error persist please create a support ticket or contact support.</div>}
                
                <button
                    className="button button--green"
                    disabled={
                        selectedDate.id === "custom"
                        || rangeSelectorOpen
                        || loading
                        || (selectedFilter.id === "campaign" && selectedCampaign == null)
                        || (selectedFilter.id === "channel" && (selectedCampaign == null || selectedChannel == null))
                    }
                    onClick={() => {
                        setError(false); 
                        createExport(
                        props.websiteId, props.websiteName, props.timezone,
                        selectedDate, selectedFilter, selectedCampaign, selectedChannel, selectedOutput, selectedGroup, selectedCollation, setLoading, (e) => {
                           console.log(e)
                            if (e == null) {
                                props.callback()
                            } else {
                                setError(true)
                            }
                            
                        }
                        )
                    }}
                >
                    {loading ? "Generating..." : "Generate Export"}
                </button>
            </div>
        </div>
    )
}

const ReportList = (props) => {
    const [tab, setTab] = useState(0)
   
    useEffect(() => {
        setTab(props.tab)
    }, [props.tab])

    return <div>
        <Tabs defaultIndex={tab} selectedIndex={tab} onSelect={(index, lastIndex, event) => {setTab(index)}}>
            <div style={{}}>
                <div style={{padding: '10px'}}>
                    <TabPanel >
                        <CampaignReport {...props} />
                    </TabPanel>
                    <TabPanel >
                        <ScheduledReportList {...props}/>
                    </TabPanel>
                </div>
            </div>
            
        </Tabs>
    </div>
}

const mapStateToProps = (state) => {
	return {
        websiteMeta: state.websites.selectedWebsite.__meta,
        timezone: state.websites.selectedWebsite.timezone,
        websiteId: state.websites.selectedWebsite.website_id,
        showDataExport: state.websites.selectedWebsite.showDataExport,
        websiteName: state.websites.selectedWebsite.name,
        activeCampaigns: state.campaigns.activeCampaigns,
        currentUser: state.login.currentUser
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		addNotification: (payload) => dispatch(addNotification(payload)),
		fetchCampaignChannels: (campaign_id) => dispatch(fetchCampaignChannels(campaign_id)),
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportList)
