import React, { Component } from 'react';
import { connect } from 'react-redux';
import BeaconRequest from '../../BeaconRequest';
// import ToolTip from '../general/ToolTip';
import { removeUserFromTeam, fetchTeamUsers, updateUser } from '../../actions/teams'
import AddTeamMemberContainer from '../../containers/forms/AddTeamMemberContainer';
import Toggle from '../general/Toggle';

class CustomerTeam extends Component {
	constructor(){
		super()
		this.state = {
			teams: []
		}
		this.handleRemoveUser = this.handleRemoveUser.bind(this)
		this.handleToggleReadOnlyUser = this.handleToggleReadOnlyUser.bind(this)
	}

	componentDidMount(){
		this.props.fetchTeamUsers(this.props.customer.customer_id).then(teams => {
			this.setState({teams: teams.response})
		})

	}

	componentWillReceiveProps(nextProps){
		if(this.props.customer.customer_id !== nextProps.customer.customer_id){
			this.props.fetchTeamUsers(nextProps.customer.customer_id).then(teams => {
				this.setState({teams: teams.response})
			})
		}
	}

	handleRemoveUser(customer_id, user_id){
		if(window.confirm("Are you sure you want to revoke access to this user? They will be notified by email.")){
			this.props.removeUserFromTeam(customer_id, user_id)
			.then(() => {
				this.props.fetchTeamUsers(customer_id).then(teams => {
					this.setState({teams: teams.response})
				})

				// If the user has removed themself from a team
				if(this.props.user.user_id === user_id){
					alert("You have removed yourself from this team. For this action to take effect, you'll now be logged out and will need to login again.");
					BeaconRequest.logout();	
				}
			})
		}
	}

	handleToggleReadOnlyUser(customer_id, email, role_id){
		if(window.confirm("Are you sure you want to change this user's permissions? They will be notified by email.")){
			this.props.updateUser(customer_id, email, role_id)
			.then(() => {
				this.props.fetchTeamUsers(customer_id).then(teams => {
					this.setState({teams: teams.response})
				})
			})
		}
	}



	render() {

		// Get the user role for the customer
		const user_role = this.props.customer.__user_role.role_id

		// Get the customer ID
		const customer_id = this.props.customer.customer_id

		// Get the ID of the currently logged in user
		const current_user_id = this.props.user.user_id

		// Loop through the team users
		const users = this.state.teams.map(user => {

			const { first_name, last_name, user_id, email, primary_user, role_id } = user

			if(user_role === 1 || user_role === 2 || user_id === current_user_id){
				return (
					<div className="table__row" key={user_id}>
						<div className="table__cell">
							{
								(!first_name || !last_name) &&
								<em>Name not provided</em>
							}

							{first_name} {last_name} { user_id === current_user_id && <span className="tag" style={{float:'right'}}><i className="far fa-user"></i> Me</span>}
						</div>
						<div className="table__cell">
							{email}
						</div>
						<div className="table__cell table__cell--right">
							{
								// If the customer is not the primary user
								// If user has the correct role (administrator is 1 or 2)
								// And the user is not the current user (so they can't remove themselves)
								// Or user is not an admin and the current user is the same as this user (so can remove themselves, but not admins)
								( ((primary_user !== 1) && (user_role === 1 || user_role === 2) && (user_id !== current_user_id)) || (user_role > 2 && user_id === current_user_id) ) &&
								<div 
								style={{
									display:"inline-grid", 
									gridAutoFlow:"column", 
									gridGap:15, 
									alignItems:"center", 
									justifyItems:"end"
								}}
								>
									<button 
									type="button" 
									className="button button--red button--small" 
									onClick={() => this.handleRemoveUser(customer_id, user_id)}>
									Revoke user access
									</button>
									
									{
										(user_role === 1 || user_role === 2) &&
										<Toggle 
											// defaultChecked={user_role === 4}
											checked={role_id === 4}
											handleToggle={() => 
												this.handleToggleReadOnlyUser(customer_id, email, role_id)
											} 
											text="Read only" 
										/>
									 } 
								</div>
							}
						</div>
					</div>
				)
			}
			return null
		})

		return(
			<React.Fragment>
				<div className="table margin-large" style={{marginTop:10}}>
					<div className="table__row">
						<div className="table__heading">Name</div>
						<div className="table__heading">Email</div>
						<div className="table__heading">Actions</div>
					</div>
					{users}
				</div>

				{
					// Show the add member container, only if user has role 1 or 2
					(user_role === 1 || user_role === 2) &&
					<AddTeamMemberContainer customer={this.props.customer} team={this.props.teamUsers} setTeams={e => this.setState({teams: e})} />
				}
			</React.Fragment>
		)
	}
}

CustomerTeam.defaultProps = {
	teamUsers: []
}

const mapStateToProps = (state) => {
	return {
		teamUsers: state.teams.teamUsers
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
        removeUserFromTeam: (customer_id, user_id) => dispatch(removeUserFromTeam(customer_id, user_id)),
		fetchTeamUsers: (customer_id) => dispatch(fetchTeamUsers(customer_id)),
		updateUser: (customer_id, email, role_id) => dispatch(updateUser(customer_id, email, role_id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTeam);