// Import action types
import { 
	FETCH_ACTIVE_WEBSITES_REQUEST,
	FETCH_ACTIVE_WEBSITES_SUCCESS,
	SET_SELECTED_WEBSITE_REQUEST,
	SET_SELECTED_WEBSITE_SUCCESS,
	SET_SELECTED_WEBSITE_ID,
	FETCH_ALL_WEBSITES_REQUEST,
	FETCH_ALL_WEBSITES_SUCCESS,
	CLEAR_SELECTED_WEBSITE,
	FETCH_CUSTOMER_WEBSITES,
	SET_LAST_TRACKING_CODE_SEEN,
	SET_USER_META
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_ACTIVE_WEBSITES_REQUEST:
			return {
				...state,
				isFetching: true
			}
		case FETCH_ACTIVE_WEBSITES_SUCCESS: // return a list of all active websites
			return {
				...state,
				activeWebsites: action.payload,
				isFetching: false
			}
		case FETCH_ALL_WEBSITES_REQUEST:
			return {
				...state,
				isFetching: true
			}
		case FETCH_ALL_WEBSITES_SUCCESS:
			return {
				...state,
				allWebsites: action.payload,
				isFetching: false
			}
		case FETCH_CUSTOMER_WEBSITES: 
			return {
				...state,
				customerWebsites: action.payload	
			}
		case SET_SELECTED_WEBSITE_REQUEST:{
			return {
				...state,
				isFetchingSelectedWebsite: true
			}
		}
		case SET_SELECTED_WEBSITE_ID: 
			return {
				...state,
				selectedWebsiteId: action.payload,
			}
		case SET_SELECTED_WEBSITE_SUCCESS: // set the website the user is currently viewing
			return {
				...state,
				selectedWebsiteId: action.responseObj.response.website_id,
				selectedWebsiteAdded: action.responseObj.response.added,
				customerId: action.responseObj.response.customer_id,
				selectedWebsiteUrl: action.responseObj.response.__urls.primaryUrl,
				selectedWebsiteName: action.responseObj.response.name,
				isFetchingSelectedWebsite: false,
				selectedWebsite: action.responseObj.response,
				lastTrackingCodeSeen: action.responseObj.response.lastTrackingCodeSeen
			}
		case 'SET_SELECTED_WEBSITE_PERIOD': 
			return {
				...state,
				website_by_period: action.responseObj.response
			}
		case SET_LAST_TRACKING_CODE_SEEN:
			return {
				...state,
				lastTrackingCodeSeen: action.payload
			}
		case CLEAR_SELECTED_WEBSITE:{
			return {
				...state,
				selectedWebsiteId: null,
				selectedWebsite: null
			}
		}
		default:
			return state;
	}
}