// Import action types
import { 
	SET_SELECTED_DATE,
	UNSET_SELECTED_DATE,
	SET_ELEMENT_SELECTED_DATE,
	UNSET_ELEMENT_SELECTED_DATE
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case SET_SELECTED_DATE:
			return {
				...state,
				selectedDateValue: action.date.id,
				selectedDateName: action.date.title,
				selectedDates: [ action.date.dateFrom, action.date.dateTo ], // pass directly from flatpickr so we can reuse easily with flatpickr

				// Also affect the element date on change
				elementSelectedDateValue: action.date.id,
				elementSelectedDateName: action.date.title,
				elementSelectedDates: [ action.date.dateFrom, action.date.dateTo ] // pass directly from flatpickr so we can reuse easily with flatpickr
			}
		case SET_ELEMENT_SELECTED_DATE:
			return {
				...state,
				elementSelectedDateValue: action.date.id,
				elementSelectedDateName: action.date.title,
				elementSelectedDates: [ action.date.dateFrom, action.date.dateTo ] // pass directly from flatpickr so we can reuse easily with flatpickr
			}
		case UNSET_SELECTED_DATE:
			return {
				...state,
				selectedDateValue: null,
				selectedDateName: undefined,
				selectedDates: []
			}
		case UNSET_ELEMENT_SELECTED_DATE:
			return {
				...state,
				elementSelectedDateValue: action.date.selectedDateValue,
				elementSelectedDateName: action.date.selectedDateName,
				elementSelectedDates: action.date.selectedDates
			}
		default:
			return state;
	}
}