import BeaconRequest from '../BeaconRequest';

// Import action types
import { 
	FETCH_PAGES_REQUEST,
	FETCH_PAGES_SUCCESS,
	FETCH_SINGLE_PAGE_REQUEST,
	FETCH_SINGLE_PAGE_SUCCESS,
	SET_PAGES_TYPE
} from './actionTypes';

// Get all pages for a given website
export const fetchPages = (sortBy, direction) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_PAGES_REQUEST,
		})
		const siteId = getState().websites.selectedWebsiteId;
		const pagesType = 'landing';

		let options = { }
		if(sortBy && direction){
			options = { ...options, order: sortBy + direction }
		}

		return BeaconRequest.get('page/' + pagesType + '/' + siteId, options)
		.then(responseObj => {
			dispatch({
				type: FETCH_PAGES_SUCCESS,
				responseObj
			})
		})
		.catch(error => { console.log(error) })
	}
}

// Set whether the user is viewing all, landing pages only, or non-landing pages only
export const setPagesType = pagesType => {
	return {
		type: SET_PAGES_TYPE,
		pagesType
	}
}

export const fetchPagesByPaging = (link) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_PAGES_REQUEST,
		})
		return BeaconRequest.paging(link)
		.then(function (responseObj) {
			dispatch({
				type:FETCH_PAGES_SUCCESS,
				responseObj
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchSinglePage = (pageId) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_SINGLE_PAGE_REQUEST,
		})

		let lifecycle_query  = 'page/' + pageId
		

		return BeaconRequest.get(lifecycle_query)
			.then(responseObj => {
				const page = responseObj.response
				return BeaconRequest.get(`page/${pageId}/graph/${BeaconRequest.startof_day(page.__lifecycle.first.timestamp)}:${BeaconRequest.endof_day(page.__lifecycle.last.timestamp)}`)
					.then(res => {
						dispatch({
							type: FETCH_SINGLE_PAGE_SUCCESS,
							payload: res.response
						})
						return res
					})
				
			})
			.catch(error => { console.log(error) })
	}
}

