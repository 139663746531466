import styled from 'styled-components'
import defaultStyles from '../../defaultStyles'

const Body = styled.div`
    color: ${defaultStyles.neutrals["700"]};
    font-family: ${defaultStyles.fontFamily};
    line-height: ${defaultStyles.lineSpacing};
    font-size: ${defaultStyles.fontSizes.body};
    background-color: ${defaultStyles.fontSizes.bodyBackgroundColour};
    font-weight: ${defaultStyles.fontWeight.regular};
`;

export default Body