import { combineReducers } from 'redux';
import login from './login';
import customers from './customers';
import websites from './websites';
import campaigns from './campaigns';
import pages from './pages';
import dates from './dates';
import ui from './ui';
import links from './links';
import channels from './channels';
import teams from './teams';
import visits from './visits';
import visitors from './visitors';
import tables from './tables'

import {conversionChartReducer} from '../molecules/ConversionChart/reducers'
import {barChartReducer} from '../molecules/BarChart/reducers'
import {visitorProfileReducer} from '../molecules/VisitorProfile/reducers'
import {browserProfileReducer} from '../molecules/BrowserProfile/reducers'
import {analyticsListReducer} from '../molecules/CampaignsList/reducers'
import {botActivityReducer} from '../molecules/BotActivity/reducers'
import {botImpactGraphReducer} from '../molecules/BotImpactGraph/reducers'
import {mediaSpendReducer} from '../molecules/MediaSpend/reducers'
import {budgetEffectivenessReducer} from '../molecules/BudgetEffectiveness/reducers';
import {performanceListReducer} from '../molecules/PerformanceList/reducers';
import {visitorMetricsReducer} from '../molecules/VisitorMetrics/reducers';
import {filterReducer} from '../organisms/WidgetContainer/reducers';
import {actionBarReducer} from '../molecules/ActionBar/reducers';

export default combineReducers({
	login,
	customers,
	websites,
	campaigns,
	pages,
	dates,
	ui,
	links,
	channels,
	teams,
	visits,
	visitors,
	tables,

	visitorProfile: visitorProfileReducer,
	browserProfile: browserProfileReducer,
	barChart: barChartReducer,
	conversionChart: conversionChartReducer,
	analyticsList: analyticsListReducer,
	botActivity: botActivityReducer,
	botImpactGraph: botImpactGraphReducer,
	mediaSpend: mediaSpendReducer,
	budgetEffectiveness: budgetEffectivenessReducer,
	performanceList: performanceListReducer,
	visitorMetrics: visitorMetricsReducer,
	actionBar: actionBarReducer,
	global: filterReducer,
})