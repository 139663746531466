import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popover from '@material-ui/core/Popover'
import { Popper } from '@material-ui/core';
import Toggle from '../general/Toggle'
import LoaderAlt from '../general/LoaderAlt'

class DropdownList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownIsOpen: false,
			anchor: null,
			filterAnchor: null,
			popperRef: null,
			placeholder: "",
			// items: this.props.menu.items,
			text: ""
		}
		this.handleToggleDropdown = this.handleToggleDropdown.bind(this);
		this.handleClearSelection = this.handleClearSelection.bind(this);
	}

	handleToggleDropdown(e){
		if (this.state.anchor == null) {
			if (e != null) {
				this.setState({anchor: e.currentTarget, items: this.props.menu.items}) // Boolean(this.state.anchor) ? null  : 
				this.input  && this.input.focus()
			} 
		} else {
			this.setState({anchor: null})
		}
	}

	// on mounting and unmounting, listen for clicks in the dom and toggle the dropdown as required
	componentDidMount() {
		this.eventListener = (event) => {
			if (this.menu != null) {
				const dropdownTitle = this.menu.contains(event.target) 
					|| (this.container != null ? this.container.contains(event.target) : false)
					|| (this.filterContainer != null ? this.filterContainer.contains(event.target) : false)
				

				if (!dropdownTitle) {
					this.setState({anchor: null, filterAnchor: null})
					this.input && this.input.blur()
					
					const find = this.props.menu.items.filter(item => item.id == this.props.selectedItem)[0]
					
					// if (find) this.setState({text: find.title })
					// else 
					this.setState({text: ""})
				}
			}
		};
		
		document.addEventListener('click', this.eventListener);
		this.setState({placeholder: this.props.menu.title.length > this.props.maxTextLength ? this.props.menu.title.substring(0, this.props.maxTextLength) + "..." : this.props.menu.title})
	}

	componentDidUpdate(prevProps ,prevState) {
		if (prevProps.selectedItem !== this.props.selectedItem) {
		
			const find = this.props.menu.items.filter(item => item.id == this.props.selectedItem)[0]
					
			// if (find) this.setState({text: find.title })
			// else 
			this.setState({text: "" })
			
		 }
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.eventListener);
	}

	// handle when an item is selected
	// first, pass it back up the chain to the parent component(s) and then fire the toggle method to close the dropdown
	handleItemSelected(item){
		this.props.selectItem(item);
		this.handleToggleDropdown();
		this.setState({anchor: null})
	}
	
	// function to handle input clearing - send an empty string as the campaign id
	handleClearSelection(){
		this.props.clearSelectedItem();
	}

	render() {
		// Loop through the items passed in
		const {items} = this.props.menu
		
		const isDropdownOpen = Boolean(this.state.anchor)
		const isFilterOpen = Boolean(this.state.filterAnchor)

		const filter = (items) => {
			const find = this.props.menu.items.filter(item => item.id == this.props.selectedItem)[0]
				
			if (find && (find.title == this.state.text)) {
				return items
			} 
			
			return items.sort((a, b) => {
				if (!this.props.sort) return 1
				if (typeof a.title === 'string') {
					return a.title.localeCompare(b.title)
				}

				return 1
			}).filter(item => typeof item.title === 'string' && item.title.toLowerCase().includes(this.state.text.toLowerCase()))
		}
	
		return (
			<nav
				ref={element => this.menu = element} 
				className={ "dropdown " + (this.props.theme) + (this.props.isDisabled ? " dropdown--disabled" : "") + (this.props.isTiny ? " dropdown--tiny" : "") }
				// border: '#5E6172 solid 1px', borderRadius: '5px',
				style={{width: '100%'}}
			>
				
				{
					// If an item is selected AND is clearable, display the button to clear the selected item
					this.props.selectedItem && this.props.isClearable && 
					<button 
					type="button" 
					title="Clear this selection" 
					className="dropdown__clear" 
					style={{height: '100%'}}
					onClick={(e) => {e.preventDefault(); this.handleClearSelection()}}>
						<i className="fas fa-times-circle"></i>
					</button>
				}

				{/* 
					Pass in the title of the dropdown menu and add a class if clearing is enabled (to add left-padding)
				*/}
				{!this.props.headless ?
					<div 
						onClick={this.handleToggleDropdown} 
						className={"dropdown__title " + (this.props.selectedItem && this.props.isClearable ? "is-clearing" : "") }
						style={{
							position: 'relative', 
							border: this.props.outline,
							paddingRight: this.props.loading ? 58 : 33
							// backgroundColor: this.props.hollow && 'transparent',
							// border: this.props.hollow &&  '1px solid #5E6172',
						}}
						
					>
						<span style={{marginRight: '10px'}}>{
							this.props.menu.title.length > this.props.maxTextLength ? this.props.menu.title.substring(0, this.props.maxTextLength) + "..." : this.props.menu.title
						}</span>

						{this.props.loading && <div class="loader-mini" style={{position: 'absolute', top: '6px', right: '35px'}}></div>}
					</div> 
					
					:
					<>
						{this.props.headlessContent(
							(e) => this.handleToggleDropdown(e), 
							isDropdownOpen 
						)}
					</>
				}
				
				< >

				

				<Popper 
					style={{
						zIndex: 3001
					}}
					ref={el => this.filterContainer = el} 
					open={isFilterOpen} 
					anchorEl={this.state.filterAnchor} 
					placement={"bottom-start"}
				>
					{this.props.filters && this.props.filters}
				</Popper> 


				<Popper 
					ref={el => this.container = el}
					open={isDropdownOpen} 
					anchorEl={this.state.anchor} 
					placement={this.props.position || "bottom-start"}
					style={{
						zIndex:  3001,
						minWidth: this.props.autocomplete ? '350px' : null
					}}
					modifiers={{
						flip: {
						  enabled: true,
						},
						preventOverflow: {
						  enabled: true,
						  boundariesElement: 'window',
						},
					}}
				>
					<ul 
						onMouseEnter={e => {
							if (this.props.onDropDownHoverEnter != null) {
								this.props.onDropDownHoverEnter(e)
							}
						}} 
						onMouseLeave={e => {
							if (this.props.onDropDownHoverLeave != null) {
								this.props.onDropDownHoverLeave(e, (i) => this.handleToggleDropdown(i))
							}
						}} 
						
						className={"dropdown__menu " + (this.props.theme)}
						style={{
							width: '100%',
							marginTop: 0,
							// marginTop: this.props.position == "bottom-start" || this.props.position == "bottom-end" || this.props.position == "bottom" || this.props.position == null  ? '10px': 0,
							// marginLeft: this.props.position == "right-start" || this.props.position == "right-end" ? '10px' : 0,
							
						}}
					>
						<div style={{position: 'sticky', top: 0, zIndex: 1}}>
							{this.props.tabs && 
								<div style={{background: 'white', padding: '0 15px', paddingTop: '5px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', borderBottom: 'solid rgb(94 97 114 / 20%) 1px'}}>
									{this.props.tabs.map(tab => {
										return <span className='text-small' style={{padding: '5px', borderBottom: tab.isSelected ? 'solid #25cccf 3px': null, marginRight: '10px', cursor: 'pointer'}} onClick={tab.onClick}>
											{tab.name}
										</span>
									}) }
								</div>
							}
							{this.props.autocomplete && 
							// backgroundColor: 'rgb(230 235 237)', 
								<div style={{backgroundColor: this.props.theme == "light" ? 'white' : '#5E6172', display: 'grid', gridTemplateColumns: 'auto', padding: '10px', }}>
									
									<div style={{marginRight: '5px'}}>
										<input 
											spellcheck="false" 
											ref={element => this.input = element} 
											className={this.props.theme == "light" ? null : "dark-input"} 
											style={{
												height: '100%', 
												marginRight: '10px', 
												background: this.props.theme == "light" ? 'white' : '#797b8b', 
												color: this.props.theme == "light" ? null : 'white',
												outline: 'none', 
												border: 'solid rgb(94 97 114 / 20%) 1px', 
												borderRadius: '5px', width: '100%', padding: '5px'
											}} 
											value={this.state.text} 
											placeholder={"Search"} 
											onChange={e => {this.setState({text: e.target.value})}} 
										/>
									</div>
								</div>
							}
						</div>
						{!this.props.loading ? 
							filter(items).map(item => {
								return (
									<li 
									className={ "dropdown__item" + (item.icon ? " has-icon" : "")}
									// key={item.id} 
									onClick={(e) => {e.preventDefault(); this.handleItemSelected(item)}}
									style={{
										backgroundColor: (this.props.isItemSelected?.(item) ?? (this.props.selectedItem == item.id)) ? (this.props.theme == 'light' ? '#E6EBED' : '#474956') : null,
										padding: '5px',
										paddingLeft: '10px',
										display: 'flex',
										alignContent: 'center',
										alignItems: 'center',
										fontSize: '13px'
									}}
									>
										{(item.colour != null && item.channel_icon == null) && <span className="key-dot" style={{backgroundColor:item.colour}}></span>}
										{item.channel_icon != null &&
											<div className="channels__icon" style={{height: '25px', width: '25px', fontSize: '26px', display: 'inline-block', marginRight: '10px'}}>
												{/* <span className="key-dot key-dot--over-icon" style={{backgroundColor:item.colour}}></span> */}
												{ item.channel_icon != null && item.channel_icon !== "" ? <i style={{color: item.colour}} className={item.channel_icon}></i> : <i style={{color: '#5E6172'}} >{item.title.charAt(0)}</i> }
											</div>
										}
										{item.icon != null && item.icon } {item.title}
										
										{/* <i class="fas fa-archive"></i> */}
										{(item.archived || item.hasSpend || item.hasVisits) && 
											<span style={{marginLeft: 'auto'}} >
												{item.hasSpend && <span style={{
													background: 'rgb(230, 235, 237)',
													fontSize: '13px',
													padding: '3px 6px',
													borderRadius: '5px',
													marginLeft: '10px'
												}}>
													P
												</span>}
												{item.hasVisits && <span style={{
													background: 'rgb(230, 235, 237)',
													fontSize: '13px',
													padding: '3px 6px',
													borderRadius: '5px',
													marginLeft: '10px'
												}}>
													A
												</span>}
												{item.archived && <span style={{
													background: 'rgb(230, 235, 237)',
													fontSize: '13px',
													padding: '3px 6px',
													borderRadius: '5px',
													marginLeft: '10px'
												}}>
													Archived
												</span>}
											</span>
										}
									</li>
								)
							})
							:
							<div style={{margin: '20px'}}><LoaderAlt /></div>
						}
						{(filter(items).length == 0 && !this.props.loading)&& <div style={{margin: '5px', color: this.props.theme == "light" ? null : 'white'}}>No results found.</div>}

						{
							// If an "add" option is passed, i.e. to add a campaign, display it at the bottom
							this.props.addText &&
								<li 
									className="dropdown__item dropdown__item--add" 
									onClick={e => {
										this.props.addMethod(e);
										this.handleToggleDropdown();
									}}
									style={{
										padding: '5px',
										paddingLeft: '10px',
									}}
								>
									<i className="fas fa-plus-circle"></i> {this.props.addText}
								</li>
						}

					</ul>
	
				</Popper >
				</>
			</nav>
		)
	}
}

DropdownList.defaultProps = {
	menuSide: 'left',
	theme: 'dark',
	isClearable: false,
	selectedItem: null,
	isDisabled: false,
	isItemSelected: null,
	width: null,
	isTiny: false
};

DropdownList.propTypes = {
	menu: PropTypes.object.isRequired,
};


export default DropdownList
