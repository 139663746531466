
export default function (state = {}, action) {
	switch(action.type){
		case 'SET_TABLE':
			return {
				...state,
				[action.title]: action.payload
			}
		
		default:
			return state;
	}
}