import React, { useState, useEffect } from 'react';

const LoaderAlt = props => {
	const [loadingMessage, setLoadingMessage] = useState(null)
	
	useEffect(() => {
		let timer1, timer2
		if(!props.noHints){
			timer1 = setTimeout(() => { 
				setLoadingMessage("There appears to be lots of data to load, hold tight!")
			}, 5000);
	
			timer2 = setTimeout(() => { 
				setLoadingMessage("This is taking longer than usual, please contact support if this message remains.")
			}, 15000);
		}
		return () => {
			if (timer1 != null) clearTimeout(timer1)
			if (timer2 != null) clearTimeout(timer2)
		};
	}, [])

	return (
		<div style={{textAlign:"center", margin:"0 auto"}}>
			<img src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/small-loader.gif" alt="Loading, please wait..." style={{margin:"0 auto", display:"block"}} />
			{ loadingMessage && <div style={{marginTop: '15px'}}>{loadingMessage}</div> }
		</div>
	)
}

export default LoaderAlt;