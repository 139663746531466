import React, { Component } from 'react'
import { connect } from 'react-redux';
import { addChannels } from '../../actions/channels';
import Modal from '../../components/general/Modal';
import DefaultChannels from '../../components/channel/DefaultChannels';
import CreateChannel from '../../components/forms/CreateChannel';
import NewChannels from '../../components/forms/NewChannels';
import PropTypes from 'prop-types';

class AddCampaignChannelsContainer extends Component {
	constructor(){
		super()
		this.state = {
			alertOnClose: false,
			newChannels:[],
			selectedChannels: [],
			isPartiallyAdded: false,
			error: null
		}
		this.handleAddChannel = this.handleAddChannel.bind(this)
		this.handleToggleChannel = this.handleToggleChannel.bind(this)
		this.handleAddChannels = this.handleAddChannels.bind(this)
		this.handleIsAdding = this.handleIsAdding.bind(this)
	}

    // on render determine if the campaign is newly added, if so auto-set the campaign
	// to be that campaign just created, otherwise render a dropdown
	
	// need to pass in campaign ID for current campaign (/campaign/{campaign_id}/channels)

	handleAddChannel(newChannel){
		if(newChannel.channel_name !== ''){
			this.setState({
				newChannels: [...this.state.newChannels, newChannel],
				selectedChannels: [...this.state.selectedChannels, newChannel],
				alertOnClose: true
			})
		}
	}

	handleToggleChannel(channel){
		if (this.state.selectedChannels.some(e => e.title === channel.title)) {
			this.setState({
				selectedChannels: this.state.selectedChannels.filter(ch => { 
					return ch.title !== channel.title; 
				})
			})
		} else {
			this.setState({
				selectedChannels: [...this.state.selectedChannels, { title: channel.title, icon: channel.icon, colour: channel.colour }]
			})
		}
	}

	handleAddChannels(){
		this.setState({error: null})
		 if(this.state.isPartiallyAdded){
			this.setState({error:"Be sure to click 'add' if you want to save your custom channel"})
		} else if (this.state.selectedChannels.length === 0) {
			this.setState({error:"You haven't selected any channels to create"})
		} else {
			this.props.addChannels(this.state.selectedChannels, this.props.campaignId)
			.then(response => {
				if (response.responseType =="error") {
					this.setState({error: `${response.additional} There was a problem with creating your channels, please try again. If the error persists please create a ticket (/tickets).`})
					return 
				}
				this.props.handleOpen(response.response)
				
				if (this.props.callback)
					this.props.callback(response.response,  this.props.campaignId)
			})
		}
	}
	
	handleIsAdding(isAdding){
		this.setState({ isPartiallyAdded: isAdding })
	}

	render() {
		return (
			<Modal
			title="Campaign Channels"
			closeLabel="Cancel"
			saveLabel="Save"
			closeAction={this.props.handleOpen}
			saveAction={this.handleAddChannels}
			alertOnClose={this.state.alertOnClose}
			message={
				this.state.error && <div className="message message--warning">
					<p >{this.state.error}</p>
				</div>
			}
			
			>
			{
				this.props.campaignName &&
				<div className="message message--notice margin-large">
					<p>Add some marketing channels to your campaign <strong>{this.props.campaignName}</strong>. Find out more about our <a href="https://www.thisisbeacon.com/analytics/multi-channel-support/" rel="noopener noreferrer" target="_blank">multi-channel marketing analytics</a>.</p>
				</div>
			}
			
			<CreateChannel 
				channels={this.state.newChannels}
				campaign={this.props.campaignId}
				handleAddChannel={this.handleAddChannel}
				isAdding={this.handleIsAdding}
			/>
			<NewChannels
				channels={this.state.newChannels}
				handleToggleChannel={this.handleToggleChannel}
			/>
			<DefaultChannels 
				handleToggleChannel={this.handleToggleChannel}
			/>

			
			</Modal>
		)
	}
}

AddCampaignChannelsContainer.propTypes = {
	campaignId: PropTypes.number,
	campaignName: PropTypes.string,
	handleOpen: PropTypes.func,
	callback: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
	return {
        addChannels: (channels, campaignId) => dispatch(addChannels(channels, campaignId))
	}
}

export default connect(null, mapDispatchToProps)(AddCampaignChannelsContainer);
