  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createConversionChartResource = ({timePeriod, filter, disableCache, cancelToken}) => {
    return {
        campaigns_with_traffic: [`${filter.type}/${filter.payload.id}/ad/funnel/${timePeriod}`, null, {cancelToken: cancelToken.token}],
    }
}

export const setConversionChartResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_CONVERSION_CHART_FETCHING,
            fetching: true
        })

        const previousToken = getState().conversionChart.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("conversion chart request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_CONVERSION_CHART_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createConversionChartResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_CONVERSION_CHART_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_CONVERSION_CHART_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_CONVERSION_CHART_FETCHING,
                        fetching: false
                    })
                }
            })
    }
}