var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
function LoadMoreButton(_a) {
    var activeTab = _a.activeTab, loadedData = _a.loadedData, loadNextDataForTab = _a.loadNextDataForTab;
    var activeData = loadedData.get(activeTab);
    return (_jsx("div", __assign({ style: { display: "flex", justifyContent: "center", margin: 8 } }, { children: _jsxs("button", __assign({ className: "button button--green", style: { margin: "0 auto" }, disabled: activeData.fetching ||
                activeData.pageCache.length === 0 ||
                activeData.pageCache[activeData.pageCache.length - 1].length === 0, onClick: function () { return loadNextDataForTab(activeTab, activeData.pageCache); } }, { children: [_jsx("i", { className: "fas fa-chevron-circle-down", style: { marginRight: "5px" } }), " ", activeData.fetching ? "Loading..." : "Load More"] })) })));
}
export default LoadMoreButton;
