
import React, {useState} from 'react'
import {widgets_list} from '../../molecules/widgets'
import { addNotification } from '../../actions/ui'
import { connect } from 'react-redux';
import VisitsListPage from '../../pages/VisitsListPage'
import Modal from '../../components/general/Modal'
import LeadsList from '../../components/leads/LeadsList'
import EngagedVisitorsList from '../../components/visitor/EngagedVisitorsList'
import moment from 'moment-timezone'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
    item: {
        paddingBottom: '20px !important'
    },
}));

const AnalyticsDashboard = (props) => {
    const [items, setItems] = useState(widgets_list)
    const classes = useStyles(); //grrr

    const [visitListModal, setVisitListModal] = useState(false)
    const [leadsModal, setLeadsModal] = useState(false)
    const [engagementModal, setEngagementModal] = useState(false)

    const createItem = (id) => {
        const findById = items.filter(item => {return item.id == id})[0]
        return items.length > 0 && 
            findById
                .content({
                    ...findById,
                    globalLayout: [{i: 'visitor_profile_widget', w: 1}],
                    datetime: props.datetime,
                    websiteId: props.websiteId,
                    currentUser: props.currentUser,
                    filter: props.filter,
                    setFilter: props.setFilter,
                    meta: props.websiteMeta,
                    setVisitListModal,
                    setLeadsModal,
                    setEngagementModal
                })
    }

    if (props.filter == null) return null

    return <>
        {visitListModal && 
            <Modal
                title={moment.unix(visitListModal.dateLabel).format('DD MMM YY')}
                closeAction={() => setVisitListModal(false)}
                disableSave={true}
                isWide
                
            >
                <VisitsListPage {...{
                    filter: props.filter, datetime: visitListModal.datetime, setFilter: e => props.setFilter(e), noContainer: true
                }}/>
            </Modal>
        }

        {leadsModal && 
            <Modal
                title={props.websiteMeta.lead_text}
                closeAction={() => setLeadsModal(false)}
                disableSave={true}
                isWide
            >
                <LeadsList date={props.datetime} for={props.filter.type} id={props.filter.payload.id} callback={() => {setLeadsModal(false)}}/>   
            </Modal>
        }

        {engagementModal &&
            <Modal
                title={"Engaged visitors"}
                closeAction={() => setEngagementModal(false)}
                disableSave={true}
                isWide
                
            >
                <EngagedVisitorsList  timestamp={props.datetime} for={props.filter.type} id={props.filter.payload.id}/>
            </Modal>
        }

        <Grid container spacing={2}>
            <Grid item xs={4} classes={classes}>
                {createItem("conversion_funnel_widget")}
            </Grid>
            <Grid item xs={8} classes={classes} style={{zIndex: 2}}>
                {createItem("website_traffic_widget")}
            </Grid>
            <Grid item xs={3} classes={classes}>
                {createItem("visitor_metrics_widget")}
            </Grid>
            <Grid item xs={6} classes={classes}>
                {createItem("visitor_profile_widget")}
            </Grid>
            <Grid item xs={3} classes={classes}>
                {createItem("browser_profile_widget")}
            </Grid>
            {props.filter.type != "link" &&
                <Grid item xs={12} classes={classes}>
                    {createItem("campaigns_list_widget")}
                </Grid>
            }
        </Grid>
    </>

}

const mapStateToProps = state => {
    return {
        websiteId: state.websites.selectedWebsiteId,
        websiteMeta: state.websites.selectedWebsite.__meta,
        currentUser: state.login.currentUser,
        customer: state.customers.selectedCustomer,
        date: state.dates.selectedDateValue,
        timezone: state.websites.selectedWebsite.timezone,
        websiteName: state.websites.selectedWebsite.name,
        showDataExport: state.websites.selectedWebsite.showDataExport,
        activeCampaigns: state.campaigns.activeCampaigns,

    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		addNotification: (payload) => dispatch(addNotification(payload)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsDashboard)