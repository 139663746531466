import moment from 'moment-timezone';
export var Importer = function BeaconRequest() {
    var ColumnMappings = {
        "Facebook Ads": {
            "startRow": 1,
            "date_format": "YYYY-MM-DD",
            "headings": { "date": "Day", "clicks": ["Unique outbound clicks", "Link clicks", "Results"], "impressions": "Impressions", "CPC": "Cost per result", "spend": ["Amount spent (GBP)", "Amount spent (USD)"] },
            "columns": { "date": null, "clicks": null, "impressions": null, "CPC": null, "spend": null },
            "seperator": /\,(?=(?:(?:[^"]*"){2})*[^"]*$)/,
            "lineSseperator": '\n'
        },
        "Google Ads": {
            "startRow": 3,
            "date_format": "YYYY-MM-DD",
            "headings": { "date": "Day", "clicks": "Clicks", "impressions": ["Impressions", "Impr."], "CPC": "Avg. CPC", "spend": "Cost" },
            "columns": { "date": null, "clicks": null, "impressions": null, "CPC": null, "spend": null },
            "seperator": /\,(?=(?:(?:[^"]*"){2})*[^"]*$)/,
            "lineSseperator": '\n'
        },
        "LinkedIn Ads": {
            "startRow": 6,
            "date_format": "M/D/YYYY",
            "headings": { "date": "Start Date (in UTC)", "clicks": "Clicks", "impressions": "Impressions", "CPC": "Average CPC", "spend": "Total Spent" },
            "columns": { "date": null, "clicks": null, "impressions": null, "CPC": null, "spend": null },
            "seperator": '\t',
            "lineSseperator": /\n(?=(?:(?:[^"]*"){2})*[^"]*$)/
        },
        "Reddit": {
            "startRow": 1,
            "date_format": "YYYY-MM-DD",
            "headings": { "date": "Date", "clicks": "Clicks", "impressions": "Impressions", "CPC": "CPC", "spend": "Amount Spent" },
            "columns": { "date": null, "clicks": null, "impressions": null, "CPC": null, "spend": null },
            "seperator": /\,(?=(?:(?:[^"]*"){2})*[^"]*$)/,
            "lineSseperator": '\n'
        },
        "Twitter": {
            "startRow": 1,
            "date_format": "DD-MMM-YYYY HH:mm",
            "headings": { "date": "Time period", "clicks": "Results", "impressions": "Impressions", "CPC": "Cost Per Result", "spend": "Spend" },
            "columns": { "date": null, "clicks": null, "impressions": null, "CPC": null, "spend": null },
            "seperator": /\,(?=(?:(?:[^"]*"){2})*[^"]*$)/,
            "lineSseperator": '\r\n'
        },
        "Manual CSV": {
            "startRow": 3,
            "date_format": "YYYY-MM-DD",
            "headings": { "date": "Day", "clicks": "Clicks", "impressions": "Impressions", "CPC": "Avg. CPC", "spend": "Cost" },
            "columns": { "date": null, "clicks": null, "impressions": null, "CPC": null, "spend": null },
            "seperator": /\,(?=(?:(?:[^"]*"){2})*[^"]*$)/,
            "lineSseperator": '\n'
        },
    };
    var ImportMode;
    this.GetSupportedImportsModes = function () {
        return Object.keys(ColumnMappings);
    };
    this.setImportMode = function (mode) {
        if (typeof ColumnMappings === "undefined") {
            return false;
        }
        ImportMode = mode;
        return true;
    };
    this.ReadFile = function (HTMLInputFile, mode) {
        return new Promise(function (resolve, reject) {
            var useMode;
            // check if mode has been set in method call
            if (typeof mode !== "undefined") {
                // check that the given mode is valid
                if (typeof ColumnMappings[mode] === "undefined") {
                    reject({ "status": "error", "reason": "mode supplied is invalid" });
                }
                useMode = mode;
            }
            else {
                // mode not given see if it was pre-set
                useMode = ImportMode;
                if (useMode == null) {
                    // not pre-set no mode selected
                    reject({ "status": "error", "reason": "mode has not been supplied or pre-set" });
                }
            }
            // store the loaded instructions for processing
            var instructions = ColumnMappings[useMode];
            // create the array of final outputs
            var out = [];
            // load the file from the given input
            var file = HTMLInputFile.files[0];
            // prepaire the file reader
            var reader = new FileReader();
            // set up file processor
            reader.onload = function (ProgressEvent) {
                // break the file into lines
                var lines = [];
                if (typeof this.result === "string") {
                    lines = this.result.trim().split(ColumnMappings[useMode].lineSseperator);
                }
                // check that there are more lines than the mimum
                if (lines.length <= instructions.startRow) {
                    reject({ "status": "error", "reason": "File supplied is not of mode given missing header lines" });
                }
                var headingsRow = instructions.startRow - 1;
                var columns = [], newColumns = lines[headingsRow].trim().split(ColumnMappings[useMode].seperator);
                newColumns.forEach(function (val, key) {
                    columns[key] = val.replace(/\"/g, '');
                });
                Object.entries(ColumnMappings[useMode].headings).forEach(function (_a, headingIndex) {
                    var key = _a[0], elm = _a[1];
                    var columnIdx;
                    if (typeof elm === "string") {
                        columnIdx = columns.findIndex(function (value) { return value === elm; });
                    }
                    else if (typeof elm === "object" && elm instanceof Array) {
                        var _loop_2 = function (i) {
                            columnIdx = columns.findIndex(function (value) { return value === elm[i]; });
                            if (columnIdx > -1) {
                                return "break";
                            }
                        };
                        for (var i = 0; i < elm.length; i++) {
                            var state_1 = _loop_2(i);
                            if (state_1 === "break")
                                break;
                        }
                    }
                    else {
                        throw new Error("Unrecognized column header type.");
                    }
                    ColumnMappings[useMode].columns[key] = columnIdx;
                });
                var flattend = false;
                try {
                    var _loop_1 = function (lineNumber) {
                        // setup the save record object
                        var data = {};
                        var date = "";
                        var columns_1 = lines[lineNumber].trim().split(ColumnMappings[useMode].seperator);
                        columns_1.forEach(function (val, key) {
                            columns_1[key] = val.replace("\"", '');
                        });
                        for (var _i = 0, _a = Object.entries(instructions.columns); _i < _a.length; _i++) {
                            var key = _a[_i][0];
                            var keyName = key.trim().replace(/"/g, '');
                            var columnPosition = instructions.columns[keyName];
                            if (key === "date") {
                                data[key] = moment(columns_1[columnPosition].trim().replace(/"/g, ''), instructions.date_format).format("YYYY-MM-DD");
                                date = data[key];
                            }
                            else if (["spend", "CPC"].includes(key)) {
                                if (columns_1[columnPosition] != null && columns_1[columnPosition] != "") {
                                    data[key] = parseFloat(parseFloat(columns_1[columnPosition].trim().replace(/"/g, '').replace(/,/g, "")).toFixed(2));
                                }
                                else {
                                    data[key] = 0.00;
                                }
                            }
                            else if (["clicks", "impressions"].includes(key)) {
                                var val = parseInt(columns_1[columnPosition].trim().replace(/"/g, '').replace(/,/g, ""));
                                data[key] = (isNaN(val)) ? 0 : val;
                            }
                            else {
                                data[key] = columns_1[columnPosition].trim();
                            }
                        }
                        if (typeof out[date] === "undefined") {
                            out[date] = data;
                            delete out[date].undefined;
                        }
                        else {
                            flattend = true;
                            for (var _b = 0, _c = Object.entries(instructions.columns); _b < _c.length; _b++) {
                                var key = _c[_b][0];
                                if (key === "date") {
                                }
                                else if (key === "CPC") {
                                    out[date][key] = 0.00;
                                    delete out[date].undefined;
                                }
                                else {
                                    out[date][key] += data[key];
                                    if (Number(out[date][key]) === out[date][key] && out[date][key] % 1 !== 0) {
                                        out[date][key] = Math.round(out[date][key] * 100) / 100;
                                    }
                                    delete out[date].undefined;
                                }
                            }
                        }
                    };
                    // go though data lines
                    for (var lineNumber = ColumnMappings[useMode].startRow; lineNumber < lines.length; lineNumber++) {
                        _loop_1(lineNumber);
                    }
                }
                catch ($e) {
                    if (lines.length <= instructions.startRow) {
                        reject({ "status": "error", "reason": "File supplied is not of mode given missing header line, columns in wrong place" });
                    }
                }
                var rows = Object.values(out);
                rows.sort(function (a, b) {
                    var time_a = new Date(a.date).getTime();
                    var time_b = new Date(b.date).getTime();
                    var test = (time_a > time_b ? 1 : -1);
                    return test;
                });
                // completed successfully return the RESULT
                resolve({ rows: rows, flags: { "flattened": flattend } });
            };
            // read the file so the processor can run
            reader.readAsText(file);
        });
    };
};
