import React, { Component } from 'react';
import ReactDOM from 'react-dom'

class Modal extends Component { 
	constructor(){
		super();
		this.state = {
			isLeaving: false
		}
		this.handleClose = this.handleClose.bind(this)
		this.handleSave = this.handleSave.bind(this)
	}

	componentDidMount() {
		document.body.style.overflow = 'hidden';
	} 

	componentWillUnmount() {
		document.body.style.overflow = 'unset'
	}

	// Check to see if the form has been changed in some way
	handleClose(){
		if(this.props.alertOnClose){
			const response = window.confirm("Are you sure you want close this window? Any changes you made will be lost.")
			if(response){
				this.doClose();
			}
		} else {
			this.doClose();
		}
	}
	
	handleSave(e){
		if(this.props.animateSave) {
			this.setState({isLeaving: true})
			setTimeout(() => {
				this.props.saveAction(e)
			}, 200)
		} else {
			this.props.saveAction(e)
		}
	}

	doClose(){
		this.setState({ isLeaving: true })
		setTimeout(()=>{
			this.props.closeAction()
		}, 200)
	}

	render() {
		return (
			ReactDOM.createPortal(
				<>
				<div 
					className={"modal-mask " + (this.state.isLeaving ? "leave" : "")} onClick={!this.props.disableClose && this.handleClose} 
					style={{zIndex: 1300}}
				>
				</div>
				<div style={{zIndex: 1300}} id="Modal" className={"modal " + (this.state.isLeaving ? "leave" : "") + (this.props.isWide && " modal--wide")}>
					<h2 className="modal__title">{this.props.title}</h2>

					{/* dumb fix for display modals out of small modals  - overflow visible - to remove! */}
					<div className="modal__content" style={this.props.overflowVisible ? {overflow: 'visible'} : {}}> 
						{this.props.children}
					</div>
					<div className="modal__footer">
						<div>
							{this.props.message}
						</div>
						<div>
							{ !this.props.disableClose && <button type="button" className="button button--transparent" onClick={this.handleClose}>{this.props.closeLabel}</button> }
						</div>
						<div>
							{ !this.props.disableSave && <button onClick={this.handleSave} type="button" className="button button--green" disabled={this.props.justdisableitlol}>{this.props.saveLabel}</button> }
						</div>
						
					</div>
				</div>
				</>,
				document.getElementById('modal-root')
			)
		)
	}
}

Modal.defaultProps = {
	closeLabel: "Close",
	saveLabel: "Save",
	isWide: false,
	animateSave: false,
}

export default Modal;
