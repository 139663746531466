import React from 'react';
import moment from 'moment-timezone';
import VisitorActions from '../visitor/VisitorActions';
import { Link } from 'react-router-dom';
var _ = require('lodash');

const VisitJourney = props => {

    const journey = props.journey.map(journey => {

        const { 
            page_id,
            page_visit_id, 
            timeOnPage, 
            __pageDetails, 
            exitPage, 
            scoringActions 
        } = journey
        return (
            <li className="journey__item" key={page_visit_id}>
                {
                    __pageDetails.screenshot ? <img src={__pageDetails.screenshot} className="journey__screenshot" alt="Screen shot" /> : <div className="journey__screenshot--none">No screenshot</div>
                }
                <div>
                    <div><strong>Page:</strong> {__pageDetails.host + __pageDetails.path}</div>
                    <div>
                        <strong>Time on page:</strong> {timeOnPage > 3600 ? moment.utc(timeOnPage*1000).format('HH:mm:ss') : moment.utc(timeOnPage*1000).format('mm:ss')}
                        { exitPage === 1 && <span className="journey__exit">Journey ended</span>}
                    </div>
                    <div>
                        <Link to={{pathname: '/pages/' + (page_id), state: { modal: true } }}>View page stats</Link> | <a rel="noopener noreferrer" href={__pageDetails.host + __pageDetails.path} target="_blank">Visit page</a>
                    </div>
                </div>
                {
                    !_.isEmpty(scoringActions) &&
                    <div className="journey__actions"><VisitorActions actions={scoringActions} /></div>
                }
            </li>
        )
    })

    return (
        <div className="card" style={{minHeight:"auto"}}>
            <div className="card__header">
                <h2 className="card__title">Visitor Journey - {props.journey.length} page{props.journey.length > 1 && <span>s</span>} viewed</h2>
            </div>
            <div className="card__content">
                <ul className="journey">
                    {journey}
                </ul>
            </div>
        </div>
    )
}

export default VisitJourney