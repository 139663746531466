import React, { Component } from 'react';
import BeaconRequest from '../../BeaconRequest';
import axios from 'axios';

class ResetPassword extends Component{
	constructor(){
		super()
		this.state = {
			link:''
		}
		this.handleResetPassword = this.handleResetPassword.bind(this)
	}

	handleResetPassword(){
		/*
			Workaround for browser blocking popup. Get the response, store is in state and then set a brief timeout before opening it in a new window.
			This means the direct result of the onClick method is a return to open window, rather than an API request
		*/
		axios.post(BeaconRequest.getServerAddress() + 'user/forgotten', {})
		.then(responseObj => {
			this.setState({
				link: responseObj.data.response
			})
		})
		setTimeout(() => {
			return window.open(this.state.link, "_tab");
		}, 500)
	}

	render(){
		return (
			<span 
			style={{color:'#ffffff',cursor:'pointer',textDecoration:'underline'}} 
			onClick={this.handleResetPassword}>
			Reset password 
			</span>
		)
	}
}

export default ResetPassword;