import React from 'react'
import { Link } from 'react-router-dom'

const NoMatch = () => {
	return (
		<div className="not-found">
			<h1>Not found</h1>
			<p>Oops, it looks like the resource you're looking for can't be found</p>
			{/* <p>Try again or <Link to="/">return to your dashboard</Link></p> */}
		</div>
	)
}

export default NoMatch