import React from 'react';
import DropdownList from '../general/DropdownList';

const CampaignChannelsDropdown = props => {
	const items = props.channels.map(channel => {
		return { id: channel.channel_id, title: channel.title, channel_icon: channel.icon, colour: channel.colour}
	})

	const menu = {
		title: props.selectedChannelName,
		items: items
	}

	let addText = props.addChannel ? "Add a Channel" : ""

	if(props.channels.length > 0){
		return (
			<DropdownList 
			clearSelectedItem={props.clearSelectedChannel}
			selectItem={props.handleSelectedItem} 
			isClearable={props.isClearable} 
			selectedItem={props.selectedChannelId} 
			menu={menu} 
			autocomplete
			theme={props.theme || "dark"}
			addText={addText}
			addMethod={props.addChannel}
			menuSide={props.menuSide}
			isDisabled={props.isDisabled}
			sort
			/>
		)
	}
	return null
}

CampaignChannelsDropdown.defaultProps = {
	selectedChannelName: "Filter by channel",
	channels: []
}

export default CampaignChannelsDropdown;