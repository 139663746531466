import React, { Component } from 'react';
import {withRouter} from 'react-router'
import { connect } from 'react-redux';
import { fetchSinglePage } from '../../actions/pages';
import Loader from '../../components/general/Loader';
import SingleTrafficGraph from '../../components/general/SingleTrafficGraph'
import Stats from '../../components/general/Stats'
import DocumentTitle from 'react-document-title';
import {BeaconRequest} from '../../BeaconRequest';
import moment from 'moment-timezone'
import ToolTip from '../../components/general/ToolTip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import {  addNotification } from '../../actions/ui'
import { redirectRouter } from '../../organisms/WidgetContainer/DropdownContainer'

const ShortLinksList = redirectRouter(props => {
	const links = props.links.map(link => {
		return (
			<div className="table__row" key={link.link_id}>
				<div className="table__cell">
					{link.notes || "No description"}
				</div>
				<div className="table__cell">
					{link.visits || 0}
				</div>
				<div className="table__cell">
					{link.leads || 0}
				</div>
				<div className="table__cell">
				<button
					onClick={e => {
						BeaconRequest.get(`link/${link.link_id}`, {__onlyCore: 1})
							.then(responseobj => 
								props.redirect(
									"link", 
									link.link_id, 
									responseobj.response.__lifecycle.first.timestamp + ":" + responseobj.response.__lifecycle.last.timestamp, 
									'/'
								)
							)
						
					}}
					className="button button--small button--green">View link details</button>
				</div>
				
			</div>
		)
	})

	return (
		<div className="table margin-small">
			<div className="table__row">
				<div className="table__heading is-fixed">
					<span 
                        className="is-sortable" 
                        onClick={() => props.handleSortBy("notes")}>
                        Description
                    </span>
				</div>
				<div className="table__heading is-fixed">
					<span 
                        className="is-sortable" 
                        // onClick={() => props.handleSortBy("updated")}
						>
                        Visits
                    </span>
				</div>
				<div className="table__heading is-fixed">
					<span 
                        className="is-sortable" 
                        onClick={() => props.handleSortBy("updated")}>
							{props.leads_title}
                    </span>
				</div>
				<div className="table__heading is-fixed"></div>
			</div>
			{links}
		</div>
	)
})

class PageSingleContainer extends Component {
	
	constructor(){
		super();
		this.state = {
			notFound: false,
			link: null
		}
		this.fetchMatchingLinks = this.fetchMatchingLinks.bind(this)
	}

	
	componentDidMount(){
		this.props.fetchSinglePage(this.props.page_id).then(response => {
			if(response === undefined){
				this.setState({
					notFound: true
				})
			}
		})

		BeaconRequest.get(`page/${this.props.page_id}/matchingLinks/`, {limit: 10, order: 'added:desc'}).then(res => {
			this.setState({link: res})
		})
	}

	fetchMatchingLinks(query) {
		BeaconRequest.paging(query).then(res => {
			this.setState({link: res})
		})

	}

	componentWillReceiveProps(nextProps){
		if (this.props.page_id !== nextProps.page_id || this.props.elementSelectedDateValue !== nextProps.elementSelectedDateValue){
			this.props.fetchSinglePage(this.props.page_id);
		}
    }

	render() {

		if(this.state.notFound){
			return (
				<div className="not-found">
					<h1>Page not available</h1>
					<p>This page can't be viewed at the moment. It may be that the page does not exist, or you do not have permission to see it.</p>
				</div>
			)
		}

		if(this.props.isFetching){
			return <Loader />
		}



		// If a page is found
		if(this.props.page){
			// Create a tools fragment for including in the render
			const tools = (
				<React.Fragment>
					<DocumentTitle title={this.props.page.path} />
					<div
						style={{gridTemplateColumns:'max-content auto', marginBottom: '20px',}}
						className="card"
					>
						<div style={{justifySelf:'end'}}>
							<a rel="noopener noreferrer" href={this.props.page.host + this.props.page.path} target="_blank">{this.props.page.path}</a>
							<i className="fas fa-external-link-alt" style={{marginLeft:6}}></i>
						</div>
					</div>
				</React.Fragment>
			)

			const noData = (
				<div className="message message--notice margin-medium">
					<h2 className="message__title">No activity</h2>
					<p>Unfortunately there was no activity.</p>
				</div>
			)

			// If a date is selected
			// if(this.props.elementSelectedDateValue){
				
			const pageStats = this.props.page.googlePageStats !== "none" && this.props.page.googlePageStats != null ? JSON.parse(this.props.page.googlePageStats) : {
				cumulativeLayoutShiftScore: 0,
				firstContentfulPaintScore: 0,
				largestContentfulPaintScore: 0,
				speedIndexScore: 0,
				timeToInteractiveScore: 0,
				totalBlockingTimeScore: 0,
				totalScoreDesktop: 0,
			}

			const headCells = [
				// { id: 'link_id', numeric: true, disablePadding: true, label: 'ID' },
				{ id: 'notes', numeric: false, disablePadding: true, label: 'Description', format: (rowData) => {
					return <>{rowData.notes}<div class="text-small color-mid-grey">Created {moment(rowData.added).format('DD MMM YYYY, h:mma')}</div></>
				} },
				{ id: 'shortCode', numeric: false, disablePadding: true, label: 'ShortCode', format: (rowData) => { 
					return <>
						<span>
							<ToolTip hint={true} text={"bcn.to/" + rowData.shortCode} tip={rowData.originalUrl} />
						</span>
						<CopyToClipboard text={'https://bcn.to/'+(rowData.shortCode)} onCopy={() => {
							
						}} >
							<button 
								type="button" 
								title="Copy to clipboard" 
								className="button button--small button--light-grey" 
								style={{marginLeft: '10px'}}
								onClick={e => {
									// e.preventDefault()

									this.props.addNotification({
										title:"Copied to clipboard",
										theme:"positive"
									})

									//stop bubbling from occuring
									if (!e) var e = window.event;
										e.cancelBubble = true;
										if (e.stopPropagation) e.stopPropagation();
									
								}}>
								<i className="fas fa-copy"></i>
							</button>
						</CopyToClipboard>
						
					</>
				} },
				
			  ];

			
			// If there is data for the current date 
			if(this.props.page.__scoring.visits.has_data){
				return (
					<div className="margin-large">
						{/* Render the tools */}
						{/* {tools} */}

						{/* Render the headline stats */}
						<Stats
							charts={false}
							data={this.props.page.__scoring} 
							screenshot={this.props.page.screenshot}
							pageStats={pageStats}
							pageReadySpeed={this.props.page.googlePageSpeed}
							pageDataCached={this.props.page.pageDataCached}

						/> 

						{/* Render the traffic graph */}
						<div className="margin-large">
							<SingleTrafficGraph 
							type="page"
							date={this.props.elementSelectedDateValue}
							data={this.props.page}
							/>
						</div>
		
						<div >
						 	<div className="stat-group">
								<h2 className="stat-group__title">
									Best Performing Links
								</h2>
								<div className="stat-group__content">
									{this.state.link != null && this.state.link.response.length > 0 ? <>
										<ShortLinksList 
											links={this.state.link.response} 
											handleSortBy={() => {}} 
											leads_title={this.props.websiteMeta.lead_text}
										/>
									</>
									:
									<div className="message message--notice margin-medium" style={{textAlign: 'left'}}>
										<h2 className="message__title">No links found</h2>
										<p>there are no matching links for this page.</p>
									</div>}
								</div>	
							</div>
						</div>
					</div>
				)
			} else {
				// If there is no data to show
				return <div>{tools}{noData}</div>
			}
		}

		return null
	}
}


const mapStateToProps = state => {
	return {
		page: state.pages.singlePage,
		isFetching: state.pages.isFetchingSinglePage,
		elementSelectedDateValue: state.dates.elementSelectedDateValue,
		websiteMeta: state.websites.selectedWebsite.__meta
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchSinglePage: (pageId) => dispatch(fetchSinglePage(pageId)),
		addNotification: (payload) => dispatch(addNotification(payload))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageSingleContainer));


