/**
 * This is a custom hook using the new Hooks API, this hook allows you to 
 * re-use statful logic between multiple components i.e. EngagedVisitors.js 
 * and CampaignLinks.js. Hooks do not share its state, all state an effects 
 * are fully isolated. To implement this custom hook for exisiting react components 
 * use withLazyListLoaderHOC.js function 
 */
import { useState } from 'react'
import BeaconRequest from '../BeaconRequest'

export default function useLazyListLoader() {
    /**
     * Use State returns a getter and setter, and is simailar to state in a react component
     */
    const [paging, setPaging] = useState({
        isFetchingNext: false
    });

    // useEffect(() => {
    //     setPaging(prevState => ({...paging, data: initialState}))
    // }, [props.initialState])

    /**
     * Sets the initial state using props
     * @param {data, next} props 
     */
    const setInitialData = ({data, next, dataLeft}) => {
        setPaging(prevState => ({...prevState, next, data, dataLeft}))
    }

    /**
     * onNext is a function for lazy loading the next iteration of data
     * it sets the isFetchingNext to true, requests the next set of data
     * and concatenates it with the current data. After the request has
     * completed sets isFetchingNext to false
     */
    const onNext = () => {
        if (paging.next != null) {
            setPaging(prevState => ({...prevState, isFetchingNext: true}))
            return BeaconRequest.paging(paging.next)
            .then(responseObj => {
                setPaging((prevState) => {
                    return {
                        data: prevState.data.concat(responseObj.response),
                        next: responseObj.paging.next,
                        dataLeft: responseObj.paging.totalResults,
                        isFetchingNext: false
                    } 
                })
            })
        }
    }

    /**
     * Returns an object containing the 3 consts above
     */
    return {
        paging, 
        onNext,
        setInitialData
    };


}
