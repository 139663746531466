import React from 'react';

const Help = props => {

	let { title, body, media } = props.content
	let help = { __html: body}

	return (
		<React.Fragment>
			{ media && <img src={media} alt={title} /> }
			<h1 className="help__title">{title}</h1>
			<p className="help__body" dangerouslySetInnerHTML={help} />
			{props.hideClose !== true &&
				<button type="button" className="help__close button button--small button--mid-grey" onClick={props.closeHelp}>
					<i className="fas fa-times"></i>
				</button>
			}
		</React.Fragment>
	)
}

export default Help;