import React, { Component } from 'react'
import { connect } from 'react-redux';
import MediaUpload from './MediaUpload';
import { getCustomerLogo, setCustomerLogo } from '../../actions/customers'
import { getWebsiteLogo, setWebsiteLogo } from '../../actions/websites'

class ElementLogo extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            logoURL: props.logoUrl,
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        switch (this.props.for) {
            case 'customer':
                this.props.getCustomerLogo().then(responseObj => this.setState({logoURL: responseObj.response.value}));
                break;
            case 'website':
                this.setState({logoURL: this.props.website.__meta.website_logo});
                break;
            default:
                break;
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.website !== prevProps.website || this.props.customer !== prevProps.customer) {
            this.setState({ logoURL: null});
            this.fetchData();
        }
    }

    handleDeleteLogo(){
        this.setState({
            logoURL:null
        });

        if(this.props.for === 'customer'){
            this.props.setCustomerLogo('');
        }

        if(this.props.for === 'website'){
            this.props.setWebsiteLogo('');
        }
    }

    handleUploadMedia(logo){
        if(this.props.for === 'customer'){
            this.props.setCustomerLogo(logo);
        }

        if(this.props.for === 'website'){
            this.props.setWebsiteLogo(logo);
        }

        this.setState({
            logoURL: logo
        });
    }

    render(){
        const dimentions = this.props.large ? 36 : 28;
        const getSingleString = () => {
            try {
                switch (this.props.for) {
                    case 'customer':
                        return this.props.customer.company.substring(0, 1);
                    case 'website':
                        return this.props.website.name.substring(0,1);
                    default:
                        return '-'
                }
            } catch(e) {
                return '-'
            }
        }

        if(this.props.logoOnly){
            if(!this.state.logoURL){ 
                return <div className="website-image website-image--small" style={{borderRadius: '50%', backgroundColor: 'white', margin: 'auto', height: dimentions, width: dimentions}}>
                    <h1 style={{width: '100%', height: '100%', fontSize: '100%', padding: '20%', textAlign: 'center', color: '#5E6172', fontWeight: '900'}}>
                        {this.props.alt ? this.props.alt.substring(0, 1).toUpperCase() : getSingleString()}
                    </h1>
                </div>
             }
            return <div className="website-image website-image--small" style={{backgroundColor: 'white',borderRadius: '50%', margin: 'auto', height: dimentions, width: dimentions}}>
                <img src={this.state.logoURL} style={{objectFit: 'contain'}} alt="Company logo" />
            </div>
        }

        if(!this.props.isAdmin && this.state.logoURL && this.state.logoURL != ''){
            return <div className="logo-upload"><img src={this.state.logoURL} className="logo-upload__image" /></div>
        }

        if(this.state.logoURL == null || this.state.logoURL == ''){
            return <MediaUpload disabled={!this.props.isAdmin} handleUploadMedia={this.handleUploadMedia.bind(this)} />
        }

        return (
            <div className="logo-upload">
                <img src={this.state.logoURL} className="logo-upload__image" />
                <button onClick={this.handleDeleteLogo.bind(this)} className="logo-upload__button">
                    <i className="fas fa-times"></i>
                </button>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
      website: state.websites.selectedWebsite,
      customer: state.customers.selectedCustomer,
      userMeta: state.login.userMeta,
    }
  }

const mapDispatchToProps = dispatch => {
	return {
        getCustomerLogo: () => dispatch(getCustomerLogo()),
        setCustomerLogo: (logo) => dispatch(setCustomerLogo(logo)),
        setWebsiteLogo: (logo) => dispatch(setWebsiteLogo(logo)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ElementLogo)
