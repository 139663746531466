// new Manager config
export const BeaconRequest_ver = "2.0";
export const appVer = "5.8.6";

let beaconRequestSystem = ""; // "LIVE_API", "QA_API", "STEW_API", "MARTIN_API, ASPEN_API, INSIGHT_API"
let beaconDevMode = "";
let beaconReporting = "";
let beaconExport = "";

export let BeaconRequest_publickey =
  "00c5cb0b2f263508ed47e857c37b3fbd229d6af77bf6ea112f1022c1324abb57";
export let BeaconRequest_client = "862546c73d1e403ad6b9f3c5c7c94589";

switch (document.location.hostname) {
  case "analytics.thisisbeacon.com":
  case "reporting.analytics.thisisbeacon.com":
  case "app.thisisbeacon.com":
    beaconRequestSystem = "LIVE_API";
    beaconDevMode = false;
    beaconReporting = "https://reporting.analytics.thisisbeacon.com";
    beaconExport = "https://exporting.analytics.thisisbeacon.com";
    console.protoLog = console.log;
    console.protoDebug = console.debug;
    console.explictDebug = false;
    console.log = function(...args) {
      if (console.explictDebug === true) {
        console.protoLog.apply(null, args);
      }
    };
    console.debug = function(...args) {
      if (console.explictDebug === true) {
        console.protoDebug.apply(null, args);
      }
    };
    break;
  case "localhost":
  default:
    beaconRequestSystem = "QA_API";
    beaconDevMode = true;
    beaconReporting = "http://127.0.0.1:3000";
    break;
  case "insights.thisisbeacon.com":
  case "reports.thisisbeacon.com":
    beaconRequestSystem = "INSIGHT_API";
    beaconDevMode = false;
    beaconReporting = "http://reports.thisisbeacon.com";
    console.protoLog = console.log;
    console.protoDebug = console.debug;
    console.explictDebug = false;
    console.log = function(...args) {
      if (console.explictDebug === true) {
        console.protoLog.apply(null, args);
      }
    };
    console.debug = function(...args) {
      if (console.explictDebug === true) {
        console.protoDebug.apply(null, args);
      }
    };
    BeaconRequest_client = "491fbe4cb4da2f3c093cea4fcbf3fe81";
    break;
  case "demo.thisisbeacon.com":
    beaconRequestSystem = "DEMO_API";
    beaconDevMode = false;
    beaconReporting = "https://reports.thisisbeacon.com";
    console.protoLog = console.log;
    console.protoDebug = console.debug;
    console.explictDebug = false;
    console.log = function(...args) {
      if (console.explictDebug === true) {
        console.protoLog.apply(null, args);
      }
    };
    console.debug = function(...args) {
      if (console.explictDebug === true) {
        console.protoDebug.apply(null, args);
      }
    };
    break;
  case "qa.thisisbeacon.com":
    beaconRequestSystem = "QA_API";
    beaconDevMode = true;
    beaconReporting = "https://reportingtest.thisisbeacon.com";
    console.protoLog = console.log;
    console.protoDebug = console.debug;
    console.explictDebug = false;
    console.log = function(...args) {
      if (console.explictDebug === true) {
        console.protoLog.apply(null, args);
      }
    };
    console.debug = function(...args) {
      if (console.explictDebug === true) {
        console.protoDebug.apply(null, args);
      }
    };
    break;
}

export const BeaconRequest_environment = beaconRequestSystem;

export const Beacon_reporting = beaconReporting;

export const DEV = beaconDevMode;
