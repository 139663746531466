export const purple = '#2E294E';
export const lilac = '#7136bc';
export const blue = '#25CCCF';
export const blue_2 = '#27b2c4';
export const blue_3 = '#2898ba';
export const blue_4 = '#2a7fb0';
export const blue_5 = '#2b65a5';
export const blue_6 = '#2E3190';
export const pink = '#FF3190';
export const yellow = '#FFD200';
export const red = '#f04c4c';
export const light_grey = '#E6EBED';
export const mid_grey = '#989898';
export const dark_grey = '#5E6172';
export const light_orange = '#E28413';
export const dark_orange = '#C45D18';
export const light_maroon = '#ef3e36';
export const mid_maroon = '#962727';
export const dark_maroon = '#470606';
export const green = '#389363';

export const visits = pink;
export const unique_visitors = yellow;
export const repeat_visits = light_orange;
export const hard_bounce = light_maroon;
export const soft_bounce = mid_maroon;
export const multi_page = dark_maroon;

export const twitter = '#28AAE1';
export const facebook = '#3B5999';
export const linkedin = '#0077B5';
export const googleads = '#3cba54';
export const microsoftads = "#0078d4";

// '#33CBCE','#3C9265', '#F08701', '#D34E24', 
// '#FECF2F', '#FC3790', '#2F4A25', '#1D3788', 
// '#78C0E0','#F2D6EE', '#363537', '#BC8DA7', 
// '#713CB9','#5DD9C1', '#664212', '#FFA4A5', 
// '#4F0D20', '#FFD6BF', '#000000', '#ABFCD8', 
// '#AB81CD',

// '#28AAE1', '#3B5999', '#0077B5', '#3cba54'


export const beaconColours = [
	
	'#3cba54',
	'#3b5998',
	'#0077b5',
	'#1da1f2',
	'#d2b48c',
	'#808080',
	'#a9a9a9',
	'#f08080',
	'#ff0000',
	'#dc143c',
	'#b22222',
	'#8b0000',
	'#ff1493',
	'#ff00ff',
	'#ffa500',
	'#ff8c00',
	'#8b4513',
	'#a52a2a',
	'#d2691e',
	'#ffd700',
	'#ffff00',
	'#bdb76b',
	'#9acd32',
	'#00ff00',
	'#008000',
	'#556b2f',
	'#5f9ea0',
	'#008080',
	'#b0e0e6',
	'#00ffff',
	'#00ced1',
	'#7fffd4',
	'#4169e1',
	'#00bfff',
	'#1e90ff',
	'#483d8b',
	'#0000ff',
	'#00008b',
	'#4b0082',
	'#800080',
	'#9400d3',
	'#ee82ee',
]
