import React, {useState, useEffect, Suspense} from 'react'
import Loader from '../../components/general/Loader';
import {withWidgetContainer} from '../withWidgetContainer'
import {
    ResponsiveContainer, PieChart, Pie, Sector, Cell, Label
  } from 'recharts';

import { connect } from 'react-redux';
import moment from 'moment-timezone'
import FormatNumber from '../../components/general/FormatNumber'

import {setBotActivityResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const BotActivity = (props) => {
    const resource = useSelector(({botActivity}) => botActivity.data)
    const isFetching = useSelector(({botActivity}) => botActivity.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setBotActivityResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Skeleton variant="rect" width='100%' height={326} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
            
        >
            <h3 className="stat-group__title" >{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
                <BotActivityChild {...props} resource={resource}/>
            </div>
        </Card>
    )
}

const InnerPieLabel = (props) => {
    return (
        <g>
        <text x={props.viewBox.cx} y={props.viewBox.cy - 5} textAnchor="middle" dominantBaseline="middle" fontSize="30px" font="Lato" fill="#5E6172">
            <FormatNumber val={props.total} />
        </text>
        <text x={props.viewBox.cx} y={props.viewBox.cy + 20} textAnchor="middle" dominantBaseline="middle" fontSize="13px" fill='#989898'>
            Total link clicks
        </text>
        </g>
    );
};

const BotActivityChild = (props) => {
    if (props.resource == null) return <Loader/>
    const graph = props.resource.graph.response
    const blocked_bots = props.resource.blocked_bots.response
    const clicks_graph = props.resource.actual_clicks.response
    const visits_graph = props.resource.actual_visits.response

    const sum_predicate = (acc, val) => acc + val.data
    const sum_clicks = clicks_graph.graph.reduce(sum_predicate, 0)
    const sum_visits = visits_graph.graph.reduce(sum_predicate, 0)
    const actual_bots = sum_clicks - sum_visits < 0 ? 0 : sum_clicks - sum_visits

    const COLORS = ['url(#pieBots)', 'url(#pieHumans)']
    const data = [
        {name: 'Bots', value: actual_bots},
        {name: 'Humans', value: sum_visits},
    ] 

    const layoutChange = {w: 1}

    const split = props.datetime.split(":")

    const isToday = props.datetime == "today" || (moment.unix(split[0]).unix() == moment().startOf('d').unix() && moment.unix(split[1]).unix() == moment().endOf('d').unix())
    const isOneDayPeriod = 
        props.datetime == "yesterday" 
        || moment.unix(split[1]).diff(moment.unix(split[0]), 'days') == 0
    
    if (isToday) {
        return <div style={{display: 'flex', fontSize: '25px', color: '#808080a6', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <i class="fas fa-exclamation-circle"></i>
        </div>
    }

    return <>
        <div style={{
            display: 'grid', 
            gridTemplateRows: layoutChange.w === 1 ? 'auto max-content': 'none',
            gridTemplateColumns: layoutChange.w > 1 ? 'max-content auto': 'none',
        }}>
            <div style={{
                textAlign: 'center', 
                margin: '10px',
                marginTop: '20px'
            }}>
                {/* <h3 class="stat-group__title">Bot Protection</h3> */}
                <div style={{display: 'grid', gridTemplateColumns: 'auto max-content'}}>
                    <div style={{}}>
                        
                        <div>
                            {isToday ?
                                <div className="text-small" style={{margin: '80px 10px', textAlign: 'center', color: '#989898'}}>
                                    <i className="margin-small text-large fas fa-robot" style={{fontSize: '40px'}}></i>
                                    <div>
                                        Detected bots will be displayed here for periods other than today.
                                    </div>
                                </div>
                            : <>
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: '60% auto'
                                }}>
                                    
                                    <ResponsiveContainer  width={'99%'} height={200}>
                                   
                                        <PieChart>
                                            <defs>
                                                <linearGradient id="pieBots" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="10%" stopColor={"#642f86"} stopOpacity={1} />
                                                    <stop offset="90%" stopColor="#d4298c" stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <defs>
                                                <linearGradient id="pieHumans" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="10%" stopColor="#6dd0ac" stopOpacity={1} />
                                                    <stop offset="90%" stopColor="#12d539" stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <Pie
                                                data={data}
                                                innerRadius={30}
                                                outerRadius={70}
                                                stroke="none"
                                                dataKey="value"
                                                isAnimationActive={false}
                                            >
                                                {
                                                    data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                }
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>

                                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                                        <div  style={{textAlign: 'left', marginTop: '20px'}} >
                                            <span className="click-fraud__item humans" style={{fontSize: '16px', color: '#40d274'}}>
                                                <i class="fas fa-user" style={{fontSize: '16px', marginRight: '10px'}}></i>
                                                    <FormatNumber val={sum_visits} />
                                                <span className="card__symbol"> </span>
                                            </span>
                                            <span className="color-mid-grey text-small">
                                                ({sum_clicks !=  0 ? ((sum_visits/sum_clicks)*100).toFixed(1) :'0.0'}%)
                                            </span>
                                            <div className="color-dark-grey text-small">
                                                Human Visitors 
                                            </div>
                                        </div>


                                        <div  style={{textAlign: 'left', marginTop: '10px'}} >
                                            <span className="click-fraud__item bots" style={{fontSize: "16px", color: '#d4298c' }}>
                                                <i class="fas fa-robot" style={{fontSize: '16px', marginRight: '10px'}}></i><FormatNumber val={actual_bots} /><span className="card__symbol"> </span>
                                            </span>
                                            <span className="color-mid-grey text-small">
                                                ({sum_clicks !=  0 ? ((actual_bots/sum_clicks)*100).toFixed(1) : '0.0'}%)
                                            </span>
                                            <div className="color-dark-grey text-small">
                                                Suspected Bots
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                            </>}
                        </div>
                    </div>
                </div>
            </div>

            <div style={{margin: '20px 0'}}>
                <div className="click-fraud__item" style={{textAlign: 'center',}} >
                    <span className="card__highlight" style={{lineHeight: '30px', fontSize: '30px',}}>
                        <i class="fas fa-shield-alt" style={{ fontSize: '30px', marginRight: '10px'}}></i><span className="card__symbol"> </span>
                    </span>
                    <span className="color-mid-grey text-small">Detecting for: </span>
                    <span className="card__highlight" style={{lineHeight: '30px', fontSize: '30px',}}>
                        <FormatNumber val={blocked_bots.total_bot_number || 0} /> {" "} <span className="card__symbol"> </span>
                    </span>
                    <span className="color-mid-grey text-small">
                        bots
                    </span>
                </div>
            </div>

        </div>
    
    </>
}

const mapStateToProps = state => {
	return {
		website: state.websites.selectedWebsite,
	}
}


export default connect(mapStateToProps, null)(BotActivity)
