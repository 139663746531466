import React, {useState, useEffect, useRef} from 'react'
import Loader from '../../components/general/Loader';
import {withWidgetContainer} from '../withWidgetContainer'

import { connect } from 'react-redux';
import * as colours from '../../colours'

import BubbleChart from './react-bubble-chart-d3';
import ResizeSensor  from 'css-element-queries/src/ResizeSensor'

import {setBrowserProfileResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const BrowserProfile = (props) => {
    const resource = useSelector(({browserProfile}) => browserProfile.data)
    const isFetching = useSelector(({browserProfile}) => browserProfile.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setBrowserProfileResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Skeleton variant="rect" width='100%' height={362} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
        >
            <h3 className="stat-group__title" style={{zIndex:0}}>{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
                <BrowserProfileChild {...props} resource={resource}/>
            </div>
        </Card>
    )
}


const BrowserProfileChild = (props) => {
    const [width, setWidth] = useState(320)
    // const [height, setHeight] = useState(230)
    const [option, setOption] = useState('os') //os, browser, device
    const containerRef = useRef();

    React.useEffect(() => {
        let sensor = new ResizeSensor(containerRef.current, () => {
            setWidth(containerRef.current.offsetWidth)
            // setHeight(containerRef.current.offsetHeight)
        })
        return () => {
            sensor.detach()
        }
    }, [containerRef])


    if (props.resource == null) return <Loader/>
    const browser_data = props.resource.browser.response

    const view = () => {
        if (props.resource.browser.response == null) return []
        switch (option) {
            case 'os':
            default:
                return  Object.keys(browser_data.OS).filter(key => key != "total").map((key,index) => {
                    if (key == "") {
                        return { label: "other", value: browser_data.OS[key].total.count, color: colours.beaconColours[index]  }
                    } 
                    return  { label: key, value: browser_data.OS[key].total.count, color: colours.beaconColours[index]  }
                })
            case 'browser':
                return Object.keys(browser_data.browser).filter(key => key != "total").map((key,index) => {
                    if (key == "") {
                        return { label: "other", value: browser_data.browser[key].total.count, color: colours.beaconColours[index]  }
                    }
                    return { label: key, value: browser_data.browser[key].total.count, color: colours.beaconColours[index]  }

                })
            case 'device': 
                return Object.keys(browser_data.device).filter(key => key != "total").map((key,index) => {
                    if (key == "") {
                        return { label: "other", value: browser_data.device[key].count.toLocaleString(), color: colours.beaconColours[index]  }
                    }
                    return { label: key, value: browser_data.device[key].count, color: colours.beaconColours[index] }
                })
        }
    }

    return <>
        <div 
            style={{border: 'none', gridTemplateColumns:"60% auto"}} 
        >
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '100%', flexWrap: 'wrap'}}>
                <span 
                    style={{padding: '10px', borderBottom: option == 'os' ? '3px solid #ffd200' : null, cursor: 'pointer', fontSize: '16px'}}
                    onClick={e => setOption('os')}
                >
                    OS
                </span>

                
                <span 
                    style={{padding: '10px', borderBottom: option == 'device' ? '3px solid #ffd200' : null, cursor: 'pointer', fontSize: '16px'}}
                    onClick={e => setOption('device')}
                >
                    Device
                </span>
                

                <span 
                    style={{padding: '10px', borderBottom: option == 'browser' ? '3px solid #ffd200' : null, cursor: 'pointer', fontSize: '16px'}}
                    onClick={e => setOption('browser')} 
                >
                    Browser
                </span>
            </div>

            <div ref={containerRef}>
                <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    <BubbleChart
                        graph= {{
                            zoom: 0.8,
                            offsetX: 0.08,
                            offsetY: 0.05,
                        }}
                        width={width} //320
                        height={260} //290
                        padding={0} 
                        showLegend={false} 
                        valueFont={{
                                family: 'Montserrat',
                                size: 13,
                                color: '#fff',
                                weight: 'bold',
                            }}
                        labelFont={{
                                family: 'Montserrat',
                                size: 16,
                                color: '#fff',
                                weight: 'bold',
                            }}
                        overflow={false}
                        data={view()}
                    />
                </div>
            </div>
        </div>

    </>
}

const mapStateToProps = state => {
	return {
		website: state.websites.selectedWebsite,
	}
}


export default connect(mapStateToProps, null)(BrowserProfile)
