import React from "react";
import CurrencySymbol from "./CurrencySymbol";

const FormatNumber = props => {
  // https://stackoverflow.com/q/2901102/874691
  if (props.dashZero && props.val == 0) {
    return <>&mdash;</>;
  } else if (props.money === true) {
    return (
      <>
        {props.money === true && <CurrencySymbol />}
        {Number(props.val).toLocaleString("en", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </>
    );
  } else if (props.percent) {
    return <>{(props.val * 100).toFixed(1) + "%"}</>;
  } else if (props.val % 1 != 0) {
    // https://stackoverflow.com/a/31581206/874691
    return (
      <>
        {Number(props.val).toLocaleString("en", { minimumFractionDigits: 2 })}
      </>
    );
  } else {
    return <>{Number(props.val).toLocaleString("en")}</>;
  }
};

export default FormatNumber;
