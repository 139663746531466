import BeaconRequest from '../BeaconRequest';

// Import action types
// import { 
// 	START_FIRST_SUBSCRIPTION
// } from './actionTypes';

export const startFirstSubscription = () =>{
	return (dispatch, getState) => {
		const customer_id = getState().customers.selectedCustomer.customer_id
		return BeaconRequest.post('customer/' + customer_id + '/startSubscription', {}, {})
		.then(responseObj => {
			// Return the response to the method calling it so it can be used to open a new window
			return responseObj.response
		})
		.catch(error => { console.log(error) })
	}
}