import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSingleVisit } from '../../actions/visits';
import { unsetElementSelectedDate } from '../../actions/dates';
import Loader from '../../components/general/Loader';
import VisitDetail from '../../components/visit/VisitDetail';
import VisitJourney from '../../components/visit/VisitJourney'
import {BeaconRequest} from '../../BeaconRequest';
import VisitorMap from '../../components/general/VisitorMap'

class VisitSingleContainer extends Component {
	constructor(){
		super();
		this.state = {
			notFound: false,
			geo_data: null,
			browser_data: null,
		}
	}

	componentDidMount(){
		BeaconRequest.get(`visit/${this.props.visit_id}/browser`).then(e => this.setState({browser_data: e.response}))
		BeaconRequest.get(`geo/visit/${this.props.visit_id}`).then(e => this.setState({geo_data: e.response.countries}))
		
			
		this.props.fetchSingleVisit(this.props.visit_id).then(response => {
			if(response === undefined){
				this.setState({
					notFound: true
				})
			}
		})
    }
    
	componentWillReceiveProps(nextProps){
		if (this.props.visit_id !== nextProps.visit_id || this.props.elementSelectedDateValue !== nextProps.elementSelectedDateValue){
			this.props.fetchSingleVisit(this.props.visit_id);
        }
    }

    componentWillUnmount(){
    	// On unmounting, clear the date state for the post the user is viewing
    	// On user feedback, we may want to stop this from happening, but let's see
    	// this.props.unsetElementSelectedDate()
    }

	render() {
		if(this.state.notFound){
			return (
				<div className="not-found">
					<h1>Visit not available</h1>
					<p>This visit can't be viewed at the moment. It may be that the visit does not exist, or you do not have permission to see it.</p>
				</div>
			)
		}

		if(this.props.isFetching){
			return <Loader />
		}

		if(this.props.visit){
			return (
				// Add visit link as well and check if it actually exists
				<div>
					<div className="margin-medium">
						<VisitDetail visit={this.props.visit} />
					</div>
					<div className="margin-large">
						<VisitJourney journey={this.props.visit.__journey} />
					</div>
					<div style={{display: 'grid', gridTemplateColumns: 'max-content auto', gridGap: '30px'}}>
						<div style={{width: '700px'}}>
						{this.state.geo_data != null &&
							<VisitorMap data={this.state.geo_data}/>
						}
						</div>
						{this.state.browser_data && <>
							<div className="margin-xlarge">
								<div className="card" style={{height: '100%'}}>
									<div className="card__header">
										<div className="card__title">
											<h3 style={{float:"left"}}>Browser Profile</h3>
											<i style={{float:"right"}} className="far fa-window-maximize"></i>
										</div>
									</div>
									<div className="card__content" >
										<dl>
											<div className="form__two-column">
												<div className="definition-list__item">
													<dt className="definition-list__title">OS</dt>
													<dd className="definition-list__value">{this.state.browser_data.os.name}</dd>
												</div>
												<div className="definition-list__item">
													<dt className="definition-list__title">OS version</dt>
													<dd className="definition-list__value">{this.state.browser_data.os.version}</dd>
												</div>
											</div>
											<div className="form__two-column">
												<div className="definition-list__item">
													<dt className="definition-list__title">Browser</dt>
													<dd className="definition-list__value">{ this.state.browser_data.browser.name }</dd>
												</div>
						
												<div className="definition-list__item">
													<dt className="definition-list__title">Browser version</dt>
													<dd className="definition-list__value">{ this.state.browser_data.browser.version }</dd>
												</div>
											</div>
											<div className="form__two-column">
												<div className="definition-list__item">
													<dt className="definition-list__title">Device</dt>
													<dd className="definition-list__value">{ this.state.browser_data.isMobile == 0 ? 'Desktop' : 'Mobile' }</dd>
												</div>
						
												{/* <div className="definition-list__item">
													<dt className="definition-list__title">User agent</dt>
													<dd className="definition-list__value">{ this.state.browser_data. }</dd>
												</div> */}
											</div>
										</dl>
									</div>
								</div>
								</div>	
						</>}
						
						
					</div>
				</div>
			)
		}

		return null
	}
}

const mapStateToProps = state => {
	return {
		visit: state.visits.singleVisit,
		isFetching: state.visits.isFetchingSingleVisit,
		elementSelectedDateValue: state.dates.elementSelectedDateValue
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchSingleVisit: (visitId) => dispatch(fetchSingleVisit(visitId)),
		unsetElementSelectedDate: () => dispatch(unsetElementSelectedDate())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitSingleContainer);