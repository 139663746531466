import React, {useEffect, useRef} from 'react'
import Loader from '../../components/general/Loader';
import {withWidgetContainer} from '../withWidgetContainer'
import VisitorMap from '../../components/general/VisitorMap'
import {setVisitorProfileResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const VisitorProfile = (props) => {
    const resource = useSelector(({visitorProfile}) => visitorProfile.data)
    const isFetching = useSelector(({visitorProfile}) => visitorProfile.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const containerRef = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setVisitorProfileResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Skeleton variant="rect" width='100%' height={362} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
        >
            <h3 className="stat-group__title" style={{zIndex:0}}>{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
                <VisitorProfileChild containerRef={containerRef} {...props} resource={resource}/>
            </div>
        </Card>
    )
    
}

const VisitorProfileChild = (props) => {
    if (props.resource == null ) return <Loader/>

    const graph = props.resource.graph.response

    return <VisitorMap headless data={graph.countries} tileWidth={2}/>
}

export default VisitorProfile