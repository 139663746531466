import React from 'react';
import { Link } from 'react-router-dom';
import StarRating from '../general/StarRating';
import moment from 'moment-timezone';
import _ from 'lodash';
import ToolTip from '../general/ToolTip';
import VisitorActions from '../visitor/VisitorActions';

const VisitsList = props => {

    const visits = props.visits.map(visit => {
        const { 
            visit_id, 
            totalTimeOnSite, 
            pageViews, 
            scoringActions,
            startOfVisitDate, 
            __link,
            added,
            lead
        } = visit
        const { shortCode, originalUrl } = visit.__link

        return (
            <Link to={{pathname: "/visits/" + (visit.visit_id), 
            state: { modal: true, referrer: window.location.pathname } }}
            key={visit_id} className="table__row table__row--link">
				<div className="table__cell">
                    <div>
                        { 
                            visit.doNotTrack === 1 && 
                            <a rel="noopener noreferrer" href="https://www.thisisbeacon.com/resource/what-is-a-do-not-track-visit/" target="_blank" style={{paddingRight:5}}><ToolTip text={<i className="fas fa-ban"></i>} tip="Visitor has Do Not Track activated - click to find out more" /></a>
                        }
                        {moment(startOfVisitDate).format('DD MMM YYYY')} at {moment(added).format('LT')}
                    </div>
                    <Link 
                    title="View the details for this link"
                    className="text-small color-mid-grey"
                    to={{pathname: "/links/" + (visit.__link.link_id), 
                    state: { modal: true, referrer: window.location.pathname } }}>
                        <ToolTip text={"bcn.to/"+shortCode} tip={originalUrl} />
                    </Link>
				</div>

                <div className="table__cell no-wrap" style={{maxWidth:200}}>
                    
                    { 
                        !_.isEmpty(visit.__campaign) ? <div title={visit.__campaign.title}>
                        <Link 
						to={{pathname: "/campaigns/" + (visit.__campaign.campaign_id), 
                        state: { modal: true, referrer: window.location.pathname } }}>
                        <span className="key-dot" style={{backgroundColor:visit.__campaign.colour}}></span>
                        {visit.__campaign.title}
                        </Link> </div> : "No campaign" 
                    }
                   
                    
                    { 
                        !_.isEmpty(visit.__channel) ? <div title={visit.__channel.title}>
                        <Link 
                        className="color-mid-grey"
						to={{pathname: "/channels/" + (visit.__channel.channel_id), 
                        state: { modal: true, referrer: window.location.pathname } }}>
                        <span className="key-dot" style={{backgroundColor:visit.__channel.colour}}></span>
                        {visit.__channel.title}
                        </Link> </div>
                        : "No channel"     
                    }
                </div>

                <div className="table__cell no-wrap" style={{maxWidth:200}} title={__link.notes}>
                    {__link.notes || <em>No description</em>}
                </div>

                <div className="table__cell">
                    <StarRating stars={visit.star_rating} />
                </div>
                <div className="table__cell">
                    {
                        totalTimeOnSite > 3600 ? moment.utc(totalTimeOnSite*1000).format('HH:mm:ss') : moment.utc(totalTimeOnSite*1000).format('mm:ss')
                    }
				</div>
                <div className="table__cell">
					{pageViews}
				</div>
                <div className="table__cell">
                    {
                        !_.isEmpty(scoringActions) && <VisitorActions lead={lead} actions={scoringActions} />
                    }
                </div>
            </Link>
        )
    })

    return (
        <React.Fragment>
            <div className="table">
                <div className="table__row">
                    <div className="table__heading is-fixed" style={{top: props.noContainer ? '-21px' : 99}}>
                        <span 
                            className="is-sortable" 
                            onClick={() => props.handleSortBy("added")}>
                            Visit
                        </span>
                    </div>

                    <div className="table__heading is-fixed" style={{top: props.noContainer ? '-21px' : 99}}>Campaign/Channel</div>

                    <div className="table__heading is-fixed" style={{top: props.noContainer ? '-21px' : 99}}>Link Description</div>

                    <div className="table__heading is-fixed" style={{top: props.noContainer ? '-21px' : 99}}>
                        <span 
                        className="is-sortable" 
                        onClick={() => props.handleSortBy("score")}
                        >
                        Rating
                        </span>
                        <span className="table__options">
                        </span>
                    </div>

                    <div className="table__heading is-fixed" style={{top: props.noContainer ? '-21px' : 99}}>
                        <span 
                            className="is-sortable" 
                            onClick={() => props.handleSortBy("totalTimeOnSite")}>
                            Duration
                        </span>
                    </div>

                    <div className="table__heading is-fixed" style={{top: props.noContainer ? '-21px' : 99}}>
                        <span 
                        className="is-sortable" 
                        onClick={() => props.handleSortBy("pageViews")}>
                        Page Views
                        </span>
                    </div>

                    <div className="table__heading is-fixed"  style={{top: props.noContainer ? '-21px' : 99}}>Visitor Actions</div>
                </div>
                {visits}
            </div>

            {
                _.isEmpty(visits) &&
                <div className="message message--warning" style={{marginTop:20}}>
                    <h2 className="message__title">No visits to show</h2>
                    <p className="no-margin">You have no recorded website visits for the criteria selected.</p>
                </div>
            }
        </React.Fragment>
    )
}

export default VisitsList;