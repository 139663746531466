import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginForm from './components/customer/LoginForm';
import SplashWebsiteSelector from './components/website/SplashWebsiteSelector';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Application from './components/Application'
import FirstLogin from './components/first_login/FirstLogin'
import BeaconRequest from './BeaconRequest'
import Loader from './components/general/Loader'
import Logout from './components/customer/Logout'
import './scss/index.css'

let timeout_timer;
class App extends Component {
  constructor(props) {
    super(props);
    this.state =  { timeout: false}
    this.setTimeout = this.setTimeout.bind(this)
  }

  setTimeout () {
    this.setState({timeout: true})
  }

  render() {	
    // If the user is logged in and their token has not expired, show the application
    if(BeaconRequest.isLoggedIn()){

      // if first login, show the intro stuff
      if(this.props.hasCompletedOnboarding === 0){
        return (
          <Router>
            <Route component={FirstLogin} />
          </Router>
        )
      }
     
      if(this.props.selectedWebsite && this.props.selectedWebsiteId && this.props.userMeta) {
        window.clearTimeout(timeout_timer)
        return (
          <Router>
            <Route component={Application} />
          </Router>
        )
      } else {
        if(this.props.currentUser){
          window.clearTimeout(timeout_timer)
          return <div className="external"><SplashWebsiteSelector /></div>
        } else {
          timeout_timer = window.setTimeout(() => {this.setTimeout()}, 30000);
          return (
            <div className="external">
              <div className="external__logout"><Logout /></div>
              {this.state.timeout ? <>
                <div style={{color: 'white', width: '500px', fontSize: '22px', textAlign: 'center', lineHeight: '1.5'}}>
                  Something may have gone wrong, please click the logout button top right of this screen. 
                  If this happens more than once, please see <a style={{color: 'white', fontSize: '22px',}} href="https://www.thisisbeacon.com/guides/browser-cache-issues/" target="_blank">https://www.thisisbeacon.com/guides/browser-cache-issues/</a> for further assistance.
                </div>
                </>:
                <Loader colour="light" />
              }
            </div>
          )
        }
      }
    }
    return (
      <Router>
        <div className="external">
          <Switch>
            <Route component={LoginForm} />
          </Switch>
        </div>
      </Router>
    )
    
  }
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
    currentUser: state.login.currentUser,
    userMeta: state.login.userMeta,
    hasCompletedOnboarding: state.customers.hasCompletedOnboarding,
    selectedWebsite: state.websites.selectedWebsite,
    selectedWebsiteId: state.websites.selectedWebsiteId // check if a website has been selected and assign to props
  }
}

export default connect(mapStateToProps, null)(App);
