import React, {useEffect, useState} from 'react'
import BeaconRequest from '../../BeaconRequest'
import moment from 'moment-timezone'
import LoaderAlt from '../../components/general/LoaderAlt'
import { connect } from 'react-redux';
import { addNotification } from '../../actions/ui'
import Modal from '../../components/general/Modal'

const WebsiteAliases = (props) => {
    const [aliases, setAlaises] = useState([])
    const [error, setError] = useState(false)
    const [loadingAliases, setLoadingAliases] = useState(false)
    const [modal, setModal] = useState(false)

    const [newUrl, setNewUrl] = useState("")
    const [newError, setNewError] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    const get_aliases = () => {
        setLoadingAliases(true)
        BeaconRequest.getNoChache(`websitealias/${props.website.website_id}`)
            .then(e => {
                setAlaises(e.response)
            }).catch(e => {
                setError(true)
            }).finally(e => {
                setLoadingAliases(false)
            })
    }

    const delete_alias = alias => {
        BeaconRequest.delete(`websitealias/${props.website.website_id}/alias/${alias.alias_id}`)
            .then(e => {
               props.addNotification({
                    title: `Deleted ${alias.url} successfully`,
                    theme: "positive"
                })
                get_aliases()
            })
            .catch(e => {
                props.addNotification({
                    title: `Unable to delete ${alias.url}`,
                    theme: "negative"
                })
            })
            
    }

    useEffect(() => {
        get_aliases()
    }, [])

    const submit = (e) => {
        if (setNewUrl == "") {
            alert("You have not entered a URL")
            return 
        }

        setSubmitting(true)
        setNewError(null)
        BeaconRequest.post(`websitealias/${props.website.website_id}`, {}, {url: newUrl})
        .then(e => {
            setNewError(null)
            setSubmitting(false)
            props.addNotification({
                title: "A new website alias has been successfully added",
                theme: "positive"
            })
            setModal(false)
            get_aliases()
        }).catch(e => {
            if (e.errorText || e.message) setNewError(e.errorText || e.message)
            else setNewError("Unexpected error, unable to add website alias")
            setSubmitting(false)
        })
    }

    return <div class="card">
        {modal && 
            <Modal
                title={"New website alias"}
                closeAction={() => {
                    setNewUrl("")
                    setNewError(null)
                    setModal(false)
                }}
                disableSave={true}
                saveLabel="Create"
                message={
                    newError &&
                    <div className="message message--warning">
                        <h2 className="message__title">Unable to create support ticket</h2>
                        <p>{newError}</p>
                        <p>If this error persists please create a ticket or contact us at support@thisisbeacon.com</p>
                    </div>
                }
            >
               <div>
                    <label className="form__label">Enter a website alias URL</label>
                    <input className="form__input margin-medium" type="url" onChange={e => setNewUrl(e.target.value)} value={newUrl}></input>

                    <button type="submit" className="button button--green" disabled={submitting} onClick={submit}>Add</button>
                </div>
                {/* {newError &&
                    <div className="message message--warning">
                        <h2 className="message__title">Unable to create support ticket</h2>
                        <p>{newError}</p>
                        <p>If this error persists please create a ticket or contact us at support@thisisbeacon.com</p>
                    </div>
                } */}

                {submitting && <LoaderAlt />}
            </Modal>
        }
        <div class="card__header">
            <h2 class="card__title">Website aliases</h2>
            
        </div>
        <div class="card__content">
            <label className="text-small">Website aliases allows Ad Fraud Protection to track multiple websites as one.</label>

            <div className="margin-medium" style={{display: 'grid', gridTemplateColumns: 'max-content auto'}}>
                <div style={{textAlign: 'left', marginTop: '10px'}} className="margin-large">
                    <button 
                        className="button" 
                        style={{
                            backgroundColor: '#25CCCF', 
                            color: 'white',
                            marginRight: '10px'
                        }} 
                        onClick={e => {
                            setModal(true)
                        }}
                    >
                            Add a new alias
                    </button>
                </div>
            </div>

            {loadingAliases ? 
                <LoaderAlt />
            :
                <div>
                    {error ? 
                        <div className="message message--warning">
                            <h2 className="message__title">Unable retrieve website aliases</h2>
                            <p>If this error persists please create a ticket or contact us at support@thisisbeacon.com</p>
                        </div>
                    : 
                        <div>
                            {aliases.length == 0 ?
                                <div className="message message--notice">
                                    <h2 className="message__title">This website has no aliases</h2>
                                </div>
                            :
                                <div className="table margin-large" style={{marginTop:10}}>
                                    <div className="table__row">
                                        <div className="table__heading">Added</div>
                                        <div className="table__heading">URL</div>
                                        <div className="table__heading">Actions</div>
                                    </div>
                                    {aliases.map(alias => {
                                        return <div className="table__row">
                                            <div className="table__cell">
                                                {moment(alias.added).format("MMM Do YYYY, h:mm a")}
                                            </div>

                                            <div className="table__cell">
                                                {alias.primaryAlias == "1" && 
                                                <i 
                                                    style={{marginRight: '10px'}}
                                                    title="primary website" 
                                                    className="far fa-window-maximize"> 
                                                </i>}
                                                <a href={alias.url} target="_blank">{alias.url}</a> 
                                            </div>

                                            <div
                                                className="table__cell" 
                                                style={{
                                                    display:"grid", 
                                                    gridTemplateColumns:"max-content max-content max-content max-content  max-content", 
                                                    gridGap:8, 
                                                    alignItems:"center",
                                                    height: '100%'
                                                }}
                                            >
                                                {!alias.primaryAlias && 
                                                    <button 
                                                        type="button" 
                                                        title="Delete alias" 
                                                        className="button button--red" 
                                                        onClick={() => delete_alias(alias)}
                                                    >
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                }
                                                    
                                            </div>
                                        </div>
                                    })}
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            
        </div>
    </div>
}

const mapDispatchToProps = (dispatch) => {
	return {
        addNotification: (payload) => dispatch(addNotification(payload)),
    } 
}

export default connect(null, mapDispatchToProps)(WebsiteAliases)