import React from 'react'

const Toggle = props => {
    return (
        <label className={"toggle " + (props.disabled && "disabled")} onChange={props.handleToggle} style={props.style}>
            <input 
            type="checkbox" 
            defaultChecked={props.defaultChecked} 
            checked={props.checked}
            disabled={props.disabled} />
            {props.textPosition == 'left' &&  <span className="toggle__label">{props.text}</span>}
            <span className="toggle__switch"></span>
            {(props.textPosition == null || props.textPosition == 'right') &&  <span className="toggle__label">{props.text}</span>}
        </label>
    )
}

export default Toggle