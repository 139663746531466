import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateCustomer, fetchSingleCustomer } from '../../actions/customers';
import CustomerSubscription from '../../components/customer/CustomerSubscription'
import EditCustomer from '../../components/forms/EditCustomer';
var _ = require('lodash');

class EditCustomerContainer extends Component {
	constructor(){
		super()
		this.state = {
			customer: {},
			customerLogo: null,
			isFetching: true
			// enableWhiteLabeling: false
		}
		this.handleChangeLastName = this.handleChangeLastName.bind(this)
		this.handleChangeAddress1 = this.handleChangeAddress1.bind(this)
		this.handleChangeAddress2 = this.handleChangeAddress2.bind(this)
		this.handleChangeCountry = this.handleChangeCountry.bind(this)
		this.handleChangeTelephone = this.handleChangeTelephone.bind(this)
		this.handleChangeCompanyName = this.handleChangeCompanyName.bind(this)
		this.handleChangeFirstName = this.handleChangeFirstName.bind(this)
		this.handleChangePostCode = this.handleChangePostCode.bind(this)
		this.handleChangeCity = this.handleChangeCity.bind(this)
		this.handleUpdateCustomer = this.handleUpdateCustomer.bind(this)
		this.handleEnableWhiteLabeling = this.handleEnableWhiteLabeling.bind(this)
		this.handleChangePassword = this.handleChangePassword.bind(this)
	}

	componentDidMount(){
		this.props.fetchSingleCustomer(this.props.customer.customer_id)
		.then(responseObj => {
			this.setState({
				customer: responseObj,
				enableWhiteLabeling: false,
				// this.props.customer.__whiteLabel.has_data,
				isFetching: false
			})
		})
	}
	handleChangeCompanyName(e){
		this.setState({
			customer: { ...this.state.customer,  company: e.target.value }
		})
	}

	handleChangeFirstName(e){
		this.setState({
			customer: { ...this.state.customer, first_name: e.target.value }
		})
	}

	handleChangeLastName(e){
		this.setState({
			customer: { ...this.state.customer,  last_name: e.target.value }
		})
	}

	handleChangeAddress1(e){
		this.setState({
			customer: { ...this.state.customer,  addr_1: e.target.value }
		})
	}

	handleChangeAddress2(e){
		this.setState({
			customer: { ...this.state.customer,  addr_2: e.target.value }
		})
	}

	handleChangeCountry(e){
		this.setState({
			customer: { ...this.state.customer,  addr_country: e.target.value }
		})
	}

	handleChangeCity(e){
		this.setState({
			customer: { ...this.state.customer,  addr_city: e.target.value }
		})
	}

	handleChangePostCode(e){
		this.setState({
			customer: { ...this.state.customer,  addr_postcode: e.target.value }
		})
	}

	handleChangeTelephone(e){
		this.setState({
			customer: { ...this.state.customer,  phone: e.target.value }
		})
	}

	

	handleUpdateCustomer(){
		if (
			// (this.state.customer.website != null && this.state.customer.website !== "")
			// && 
			(this.state.customer.company != null && this.state.customer.company !== "")
			&& (this.state.customer.addr_1 != null && this.state.customer.addr_1 !== "")
			&& (this.state.customer.addr_city != null && this.state.customer.addr_city !== "")
			&& (this.state.customer.addr_postcode != null && this.state.customer.addr_postcode !== "")
			&& (this.state.customer.first_name != null && this.state.customer.first_name !== "")
			&& (this.state.customer.last_name != null && this.state.customer.last_name !== "")
			)
			this.props.updateCustomer(this.state.customer)
		else 
			alert('Please fill all required fields')
	}

	handleEnableWhiteLabeling(){
		this.setState({
			enableWhiteLabeling: !this.state.enableWhiteLabeling
		})
	}


	handleChangePassword(e){
		this.setState({
			accessPassword: e.target.value
		})
	}

	render() {
		const user_role = this.props.customer.__user_role_id

		if(!this.state.isFetching){
			return (
				<React.Fragment>
					{
						(user_role === 1 || user_role === 2) &&
						<div className="margin-medium" style={{minHeight:'auto'}}>
							<div className="card__header"><h2 className="card__title">Payment Plan</h2></div>
							<div className="card__content">
								<CustomerSubscription customer={this.state.customer} />
							</div>
						</div>
					}
					<div>
						<div className="card__content">
							<EditCustomer 
							customer={this.state.customer} 
							handleChangeWebsite={this.handleChangeWebsite}
							handleChangeFirstName={this.handleChangeFirstName}
							handleChangeLastName={this.handleChangeLastName}
							handleChangeCity={this.handleChangeCity}
							handleChangeTelephone={this.handleChangeTelephone}
							handleChangeCountry={this.handleChangeCountry}
							handleChangeCompanyName={this.handleChangeCompanyName}
							handleChangeAddress1={this.handleChangeAddress1}
							handleChangeAddress2={this.handleChangeAddress2}
							handleChangePostCode={this.handleChangePostCode}
							handleUpdateCustomer={this.handleUpdateCustomer}
							website={this.state.customer.website}

							handleEnableWhiteLabeling={this.handleEnableWhiteLabeling}
							enableWhiteLabeling={this.state.enableWhiteLabeling}
							handleChangePassword={this.handleChangePassword}
							accessPassword={this.state.accessPassword}

							whiteLabel={this.state.customer.__whiteLabel}
							/>
						</div>
					</div>
				</React.Fragment>
			)
		}
		return null
	}
}


const mapStateToProps = (state) => {
	return {
		customer: state.customers.selectedCustomer,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		updateCustomer: (customer) => dispatch(updateCustomer(customer)),
		fetchSingleCustomer: (customer_id) => dispatch(fetchSingleCustomer(customer_id))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerContainer)