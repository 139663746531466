/**
 * This is a wrapper for existing react components so that they can use useLazyListLoader hook,
 * The encapsulated component will recieve  the state from the hook as props.
 */

import React from 'react'
import useLazyListLoader from './useLazyListLoader';

export const withLazyListLoaderHOC = (Component) => {

    return (props) => {
        const ListLazyLoader = useLazyListLoader();

        return <Component lazyLoader={ListLazyLoader} {...props} />
    }
}
