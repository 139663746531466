  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createBotImpactGraphResource = ({timePeriod, filter, cancelToken}) => {
    return {
        graph: [`${filter.type}/${filter.payload.id}/graph/bot-impact/${timePeriod}`, null,  {cancelToken: cancelToken.token}]
    }
}



export const setBotImpactGraphResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_IMPACT_GRAPH_FETCHING,
            fetching: true
        })

        const previousToken = getState().botImpactGraph.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("bot impact graph requests cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_IMPACT_GRAPH_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createBotImpactGraphResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_BOT_IMPACT_GRAPH_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_IMPACT_GRAPH_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_IMPACT_GRAPH_FETCHING,
                        fetching: false
                    })
                }
            })
    }
}
