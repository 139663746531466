  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createBudgetEffectivenessResource = ({timePeriod, filter, disableCache, cancelToken}) => {

    let graph_query,blocked_bots,actual_clicks, actual_visits

    graph_query = `${filter.type}/${filter.payload.id}/graph/budget-effectiveness/${timePeriod}`
    blocked_bots = `${filter.type}/${filter.payload.id}/blocks/${timePeriod}`
    actual_clicks = `${filter.type}/${filter.payload.id}/graph/clicks/${timePeriod}`
    actual_visits = `${filter.type}/${filter.payload.id}/graph/visits/${timePeriod}`
    
    return {
        graph: [graph_query, null, {cancelToken: cancelToken.token}],
        blocked_bots: [blocked_bots, null, {cancelToken: cancelToken.token}],
        actual_clicks: [actual_clicks, null, {cancelToken: cancelToken.token}],
        actual_visits: [actual_visits, null, {cancelToken: cancelToken.token}],
    }
}


export const setBudgetEffectivenessResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_BUDGET_EFFECTIVENESS_FETCHING,
            fetching: true
        })

        const previousToken = getState().budgetEffectiveness.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("budget effectiveness request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_BUDGET_EFFECTIVENESS_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createBudgetEffectivenessResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_BUDGET_EFFECTIVENESS_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_BUDGET_EFFECTIVENESS_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_BUDGET_EFFECTIVENESS_FETCHING,
                        fetching: false
                    })
                }

            })
    }
}