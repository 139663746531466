  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createVisitorProfileResource = ({timePeriod, filter, disableCache, cancelToken}) => {
    let graph_query
    switch (filter.type) {
        case "website":
        default: 
            graph_query = `website/${filter.payload.id}/geoData/${timePeriod}`
            break;
        case "campaign":
            graph_query = `campaign/${filter.payload.id}/geoData/${timePeriod}`
            break;
        case "channel":
            graph_query = `channel/${filter.payload.id}/geoData/${timePeriod}`
            break
        case "link":
            graph_query = `link/${filter.payload.id}/geoData/${timePeriod}`
            break
    }

    return {
        graph: [graph_query, null, {cancelToken: cancelToken.token}],
    }
}

export const setVisitorProfileResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_VISITOR_PROFILE_FETCHING,
            fetching: true
        })

        const previousToken = getState().visitorProfile.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("media spend request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_VISITOR_PROFILE_CANCEL_TOKEN,
            previousRequestSource: source
        })
  
        createResourceFromQueries(createVisitorProfileResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_VISITOR_PROFILE_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_VISITOR_PROFILE_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_VISITOR_PROFILE_FETCHING,
                        fetching: false
                    })
                }
            })
    }
  }