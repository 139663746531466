import React from 'react';

const FirstLoginInvited = (props) => {
	return (
		<div className="takeover">
			<div className="takeover__content">
				<h1 className="takeover__title margin-large">Welcome to Beacon</h1>
				<p className="margin-large">You made the team at <strong>{props.selectedCustomer.company}</strong>! From here you can create Veracity Links, organise your marketing campaigns and see which channels work best for you.</p>
				<button className="button button--large button--pink" onClick={props.handleCloseWelcome}>See the dashboard</button>
			</div>
		</div>
	)
}

export default FirstLoginInvited;