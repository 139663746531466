import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createCampaign } from '../../actions/campaigns';
import Modal from '../../components/general/Modal';
import CreateCampaign from '../../components/forms/CreateCampaign';
import AddCampaignChannelsContainer from './AddCampaignChannelsContainer';
import * as colours from '../../colours';
import PropTypes from 'prop-types';

class CreateCampaignContainer extends Component {
	constructor(){
		super()
		this.state = {
			alertOnClose: false,
			campaignName: '',
			campaignDescription: '',
			campaignColour: colours.beaconColours[Math.floor(Math.random()*colours.beaconColours.length)], // generate a random colour from the palette
			toggleAddChannel: false,
			startDate: false, 
			endDate: false,
			endDateDisabled: true,
			datesVisible: false,
			selectedType: '',
			showIdentifiedData: false,

			error: null
		}
		this.handleCreateCampaign = this.handleCreateCampaign.bind(this)
		this.handleChangeCampaignName = this.handleChangeCampaignName.bind(this)
		this.handleChangeCampaignDescription = this.handleChangeCampaignDescription.bind(this)
		this.handleGetSelectedColour = this.handleGetSelectedColour.bind(this)
		this.handleToggleAddChannel = this.handleToggleAddChannel.bind(this)
		this.handleChangeStartDate = this.handleChangeStartDate.bind(this)
		this.handleChangeEndDate = this.handleChangeEndDate.bind(this)
	}

    handleCreateCampaign(e){
		e.preventDefault();
		if(!this.state.campaignName){
			alert("Please enter a name for your campaign")
		} else {
			let valid_flag = true;
			let filteredSettingsLinks = this.state.settingsLinks != null ? this.state.settingsLinks.filter(temp => temp != "" && temp != '/') : []
			
			// 
			if (filteredSettingsLinks.length === 0 
				&& this.state.selectedType != 'identified_visitor'
				&& this.state.selectedType != 'shopify_thank_you'
			) {
				alert('You have selected a lead definition that requires at least one URL')
				valid_flag = false
			} else {
				filteredSettingsLinks = filteredSettingsLinks.map((link, i) => {
					if (this.state.selectedType == 'external_link') {
						if (!(link.startsWith('http://') || link.startsWith('https://'))) {
							alert(link + ' is not a valid URL, an external URL must be absolute and start with http:// or https://')
							valid_flag = false
						} 
					} else if (this.state.selectedType == 'url_match'  || this.state.selectedType == 'preg_match') {
						let tmp = link
						if (!(link.startsWith('/'))) {
							tmp = "/" + link
						}

						if (link.endsWith('/')) {
							tmp = tmp.substring(0, tmp.length - 1);
						}

						if (link.startsWith('http://') || link.startsWith('https://')) {
							alert(link + ' is not a valid URL, an internal URL must be relative and not start with http:// or https://')
							valid_flag = false
						} 

						return tmp
					}

					return link
				})
				
			}
			
			if (valid_flag) {
				if(this.state.endDate < this.state.startDate && !this.state.endDateDisabled){
					alert("Campaign end date cannot be set before started date")
				} else if(!this.state.startDate && this.state.endDate){
					alert("Please enter a start date for your campaign")
				} else {
					this.setState({error: null})
					this.props.createCampaign({...this.state, settingsLinks: filteredSettingsLinks}).then(response => {
						// if this modal is triggered from the dashboard on first campaign creation, do the following
						if (response.responseType == "error") {
							this.setState({error: response.additional})
							return
						}

						if(this.props.isFirst){
							this.props.firstAdded()
						}
						this.setState({
							toggleAddChannel: true,
							newCampaignId: response.response.campaign_id,
							newCampaignName: response.response.title
						})
						if (this.props.callback)
							this.props.callback({id: response.response.campaign_id, title: response.response.title})
					})
				}
			}
			
		}
    }

    handleChangeCampaignName(e){
    	this.setState({ campaignName: e.target.value, alertOnClose: true })
    }

    handleChangeCampaignDescription(e){
    	this.setState({ campaignDescription: e.target.value, alertOnClose: true })
	}
	
	handleGetSelectedColour(colour){
		this.setState({ campaignColour: colour, alertOnClose: true })
	}

	handleChangeStartDate(e){
		this.setState({ startDate: e.target.value })
	}

	handleChangeEndDate(e){
		this.setState({ endDate: e.target.value })
	}

	// When this is called, close the add channel window AND close the add campaign window also
	handleToggleAddChannel(){
		this.setState({
			toggleAddChannel: !this.state.toggleAddChannel
		})
		this.props.handleOpen()
	}

	handleToggleEndDate(){
		this.setState({
			endDateDisabled: !this.state.endDateDisabled
		})
	}

	handleChangeDatesVisible(){
		this.setState({
			datesVisible: !this.state.datesVisible
		}, () => {
			if(!this.state.datesVisible){
				this.setState({
					startDate: '',
					endDate: ''
				})
			}
		})
	}

	render() {
		if(this.state.toggleAddChannel){
			return <AddCampaignChannelsContainer 
			handleOpen={this.handleToggleAddChannel}
			campaignId={this.state.newCampaignId}
			campaignName={this.state.newCampaignName}
			callback={(e, id) => this.props.channelsCallback(e, id)}
			/>
		}
		return (
			<Modal 
				title="Add Campaign"
				closeLabel="Cancel"
				saveLabel="Create Campaign"
				closeAction={this.props.handleOpen}
				saveAction={this.handleCreateCampaign}
				alertOnClose={this.state.alertOnClose}
				message={
					this.state.error && <div className="message message--warning">
						<h2 className="message__title">Unable to create campaign</h2>
						<p >{this.state.error}</p>
					</div>
				}
				>	
				<CreateCampaign
				defaultType={() => {
					if (this.props.website.__meta["default-campaign-goal"] == null) {return 'identified_visitor'}
					if (this.props.website.__meta["default-campaign-goal"] === 'identified_visitor') {
						return 'identified_visitor'
					} else {
						return JSON.parse(this.props.website.__meta["default-campaign-goal"])[0].type
					}
				}}
				defaultSettings={() => {
					if (this.props.website.__meta["default-campaign-goal"] == null) {return []}
					if (this.props.website.__meta["default-campaign-goal"] === 'identified_visitor') {
						return []
					} else {
						const settings = JSON.parse(this.props.website.__meta["default-campaign-goal"])[0].settings
						if (settings == "") {
							return []
						} 

						return settings
					}
				}}
				defaultSID={() => {
					// if (this.props.campaign.__meta.show_identified_data == '1') {
					// 	return 'true'
					// } else if (this.props.campaign.__meta.show_identified_data == '0') {
						return 'false'
					// } else {
					// 	return this.props.campaign.__meta.show_identified_data
					// }
				}}
				selectedType={(selectedType) => this.setState({ selectedType }) }
				settingsLinks={(settingsLinks) => this.setState({ settingsLinks }) }
				indetifiedData={(showIdentifiedData) => this.setState({ showIdentifiedData })}
				defaultColour={this.state.campaignColour}
				getSelectedColour={this.handleGetSelectedColour}
				campaignName={this.state.campaignName}
				campaignDescription={this.state.campaignDescription}
				handleChangeCampaignName={this.handleChangeCampaignName}
				handleChangeCampaignDescription={this.handleChangeCampaignDescription}
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				handleChangeStartDate={this.handleChangeStartDate}
				handleChangeEndDate={this.handleChangeEndDate}
				handleToggleEndDate={this.handleToggleEndDate.bind(this)}
				endDateDisabled={this.state.endDateDisabled}
				datesVisible={this.state.datesVisible}
				handleChangeDatesVisible={this.handleChangeDatesVisible.bind(this)}
			/>
			</Modal>
		)
	}
}

CreateCampaignContainer.propTypes = {
	handleOpen: PropTypes.func,
	callback: PropTypes.func,
	channelsCallback: PropTypes.func,
};

const mapStateToProps = state => {
	return {
		campaign: state.campaigns.singleCampaign,
		website: state.websites.selectedWebsite,
		websiteMeta: state.websites.selectedWebsite.__meta
	}
}

const mapDispatchToProps = dispatch => {
	return {
		createCampaign: (payload) => dispatch(createCampaign(payload))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignContainer);
