// Import action types
import { 
	SET_SELECTED_DATE,
	UNSET_SELECTED_DATE,
	SET_ELEMENT_SELECTED_DATE,
	UNSET_ELEMENT_SELECTED_DATE
} from './actionTypes';

export const setSelectedDate = date => {
	return {
		type: SET_SELECTED_DATE,
		date
	}
}

export const setElementSelectedDate = date => {
	return {
		type: SET_ELEMENT_SELECTED_DATE,
		date
	}
}

export const unsetElementSelectedDate = () => {
	return (dispatch, getState) => {
		const date = { 
			selectedDateValue: getState().dates.selectedDateValue, 
			selectedDateName: getState().dates.selectedDateName, 
			selectedDates: getState().dates.selectedDates 
		}
		dispatch({
			type: UNSET_ELEMENT_SELECTED_DATE,
			date
		})
	}
}

export const unsetSelectedDate = () => {
	return {
		type: UNSET_SELECTED_DATE
	}
}