import React, { Component } from 'react'
import { connect } from 'react-redux';
import { createFirstWebsite, setSelectedWebsite, fetchSupportedCurrencies } from '../../actions/websites';
import HelpContainer from '../../containers/general/HelpContainer';
import BeaconRequest from '../../BeaconRequest';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone'
var _ = require('lodash')


class AddFirstWebsite extends Component {
	constructor(){
		super()
		this.state = {
			websiteUrl: '',
			websiteName: '',
			websiteCurrency:'',
			supportedCurrencies: [],
			leadsText: 'Leads',
			timezone: moment.tz.guess(),
			canSubmit: false, 
			hasWebsite: false
		}
		this.handleChangeWebsiteUrl = this.handleChangeWebsiteUrl.bind(this)
		this.handleChangeWebsiteName = this.handleChangeWebsiteName.bind(this)
		this.handleCreateWebsite = this.handleCreateWebsite.bind(this)
		this.handleChangeWebsiteCurrency = this.handleChangeWebsiteCurrency.bind(this)
		this.handleChangeLeadsText = this.handleChangeLeadsText.bind(this)
	}

	// When the component loads, check for two things: first, that the user has no websites. This will catch users who've added a site, not completed and then
	// logged back in at a later date. Secondly, check for a selected website. This will check the current session. 
	// In either case, we need to move past this slide onto the next
	componentDidMount(){
		this.props.fetchSupportedCurrencies()
		.then((responseObj) => {
			// On mounting get the supported currencies and pass to the state object
			// Also set the default website currency to the first one in the array
			this.setState({ supportedCurrencies: responseObj, websiteCurrency: responseObj[0].code })
		})
		
		if(!_.isEmpty(this.props.websites) || this.props.selectedWebsite){
			if(!_.isEmpty(this.props.websites)){
				this.props.setSelectedWebsite(this.props.websites[0])
			}			

			this.setState({
				hasWebsite: true
			})
			setTimeout(() => {
				this.props.changeSlide(2)
			}, 2500)
		}
	}

	handleCreateWebsite(e){
		e.preventDefault()
		this.setState({
			canSubmit:false
		})
    	this.props.createFirstWebsite(this.state)
    	.then(responseObj => {
			BeaconRequest.post('user/' + this.props.userId + '/onboardingComplete', {})
			.then(response =>{
				this.props.changeSlide();
				this.props.setSelectedWebsite(responseObj.response) // after the website is added, set it as the selected website
			})
			.catch(error => { console.log(error) })
    	})
    	.catch(error => {
    		const code = error.code
    		if(code === 1411){
    			alert("It looks like you've already registered this website! If you need help, you can contact us at support@thisisbeacon.com");
    		} else if(code === 1412){
    			alert("It looks like this website has already been registered to another customer. If you need help, you can contact us at support@thisisbeacon.com");
    		} else if(code === 3002){
    			alert("Hmm, it doesn't look like that website exists. Please check the address, and if the issue persists contact us at support@thisisbeacon.com");
    		} else if(code === 2000){
    			alert("Please ensure the URL has been entered correctly. You may be missing http:// or https:// from your website address.");
			}
			this.setState({
				canSubmit:true
			})
    	})
    }

    handleChangeWebsiteUrl(e){
    	this.setState({ 
    		websiteUrl: e.target.value,
    		canSubmit: true
    	}, () => {
    		if(!this.state.websiteUrl){
    			this.setState({
    				canSubmit:false
    			})
    		}
    	})
	}
	
	handleChangeWebsiteName(e){
    	this.setState({ 
    		websiteName: e.target.value
    	});
	}
	
	handleChangeWebsiteCurrency(e){
		this.setState({ websiteCurrency: e.target.value, alertOnClose: true  });
	}

	handleChangeLeadsText(e){
		this.setState({ leadsText: e.target.value, alertOnClose: true  });
	}

	render(){

		const currencies = this.state.supportedCurrencies.map(currency => {
			return (
				<option 
				key={currency.currency_id} 
				value={currency.code}
				dangerouslySetInnerHTML={ { __html: currency.symbol } }>
				</option>
			)
		})

		return (
			<div className="first-login__slide">
				<div className="first-login__image">
					<img 
						src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/newOnboarding/Onboarding_2.png" 
						style={{objectFit: 'cover', width: '100%', height: '100%'}}
					/>
				</div>
				<form className="first-login__content" onSubmit={this.handleCreateWebsite}>
					<h1 className="first-login__title">Connect your website</h1>
					{ 
						this.state.hasWebsite ? 
						<div className="margin-medium message message--notice">
							Looks like you've already added your first website. Stand by...
						</div> 
						: 
						<div>
							<label className="form__label">Website URL</label>
							<input type="text" className="form__input margin-medium" placeholder="http://" autoFocus onChange={this.handleChangeWebsiteUrl} /> 


							<ul style={{display:"grid",gridTemplateColumns:"auto max-content", gridGap:10}}>
								<li className="margin-medium">
									<label className="form__label">Website Name</label>
									<input type="text" className="form__input" onChange={this.handleChangeWebsiteName} /> 
								</li>
								<li className="margin-medium">
									<div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
										<label className="form__label">Website Currency</label>
										<div><HelpContainer id={19} /></div>
									</div>
									<div className="select-container" style={{width:"100%"}}>
										<select className="select" onChange={this.handleChangeWebsiteCurrency}>{currencies}</select>
									</div>
								</li>
							</ul>

							<ul style={{display:"grid",gridTemplateColumns:"auto max-content", gridGap:10}}>
								<li className="margin-medium">
									<div  style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
										<label className="form__label">Timezone</label>
										<div><HelpContainer id={22} /></div>
									</div>
									<div  style={{width:"100%"}}>
										{/* <select className="select" onChange={this.handleChangeWebsiteCurrency}>{currencies}</select> */}
										<Autocomplete
											id="combo-box-demo"
											options={moment.tz.names()}
											getOptionLabel={(option) => option}
											defaultValue={this.state.timezone}
											inputValue={this.state.timezone}
											onInputChange={(event, newInputValue) => {
												// props.handleTimezoneChange(newInputValue)
												this.setState({timezone: newInputValue})
											}}
											renderInput={(params) => (
												<div style={{width: '100%'}} ref={params.InputProps.ref}>
													<input {...params.inputProps} type="text" className="form__input"  />
												</div>
											)}
										/>
									</div>
								</li>
							</ul>
							<ul style={{display:"grid",gridTemplateColumns:"1fr 1fr", gridGap:10}}>
								<li className="form__item  margin-medium">
									{/* <div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
										<label className="form__label">Leads/Other Outcomes name</label>
										<div><HelpContainer id={21} /></div>
									</div>
									
									<input type="text" className="form__input" value={this.state.leadsText} placeholder="Leads" onChange={this.handleChangeLeadsText} autoFocus required /> */}
								</li>
								<li style={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center'
								}}>
									<button type="submit" className="button button--yellow" disabled={!this.state.canSubmit}>Next</button>
								</li>
							</ul>
						</div>
					}
				</form>
			</div>
		)
	}
}

const mapStateToProps = state => {
  return {
    websites: state.websites.activeWebsites,
    selectedWebsite: state.websites.selectedWebsiteId,
	userId: state.login.currentUser.user_id,
  }
}

const mapDispatchToProps = dispatch => {
	return {
		createFirstWebsite: (payload) => dispatch(createFirstWebsite(payload)),
		setSelectedWebsite: (payload) => dispatch(setSelectedWebsite(payload)),
		fetchSupportedCurrencies: () => dispatch(fetchSupportedCurrencies())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFirstWebsite)
