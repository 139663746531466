import React from 'react';
import FontAwesomePicker from '../general/FontAwesomePicker'
import ColourPicker from '../general/ColourPicker';
import BeaconRequest from '../../BeaconRequest'
import LoaderAlt from '../general/LoaderAlt'
import * as colours from '../../colours';
import Checkbox from '../general/Checkbox';

const EditChannel = props => {
    const [usedColours, setUsedColours] = React.useState(null)
    const [error, setError] = React.useState(false)
	const usedColoursLoading = usedColours == null

	React.useEffect(() => {
        setError(false)
		BeaconRequest.get(`campaign/${props.campaign.campaign_id}/channels/coloursInUse`)
        .then(res => setUsedColours(res.response))
        .catch(err => setError(true))
    }, [])
    
	return (
		<ul>
			<div>
                <h2 className="form__title">Channel Details</h2>
                <li className="form__item">
                    <div style={{position:"relative"}}>
                        <label className="form__label" for="channel-name">Channel name</label>
                        <input 
                        id="channel-name"
                        type="text" 
                        className="form__input" 
                        value={props.channelName}
                        onChange={props.handleChangeChannelName}
                        style={{paddingLeft:58}}
                        />

                        <div 
                        style={{
                            position:"absolute",
                            right:0,
                            bottom:0,
                        }}>
                            <FontAwesomePicker 
                            selectedIcon={props.channelIcon} 
                            getSelectedIcon={props.handleChangeChannelIcon} 
                            />
                        </div>

                        <div 
                        style={{position:'absolute', 
                        left:4, 
				        bottom:"-1px" }}
                        >
                            {(usedColoursLoading || error)? 
                            <div style={{marginLeft: '15px'}}><LoaderAlt noHints /></div>
                            :
                            <ColourPicker 
                                usedColours={usedColours} 
                                defaultColour={props.defaultColour} 
                                selectedColour={props.getSelectedColour} 
                            />
                            }
                        </div>
                    </div>
                </li>
                <h2 className="form__title">Ad Channels<span style={{fontSize:14}} className="form__optional">Optional</span></h2>
                <p>This option allows you to set the ad networks associated with this campaign. We do this automatically using your ad imports, but you can specify manually here.</p>
                <p className="margin-small">Setting a network to active here will also activate it on the campaign. Disabling it, however, will not disable it on the campaign.</p>
                <li className="margin-medium">
                    <div>
                        <Checkbox checked={props.adChannels.includes(1)} handleToggle={props.handleToggleAdChannel(1)} text="Other" />
                    </div>
                    <div>
                        <Checkbox checked={props.adChannels.includes(2)} handleToggle={props.handleToggleAdChannel(2)} text="Google Ads" />
                    </div>
                    <div>
                        <Checkbox checked={props.adChannels.includes(3)} handleToggle={props.handleToggleAdChannel(3)} text="Facebook Ads" />
                    </div>
                    <div>
                        <Checkbox checked={props.adChannels.includes(4)} handleToggle={props.handleToggleAdChannel(4)} text="LinkedIn Ads" />
                    </div>
                    <div>
                        <Checkbox checked={props.adChannels.includes(5)} handleToggle={props.handleToggleAdChannel(5)} text="Microsoft Ads" />
                    </div>
                </li>
            </div>
            {props.error && <div className="message message--warning">
                <p >{props.error}</p>
                <p>If the issue persists create or ticket or contact us at support@thisisbeacon.com</p>
            </div>
            }
		</ul>
	)
}

export default EditChannel
