// Import action types
import { 
	TOGGLE_CREATE_LINK_MODAL,
	TOGGLE_NEWLY_CREATED_LINK_MODAL,
	ADD_NOTIFICATION,
	REMOVE_NOTIFICATION,
	TOGGLE_DATE_RANGE_SELECT,
	TOGGLE_ELEMENT_DATE_RANGE_SELECT,
	SNOOZE_TRACKING_CODE_REMINDER,
	SET_CAMPAIGN_LINK_VIEW,
	SET_OVERLAY_ROOT,
	SET_SELECTED_TAB,
	TOGGLE_LINKS_ONLY_VISITS,
	TOGGLE_REPORT_LESSONS
} from './actionTypes';

export const toggleCreateLinkModal = () => {
	return {
		type: TOGGLE_CREATE_LINK_MODAL
	}
}

export const toggleNewlyCreatedLinkModal = () => {
	return {
		type: TOGGLE_NEWLY_CREATED_LINK_MODAL
	}
}

export const addNotification = payload => {
	return {
		type: ADD_NOTIFICATION,
		payload
	}
}

export const removeNotification = id => {
	return {
		type: REMOVE_NOTIFICATION,
		id
	}
}

export const toggleDateRangeSelect = () => {
	return {
		type: TOGGLE_DATE_RANGE_SELECT
	}
}

export const toggleElementDateRangeSelect = () => {
	return {
		type: TOGGLE_ELEMENT_DATE_RANGE_SELECT
	}
}

export const snoozeTrackingCodeReminder = () =>{
	return {
		type: SNOOZE_TRACKING_CODE_REMINDER,
		payload: new Date()
	}
}

export const setCampaignLinkView = viewId => {
	return {
		type: SET_CAMPAIGN_LINK_VIEW,
		viewId
	}
}

export const setOverlayRoot = referrer => {
	return {
		type: SET_OVERLAY_ROOT,
		referrer
	}
}

export const setSelectedTab = tabId => {
	return {
		type: SET_SELECTED_TAB,
		tabId
	}
}

export const toggleLinksOnlyVisits = () =>{
	return {
		type: TOGGLE_LINKS_ONLY_VISITS
	}
}

export const toggleReportLessons = (payload) => {
	if (payload != null) {
		return {
			type: TOGGLE_REPORT_LESSONS,
			payload
		}
	} else {
		return {
			type: TOGGLE_REPORT_LESSONS
		}
	}
	
}
