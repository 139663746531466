import React from 'react';

const Loader = props => {
	return (
		<React.Fragment>
			<div className="loader"></div>
			{ props.colour === "light" ? <img src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/Spinner-0.6s-231px.svg" className="loader__icon" alt="Loading, please wait..." /> : <img src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/Spinner-0.6s-231px.svg" className="loader__icon" alt="Loading, please wait..." /> }
		</React.Fragment>
	)
}

export default Loader;