// Import action types
import { 
	FETCH_TEAM_USERS
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_TEAM_USERS:
			return {
				...state,
				teamUsers: action.teamUsers
			}
		default:
			return state;
	}
}