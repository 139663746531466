var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import DateFilterContainer from '../../containers/date/DateFilterContainer';
import DateRangeSelect from '../../components/date/DateRangeSelect';
import DropdownContainer from './DropdownContainer';
import { connect } from 'react-redux';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { addNotification } from '../../actions/ui';
import PerformanceDashboard from '../PerformanceDashboard';
import ActionBar from '../../molecules/ActionBar';
import VisitsListPage from '../../pages/VisitsListPage';
import { setFilter } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import BeaconRequest from '../../BeaconRequest';
import BeaconRequestPHP8 from '../../BeaconRequestPHP8';
import NoMatch from '../../components/general/NoMatch';
import { redirectRouter } from './DropdownContainer';
import AnalyticsDashboard from '../AnalyticsDashboard';
import GoogleDashboard from '../GoogleDashboard';
var oneOneEight = {
    type: "campaign",
    payload: { id: 1753, title: "118 Campaign", colour: "#fecf2f" }
};
// https://github.com/reactjs/react-tabs/issues/126 
// <3 
var LazyTabPanel = function (props) {
    var _a = useState(false), initialized = _a[0], setInitialized = _a[1];
    useEffect(function () {
        if (props.selected && !initialized) {
            setInitialized(true);
        }
    }, [props.selected, initialized]);
    return _jsx(TabPanel, __assign({ forceRender: initialized }, props));
};
LazyTabPanel.tabsRole = 'TabPanel';
var WidgetContainer = function (props) {
    var container = React.useRef(null);
    var _a = useState(props.date), datetime = _a[0], setDatetime = _a[1];
    // const [filter, setFilter] = useState(props.currentUser.user_id === oneOneEightID // 118 HACK
    //     ? oneOneEight
    //     : {type: 'website', payload: {id: props.websiteId}}
    // )
    var _b = useState(0), selectedTab = _b[0], setSelectedTab = _b[1];
    var _c = useState(true), hasconnection = _c[0], setHasconnection = _c[1];
    var filter = useSelector(function (_a) {
        var global = _a.global;
        return global.filter;
    });
    var invalidState = useSelector(function (_a) {
        var global = _a.global;
        return global.invalidState;
    });
    var dispatch = useDispatch();
    //debug
    useEffect(function () {
        setDatetime(props.date);
    }, [props.date]);
    useEffect(function () {
        function getConnection(provider, websiteId, noCache) {
            if (noCache === void 0) { noCache = false; }
            var url = "website/".concat(websiteId, "/connection/").concat(provider);
            var promise = noCache ? BeaconRequestPHP8.getNoChache(url) : BeaconRequest.get(url);
            return promise.then(function (_a) {
                var response = _a.response;
                return response != null && typeof response.account_id === "string";
            });
        }
        var fetchConnections = function () { return __awaiter(void 0, void 0, void 0, function () {
            var hasGoogleConnection, hasFacebookConnection, hasLinkedInConnection, hasMicrosoftConnection;
            return __generator(this, function (_a) {
                hasGoogleConnection = getConnection("google", props.websiteId, true);
                hasFacebookConnection = getConnection("facebook", props.websiteId, true);
                hasLinkedInConnection = getConnection("linkedin", props.websiteId, true);
                hasMicrosoftConnection = getConnection("microsoft", props.websiteId, true);
                setHasconnection(hasGoogleConnection || hasFacebookConnection || hasLinkedInConnection || hasMicrosoftConnection);
                return [2 /*return*/];
            });
        }); };
        fetchConnections();
    }, [props.websiteId]);
    useEffect(function () {
        var urlParams = new URLSearchParams(window.location.search);
        var model = urlParams.get('view');
        switch (model) {
            case 'performance':
            default:
                setSelectedTab(0);
                break;
            case 'analytics':
                setSelectedTab(1);
                break;
            case 'visits':
                setSelectedTab(2);
                break;
            case 'google':
                setSelectedTab(3);
                break;
        }
    }, [props.location]);
    useEffect(function () {
        var urlParams = new URLSearchParams(window.location.search);
        var model = urlParams.get('view');
        switch (model) {
            case 'performance':
            default:
                setSelectedTab(0);
                break;
            case 'analytics':
                setSelectedTab(1);
                break;
            case 'visits':
                setSelectedTab(2);
                break;
            case 'google':
                setSelectedTab(3);
                break;
        }
    }, [props.location]);
    if (props.websiteId == null)
        return _jsx("div", { children: "error: website not set" });
    return (_jsx("div", __assign({ style: { marginBottom: '20px' } }, { children: _jsxs(Tabs, __assign({ defaultIndex: 0, selectedIndex: selectedTab }, { children: [_jsx(TabList, __assign({ style: { marginBottom: 0, position: 'sticky', top: 0, zIndex: '13', backgroundColor: '#e6ebed24' } }, { children: _jsx("div", __assign({ style: { paddingTop: '10px', paddingBottom: '10px', borderBottom: '1px solid rgb(208, 208, 208)' } }, { children: _jsx("div", __assign({ style: { margin: 'auto', maxWidth: '80rem' } }, { children: _jsxs("div", __assign({ style: {
                                    display: "grid", gridAutoFlow: "column",
                                    gridAutoColumns: "max-content auto",
                                    gridGap: 20,
                                    padding: '0 20px'
                                } }, { children: [_jsx(DateFilterContainer, __assign({}, { theme: 'light' })), props.isOpen && _jsx(DateRangeSelect, {}), _jsx(DropdownContainer, __assign({}, {
                                        websiteId: props.websiteId,
                                        datetime: datetime,
                                        filter: filter,
                                    }))] })) })) })) })), _jsx(ActionBar, __assign({}, {
                    filter: filter,
                    datetime: datetime,
                    setFilter: function (e) { return dispatch(setFilter(e)); }
                })), _jsx(LazyTabPanel, { children: invalidState ?
                        _jsx(NoMatch, {})
                        :
                            _jsxs("div", __assign({ className: "container", style: { margin: 'auto', padding: '0 20px' } }, { children: [!hasconnection &&
                                        _jsx("ul", __assign({ className: "margin-large" }, { children: _jsxs("div", __assign({ className: "message message--warning" }, { children: [_jsx("i", { className: "fas fa-exclamation-triangle", style: { color: 'red', marginRight: '5px' } }), _jsx("p", __assign({ style: { textDecoration: 'underline', cursor: 'pointer', display: 'inline-block', }, onClick: function (e) { return props.history.push('/connections'); } }, { children: "You have no AD network connections, to view data on the performance dashboard please connect atleast 1 account." }))] })) })), _jsx(PerformanceDashboard, { datetime: datetime, filter: filter, setFilter: function (e) { return dispatch(setFilter(e)); } })] })) }), _jsx(LazyTabPanel, { children: invalidState ?
                        _jsx(NoMatch, {})
                        :
                            _jsx("div", __assign({ ref: container, className: "container", style: { margin: 'auto', padding: '0 20px' } }, { children: _jsx(AnalyticsDashboard, { datetime: datetime, filter: filter, setFilter: function (e) { return dispatch(setFilter(e)); } }) })) }), _jsx(LazyTabPanel, { children: invalidState ?
                        _jsx(NoMatch, {})
                        :
                            _jsx(VisitsListPage, __assign({}, {
                                filter: filter,
                                datetime: datetime,
                                setFilter: function (e) { return dispatch(setFilter(e)); },
                            })) }), _jsx(LazyTabPanel, { children: invalidState ? (_jsx(NoMatch, {})) : (_jsx("div", __assign({ ref: container, className: "container", style: { margin: 'auto', padding: '0 20px' } }, { children: _jsx(GoogleDashboard, { timePeriod: datetime, websiteId: props.websiteId }) }))) })] })) })));
};
var mapStateToProps = function (state) {
    return {
        websiteId: state.websites.selectedWebsiteId,
        websiteMeta: state.websites.selectedWebsite.__meta,
        currentUser: state.login.currentUser,
        customer: state.customers.selectedCustomer,
        date: state.dates.selectedDateValue,
        timezone: state.websites.selectedWebsite.timezone,
        websiteName: state.websites.selectedWebsite.name,
        showDataExport: state.websites.selectedWebsite.showDataExport,
        activeCampaigns: state.campaigns.activeCampaigns,
        // dashboardTab: state.login.selectedDashboard,
        isOpen: state.ui.showDateRangeSelect,
        paging: state.visits.paging,
        userMeta: state.login.userMeta,
        selectedCampaignId: state.campaigns.selectedCampaignId,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        addNotification: function (payload) { return dispatch(addNotification(payload)); },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(redirectRouter(WidgetContainer));
