var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@material-ui/core";
import Axios from "axios";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import BeaconRequest from "../../BeaconRequest";
import { Collations, SelectCollation } from "./Collation";
import { createEmptyData } from "./Data";
import LoadMoreButton from "./LoadMoreButton";
import { Tab, TabBar, TabDetails } from "./Tab";
import Table from "./Table";
function GoogleDashboard(_a) {
    var timePeriod = _a.timePeriod, websiteId = _a.websiteId;
    var _b = useState(Collations[0]), collation = _b[0], setCollation = _b[1];
    var _c = useState(Tab.Customer), activeTab = _c[0], setActiveTab = _c[1];
    var _d = useState(createEmptyData()), loadedData = _d[0], setLoadedData = _d[1];
    var loadNextDataForTab = useCallback(function (tab, pageCache) {
        if (pageCache === void 0) { pageCache = []; }
        if (typeof timePeriod !== "string" ||
            typeof websiteId !== "number" ||
            timePeriod.length === 0 ||
            websiteId <= 0) {
            return null;
        }
        setLoadedData(produce(function (current) {
            current.get(tab).fetching = true;
        }));
        var url = "website/".concat(websiteId, "/google/reporting/").concat(TabDetails.get(tab).endpoint, "/").concat(timePeriod, "/").concat(pageCache.length);
        var cancelToken = Axios.CancelToken.source();
        BeaconRequest.get(url, {
            "!pageCache": JSON.stringify(pageCache),
            "!collation": collation.id
        }, { cancelToken: cancelToken.token }).then(function (_a) {
            var response = _a.response;
            setLoadedData(produce(function (current) {
                var data = current.get(tab);
                data.fetching = false;
                data.rows = __spreadArray(__spreadArray([], data.rows, true), response.rows, true);
                data.pageCache = response.pageCache;
            }));
        });
        return cancelToken;
    }, [timePeriod, websiteId, collation]);
    var handleCollationChanged = useCallback(function (newCollation) {
        setCollation(newCollation);
        setLoadedData(createEmptyData());
    }, []);
    useEffect(function () {
        setLoadedData(createEmptyData());
    }, [timePeriod]);
    useEffect(function () {
        if (loadedData.get(activeTab).pageCache.length === 0) {
            var cancelToken = loadNextDataForTab(activeTab);
            return cancelToken === null || cancelToken === void 0 ? void 0 : cancelToken.cancel;
        }
    }, [loadNextDataForTab, loadedData, activeTab]);
    return (_jsx(Grid, __assign({ container: true, spacing: 2 }, { children: _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs("div", __assign({ className: "card", style: { padding: "10px" } }, { children: [_jsx(TabBar, { activeTab: activeTab, setActiveTab: setActiveTab }), _jsx(SelectCollation, { collation: collation, setCollation: handleCollationChanged }), _jsx(Table, { activeTab: activeTab, loadedData: loadedData, collation: collation, timePeriod: timePeriod }), _jsx(LoadMoreButton, { activeTab: activeTab, loadedData: loadedData, loadNextDataForTab: loadNextDataForTab })] })) })) })));
}
export default GoogleDashboard;
