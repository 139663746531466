import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import StarRating from '../general/StarRating';
import moment from 'moment-timezone';
import _ from 'lodash';
import ToolTip from '../general/ToolTip';
import VisitorActions from '../visitor/VisitorActions';
import LoaderAlt from '../general/LoaderAlt'
import BeaconRequest from '../../BeaconRequest';
import useLazyListLoader from '../../hooks/useLazyListLoader';

/**
 * 
 * This component exists because the visits list from the dashboard needs to pass
 * referrers into the <Link> components used here. The trade-off was to pass in a prop
 * and do a check before rendering each link or, as has been chosen, replicating the
 * file and editing the links to remove referrers. The issue was that occassionally 
 * the referrer broke the flow between sidebars and was documented here
 * https://trello.com/c/1dVsGv7i/1311-zero-content-after-exiting-campaign-views
 */
const VisitsListLite = props => {
    const [isFetching, setIsFetching] = useState(true)
    const {paging, onNext, setInitialData} = useLazyListLoader(0)

    useEffect(() => {
        BeaconRequest.get(props.query + "/summary/",  {order: "score:desc"})
        .then(responseObj => {
            setInitialData({
                data: responseObj.response, 
                next: responseObj.paging.next,
                dataLeft: responseObj.paging.totalResults
            })
            setIsFetching(false)
        })
        .catch(error => { console.log(error) })
    }, [props.query])

    if(isFetching){
        return <LoaderAlt />
    }

    const isFetchingNext = paging.isFetchingNext;
    const hasLoadedAll = paging.next == null;

    const visits = paging.data.sort((a,b) => b.star_rating-a.star_rating).map(visit => { //sort star rating by descending order
        const { 
            visit_id, 
            totalTimeOnSite, 
            pageViews, 
            scoringActions,
            startOfVisitDate, 
            // startOfVisitTime,
            __link,
            added,
            lead
        } = visit

        const { shortCode } = visit.__link

        return (
            <div key={visit_id} className="table__row">
				<div className="table__cell">
                    <div>
                        { 
                            visit.doNotTrack === 1 && 
                            <a rel="noopener noreferrer" href="https://www.thisisbeacon.com/resource/what-is-a-do-not-track-visit/" target="_blank" style={{paddingRight:5}}><ToolTip text={<i className="fas fa-ban"></i>} tip="Visitor has Do Not Track activated - click to find out more" /></a>
                        }
                        {moment(startOfVisitDate).format('DD MMM YYYY')} at {moment(added).format('LT')}
                    </div>
                    <Link 
                    title="View the details for this link"
                    className="text-small color-mid-grey"
                    to={{pathname: "/links/" + (visit.__link.link_id) }}>
                        bcn.to/{shortCode}
                    </Link>
				</div>

                <div className="table__cell no-wrap" style={{maxWidth:200}}>
                    { 
                        !_.isEmpty(visit.__campaign) ? 
                        <Link 
						to={{pathname: "/campaigns/" + (visit.__campaign.campaign_id), 
                        state: { modal: true, referrer: window.location.pathname } }}>
                        <span className="key-dot" style={{backgroundColor:visit.__campaign.colour}}></span>
                        {visit.__campaign.title}
                        </Link> : "No campaign" 
                    }
                    <div>
                    { 
                        !_.isEmpty(visit.__channel) ? 
                        <Link 
                        className="color-mid-grey"
						to={{pathname: "/channels/" + (visit.__channel.channel_id), 
                        state: { modal: true, referrer: window.location.pathname } }}>
                        <span className="key-dot" style={{backgroundColor:visit.__channel.colour}}></span>
                        {visit.__channel.title}</Link> : "No channel"     
                    }
                    </div>
                </div>

                <div className="table__cell no-wrap" style={{maxWidth:200}}>
                    {__link.notes}
                </div>

                <div className="table__cell">
                    <StarRating stars={visit.star_rating} />
                </div>
                <div className="table__cell">
                    {
                        totalTimeOnSite > 3600 ? moment.utc(totalTimeOnSite*1000).format('HH:mm:ss') : moment.utc(totalTimeOnSite*1000).format('mm:ss')
                    }
				</div>
                <div className="table__cell">
					{pageViews}
				</div>
                
                <div className="table__cell">
                    {
                        !_.isEmpty(scoringActions) && <VisitorActions leader={lead} actions={scoringActions} />
                    }
                </div>
                <div className="table__cell">
					<Link to={{pathname: "/visits/" + (visit.visit_id) }}
					className="button button--small button--green">View visit details</Link>
				</div>
            </div>
        )
    })

    return (
        <>
            <div className="table">
                <div className="table__row">
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}>Visit</div>
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}>Campaign/Channel</div>
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}>Link Description</div>
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}>Rating</div>
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}>Duration</div>
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}>Page views</div>
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}>Visitor Actions</div>
                    <div className="table__heading is-fixed" style={{top:props.fromTop}}></div>
                </div>
                {visits}
            </div>
            <div style={{textAlign: "center", marginTop: '10px'}}>
            {!hasLoadedAll && 
                <>
                    {isFetchingNext ? (
                        <LoaderAlt />
                    )  : (
                        <button className="button button--no-border button--green" onClick={() => onNext()}>
                            {"Load More - (" + paging.dataLeft + " in total)"}
                        </button>
                    )}
                </>
            }
            </div>

            {
                _.isEmpty(visits) &&
                <div className="message message--warning" style={{marginTop:20}}>
                    <h2 className="message__title">No visits to show</h2>
                    <p className="no-margin">You have no recorded website visits for the criteria selected.</p>
                </div>
            }
        </>
    )
}

VisitsListLite.defaultProps = {
    fromTop:'71px'
}

export default VisitsListLite;