var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DropdownList from "../../components/general/DropdownList";
export var Collations = [
    { id: "summary", title: "Summary" },
    { id: "date", title: "Day" },
    { id: "week", title: "Week" },
    { id: "month", title: "Month" },
    { id: "quarter", title: "Quarter" },
    { id: "year", title: "Year" }
];
export function SelectCollation(_a) {
    var collation = _a.collation, setCollation = _a.setCollation;
    return (_jsxs("div", __assign({ style: { margin: "10px 0", maxWidth: "15rem" } }, { children: [_jsx("div", __assign({ style: { marginBottom: "0.25rem" } }, { children: "Collation" })), _jsx(DropdownList, { selectItem: setCollation, selectedItem: collation.id, menu: {
                    title: collation.title,
                    items: Collations
                } })] })));
}
