import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchSingleChannel, updateChannel } from "../../actions/channels";
import Modal from "../../components/general/Modal";
import EditChannel from "../../components/forms/EditChannel";

class EditChannelContainer extends Component {
  constructor() {
    super();
    this.state = {
      alertOnClose: false,
      channelName: "",
      channelSpend: "",
      channelIcon: "",
      channelColour: "",
      reportedClicks: 0,
      adChannels: [],
      error: null
    };
    this.handleChangeChannelName = this.handleChangeChannelName.bind(this);
    this.handleChangeChannelSpend = this.handleChangeChannelSpend.bind(this);
    this.handleUpdateChannel = this.handleUpdateChannel.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeChannelIcon = this.handleChangeChannelIcon.bind(this);
    this.handleGetSelectedColour = this.handleGetSelectedColour.bind(this);
    this.handleChangeReportedClicks = this.handleChangeReportedClicks.bind(
      this
    );
    this.handleToggleAdChannel = this.handleToggleAdChannel.bind(this);
  }

  componentDidMount() {
    this.setState({
      channelName: this.props.channel.title,
      channelSpend: this.props.channel.spend,
      channelIcon: this.props.channel.icon,
      channelId: this.props.channel.channel_id,
      channelColour: this.props.channel.colour,
      reportedClicks: this.props.channel.reportedClicks,
      adChannels:
        this.props.channel.__adchannels?.map(
          ({ adchannel_id }) => adchannel_id
        ) ?? []
    });
  }

  handleChangeChannelName(e) {
    this.setState({
      channelName: e.target.value
    });
  }

  handleChangeChannelSpend(e) {
    this.setState({
      channelSpend: e.target.value
    });
  }

  handleChangeChannelIcon(icon) {
    this.setState({
      channelIcon: icon
    });
  }

  handleCloseModal() {
    this.props.handleOpen();
  }

  handleUpdateChannel(e) {
    e.preventDefault();
    this.setState({ error: null });
    this.props.updateChannel(this.state).then(e => {
      if (e.responseType == "error") {
        this.setState({ error: e.additional });
      } else {
        this.props.fetchSingleChannel(this.state.channelId);
        this.props.handleOpen();
      }
    });
  }

  handleGetSelectedColour(colour) {
    this.setState({ channelColour: colour, alertOnClose: true });
  }

  handleChangeReportedClicks(e) {
    this.setState({
      reportedClicks: e.target.value
    });
  }

  handleToggleAdChannel(adChannelId) {
    return event => {
      this.setState({ adChannels: [adChannelId] });
    };
  }

  render() {
    return (
      <Modal
        title="Edit Channel"
        closeLabel="Cancel"
        saveLabel="Save"
        closeAction={this.props.handleCloseModal}
        saveAction={this.handleUpdateChannel}
        alertOnClose={this.state.alertOnClose}
        overflowVisible
      >
        {
          <EditChannel
            campaign={this.props.campaign}
            defaultColour={this.props.channel.colour}
            getSelectedColour={this.handleGetSelectedColour}
            channelName={this.state.channelName}
            channelSpend={this.state.channelSpend}
            reportedClicks={this.state.reportedClicks}
            channelIcon={this.state.channelIcon}
            adChannels={this.state.adChannels}
            handleChangeChannelName={this.handleChangeChannelName}
            handleChangeChannelSpend={this.handleChangeChannelSpend}
            handleChangeReportedClicks={this.handleChangeReportedClicks}
            handleChangeChannelIcon={this.handleChangeChannelIcon}
            handleToggleAdChannel={this.handleToggleAdChannel}
            error={this.state.error}
          />
        }
      </Modal>
    );
  }
}

EditChannelContainer.propTypes = {
  handleOpen: PropTypes.func,
  handleCloseModal: PropTypes.func,
  channel: PropTypes.object,
  campaign: PropTypes.object
};

// const mapStateToProps = state => {
// 	return {
// 		campaign: state.campaigns.singleCampaign
// 	}
// }

const mapDispatchToProps = dispatch => {
  return {
    fetchSingleChannel: channelId => dispatch(fetchSingleChannel(channelId)),
    updateChannel: payload => dispatch(updateChannel(payload))
  };
};

export default connect(null, mapDispatchToProps)(EditChannelContainer);
