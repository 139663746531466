import React, { Component } from 'react';
import CustomerTeam from '../../components/customer/CustomerTeam';
var _ = require('lodash');

class CustomerTeamContainer extends Component {
	constructor(){
		super();
		this.state = {
			customer: {},
		}
	}

	componentDidMount(){
		this.setState({
			customer: this.props.customer
		})
	}

	componentWillReceiveProps(nextProps){
		this.setState({
			customer: nextProps.customer
		})
	}

	render() {
		if(!_.isEmpty(this.state.customer)){
			return <CustomerTeam customer={this.state.customer} user={this.props.user} />
		} 
		
		return null
	}
}

export default CustomerTeamContainer;