import { actionTypes } from "./constants";

export const initialState = {
    data: null,   
    previousRequestSource: null,
    fetching: false
}

export const mediaSpendReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MEDIA_SPEND_DATA:
            return {
                ...state,
                data: action.payload
            }
        case actionTypes.SET_MEDIA_SPEND_CANCEL_TOKEN:
            return {
                ...state,
                previousRequestSource: action.previousRequestSource
            }
        case actionTypes.IS_MEDIA_SPEND_FETCHING:
            return {
                ...state,
                fetching: action.fetching
            }
        default:
            return state;
    }
} 