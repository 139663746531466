import React, {useCallback, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import moment from 'moment-timezone'

function DateRangeSelect(props) {
	const [leaving, setLeaving] = useState(false);
	const [dateRange, setDateRange] = useState({from: null, to: null})

	const handleChangeDate = useCallback((date) => {
		const timeZoneOffset = date.date[0].getTimezoneOffset() + moment(date.date[0]).utcOffset()

		const from = moment(date.date[0]).subtract(timeZoneOffset, 'minutes').startOf('day')
        const to = moment(date.date[1]).subtract(timeZoneOffset, 'minutes').endOf('day')
        
		const title = moment(from).format("DD MMM YY") + ' - ' + moment(to).format("DD MMM YY")

		const dateFromUnix = moment(from).utc().unix()
		let dateToUnix = moment(to).utc().unix()

		setDateRange({ from, to, title, id: dateFromUnix + ":" + dateToUnix });
	}, []);

	const handleClose = useCallback((action) => {
	    setLeaving(true);
		setTimeout(() => {
			// Toggle the range selector on the UI
			props.toggleDateRangeSelect()

			if(action !== 'cancel'){
				// Check if anything has changed
				// If it has, fire the request to change dates
				if(props.dateRange === null || props.dateRange.from !== dateRange.from || props.dateRange.to !== dateRange.to) {
				    props.setSelectedDateRange(dateRange);
				}
			}

			setLeaving(false);
		}, 200)
	}, [dateRange, props]);

	if(!props.isOpen) {
		return null;
	}
	return (
		<React.Fragment>
			<div className={"date-range-select-mask " + (leaving ? "leave" : "")} onClick={() => handleClose('cancel')}/>
			<div className={"date-range-select " + (leaving ? "leave" : "")}>
				<div className="date-range-select__picker">
					<Flatpickr
						value={props.dateRange}
						options={ { mode:'range', defaultHour: 0, inline: true, maxDate: props.maxDate ?? moment().toDate() } }
						onChange={date => { handleChangeDate({date}) }}
						onDayCreate={function(dObj, dStr, fp, dayElem){
					}}
					/>
				</div>
				<ul className="date-range-select__tools">
					<li className="date-range-select__dates">{dateRange.title}</li>
					<li><button className="button button--small button--white hollow" onClick={() => handleClose('cancel')}>Cancel</button></li>
					<li><button className="button button--small button--yellow" onClick={handleClose}>Set Dates</button></li>
				</ul>
			</div>
		</React.Fragment>
	)
}

export default DateRangeSelect;
