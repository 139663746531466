var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import Loader from "../../components/general/Loader";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Skeleton from "@material-ui/lab/Skeleton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CreateCampaignContainer from "../../containers/forms/CreateCampaignContainer";
import CreateShortLink from "../../components/link/CreateShortLink";
import { PerformanceExport, customPeriods } from "../../components/report/ReportList";
import { addNotification } from "../../actions/ui";
import { toggleCampaign } from "../../actions/campaigns";
import Modal from "../../components/general/Modal";
import BeaconRequest from "../../BeaconRequest";
import BeaconRequestPHP8 from "../../BeaconRequestPHP8";
import AddCampaignChannelsContainer from "../../containers/forms/AddCampaignChannelsContainer";
import EditCampaignContainer from "../../containers/forms/EditCampaignContainer";
import EditChannelContainer from "../../containers/forms/EditChannelContainer";
import EditLinkContainer from "../../containers/forms/EditLinkContainer";
import { setBarChartResource } from "../BarChart/actions";
import { setConversionChartResource } from "../ConversionChart/actions";
import { setVisitorProfileResource } from "../VisitorProfile/actions";
import { setBrowserProfileResource } from "../BrowserProfile/actions";
import { setAnalyticsListResource } from "../CampaignsList/actions";
import { setBotActivityResource } from "../BotActivity/actions";
import { setBotImpactGraphResource } from "../BotImpactGraph/actions";
import { setMediaSpendResource } from "../MediaSpend/actions";
import { setBudgetEffectivenessResource } from "../BudgetEffectiveness/actions";
import { setPerformanceListResource } from "../PerformanceList/actions";
import { useSelector, useDispatch } from "react-redux";
import DropdownList from "../../components/general/DropdownList";
import Overlay from "../../components/general/Overlay";
import { redirectRouter } from "../../organisms/WidgetContainer/DropdownContainer";
import { setActionBarResource, clearResource } from "./actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ImportTools from "../../components/link/ImportTools";
import Axios from "axios";
var SpendImportTool = function (props) {
    var _a = useState(null), spendData = _a[0], setSpendData = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var fetchSpendData = useCallback(function () {
        if (["campaign", "channel", "link"].includes(props.filter.type)) {
            setLoading(true);
            BeaconRequest.getNoChache("".concat(props.filter.type, "/").concat(props.filter.payload.id, "/").concat(props.filter.type === "link" ? "" : "spend/tab"))
                .then(function (e) {
                setSpendData(e.response);
            })
                .finally(function () { return setLoading(false); });
        }
    }, [props.filter.type, props.filter.payload.id, props.datetime]);
    useEffect(function () {
        fetchSpendData();
    }, [fetchSpendData]);
    return (_jsx(Modal, __assign({ title: "Spend import tool", closeAction: function () { return props.setSpendImport(false); }, disableSave: true }, { children: loading ? (_jsx(Loader, {})) : (_jsx(ImportTools, { spendData: spendData, fetchSpendData: fetchSpendData, fetchSingleLinkNoChache: function () { return props.refreshDashboard(); } })) })));
};
var getLeadDefinitionDescriptions = function (obj) {
    if (obj.__meta.lead_definition == "identified_visitor")
        return "Identified visitor from form fill";
    else {
        switch (JSON.parse(obj.__meta.lead_definition)[0].type) {
            case "identified_visitor":
            default:
                return "Identified visitor from form fill";
            case "external_link":
                return "Clicking on an external link";
            case "url_match":
            case "preg_match":
                return "Visiting an internal page";
            case "shopify_thank_you":
                return "Shopify: order thank you page";
        }
    }
};
export var Popup = function (props) {
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    // const [placement, setPlacement] = React.useState('bottom');
    var hand_open_help = function (event) {
        setAnchorEl(event.currentTarget);
        setOpen(function (prev) { return !prev; });
        // setPlacement(newPlacement);
    };
    return (_jsx(ClickAwayListener, __assign({ onClickAway: function () { return setOpen(false); } }, { children: _jsxs("div", __assign({ style: { display: "inline-block" } }, { children: [_jsx("span", __assign({ style: __assign({}, props.style), onClick: hand_open_help }, { children: props.children })), _jsx(Popper, __assign({ open: open, anchorEl: anchorEl, placement: props.placement, transition: true, modifiers: {
                        flip: {
                            enabled: false
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "window"
                        },
                        arrow: {
                            enabled: false
                            //   element: arrowRef,
                        }
                    }, style: { zIndex: 15 } }, { children: function (_a) {
                        var TransitionProps = _a.TransitionProps;
                        return (_jsx(Fade, __assign({}, TransitionProps, { timeout: 150 }, { children: _jsx("div", __assign({ className: "card", style: { maxWidth: "300px", padding: "10px" } }, { children: props.content })) })));
                    } }))] })) })));
};
var SkeletonLoader = function () {
    return (_jsxs("div", __assign({ style: {
            margin: "auto",
            maxWidth: "80rem",
            padding: "20px 20px 0",
            marginBottom: 0,
            height: "105px"
        } }, { children: [_jsxs("div", __assign({ style: { marginBottom: "10px" } }, { children: [_jsx(Skeleton, { variant: "rect", width: 21, height: 21, style: { display: "inline-block", marginRight: "10px" } }), _jsx(Skeleton, { variant: "rect", width: 300, height: 21, style: { display: "inline-block" } })] })), _jsx(Skeleton, { variant: "rect", width: 175, height: 13, style: { marginBottom: "10px" } }), _jsxs("div", { children: [_jsx(Skeleton, { variant: "rect", width: 21, height: 13, style: { display: "inline-block", marginRight: "10px" } }), _jsx(Skeleton, { variant: "rect", width: 200, height: 13, style: { display: "inline-block", marginRight: "10px" } }), _jsx(Skeleton, { variant: "rect", width: 21, height: 13, style: { display: "inline-block", marginRight: "10px" } }), _jsx(Skeleton, { variant: "rect", width: 200, height: 13, style: { display: "inline-block", marginRight: "10px" } }), _jsx(Skeleton, { variant: "rect", width: 21, height: 13, style: { display: "inline-block", marginRight: "10px" } }), _jsx(Skeleton, { variant: "rect", width: 200, height: 13, style: { display: "inline-block", marginRight: "10px" } })] })] })));
};
function usePrevious(value) {
    var ref = React.useRef();
    useEffect(function () {
        ref.current = value;
    }, [value]);
}
var ActionBar = function (props) {
    var resource = useSelector(function (_a) {
        var actionBar = _a.actionBar;
        return actionBar.data;
    });
    var isFetching = useSelector(function (_a) {
        var actionBar = _a.actionBar;
        return actionBar.fetching;
    });
    var _a = useState("performance"), selectedTab = _a[0], setSelectedTab = _a[1];
    var previousDateTime = usePrevious(props.datetime);
    var previousFilter = usePrevious(props.filter);
    var _b = useState(), type = _b[0], setType = _b[1];
    var _c = useState(false), createCampaign = _c[0], setCreateCampaign = _c[1];
    var _d = useState(false), createLink = _d[0], setCreateLink = _d[1];
    var _e = useState(false), createExport = _e[0], setCreateExport = _e[1];
    var _f = useState(false), spendImport = _f[0], setSpendImport = _f[1];
    var _g = useState(false), createChannel = _g[0], setCreateChannel = _g[1];
    var _h = useState(false), editCampaign = _h[0], setEditCampaign = _h[1];
    var _j = useState(false), editChannel = _j[0], setEditChannel = _j[1];
    var _k = useState(false), editLink = _k[0], setEditLink = _k[1];
    var _l = useState(false), multiLinkCreator = _l[0], setMultiLinkCreator = _l[1];
    var dispatch = useDispatch();
    var filter = props.filter, datetime = props.datetime;
    useLayoutEffect(function () {
        //replace with after resource is loaded change type
        dispatch(clearResource()); //type changes before the request completes
        props.filter && setType(props.filter.type);
    }, [props.filter]);
    useEffect(function () {
        if ((props.filter && previousFilter == null) ||
            (props.filter &&
                previousFilter &&
                (previousFilter.payload.id != props.filter.payload.id ||
                    previousFilter.type != props.filter.type))) {
            dispatch(setActionBarResource({
                timePeriod: props.datetime,
                filter: props.filter
            }));
        }
    }, [props.datetime, props.filter]);
    var refreshResource = function () {
        dispatch(setActionBarResource({
            timePeriod: props.datetime,
            filter: props.filter,
            disableCache: true
        }));
    };
    var split = datetime.split(":");
    var isToday = datetime == "today" ||
        (moment.unix(split[0]).unix() ==
            moment()
                .startOf("d")
                .unix() &&
            moment.unix(split[1]).unix() ==
                moment()
                    .endOf("d")
                    .unix());
    var isOneDayPeriod = datetime == "yesterday" ||
        moment.unix(split[1]).diff(moment.unix(split[0]), "days") == 0;
    useEffect(function () {
        var urlParams = new URLSearchParams(window.location.search);
        var view = urlParams.get("view");
        var model = urlParams.get("model");
        setSelectedTab(view || "performance");
    }, [props.location]);
    var getPerformanceTime = function () {
        var find = customPeriods.filter(function (e) {
            return e.value.includes(datetime.replace("last", ""));
        });
        if (find[0]) {
            return {
                id: find[0].value,
                title: find[0].label
            };
        }
        else {
            var custom = datetime.split(":");
            return {
                from: moment.unix(custom[0]),
                to: moment.unix(custom[1]),
                title: moment.unix(custom[0]).format("DD MMM YY") +
                    " - " +
                    moment.unix(custom[1]).format("DD MMM YY"),
                id: datetime
            };
        }
    };
    var refreshDashboard = useCallback(function () {
        if (props.location.search.includes("view=analytics")) {
            dispatch(setBarChartResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            dispatch(setConversionChartResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            dispatch(setVisitorProfileResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            dispatch(setBrowserProfileResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            if (props.filter.type !== "link") {
                dispatch(setAnalyticsListResource({
                    timePeriod: props.datetime,
                    filter: props.filter,
                    disableCache: true
                }));
            }
        }
        if (props.location.search.includes("view=performance")) {
            dispatch(setBotActivityResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            dispatch(setBotImpactGraphResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            dispatch(setMediaSpendResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            dispatch(setBudgetEffectivenessResource({
                timePeriod: props.datetime,
                filter: props.filter,
                disableCache: true
            }));
            if (props.filter.type !== "link") {
                dispatch(setPerformanceListResource({
                    timePeriod: props.datetime,
                    filter: props.filter,
                    disableCache: true
                }));
            }
        }
    }, [props.location.pathname, props.datetime, props.filter]);
    var view = new URLSearchParams(window.location.search).get("view");
    var _m = useState(false), hasGoogleConnection = _m[0], setHasGoogleConnection = _m[1];
    useEffect(function () {
        if (typeof props.website.website_id !== "number")
            return;
        var url = "website/".concat(props.website.website_id, "/connection/google");
        var cancelToken = Axios.CancelToken.source();
        BeaconRequestPHP8.get(url, null, { cancelToken: cancelToken.token }).then(function (_a) {
            var response = _a.response;
            setHasGoogleConnection(response !== null &&
                response.token_set &&
                typeof response.account_id === "string" &&
                response.account_id.length > 0 &&
                response.invalidated === 0);
        });
        return cancelToken.cancel;
    }, [props.website.website_id]);
    if (props.filter == null)
        return null;
    var isLoadingObj = isFetching || resource == null;
    var obj = resource && resource.graph.response;
    return (_jsxs(_Fragment, { children: [createCampaign && (_jsx(CreateCampaignContainer, { handleOpen: function () { return setCreateCampaign(false); }, callback: function (e) {
                    props.setFilter({ type: "campaign", payload: e });
                    // setCreateChannel(true)
                }, channelsCallback: function (e, id) {
                    props.setFilter({ type: "campaign", payload: { id: id } });
                } })), createChannel && (_jsx(AddCampaignChannelsContainer, { campaignId: props.filter.payload.id, campaignName: props.filter.payload.name, handleOpen: function () { return setCreateChannel(false); }, callback: function (e, campaign) {
                    props.setFilter({ type: "campaign", payload: campaign });
                } })), createLink && _jsx(CreateShortLink, { close: function (e) { return setCreateLink(false); } }), createExport && (_jsx(Modal, __assign({ title: "Data export", closeAction: function () { return setCreateExport(false); }, disableSave: true }, { children: _jsx(PerformanceExport, __assign({}, props, { modal: true, selectedFilter: {
                        id: filter.type,
                        title: filter.type.replace(/^\w/, function (c) { return c.toUpperCase(); })
                    }, selectedDate: getPerformanceTime(), selectedCampaign: filter.type == "campaign" ? obj : obj.__campaign, selectedChannel: filter.type == "channel" && obj, websiteId: props.website.website_id, timezone: props.timezone, callback: function () {
                        props.addNotification({
                            title: "Your data export will be ready in the next 10 minutes on this screen. Please come back here or refresh shortly.",
                            theme: "positive"
                        });
                        setCreateExport(false);
                        props.history.push("/report?tab=3");
                    } })) }))), multiLinkCreator && (_jsx(Overlay, __assign({ for: "multiLinkCreator", title: "Link Suite" }, props, { close: function (e) { return setMultiLinkCreator(false); }, cancel: function (e) { return setMultiLinkCreator(false); } }))), editCampaign && (_jsx(EditCampaignContainer, { handleOpen: function () {
                    setEditCampaign(false);
                    refreshDashboard();
                    refreshResource();
                }, cancel: function () { return setEditCampaign(false); }, campaign: obj })), spendImport && (_jsx(SpendImportTool, { filter: props.filter, datetime: props.datetime, setSpendImport: setSpendImport, refreshDashboard: refreshDashboard })), editChannel && (_jsx(EditChannelContainer, { handleOpen: function () {
                    setEditChannel(false);
                    // refreshDashboard()
                    refreshResource();
                }, handleCloseModal: function () { return setEditChannel(false); }, channel: obj, campaign: obj.__campaign })), editLink && (_jsx(EditLinkContainer, { handleOpen: function () {
                    setEditLink(false);
                    refreshResource();
                }, handleCloseModal: function () { return setEditLink(false); }, link: obj })), selectedTab !== "google" && (_jsx("div", __assign({ style: {
                    background: "white",
                    boxShadow: "inset rgb(0 0 0 / 25%) 0px 0.5px 6px",
                    paddingBottom: "10px"
                } }, { children: resource == null ? (_jsx(SkeletonLoader, {})) : (_jsxs("div", __assign({ className: "container", style: { margin: "auto", padding: "20px 20px 5px" } }, { children: [_jsxs("ul", __assign({ className: "margin-small", style: {
                                display: "grid",
                                gridTemplateColumns: "max-content auto max-content"
                            } }, { children: [_jsxs("div", __assign({ style: { display: "flex", alignItems: "center" } }, { children: [type == "campaign" && (_jsx("div", { style: {
                                                display: "inline-block",
                                                backgroundColor: obj.colour,
                                                marginRight: 12,
                                                width: "16px",
                                                height: "16px",
                                                borderRadius: "4px"
                                            } })), type == "channel" && (_jsx("div", __assign({ style: {
                                                display: "inline-block",
                                                color: obj.colour,
                                                marginRight: 12,
                                                borderRadius: "4px"
                                            } }, { children: _jsx("i", { className: obj.icon }) })))] })), _jsxs("li", __assign({ className: "overlay__text text-large", style: {
                                        fontSize: "11pt",
                                        fontWeight: 700,
                                        display: "flex",
                                        alignItems: "center"
                                    } }, { children: [type == "website" && obj.name, type == "campaign" && obj.title, type == "channel" && obj.title, type == "link" && _jsx(_Fragment, { children: obj.notes || "No description" }), obj != null && obj.archived != null && obj.archived == 1 && (_jsx("span", __assign({ style: {
                                                background: "#E6EBED",
                                                fontSize: "13px",
                                                padding: "3px 6px",
                                                borderRadius: "5px",
                                                marginLeft: "10px"
                                            } }, { children: "ARCHIVED" })))] })), _jsx("li", {})] })), _jsx("ul", __assign({ className: "margin-small" }, { children: _jsxs("li", __assign({ className: "overlay__text", style: { fontSize: "11pt" } }, { children: [type == "website" && (_jsxs(_Fragment, { children: [_jsx("a", __assign({ href: obj.__urls.primaryUrl, target: "_blank" }, { children: obj.__urls.primaryUrl })), _jsx("i", { className: "fas fa-external-link-alt", style: { marginLeft: "6px" } })] })), type == "campaign" && (_jsx(_Fragment, { children: obj.description || "No description" })), type == "channel" && (_jsx(_Fragment, { children: obj.description || "No description" })), type == "link" && (_jsxs("div", { children: [_jsx(CopyToClipboard, __assign({ text: "https://bcn.to/" + obj.shortCode }, { children: _jsx("button", __assign({ type: "button", title: "Copy to clipboard", className: "button button--small button--light-grey", style: { marginRight: "5px" }, onClick: function (e) {
                                                        e.preventDefault();
                                                        var payload = {
                                                            title: "Copied to clipboard",
                                                            theme: "positive"
                                                        };
                                                        props.addNotification(payload);
                                                    } }, { children: _jsx("i", { className: "fas fa-copy" }) })) })), "https://bcn.to/" + obj.shortCode] }))] })) })), _jsxs("ul", __assign({ className: "overlay__details", style: { fontSize: "9pt" } }, { children: [type == "website" && (_jsxs("li", { children: [_jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-clock", style: { opacity: 0.6 } }), " ", _jsx("span", __assign({ style: { marginRight: "5px" } }, { children: "Last Active:" })), " ", moment(obj.lastActive).format("MMM Do YYYY hh:mm")] })), _jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-globe-europe", style: { opacity: 0.6 } }), " ", _jsx("span", __assign({ style: { marginRight: "5px" } }, { children: "Timezone:" })), " ", obj.timezone] })), _jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-clock", style: { opacity: 0.6 } }), " ", _jsx("span", __assign({ style: { marginRight: "5px" } }, { children: "Website Time:" })), " ", moment().tz(obj.timezone).format("hh:mm A")] })), _jsx("span", {})] })), type == "campaign" && (_jsxs("li", { children: [_jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-clock", style: { opacity: 0.6 } }), " ", _jsx("span", __assign({ style: { marginRight: "5px" } }, { children: "Running Dates:" })), moment
                                                    .unix(obj.__lifecycle.first.timestamp)
                                                    .format("MMM Do YYYY"), "\u2013", moment
                                                    .unix(obj.__lifecycle.last.timestamp)
                                                    .format("MMM Do YYYY")] })), _jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-clipboard", style: { opacity: 0.6, marginRight: "5px" } }), "Recording identifiable data:", " ", obj.__meta.show_identified_data ? (_jsx("span", { children: "Yes" })) : (_jsx("span", { children: "No" }))] })), _jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-bullseye", style: { opacity: 0.6, marginRight: "5px" } }), " ", _jsxs("span", __assign({ style: { marginRight: "5px" } }, { children: ["Goal definition:", " "] })), getLeadDefinitionDescriptions(obj) !=
                                                    "Identified visitor from form fill" ? (_jsx(Popup, __assign({ content: _jsx("div", { children: JSON.parse(obj.__meta.lead_definition)[0].settings.map(function (url) {
                                                            if (getLeadDefinitionDescriptions(obj) ==
                                                                "Visiting an internal page") {
                                                                return (_jsx("div", { children: props.website.__urls.primaryUrl +
                                                                        "" +
                                                                        url }));
                                                            }
                                                            else {
                                                                return _jsx("div", { children: url });
                                                            }
                                                        }) }) }, { children: _jsx("span", __assign({ style: {
                                                            textDecoration: "underline",
                                                            cursor: "pointer"
                                                        } }, { children: getLeadDefinitionDescriptions(obj) })) }))) : (_jsx("span", { children: "Identified visitor from form fill" }))] }))] })), type == "channel" && (_jsx("li", { children: _jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-clock", style: { opacity: 0.6 } }), " ", _jsx("span", __assign({ style: { marginRight: "5px" } }, { children: "Running Dates:" })), moment
                                                .unix(obj.__lifecycle.first.timestamp)
                                                .format("MMM Do YYYY"), "\u2013", moment
                                                .unix(obj.__lifecycle.last.timestamp)
                                                .format("MMM Do YYYY")] })) })), type == "link" && (_jsxs("li", { children: [_jsxs("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: [_jsx("i", { className: "fas fa-clock", style: { opacity: 0.6 } }), " ", _jsx("span", __assign({ style: { marginRight: "5px" } }, { children: "Running Dates:" })), moment
                                                    .unix(obj.__lifecycle.first.timestamp)
                                                    .format("MMM Do YYYY"), "\u2013", moment
                                                    .unix(obj.__lifecycle.last.timestamp)
                                                    .format("MMM Do YYYY")] })), _jsx("span", __assign({ style: { marginRight: "20px", display: "inline-block" } }, { children: _jsxs(Popup, __assign({ content: _jsx("div", __assign({ style: { overflowWrap: "break-word" } }, { children: obj.originalUrl })) }, { children: [_jsx("i", { className: "fas fa-link", style: { opacity: 0.6 } }), " ", _jsx("span", __assign({ style: {
                                                            marginRight: "5px",
                                                            textDecoration: "underline",
                                                            cursor: "pointer"
                                                        } }, { children: "Original url" }))] })) }))] }))] }))] }))) }))), _jsx("div", __assign({ style: {
                    position: "sticky",
                    top: 50,
                    zIndex: 13,
                    marginBottom: "30px",
                    background: "white",
                    boxShadow: "0 0.5px 0 rgba(0,0,0,0.25)"
                } }, { children: _jsxs("div", __assign({ style: {
                        display: "grid",
                        gridTemplateColumns: "auto max-content",
                        width: "100%",
                        margin: "auto",
                        maxWidth: "80rem",
                        padding: "0 20px"
                    } }, { children: [_jsxs("div", __assign({ style: {
                                padding: "0 20px",
                                paddingTop: "5px",
                                display: "flex",
                                flexDirection: "row"
                            } }, { children: [_jsx("span", __assign({ style: {
                                        padding: "10px",
                                        fontWeight: 700,
                                        borderBottom: selectedTab == "performance" && "solid #ffd200 3px",
                                        marginRight: "20px",
                                        cursor: "pointer",
                                        fontSize: "16px"
                                    }, onClick: function (e) {
                                        props.redirect(null, null, null, null, "performance");
                                    } }, { children: "Performance" })), _jsx("span", __assign({ style: {
                                        padding: "10px",
                                        fontWeight: 700,
                                        borderBottom: selectedTab == "analytics" && "solid #ffd200 3px",
                                        marginRight: "20px",
                                        cursor: "pointer",
                                        fontSize: "16px"
                                    }, onClick: function (e) {
                                        props.redirect(null, null, null, null, "analytics");
                                    } }, { children: "Analytics" })), _jsx("span", __assign({ style: {
                                        padding: "10px",
                                        fontWeight: 700,
                                        borderBottom: selectedTab == "visits" && "solid #ffd200 3px",
                                        marginRight: "20px",
                                        cursor: "pointer",
                                        fontSize: "16px"
                                    }, onClick: function (e) {
                                        props.redirect(null, null, null, null, "visits");
                                    } }, { children: "Visits" })), (hasGoogleConnection || selectedTab === "google") && (_jsx("span", __assign({ style: {
                                        padding: "10px",
                                        fontWeight: 700,
                                        borderBottom: selectedTab == "google" && "solid #ffd200 3px",
                                        marginRight: "20px",
                                        cursor: "pointer",
                                        fontSize: "16px"
                                    }, onClick: function (e) {
                                        props.redirect(null, null, null, null, "google");
                                    } }, { children: "Google" })))] })), selectedTab !== "google" && (_jsxs("div", __assign({ style: { padding: "10px", display: "flex", flexDirection: "row" } }, { children: [props.filter.type == "website" &&
                                    props.selectedCustomer.__user_role.role_id !== 4 && (_jsxs(_Fragment, { children: [_jsx("span", __assign({ style: {
                                                display: "inline-block",
                                                width: "130px",
                                                height: "29px",
                                                marginRight: "10px"
                                            } }, { children: _jsx(DropdownList, { selectItem: function (selected) {
                                                    // props.selectedCustomer.__user_role.role_id !== 4
                                                    switch (selected.id) {
                                                        case "edit":
                                                            props.history.push("/website");
                                                            break;
                                                        // case "createMultiLink":
                                                        //     setMultiLinkCreator(true)
                                                        //     break;
                                                        default:
                                                            break;
                                                    }
                                                }, menu: {
                                                    title: _jsx("span", { children: "Actions" }),
                                                    items: [
                                                        {
                                                            id: "edit",
                                                            title: "Edit",
                                                            icon: (_jsx("i", { className: "fas fa-edit", style: { marginRight: "5px" } }))
                                                        }
                                                        // {id: "createMultiLink", title: "Multi-link creator", icon: <i className="fas fa-list" style={{marginRight: '5px'}}></i>},
                                                    ]
                                                }, outline: "1px solid #5E6172", position: "middle", theme: "light", isDisabled: isLoadingObj }) })), _jsx("button", __assign({ className: "button button--dark-grey hollow", style: { marginRight: "10px" }, disabled: isLoadingObj, onClick: function (e) { return setCreateLink(true); } }, { children: "Create Veracity link" })), _jsxs("button", __assign({ className: "button button--green", style: { marginRight: "10px" }, disabled: isLoadingObj, onClick: function (e) { return setCreateCampaign(true); } }, { children: [_jsx("i", { className: "fas fa-plus", style: { marginRight: "5px" } }), " ", "Create campaign"] }))] })), props.filter.type == "campaign" &&
                                    props.selectedCustomer.__user_role.role_id !== 4 && (_jsxs(_Fragment, { children: [_jsx("span", __assign({ style: {
                                                display: "inline-block",
                                                width: "130px",
                                                height: "29px",
                                                marginRight: "10px"
                                            } }, { children: _jsx(DropdownList, { selectItem: function (selected) {
                                                    switch (selected.id) {
                                                        case "edit":
                                                            setEditCampaign(true);
                                                            break;
                                                        case "archive":
                                                            obj != null &&
                                                                props
                                                                    .toggleCampaign(obj, obj.archived)
                                                                    .then(function () { return refreshResource(); });
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }, menu: {
                                                    title: _jsx("span", { children: "Actions" }),
                                                    items: [
                                                        {
                                                            id: "edit",
                                                            title: "Edit",
                                                            icon: (_jsx("i", { className: "fas fa-edit", style: { marginRight: "5px" } }))
                                                        },
                                                        {
                                                            id: "archive",
                                                            title: obj != null && obj.archived == 0
                                                                ? "Archive"
                                                                : "Un-archive",
                                                            icon: (_jsx("i", { className: "fas fa-archive", style: { marginRight: "5px" } }))
                                                        }
                                                    ]
                                                }, outline: "1px solid #5E6172", position: "middle", theme: "light", isDisabled: isLoadingObj }) })), _jsx("button", __assign({ className: "button button--dark-grey hollow", style: { marginRight: "10px" }, disabled: isLoadingObj, onClick: function (e) { return setCreateLink(true); } }, { children: "Create Veracity link" })), _jsxs("button", __assign({ className: "button button--green", style: { marginRight: "10px" }, disabled: isLoadingObj, onClick: function (e) { return setCreateChannel(true); } }, { children: [_jsx("i", { className: "fas fa-plus", style: { marginRight: "5px" } }), " ", "Create channel", " "] }))] })), props.filter.type == "channel" &&
                                    props.selectedCustomer.__user_role.role_id !== 4 && (_jsxs(_Fragment, { children: [_jsx("span", __assign({ style: {
                                                display: "inline-block",
                                                width: "130px",
                                                height: "29px",
                                                marginRight: "10px"
                                            } }, { children: _jsx(DropdownList, { selectItem: function (selected) {
                                                    // props.selectedCustomer.__user_role.role_id !== 4
                                                    switch (selected.id) {
                                                        case "edit":
                                                            setEditChannel(true);
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }, menu: {
                                                    title: _jsx("span", { children: "Actions" }),
                                                    items: [
                                                        {
                                                            id: "edit",
                                                            title: "Edit",
                                                            icon: (_jsx("i", { className: "fas fa-edit", style: { marginRight: "5px" } }))
                                                        }
                                                    ]
                                                }, outline: "1px solid #5E6172", position: "middle", theme: "light", isDisabled: isLoadingObj }) })), _jsxs("button", __assign({ className: "button button--green", disabled: isLoadingObj, style: { marginRight: "10px" }, onClick: function (e) { return setCreateLink(true); } }, { children: [_jsx("i", { className: "fas fa-plus", style: { marginRight: "5px" } }), " ", "Create Veracity link", " "] }))] })), props.filter.type == "link" &&
                                    props.selectedCustomer.__user_role.role_id !== 4 && (_jsx(_Fragment, { children: _jsx("span", __assign({ style: {
                                            display: "inline-block",
                                            width: "130px",
                                            height: "29px",
                                            marginRight: "10px"
                                        } }, { children: _jsx(DropdownList, { selectItem: function (selected) {
                                                // props.selectedCustomer.__user_role.role_id !== 4
                                                switch (selected.id) {
                                                    case "edit":
                                                        setEditLink(true);
                                                        break;
                                                    case "import":
                                                        setSpendImport(obj);
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }, menu: {
                                                title: _jsx("span", { children: "Actions" }),
                                                items: [
                                                    {
                                                        id: "edit",
                                                        title: "Edit",
                                                        icon: (_jsx("i", { className: "fas fa-edit", style: { marginRight: "5px" } }))
                                                    },
                                                    {
                                                        id: "import",
                                                        title: "Manage Spend",
                                                        icon: (_jsx("i", { className: "fas fa-upload", style: { marginRight: "5px" } }))
                                                    }
                                                ]
                                            }, outline: "1px solid #5E6172", position: "middle", theme: "light", isDisabled: isLoadingObj }) })) }))] })))] })) }))] }));
};
var mapStateToProps = function (state) {
    return {
        website: state.websites.selectedWebsite,
        currency: state.websites.selectedWebsite.__meta,
        selectedCustomer: state.customers.selectedCustomer,
        timezone: state.websites.selectedWebsite.timezone
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        addNotification: function (payload) { return dispatch(addNotification(payload)); },
        toggleCampaign: function (campaign, toggle) {
            return dispatch(toggleCampaign(campaign, toggle));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(redirectRouter(ActionBar));
