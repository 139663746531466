import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addUserToTeam, fetchTeamUsers } from '../../actions/teams';
import AddTeamMember from '../../components/forms/AddTeamMember';
import { Link } from 'react-router-dom';

class AddTeamMemberContainer extends Component {
	constructor(){
		super()
		this.state = {
			email: '',
			readOnly: false
		}
		this.handleChangeEmail = this.handleChangeEmail.bind(this)
		this.handleChangeReadOnly = this.handleChangeReadOnly.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount(){
		this.setState({
			customer_id:this.props.customer.customer_id
		})
	}

	handleChangeEmail(e){
		this.setState({
			email: e.target.value
		})
	}

	handleChangeReadOnly(e){
		this.setState({
			readOnly:e.target.checked
		})
	}

	handleSubmit(e){
		e.preventDefault();
		this.props.addUserToTeam(this.state)
		.then(()=>{
			// Re-fetch team
			this.props.fetchTeamUsers(this.state.customer_id).then(teams => {
				this.props.setTeams(teams.response);
			})
		})
		this.setState({
			email: "",
			readOnly: false
		})
	}

	render() {
		const team_limit = this.props.customer.__subscription.__package_details.team_size
		const team_size = this.props.team.length

		if(team_size < team_limit){
			return (
				<AddTeamMember 
				email={this.state.email} 
				handleChangeEmail={this.handleChangeEmail} 
				handleChangeReadOnly={this.handleChangeReadOnly}
				handleSubmit={this.handleSubmit}
				/>
			)
		} else {
			return (
				<div className="message message--warning">
					<h2 className="message__title">Plan limit hit!</h2>
					<p className="margin-small">You've maxed out the number of people you can add to your team. You'll need to upgrade your plan to add more people.</p>
					<p>To start or upgrade your plan, visit your <Link to="/company">account page here</Link></p>
				</div>
			)
		}
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
        addUserToTeam: (payload) => dispatch(addUserToTeam(payload)),
        fetchTeamUsers: (customer_id) => dispatch(fetchTeamUsers(customer_id))
    }
}

export default connect(null, mapDispatchToProps)(AddTeamMemberContainer);