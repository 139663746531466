
// https://blog.prototypr.io/ui-grid-best-practices-efd6c4f9d16
const defaultStyles = {
	neutrals: {
		900: '#231f20',
		600: '#666666',
		300: '#e667e8',
		200: '#f1f2f2'
	},
	onNeutral: {
		900: '#ffffff',
		600: '#ffffff',
		300: '#231f20',
		200: '#231f20',
	},
	primary: {
		500: '#ffd200',
		//missig shades
	},
	secondary: {
		600: '#25cccf',
		500: '#2a7fb0',
		400: '#2e3190'
	},
	onSecondary: '#ffffff',
	onPrimary: '#231f20',
	error: '#f04c4c',
	success: '#389363',
	white: '#ffffff',
	black: '#000000',
    bodyBackgroundColour: '#edf6ff',
	brand: {
		twitter: '#28AAE1',
		facebook: '#3B5999',
		linkedin: '#0077B5',
		google: '#3cba54',
		microsoft: '#0078d4',
	},
	fontFamily: "'Montserrat', sans-serif",
	fontSizes: {
		display: '41.25pt', //modal titles
		header: '26.25pt', //page titles
		title1: '18.75pt', //tabs, titles, forms
		title2: '13.5pt', //buttons, tabs, titles, forms
		headline: '16.5pt', //info paragraphs
		body: '10.5pt', //station descriptions
		caption: '8pt', //timestamps, footers
	},
    fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
    },
	lineSpacing: '140%', //to 180 max
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    gradients: {
        ltr: 'linear-gradient(to left, #d4298c, #25cccf)',
        rtl: '',
        ttb: '',
        btt: '',
    },
    breakPoints: {
        xl: 1536,
        lg: 1280,
        md: 900,
        sm: 600,
        xs: 0
    },
    gridColumns: 12,
	gutter: 8,
}
export default defaultStyles
