import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSingleCampaign, updateCampaign } from '../../actions/campaigns';
import Modal from '../../components/general/Modal';
import EditCampaign from '../../components/forms/EditCampaign';
import moment from 'moment-timezone';
import produce from 'immer';

class EditCampaignContainer extends Component {
	constructor(){
		super()
		this.state = {
			campaignID: '',
			campaignName: '',
			campaignDescription: '',
			campaignColour: '',
			selectedType: '',
			settingsLinks: [],
			showIdentifiedData: false,
			isSaving: false,
			startDate: '', 
			endDate: '',
			endDateDisabled: true,
			datesVisible: false,
			error: null,
			adChannels: [],
		}
		this.handleCloseModal = this.handleCloseModal.bind(this)
		this.handleCancel = this.handleCancel.bind(this)
		this.handleUpdateCampaign = this.handleUpdateCampaign.bind(this)
		this.handleChangeCampaignName = this.handleChangeCampaignName.bind(this)
		this.handleChangeCampaignDescription = this.handleChangeCampaignDescription.bind(this)
		this.handleGetSelectedColour = this.handleGetSelectedColour.bind(this)
		this.handleChangeStartDate = this.handleChangeStartDate.bind(this)
		this.handleChangeEndDate = this.handleChangeEndDate.bind(this)
		this.handleToggleAdChannel = this.handleToggleAdChannel.bind(this)
	}

	// on mounting assign the props to state for use in this component
	componentDidMount(){
		this.setState({
			campaignId: this.props.campaign.campaign_id,
			campaignName: this.props.campaign.title,
			campaignDescription: this.props.campaign.description,
			campaignColour: this.props.campaign.colour,
			startDate: this.props.campaign.startDate && moment.unix(this.props.campaign.startDate).format("YYYY-MM-DD"),
			endDate: this.props.campaign.endDate && moment.unix(this.props.campaign.endDate).format("YYYY-MM-DD"),
			endDateDisabled: !this.props.campaign.endDate,
			datesVisible: this.props.campaign.startDate ? true : false,
			adChannels: this.props.campaign.__adchannels?.map(({adchannel_id}) => adchannel_id) ?? [],
		})
	}


	handleToggleEndDate(){
		this.setState({
			endDateDisabled: !this.state.endDateDisabled,
			endDate: 0
		})
	}

	// Method to handle whether the create campaign modal is open or not
    handleCloseModal(){
    	this.props.handleOpen()
	}
	
	handleCancel(){
		this.props.cancel()
	}

    handleUpdateCampaign(e){
			this.setState({error: null})
			if(this.state.endDate < this.state.startDate){
				alert("Campaign end date cannot be set before started date")
			} else if(!this.state.startDate && this.state.endDate){
				alert("Please enter a start date for your campaign")
			} else {
				e.preventDefault();
				
				let valid_flag = true;
				let filteredSettingsLinks = this.state.settingsLinks != null ? this.state.settingsLinks.filter(temp => temp != "" && temp != '/') : []
				
				if (filteredSettingsLinks.length === 0 
					&& this.state.selectedType != 'identified_visitor'
					&& this.state.selectedType != 'shopify_thank_you'
				) {
					alert('You have selected a lead definition that requires at least one URL')
					valid_flag = false
				} else {
					filteredSettingsLinks = filteredSettingsLinks.map(link => {
						if (this.state.selectedType == 'external_link') {
							if (!(link.startsWith('http://') || link.startsWith('https://'))) {
								alert(link + ' is not a valid URL, an external URL must be absolute and start with http:// or https://')
								valid_flag = false
							} 
						} 
						else if (this.state.selectedType == 'url_match' || this.state.selectedType == 'preg_match') {
							let tmp = link
							if (!(link.startsWith('/'))) {
								tmp = "/" + link
							}

							if (link.endsWith('/')) {
								tmp = tmp.substring(0, tmp.length - 1);
							}

							if (link.startsWith('http://') || link.startsWith('https://')) {
								alert(link + ' is not a valid URL, an internal URL must be relative and not start with http:// or https://')
								valid_flag = false
							} 

							return tmp
						}
						
						return link
					})
				}

				if (valid_flag) {
					
					this.setState({isSaving: true}, () => {
						this.props.updateCampaign({...this.state, settingsLinks: filteredSettingsLinks})
						.then( (e) => {
							if (e.responseType == "error") {
								this.setState({error: e.additional, isSaving: false})
							} else {
								this.props.fetchSingleCampaign(this.state.campaignId)
								this.props.handleOpen()
								this.forceUpdate()
							}
						}) 
					})
					
				}
			}
    }

    handleChangeCampaignName(e){
    	this.setState({ campaignName: e.target.value, alertOnClose: true })
    }

    handleChangeCampaignDescription(e){
    	this.setState({ campaignDescription: e.target.value, alertOnClose: true })
	}
	
	handleGetSelectedColour(colour){
		this.setState({ campaignColour: colour, alertOnClose: true })
	}

	handleChangeStartDate(e){
		this.setState({ startDate: e.target.value })
	}

	handleChangeEndDate(e){
		this.setState({ endDate: e.target.value })
	}

	handleChangeDatesVisible(){
		this.setState({
			datesVisible: !this.state.datesVisible
		}, () => {
			if(!this.state.datesVisible){
				this.setState({
					startDate: '',
					endDate: ''
				})
			}
		})
	}

	handleToggleAdChannel(adChannelId) {
		return (event) => {
			const checked = event.target.checked;
			this.setState(produce(prevState => {
				const index = prevState.adChannels.findIndex(v => v === adChannelId);
				if(index >= 0 && checked === false) {
					prevState.adChannels.splice(index, 1);
				} else if(index === -1 && checked === true) {
					prevState.adChannels.push(adChannelId);
				}
			}))
		}
	}

	render() {
		
		return (
			<Modal 
			title="Edit Campaign"
			closeLabel="Cancel"
			saveLabel="Save"
			justdisableitlol={this.state.isSaving}
			closeAction={this.handleCancel}
			saveAction={this.handleUpdateCampaign}
			alertOnClose={this.state.alertOnClose}
			>	
			{
				this.state.isFetching ?
				<span>Loading your campaign...</span>
				:
				<EditCampaign
				defaultType={() => {
					if (this.props.campaign.__meta.lead_definition === 'identified_visitor') {
						return 'identified_visitor'
					} else {
						return JSON.parse(this.props.campaign.__meta.lead_definition)[0].type
					}
				}}
				defaultSettings={() => {
					if (this.props.campaign.__meta.lead_definition === 'identified_visitor') {
						return []
					} else {
						let settings = JSON.parse(this.props.campaign.__meta.lead_definition)[0].settings
						
						if (settings == "") {
							return []
						} else {
							settings = settings.filter(link => link != null).map(link => {
								if (link.startsWith('/')) {
									return link.substring(1,link.length)
								}
								return link
							})
						}

						return settings
					}
				}}
				defaultSID={() => {
					if (this.props.campaign.__meta.show_identified_data == '1') {
						return 'true'
					} else if (this.props.campaign.__meta.show_identified_data == '0') {
						return 'false'
					} else {
						return this.props.campaign.__meta.show_identified_data
					}
				}}
				selectedType={(selectedType) => this.setState({ selectedType }) }
				settingsLinks={(settingsLinks) => this.setState({ settingsLinks }) }
				indetifiedData={(showIdentifiedData) => this.setState({ showIdentifiedData })}
				defaultColour={this.props.campaign.colour}
				getSelectedColour={this.handleGetSelectedColour}
				campaignName={this.state.campaignName}
				campaignDescription={this.state.campaignDescription}
				handleChangeCampaignName={this.handleChangeCampaignName}
				handleChangeCampaignDescription={this.handleChangeCampaignDescription}
				handleUpdateCampaign={this.handleUpdateCampaign}
				startDate={this.state.startDate}
				endDate={this.state.endDate}
				handleChangeStartDate={this.handleChangeStartDate}
				handleChangeEndDate={this.handleChangeEndDate}
				handleToggleEndDate={this.handleToggleEndDate.bind(this)}
				endDateDisabled={this.state.endDateDisabled}
				datesVisible={this.state.datesVisible}
				handleChangeDatesVisible={this.handleChangeDatesVisible.bind(this)}
				error={this.state.error}
				adChannels={this.state.adChannels}
				handleToggleAdChannel={this.handleToggleAdChannel}
				/>
			}
			</Modal>
		)
	}
}

EditCampaignContainer.propTypes = {
	handleOpen: PropTypes.func,
	cancel: PropTypes.func,
	campaign: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		// campaign: state.campaigns.singleCampaign
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchSingleCampaign: (campaignId) => dispatch(fetchSingleCampaign(campaignId)),
		updateCampaign: (payload) => dispatch(updateCampaign(payload))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaignContainer);
