import React from 'react';
import DropdownList from '../general/DropdownList';

// Receive props from ActiveCampaignsDropdownContainer and render them here
const ActiveCampaignsDropdown = props => {
	// Prepare the props for passing into the presentational component (Dropdown)
	const items = props.campaigns.map(campaign => {
		return { id: campaign.campaign_id, title: campaign.title, colour: campaign.colour }
	})

	// Pass this into the menu object
	const menu = {
		title: props.selectedCampaignName,
		items: items
	}

	// If there is an add campaign method, use it
	let addText = props.addCampaign ? "Add a campaign" : ""

	return (
		<DropdownList 
		clearSelectedItem={props.clearSelectedCampaign}
		selectItem={props.handleSelectedItem} 
		isClearable={props.isClearable} 
		selectedItem={props.selectedCampaignId} 
		menu={menu} 
		autocomplete
		theme={props.theme || "dark"}
		addText={addText}
		addMethod={props.addCampaign}
		menuSide={props.menuSide}
		isDisabled={props.isDisabled}
		sort
		/>
	)
}

ActiveCampaignsDropdown.defaultProps = {
	selectedCampaignName: "Filter by Campaign"
}

export default ActiveCampaignsDropdown;