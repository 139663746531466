// Import action types
import { 
	FETCH_DEFAULT_CHANNELS_REQUEST,
	FETCH_DEFAULT_CHANNELS_SUCCESS,
	FETCH_CAMPAIGN_CHANNELS_REQUEST,
	FETCH_CAMPAIGN_CHANNELS_SUCCESS,
	SET_SELECTED_CHANNEL,
	UNSET_SELECTED_CHANNEL,
	CLEAR_CAMPAIGN_CHANNELS,
	FETCH_SINGLE_CHANNEL_REQUEST,
	FETCH_SINGLE_CHANNEL_SUCCESS
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_DEFAULT_CHANNELS_REQUEST:
			return {
				...state,
				isFetchingDefaultChannels: true
			}
		case FETCH_DEFAULT_CHANNELS_SUCCESS:
			return {
				...state,
				defaultChannels: action.defaultChannels,
				isFetchingDefaultChannels: false
			}
		case FETCH_CAMPAIGN_CHANNELS_REQUEST:
			return {
				...state,
				isFetchingCampaignChannels: true
			}
		case FETCH_CAMPAIGN_CHANNELS_SUCCESS:
			return {
				...state,
				campaignChannels: action.campaignChannels,
				isFetchingCampaignChannels: false
			}
		case CLEAR_CAMPAIGN_CHANNELS:
			return {
				...state,
				campaignChannels: []
			}
		case SET_SELECTED_CHANNEL:
			return {
				...state,
				selectedChannelId: action.payload.channel_id,
				selectedChannelName: action.payload.title
			}
		case UNSET_SELECTED_CHANNEL:
			return {
				...state,
				selectedChannelId: null,
				selectedChannelName: undefined
			}
		case FETCH_SINGLE_CHANNEL_REQUEST:
			return {
				...state,
				isFetchingSingleChannel: true
			}
		case FETCH_SINGLE_CHANNEL_SUCCESS:
			return {
				...state,
				isFetchingSingleChannel: false,
				singleChannel: action.responseObj.response
			}
		default:
			return state;
	}
}