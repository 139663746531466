import { 
	FETCH_VISITS,
	FETCH_SINGLE_VISIT_REQUEST,
	FETCH_SINGLE_VISIT_SUCCESS,
	FETCH_VISITS_BY_LINK,
	FETCH_VISITS_BY_CAMPAIGN,
	FETCH_VISITS_BY_CHANNEL
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_VISITS:
			return {
				...state,
                allVisits: action.responseObj.response,
				paging: action.responseObj.paging,
			}
		case FETCH_SINGLE_VISIT_REQUEST: 
			return {
				...state,
				isFetchingSingleVisit: true
			}
		case FETCH_SINGLE_VISIT_SUCCESS: 
			return {
				...state,
				isFetchingSingleVisit: false,
				singleVisit: action.responseObj.response
			}
		case FETCH_VISITS_BY_LINK:
			return {
				...state,
				linkVisits: action.responseObj.response
			}
		case FETCH_VISITS_BY_CAMPAIGN:
			return {
				...state,
				campaignVisits: action.responseObj.response
			}
		case FETCH_VISITS_BY_CHANNEL: 
			return {
				...state,
				channelVisits: action.responseObj.response
			}
		default:
			return state;
	}
}