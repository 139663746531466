import React, { Component } from 'react'
import { FontAwesomeIcons } from '../../constants';

class FontAwesomePicker extends Component {
    constructor(){
        super()
        this.state = {
            showIcons: false,
            selectedIcon: ""
        }
        this.handleSelectIcon = this.handleSelectIcon.bind(this)
        this.handleToggleIcons = this.handleToggleIcons.bind(this)
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.selectedIcon){
            this.setState({
                selectedIcon:nextProps.selectedIcon
            })
        }

    }

    handleSelectIcon(icon){
        this.setState({
            showIcons: !this.state.showIcons,
            selectedIcon: icon
        })
        this.props.getSelectedIcon(icon)
    }

    handleToggleIcons(){
        this.setState({
            showIcons: !this.state.showIcons
        })
    }

    render() {
        const icons = FontAwesomeIcons.map(icon => {
            let isAdded = false
            if (this.props.disabled) {
               isAdded = this.props.disabled.includes(icon)
            }
            return (
                <i 
                className={"font-awesome-picker__icon " + icon }
                style={{opacity: isAdded ? 0.2: 1}} 
                disabled={true}
                key={icon}
                onClick={() => {if (!isAdded) {this.handleSelectIcon(icon)}}}></i>
            )
        })

        return (
            <div className="font-awesome-picker">
                <button className="font-awesome-picker__button" onClick={this.handleToggleIcons}>
                    {this.props.controlled ?
                        <>{ this.props.selectedIcon ?  <i className={this.props.selectedIcon}></i> : <span style={{textDecoration:"underline"}}>Select Icon</span> }</>
                        :
                        <>{ this.state.selectedIcon ? <i className={this.state.selectedIcon}></i> : <span style={{textDecoration:"underline"}}>Select Icon</span> }</>
                    } 
                    
                </button>
                {
                    this.state.showIcons &&
                    <div className="font-awesome-picker__list">
                        {icons}
                    </div>
                }
            </div>
        )
    }
}

export default FontAwesomePicker