import React from 'react';
import ElementLogo from '../general/ElementLogo';

const EditCustomer = props => {
	const { 
		company,
		first_name,
		last_name,
		website,
		addr_1,
		addr_2,
		addr_city,
		addr_country,
		addr_postcode,
		phone,
		__user_role,
	} = props.customer
	
	if(__user_role.role_id === 1 || __user_role.role_id === 2){
		return (
			<ul>
				<fieldset className="margin-large">
					<legend>Your Company</legend>
					<div className="form__two-column">
						<div style={{position:"relative"}}>
							<label className="form__label">Company Logo</label>
							<div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
								<ElementLogo isAdmin={ __user_role.role_id === 1 || __user_role.role_id === 2 } for="customer" />
							</div>
						</div>
						<div>
							<li className="form__item">
								<label className="form__label">Company Website</label>
								<input type="text" className="form__input" value={website} onChange={props.handleChangeWebsite} required />
							</li>

							<li className="form__item">
								<label className="form__label">Company Name</label>
								<input type="text" className="form__input" value={company} onChange={props.handleChangeCompanyName} required />
							</li>
						</div>
					</div>

					<div className="form__two-column">
						<li className="form__item">
							<label className="form__label">Address 1</label>
							<input type="text" className="form__input" value={addr_1} onChange={props.handleChangeAddress1} required />
						</li>

						<li className="form__item">
							<label className="form__label">Address 2<span className="form__optional">Optional</span></label>
							<input type="text" className="form__input" value={addr_2} onChange={props.handleChangeAddress2} />
						</li>
					</div>

					<div className="form__two-column">
						<li className="form__item">
							<label className="form__label">Country<span className="form__optional">Optional</span></label>
							<input type="text" className="form__input" value={addr_country} onChange={props.handleChangeCountry} />
						</li>

						<li className="form__item">
							<label className="form__label">Town/City</label>
							<input type="text" className="form__input" value={addr_city} onChange={props.handleChangeCity} required />
						</li>
					</div>

					<div className="form__two-column">
						<li>
							<label className="form__label">Postcode</label>
							<input type="text" className="form__input" value={addr_postcode} onChange={props.handleChangePostCode} required />
						</li>

						<li>
							<label className="form__label">Telephone<span className="form__optional">Optional</span></label>
							<input type="text" className="form__input" value={phone} onChange={props.handleChangeTelephone} />
						</li>
					</div>
				</fieldset>

				<fieldset className="margin-large">
					<legend>Your Details</legend>
					<div className="form__two-column">
						<li>
							<label className="form__label">First Name</label>
							<input type="text" className="form__input" value={first_name} onChange={props.handleChangeFirstName} required />
						</li>

						<li>
							<label className="form__label">Surname</label>
							<input type="text" className="form__input" value={last_name} onChange={props.handleChangeLastName} required />
						</li>
					</div>
				</fieldset>

				<div className="margin-large">
					<button className="button button--green" onClick={props.handleUpdateCustomer}>Update Details</button>
				</div>
			</ul>
		)
	} else {
		return (
			<div>
				<div className="message message--notice margin-medium">
					<p>You do not have administration rights over this company so the details displayed here are limited, and cannot be updated.</p>
				</div>
				<ul>
					<div className="form__two-column">
						<li className="form__item">
							<label className="form__label">First Name</label>
							<input type="text" className="form__input" value={first_name} disabled />
						</li>

						<li className="form__item">
							<label className="form__label">Surname</label>
							<input type="text" className="form__input" value={last_name} disabled />
						</li>
					</div>
					<div className="form__two-column">
						<li className="form__item">
							<label className="form__label">Company Website</label>
							<input type="text" className="form__input" value={website} disabled />
						</li>

						<li className="form__item">
							<label className="form__label">Company Name</label>
							<input type="text" className="form__input" value={company} disabled />
						</li>
					</div>
				</ul>
			</div>
		)
	}
}

export default EditCustomer