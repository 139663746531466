import React, { Component, useState, useEffect } from 'react'
import Welcome from './Welcome'
import AddFirstWebsite from './AddFirstWebsite'
import AddCode from './AddCode'
import ConnectAds from './ConnectAds'

class FirstLogin extends Component {
	constructor(props){
		super(props);
		let slide = 0
		if (window.location.href.includes("/connections")) {
			slide = 2
			props.history.replace("/")
		}
		this.state = {
			slide,
			isLeaving: false
		}

		this.slides = [
			Welcome,
			AddFirstWebsite,
			ConnectAds,
			AddCode
		]

		this.handleChangeSlide = this.handleChangeSlide.bind(this);
	}

	compelte = () =>{

	}

	handleChangeSlide(slide){
		this.setState({
			isLeaving: true
		})
		setTimeout(() => {
			this.setState({
				slide: slide != null ? slide :  this.state.slide+1,
				isLeaving: false
			})
		}, 200)
	}
	
	render(){
		let Slide = this.slides[this.state.slide];
		return (
			<div className={"first-login " + (this.state.isLeaving ? "leave" : "")}>
				<Slide changeSlide={this.handleChangeSlide} />
			</div>
		)
	}
}
export default FirstLogin
