import React, {useEffect, useMemo, useState} from 'react'
import BeaconRequest from '../../BeaconRequest'

import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';

import LoaderAlt from '../../components/general/LoaderAlt';

import {withWidgetContainer} from '../withWidgetContainer'
import FormatNumber from '../../components/general/FormatNumber'
import * as colours from '../../colours'
import DropdownList from '../../components/general/DropdownList';

import {setPerformanceListResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import Modal from '../../components/general/Modal'
import moment from 'moment-timezone'
import { redirectRouter } from '../../organisms/WidgetContainer/DropdownContainer';
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'
import Loader from '../../components/general/Loader';

let scrollInterval;
const VirutualisedTable = (props) => {
  const {tableHeight, rowHeight, length, overScan = 10, columns, rows} = props 
  const [top, setTop] = useState(0)
  const [index, setIndex] = useState(0)
  const [end, setEnd] = useState(Math.ceil(tableHeight*2)/rowHeight)

  const onScroll = ({target}) => {
    const _scrollTop = target.scrollTop
    const _index = Math.floor(_scrollTop/rowHeight)
    setIndex(_index)
    setEnd(_index + Math.ceil((tableHeight*2)/rowHeight))
    setTop((_scrollTop/rowHeight)*rowHeight)
  }

  // https://jsfiddle.net/1wtnfcgq/6/
  const generateRows = () => {
    const items = []

    do {
      if (index >= rows.length) {
        index = rows.length
        break
      }

      const rowAttrs = {
        style: {
          position: "absolute",
          top: (index * rowHeight),
          left: 0,
          height: rowHeight,
          lineHeight: `${rowHeight}px`
        },
      }

      items.push(
        <div {...rowAttrs} key={index}>
          {columns.map((column, i) =>
            <td key={i}>
              {rows[index][column]}
            </td>
          )}
        </div>
      )

      index++
    } while (index < end)

    return items
  }

  return <div>


  </div>
}

const PerformanceList = (props) => {
    const resource = useSelector(({performanceList}) => performanceList.data)
    const isFetching = useSelector(({performanceList}) => performanceList.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
          dispatch(setPerformanceListResource({filter: props.filter, timePeriod: props.datetime}))
        }
    }, [props.filter, props.datetime, dispatch, prevDatetime, prevFilter])

    if (resource == null) {
      return <div >
          <Skeleton variant="rect" width='100%' height={300} style={{borderRadius: '5px',marginBottom: '10px'}} /> 
      </div>
  }

  return (
      <Card 
          style={{
              background:'transparent', 
              border: 'none', 
              position: 'relative',
              boxShadow: 'none'
          }}
      >
          {isFetching && <Loader />}
          <div style={{ 
              height: '100%', width: '100%', 
              filter: isFetching && 'blur(1px)' 
          }}>
              <PerformanceListChild 
                {...props} 
                resource={resource}
                createResource={(limit, start) => dispatch(setPerformanceListResource({filter: props.filter, timePeriod: props.datetime, paging: {start, limit} }))}
              />
          </div>
      </Card>
  )
}

//remove
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: 15
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableCell: {
      padding: '12px'
    },
    visuallyHidden: {
    },
  }));
  

const PerformanceListChild = (props) => {
    const classes = useStyles();
    const [campaigns_res, setCampaigns_res] = useState([])
    const [linksPaging, setLinksPaging] = useState(null)
    const [type, setType] = useState("campaigns")
    const [view, setView] = useState("table")
    const [option, setOption] = useState({id: "active", title: 'Active ' + type})
    const [linkOption, setLinkOption] = useState({id: "active", title: 'Visited'})
    const [links, setLinks] = useState([])
    const [loadingLinks, setLoadingLinks] = useState(true)

    useEffect(() => {
      setCampaigns_res([])
      if (props.filter.type === "website") {
        setType("campaigns")
      } else if (props.filter.type === "campaign") {
        setType("channels")
      } else if (props.filter.type === "channel") {
        setType("links")
      } 
    }, [props.resource, props.filter])

    //sort out this mess too!
    useEffect(() => {
      const tmp_res = props.resource.graph
      const tmp = tmp_res.response

      if (props.filter.type === "website") {
        if (tmp == null) setCampaigns_res([])
        else {
          setCampaigns_res(tmp)
          
          
        }
      } else if (props.filter.type === "campaign") {
        if (tmp == null) setCampaigns_res([])
        else {
          setCampaigns_res(tmp)

          
        }
      } else if (props.filter.type === "channel") {
        if (tmp == null) setCampaigns_res([])
        else {
          setCampaigns_res(tmp)
         
         
        }
      } 
      
    }, [type, props.resource, props.filter])


    const [page, setPage] = useState(0)

    const rowsPerPage = 10
    const [limit, setLimit] = useState(rowsPerPage)
    const [start, setStart] = useState(page*limit)

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('visits');

    const [exportModal, setExportModal] = useState(false)
    const [collation, setCollation] = useState({id: "summary", title: 'Summary'})
    const [exportFormat, setExportFormat] = useState({id: "csv", title: 'CSV'})
    const [exportName, setExportName] = useState("")

    const [loadingDaily, setLoadingDaily] = useState(false)
    const [daily, setDaily] = useState([])

    useEffect(() => {
      setPage(0)
      setLimit(rowsPerPage)
      setStart(0)
    }, [props.filter, props.datetime]) 

    useEffect(() => {
      if (option.id != "active") {
        setLoadingLinks(true)
        BeaconRequest.get(
          `${props.filter.type}/${props.filter.payload.id}/pd/${option.id}/${props.datetime}`
          )
        .then(e => {
          setLinks(e.response)
        })
        .catch(e => {
          setLinks([])
        })
        .finally(e => setLoadingLinks(false))
      }
    }, [option, linkOption, props.filter, props.datetime, start, limit])

    const collationType = useMemo(() => {
      if (option.id == "links") {
        return 'link'
      } else if (option.id == "channels") {
        return 'channel'
      } else {
        return type.substring(0, type.length - 1)
      }
    }, [option, type]);

    useEffect(() => {
      setLoadingDaily(true)
      let query = `${props.filter.type}/${props.filter.payload.id}/export/performance/${collationType}/${props.datetime}`
      BeaconRequest.get(query)
       .then(e => {
         setDaily(e.response)
       })
       .finally(e => setLoadingDaily(false))
    }, [option, linkOption, props.filter, props.datetime, collationType])
  

    const getTable = (res) => res.map(item => {
        const id = () => {
            if (props.filter.type === "website") return "campaign_id"
            else if (props.filter.type === "campaign") return "channel_id"
            else return "link_id"
        }
        const name = () => {
          if (props.filter.type === "website") return "campaign_name"
          else if (props.filter.type === "campaign") return "channel_name"
          else return "link_name"
        }
        const idForTable = () => {
          if (option.id == "links") {
            return item["link_id"] 
          } else if (option.id == "channels") {
            return item["channel_id"] 
          } else {
            return item[`${id()}`]
          }
        }
        const getColour = () => {
          if (option.id == "links" || type == "links") {
            return null
          } else if (option.id == "channels"|| type == "channels") {
            return item.channel_colour
          } else {
            return item.campaign_colour
          }
        }
        const getIcon = () => {
          if (option.id == "links"|| type == "links") {
            return null
          } else if (option.id == "channels"|| type == "channels") {
            return item.channel_icon
          } else {
            return null
          }
        }
        const getName = () => {
          if (option.id == "links"|| type == "links") {
            return item.notes || item.link_notes || "No description"
          } else if (option.id == "channels"|| type == "channels") {
            return item.channel_name || item.name
          } else {
            return item[name()] || item.name
          }
        }
        return {
            id: idForTable(),
            name: getName(),
            icon: getIcon(),
            ad_channels: item.ad_channels,

            impressions: item.impressions,
            visits: item.visits,
            clicks: item.clicks,
            bot: item.bots,
            bot_percent: item.bot_percentage,
            leads: item.leads,
            clicks_percent: item.clicks/item.impressions,
            wasted_spend: item.wasted_spend,
            colour: getColour(),
            spend: item.spend,

            cpc: item.clicks == 0 ? 0 : parseFloat((item.spend/item.clicks).toFixed(2)),
            cpv: item.visits == 0 ? 0 : parseFloat((item.spend/item.visits).toFixed(2)),
            cpl: item.leads == 0 || item.leads == null ? 0 : parseFloat((item.spend/item.leads).toFixed(2)),
            ...(collation.id == "daily" ? {start_date: item.start_date, end_date: item.end_date} : {})
        }
    })

    const campaigns = getTable(campaigns_res)

    const mappedLinks = getTable(links)

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
      
      function getComparator(order, orderBy) {
        return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
      }
      
      function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
      }

      function capitalize(text) {
          return text.charAt(0).toUpperCase() + text.slice(1);
      }
      
      const headCells = [
        // capitalize(type.substring(0, type.length - 1))
        { id: 'name', numeric: false, disablePadding: true, label: "Title"},
        { id: 'impressions', numeric: true, disablePadding: true, label: 'Impressions' },
        { id: 'clicks', numeric: true, disablePadding: true, label: 'Clicks' },
        { id: 'visits', numeric: true, disablePadding: true, label: 'Visitors' },
        { id: 'bot', numeric: true, disablePadding: true, label: 'Bots' },
        { id: 'bot_percent', numeric: true, disablePadding: true, label: 'Bot %' },
        { id: 'cpc', numeric: true, disablePadding: true, label: 'CPC'},
        { id: 'cpv', numeric: true, disablePadding: true, label: 'CPV'},
        { id: 'cpl', numeric: true, disablePadding: true, label: props.meta.cost_per_lead_text || 'CPA'},
        { id: 'wasted_spend', numeric: true, disablePadding: true, label: 'Wasted spend' },
        { id: 'spend', numeric: true, disablePadding: true, label: 'Spend'},
      ];


      function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
          onRequestSort(event, property);
        };
      
        return (
          <div  className="table__row"  style={{position: 'sticky', top: '142px'}}>
              {headCells.map((headCell, index) => (
                <div
                  className="table__heading is-fixed"
                  sortDirection={orderBy === headCell.id ? order : false}
                  style={{textAlign: headCell.numeric && headCell.label != "%"  ? 'right' : 'left', }}
                >
                  {headCell.sort == null ? <>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                        style={{color:'rgb(76 76 76)'}}
                        hideSortIcon={true}
                      >
                        
                        {headCell.label}
                      </TableSortLabel>
                    </>
                    :
                    <>{headCell.label}</>
                  }
                </div>
              ))}
           </div>
        );
      }

    const NoFilterHead = (props) => {
        return <div className="table__row">
            {headCells.map((headCell, index) => (
              <div
                className="table__heading"
              >
                  <>{headCell.label}</>
              </div>
            ))}
          </div>
          
    }

    const tableRow = (row, i, all, isFixed) => {
      return <div 
            hover
            className="table__row table__row--link"
         
            onClick={event => {
              
              !isFixed && props.redirect(collationType, row.id)
            }}
        >
          <div 
            className={`table__cell  ${isFixed && "is-fixed-bottom"}`}
            style={{minWidth: '200px'}}
          >
              <div style={{display: 'grid', gridTemplateColumns: 'auto max-content'}}>
                  <div
                  style={{
                    whiteSpace: 'nowrap', 
                    textOverflow: 'ellipsis', 
                    overflow: 'hidden',
                    maxWidth: '350px',
                  }} 
                >
                    {(row.colour || row.icon) && 
                      <div className="channels__icon channels__icon--inline">
                          <span className="key-dot key-dot--over-icon" style={{backgroundColor:row.colour}}></span>
                          { row.icon ? <i className={row.icon}></i> : <i>{row.name.charAt(0)}</i> }
                      </div>
                    }
                    <span title={row.name}>{row.name}</span>
                </div>
                <div style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                  {row.ad_channels && row.ad_channels.map(channel => {
                    return <>
                        {channel == "Google Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-google" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {channel == "Facebook Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-facebook-f" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {channel == "LinkedIn Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-linkedin-in" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {channel == "Microsoft Ads" &&
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fab fa-microsoft" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                        {(channel != "Google Ads" && channel != "Facebook Ads" && channel != "LinkedIn Ads" && channel != "Microsoft Ads") && 
                          <span style={{width: '25px', display: 'flex', alignContent: 'center', alignItems: 'center', height: '100%'}}>
                            <i class="fas fa-question" style={{marginRight: '5px', margin: 'auto'}}></i>
                          </span>
                        }
                    </>
                  })}
              </div>
            </div>
          </div>
          
          <div className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
              <FormatNumber dashZero val={row.impressions}/>
          </div> 

          <div className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
            <FormatNumber dashZero val={row.clicks} />
            
          </div> 

          <div className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
            <FormatNumber dashZero val={row.visits} />
          </div> 
          
          <div className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
            <FormatNumber dashZero val={row.bot} />
          </div> 
          <div className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
              <span className="table__cell--with-tag">
                <span className="tag" style={{backgroundColor: getBotColour(row.bot_percent/100, 'bots'), color: 'white'}}>
                   <FormatNumber val={row.bot_percent/100} percent/>
                </span>
            </span>
          </div> 

          <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
                <FormatNumber dashZero money val={row.cpc} />
          </div>

          <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
                <FormatNumber dashZero money val={row.cpv} />
          </div>

          <div  className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
                <FormatNumber dashZero money val={row.cpl} />
          </div>

          <div className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
                <FormatNumber dashZero money val={row.wasted_spend} />
          </div>

          <div className={`table__cell table__cell--right  ${isFixed && "is-fixed-bottom"}`}>
                <FormatNumber dashZero money val={row.spend} />
          </div>
        </div>
    }

    const domain = {
      visitor: [0, 0.75, 0.9],
      clicks: [0, 0.45, 0.6],
      bots: [0, .1, .25]
    }

    const getBotColour = (percent, key) => {
      if (percent >= domain[key][2]) {
        return colours.red;
      } else if (percent >= domain[key][1] && percent < domain[key][2]) {
        return colours.yellow;
      } else if (percent  >= domain[key][0] && percent < domain[key][1]) {
        return colours.green;
      } else {
        return colours.green;
      }
  }
    
    const getTagColour = (percent, key) => {
        if (percent >= domain[key][2]) {
          return colours.green;
        } else if (percent >= domain[key][1] && percent < domain[key][2]) {
          return colours.yellow;
        } else if (percent >= domain[key][0] && percent < domain[key][1]) {
          return colours.red;
        } else {
          return colours.red;
        }
    }


    let result =   campaigns.reduce((acc, row) => {
      return {
        'name': 'Total',
        'traffic': '-',
        'impressions': acc.impressions+row.impressions,
        'clicks': acc.clicks+row.clicks,
        'visits': acc.visits+row.visits,
        'bot': acc.bot+row.bot,
        'bot_percent': 0,
        'leads': acc.leads+(row.leads || 0),
        'spend': acc.spend+row.spend,
        'cpv': 0,
        'cpl': 0,
        'wasted_spend': acc.wasted_spend+row.wasted_spend,
        'cpc': 0,

      }
    }, {
      'name': 'Total',
      'traffic': '-',
      'impressions': 0,
      'clicks': 0,
      'visits': 0,
      'bot': 0,
      'bot_percent': 0,
      'leads': 0,
      'spend': 0,
      'cpv': 0,
      'cpl': 0,
      'wasted_spend': 0,
      'cpc': 0
    })

    result.visits_percent = result.clicks == 0 ? 0 : ((result.visits/result.clicks)*100).toFixed(2)
    result.bot_percent = result.clicks == 0 ? 0 : ((result.bot/result.clicks)*100).toFixed(2) 
    result.cpv = result.visits == 0 ? 0 : (result.spend /result.visits).toFixed(2)
    result.cpc = result.clicks == 0 ? 0 : (result.spend /result.clicks).toFixed(2)
    result.cpl = result.leads == 0 ? 0 : (result.spend /result.leads).toFixed(2)

    const downloadToFile = (json, timezone, total, exportType, title) => {
        const process = () => {
            switch (exportType) {
                case "json":
                    return JSON.stringify(json, undefined, 2);
                case "csv":
                        const totalToRow = (obj) => {
                            return [
                                "Total",
                                null,
                                obj.impressions,
                                obj.clicks,
                                obj.visits,
                                obj.bot,
                                obj.bot_percent,
                                obj.cpc,
                                obj.cpv,
                                obj.cpl,
                                obj.wasted_spend,
                                obj.spend,
                            ]
                        }
                        const childType = type.substring(0, type.length - 1)


                        const objToArray = (obj) => {
                            if (collation.id == "daily") {
                                return [
                                  
                                  '\"' + obj.name  + '\"',
                                  obj.id,
                                  obj.start_date,
                                  obj.end_date,
                                  obj.impressions,
                                  obj.clicks,
                                  obj.visits,
                                  obj.bot,
                                  obj.bot_percent,
                                  obj.cpc,
                                  obj.cpv,
                                  obj.cpl,
                                  obj.wasted_spend,
                                  obj.spend,
                              ]
                            } else {
                              return [
                                  '\"' + obj.name  + '\"',
                                  obj.id,
                                  obj.impressions,
                                  obj.clicks,
                                  obj.visits,
                                  obj.bot,
                                  obj.bot_percent,
                                  obj.cpc,
                                  obj.cpv,
                                  obj.cpl,
                                  obj.wasted_spend,
                                  obj.spend,
                              ]
                            }
                            
                        }
                        const wrapValue = (value) => `${value ?? ""}`;
                        const capType =  capitalize(collationType)
                        return [
                          // link name, link desc, id, start date, end date,Impressions,Clicks,Visitors,"Visitor percentage",Bots,"Bot percentage", spend, cpc, cpv, cpm, cpl
                          collation.id == "daily" ?
                            `"${capType} Name","${capType} ID", "Start date", "End date",Impressions,Clicks,Visitors,Bots,"Bot percentage",CPC,CPV,${props.meta.cost_per_lead_text || 'CPA'},"Wasted spend",Spend`
                            : `"${capType} Name","${capType} ID",Impressions,Clicks,Visitors,Bots,"Bot percentage",CPC,CPV,${props.meta.cost_per_lead_text || 'CPA'},"Wasted spend",Spend`
                            ,
                            ...Object.entries(json).map(([key, value]) => {
                                return [objToArray(value)]
                            })
                            .concat(total != null ? [totalToRow(total)] : [])
                            .map(row => row.map(wrapValue).join(","))
                        ].join("\n")
                default:
                    return null;
            }
        }
        
        const result = process()
        const name = title
        const blob = new Blob([result], {type: exportType === "json" ? "application/json" :  "text/csv"});
        const link = document.createElement("a");
        link.setAttribute("download",`${name}.${exportType}`);
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.click();
    }

    useEffect(() => { 
      if (exportModal) {
        const unixstr = BeaconRequest.tz_conversion(props.datetime).split(":")
        const name = props.resource.onlyCore.response.name || props.resource.onlyCore.response.title || props.resource.onlyCore.response.notes
        // const childType = capitalize(collationType + "s")
        // ${childType} 
        // for ${props.filter.type} 
        setExportName(`${name} ${collation.id} performance export covering ${moment.unix(unixstr[0]).format('DD-MM-YY')} to ${moment.unix(unixstr[1]).format('DD-MM-YY')}`)
      }
    }, [exportModal, collation, props.datetime, props.resource.onlyCore])


    const closeModal = () => {
      setExportModal(false)
      setCollation({id: "summary", title: 'Summary'})
      setExportFormat({id: "csv", title: 'CSV'})
    }

    return <>

        {exportModal && 
          <Modal
            title="Export"
            closeAction={() => closeModal()}
            saveLabel="Export"
            saveAction={() => {
                if (option.id == "links" || option.id == "channels") {
                  if (collation.id == 'daily')
                    downloadToFile(exportFormat.id == "json" ? daily : getTable(daily), null, null, exportFormat.id, exportName)
                  else 
                    downloadToFile(exportFormat.id == "json" ? props.resource.campaigns.response : mappedLinks, null, result, exportFormat.id, exportName)
                } 
                else {
                  if (collation.id == 'daily') {
                    downloadToFile(exportFormat.id == "json" ? daily : getTable(daily), null, null, exportFormat.id, exportName)
                  } else 
                    downloadToFile(exportFormat.id == "json" ? props.resource.campaigns.response : campaigns, null, result, exportFormat.id, exportName)
                }
                
                closeModal()
            }}
            disableSave={collation.id == "daily" && loadingDaily}
            >
                <ul>
                  <li className="form__item">
                    <label className="form__label">Export name</label>
                    <input 
                      type="text"
                      className="form__input" 
                      value={exportName} 
                      onChange={e => setExportName(e.target.value)} 
                      autoFocus 
                      required 
                    />
                  </li>

                  <li className="form__two-column">
                    <div className="form__item">
                      <label className="form__label">Collation mode</label>
                      <DropdownList 
                          selectItem={selected => {setCollation(selected)}} 
                          menu={{
                            title: collation.title,
                            items: [
                              {id: "summary", title: 'Summary'},
                              {id: "daily", title: 'Daily'},
                            ]
                          }}
                        >
                      </DropdownList>
                    </div>
                    <div className="form__item">
                      <label className="form__label">Export format</label>
                      <DropdownList 
                          selectItem={selected => {setExportFormat(selected)}} 
                          menu={{
                            title: exportFormat.title,
                            items: [
                              {id: "csv", title: 'CSV'},
                              {id: "json", title: 'JSON'},
                            ]
                          }}
                        >
                      </DropdownList>
                    </div>
                  </li>
                </ul>
          </Modal>
        }
        

        <div className="card" style={{padding: '10px'}}>
          {props.filter.type != 'link' &&
            <div style={{zIndex: 11, margin: '10px 0', display: 'grid', gridTemplateColumns: 'max-content auto', borderBottom: '1px solid #E6EBED', backgroundColor: 'white', position:'sticky', top:'99px'}}>
               
                {props.filter.type != 'channel' ?
                  <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                    <span 
                      style={{padding: '10px', borderBottom: option.id == "active" ? '3px solid #ffd200' : null, marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                      onClick={e => setOption({id: "active", title: 'Active ' + type})}
                    >
                      Active {type}
                    </span>

                    {props.filter.type != 'campaign' && 
                      <span 
                        style={{padding: '10px', borderBottom: option.id == "channels" ? '3px solid #ffd200' : null, marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                        onClick={e => setOption({id: "channels", title: 'Channels'})}
                      >
                        Active channels
                      </span>
                    }

                    <span 
                      style={{padding: '10px', borderBottom: option.id == "links" ? '3px solid #ffd200' : null, marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                      onClick={e => setOption({id: "links", title: 'Links'})}
                    >
                      Active links
                    </span>
              
                    
                  </div>
                  :
                  <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                    <span 
                      style={{padding: '10px', borderBottom: '3px solid #ffd200', marginRight: '20px', cursor: 'pointer', fontSize: '16px'}}
                    >
                      Active links
                    </span>
                  </div>
                }
                  <div style={{marginLeft: 'auto', marginTop: '7px'}}>
                    <button className="button button--green" 
                     onClick={e => {
                      setExportModal(true)
                    }} ><i class="fas fa-download" style={{marginRight: '5px'}}></i> Export this table</button>

                  </div>
            </div>
          }

            <div >
              <div className="table">
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={(event, property) => {
                        if (property == orderBy) {
                          const isAsc = orderBy === property && order === 'asc';
                          setOrder(isAsc ? 'desc' : 'asc');
                        } else {
                          setOrder('desc');
                        }
                        setOrderBy(property);
                      }}
                      
                      rowCount={option.id == "active" ?  campaigns.length : mappedLinks}
                    />
                  
                      {option.id == "active" ? 
                        stableSort(campaigns, getComparator(order, orderBy))
                          // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map(tableRow)
                        : 
                        stableSort(mappedLinks, getComparator(order, orderBy))
                          .map(tableRow)
                      }
                    
                    {tableRow(result, campaigns.length + 1, null, true)}
              </div>
            </div>
          </div>
      
    </>
}
// withWidgetContainer
export default redirectRouter(PerformanceList)


