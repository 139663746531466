import React, { useRef, useEffect, useState} from 'react';
import ResizeSensor  from 'css-element-queries/src/ResizeSensor'
import * as d3proxy from 'd3'; //https://github.com/d3/d3/issues/2814
import {event as currentEvent, schemeGnBu} from 'd3';
import { HeightTwoTone } from '@material-ui/icons';
var d3 = Object.assign({}, require("d3-queue"), require("d3-geo"), require("d3-geo-projection"), require('d3-scale-chromatic'), require("d3"));

let resize_handler
function VisitorMap(props){
    const ref = useRef();
	const tooltip = useRef();
	const myRef = useRef(null)
	const [width, setWidth] = useState(null)
	const [height, setHeight] = useState(null)
	const [centered, setCentered] = useState()
	const [topo, setTopo] = useState(null) //geo json
	const [zoomScale, setZoomScale] = useState(1)
	const [tableView, setTableView] = useState(false)
	const zoomIn = useRef()
	const zoomOut = useRef()

	useEffect(() => {
		var oReq = new XMLHttpRequest();
		oReq.addEventListener("load",async (e) => {
			setTopo(JSON.parse(e.target.response))
		});
		oReq.addEventListener("error", (e) => {
			setTopo(null)
		});
		oReq.open("GET", "https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson");
		oReq.send()

		return () => {
		}

	}, [])

    useEffect(() => {
		if (topo != null) {

			d3.select(ref.current).text("")//clear before redraw
			var div = d3.select(ref.current)
					
			const zoom = d3.zoom()
			.scaleExtent([1, 40])
			.translateExtent([[0, 0], [width, height]],)
			.on("zoom", zoomed);
		
			var svg = div.append('svg')
					.attr("width", width)
					.attr("height", height)
					.style("pointer-events", "all")
					.call(zoom) 
					.on("wheel.zoom", null);
					

			if (zoomIn.current != null){
				zoomIn.current.addEventListener('click', e => {
					svg.transition().call(zoom.scaleBy, 2)
					setZoomScale(zoomScale * 0.5)
				})
			}
			if (zoomOut.current != null){
				zoomOut.current.addEventListener('click', e => {
					svg.transition().call(zoom.scaleBy, 0.5)
					setZoomScale(zoomScale * 2)
				})
			}
			
			var Tooltip = d3.select(ref.current)
					.append("div")
					.attr("class", "custom-tooltip")
					.style("position", "absolute")
					.style("z-index", 1070)
					.style("opacity", 1)

			var mouseover = function(d) {
				Tooltip.style("opacity", 1)
			}
			var mousemove = function(d) {
				const id = d3.select(this).attr("id")
				let name = d3.select(this).attr("name")
				if (name === "England") name = "United Kingdom"
				const match = props.data.filter(el => el.code === id)[0]
			
				let px = currentEvent.offsetX+10, py = currentEvent.offsetY, threshhold = 60
				if ((width - threshhold) < px) {
					px = px - 120 //change 120 to calculate anchor position, do same for top and bottom
				}

				Tooltip
					.html(name  + " " + (match != null ? match.percentage : '0%'))
					.style("left", (px) + "px")
					.style("top", (py) + "px")
			}
			var mouseleave = function(d) {
				Tooltip.style("opacity", 0)
			}

			const domain_colours = ['#E2E2EA', '#FADCEB', '#F088B6', '#EA5297', '#E6007E']

			var data = d3.map();
			const max = Math.max(...props.data.map(e => e.count))
			props.data.forEach(el => {
				data.set(el.code, el.count/max)
			});
			
			var colorScale = d3.scaleThreshold()
			.domain([0.01, 0.25,0.4, 0.5, 0.75])
			// .range(d3.schemeBlues[8]);
			.range(domain_colours)

			const g = svg.append("g")
			
			// geoMercator()
			// geoCylindricalStereographic
			// geoEckert3
			var projection = d3.geoMercator()
				.scale((props.tileWidth  && props.tileWidth == 1) ? 50 : 90)
				.center([0, 30])
				.translate([(width / 2), height / 2])

			var path = d3.geoPath()
					.projection(projection)

			function zoomed(d) {
				g.attr("transform", currentEvent.transform)
			}
			g.selectAll("path")
				.data(topo.features.filter(d => d.properties.name != "Antarctica"))
				.enter()
				.append("path")
				.attr("d", path)
				.attr("fill", function (d) {
					d.total = data.get(d.id) || 0;
					return colorScale(d.total);
					// return '#E2E2EA'
				})
				//  "white"
				.style("strokeWidth", function (d) {
					return 0
				})
				// .style("opacity", .8)
				
				.attr("class", function(d){ return "Country" } )
				.attr("id",function(d) {return d.id})
				.attr("name",function(d) {return d.properties.name})

				.on("mouseover", mouseover)
				.on("mousemove", mousemove)
				.on("mouseleave", mouseleave)

				// .on("click", clicked)
		}
	}, [props.data, topo, zoomIn, zoomOut, width, height]);

	useEffect(() => {
		let sensor = new ResizeSensor(myRef.current, () => {
			if (myRef.current != null) {
				setWidth(myRef.current.offsetWidth) 
				setHeight(myRef.current.offsetHeight)
			}
		})
		
		return () => {
			sensor.detach()
		}
		
        
    }, [myRef])

	const top_countries = props.data.sort((a,b) => b.count-a.count).filter(a => {
		const num = a.percentage.substring(0, a.percentage.length - 1)
		return num > 0.5
	}).slice(0,5)

	const map = () => {
		return <div ref={myRef} style={{width: '100%', height:'362px'}} >
			{(width != null && height != null) && <>
				<div style={{position: 'absolute', top: '10px', right: '10px', display: 'flex', flexDirection: 'column'}}>
					<button className="button button--dark-grey" style={{padding: '2px 8px', marginBottom: '5px'}} ref={zoomIn}><i className="fas fa-plus"/></button>
					<button className="button button--dark-grey" style={{padding: '2px 8px', marginBottom: '5px'}} ref={zoomOut}><i className="fas fa-minus"/></button>
					<button className="button button--dark-grey" style={{padding: '2px 8px', marginBottom: '5px'}} onClick={e => setTableView(!tableView)}><i className="fas fa-table"></i></button>	
				</div>
				{tableView && 
					<div style={{background: 'rgba(8, 7, 48, 0.8)', overflow: 'auto',zIndex:11, position: 'absolute', top: 0, right: 0, left: 0, bottom: 0,}}>
						<button className="button" style={{position: 'absolute', top: '5px', right: '5px', padding: '5px 10px', fontSize: '10pt', color: 'white', textDecoration: 'underline'}} onClick={e => setTableView(false)}>close</button>
						<div className="table" style={{padding: '10px', marginTop: '30px', border: 'none'}}>
							<div className="table__row">
								<div className="table__heading">
									Country name
								</div>
								<div className="table__heading">
									%
								</div>
							</div>
							{props.data.sort((a,b) => b.count-a.count).map(data => {
								return <div className="table__row">
									<div className="table__cell">
										{data.name}
									</div>
									<div className="table__cell">
										{data.percentage}
									</div>
								</div>
							})

							}
						</div>
					</div>

				}
				{/* {top_countries.length > 0 && 
					<span style={{
						position: 'absolute', 
						bottom: '0', left: '0', 
						overflow: 'auto', 
						padding: '15px', 
						textAlign: 'left',
						maxHeight: '340px'
					}}>
						<ul>
							<li className=" margin-small text-small"><b>Top countries:</b></li>
							{top_countries.map(key => {
								return <li className=" margin-small text-small" >{key.name}: {key.percentage}</li>
							})}
							{props.data.length - 5 > 0 &&
								<li className=" margin-small text-small" >+ {props.data.length - 5} more</li>
							}
						</ul>
					</span>
				} */}
				<div className="grabbable" ref={ref} />
			</>}
		</div>
	}

	if (!props.headless) {
		return <div className="stat-group margin-xlarge" style={{height: '362px'}} >
			<h2 className="stat-group__title" style={{zIndex: 1}}>Visitor Profile</h2>
			<div className="chart stat-group__content" style={{padding: 0}} >
				<div style={{width: '100%', height:'100%'}} >
					{map()}
				</div>
			</div>
		</div>
	}

	return map()
}

export default VisitorMap