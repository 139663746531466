import { 
	FETCH_SINGLE_VISITOR_REQUEST,
	FETCH_SINGLE_VISITOR_SUCCESS,
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_SINGLE_VISITOR_REQUEST: 
			return {
				...state,
				isFetchingSingleVisitor: true
			}
		case FETCH_SINGLE_VISITOR_SUCCESS: 
			return {
				...state,
				isFetchingSingleVisitor: false,
				singleVisitor: action.responseObj.response
			}
		default:
			return state;
	}
}