import React from 'react'
import { connect } from 'react-redux';
var _ = require('lodash')

const CurrencySymbol = props => {
    // Extract and format the symbol
    return <span dangerouslySetInnerHTML={{ __html: props.currency.currency_symbol}} />
}

const mapStateToProps = state => {
    return {
        currency:state.websites.selectedWebsite.__meta
    }
}

export default connect(mapStateToProps, null)(CurrencySymbol)