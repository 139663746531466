import React, { Component } from 'react'
import { connect } from 'react-redux';

class DefaultChannels extends Component {
    render(){
        const defaultChannels = this.props.defaultChannels.map(channel => {
            return (
                <React.Fragment 
                key={channel.channel_id}
                >
                    <input 
                    type="checkbox"
                    id={channel.channel_id}
                    style={{display:"none"}}
                    onChange={() => this.props.handleToggleChannel(channel)}
                    />
                    <label 
                    className="channels__item"
                    htmlFor={channel.channel_id}
                    >
                        <div className="channels__title">
                            <div className="channels__icon">
                                <span className="key-dot key-dot--over-icon" style={{backgroundColor:channel.colour}}></span>
                                <i className={channel.icon}></i>
                            </div>
                            {channel.title}
                        </div>
                    </label>
                </React.Fragment>
            )
        })
        return (
            <div className="channels">
                <p className="margin-medium">Or add some of our ready-made channels...</p>
                {defaultChannels}
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
		defaultChannels: state.channels.defaultChannels
	}
}

export default connect(mapStateToProps, null)(DefaultChannels);