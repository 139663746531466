import React from 'react';
import { connect } from 'react-redux';
import PagesListContainer from '../containers/page/PagesListContainer';
import Paging from '../components/general/Paging';
import DocumentTitle from 'react-document-title';

const PagesListPage = props => {
	return (
		<React.Fragment>
			<DocumentTitle title="Pages" />
			
			
			<div className="container" >
				<div className="card" style={{padding: '10px', marginBottom: '20px', marginTop: '40px', minHeight: '500px'}}>
					{/* <h1 className="main-content--title margin-large">Pages</h1> */}
					<div className="filters margin-medium">
						<div className="filters__content">
							<Paging paging={props.paging} for="pages" />
						</div>
					</div>
					<div className="margin-medium">
						<PagesListContainer />
					</div>
					<div className="filters">
						<div className="filters__content">
							<Paging paging={props.paging} for="pages" />
						</div>
					</div>
				</div>
			</div>

			
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		paging: state.pages.paging,
		userMeta: state.login.userMeta
	}
}

export default connect(mapStateToProps, null)(PagesListPage)
