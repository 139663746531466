// Import action types
import { 
	FETCH_LINKS_REQUEST,
	FETCH_LINKS_SUCCESS,
	FETCH_SINGLE_LINK_REQUEST,
	FETCH_SINGLE_LINK_SUCCESS,
	CREATE_SHORT_LINK_SUCCESS,
	FETCH_RECENT_LINKS_REQUEST,
	FETCH_RECENT_LINKS_SUCCESS,
	SET_SELECTED_LINK,
	UNSET_SELECTED_LINK
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_LINKS_REQUEST:
			return {
				...state,
				isFetchingLinks: true
			}
		case FETCH_LINKS_SUCCESS:
			return {
				...state,
				linksList: action.responseObj.response,
				paging: action.responseObj.paging,
				isFetchingLinks: false
			}
		case FETCH_RECENT_LINKS_REQUEST:
			return {
				...state,
				isFetchingRecentLinks: true
			}
		case FETCH_RECENT_LINKS_SUCCESS:
			return {
				...state,
				recentLinksList: action.responseObj.response,
				isFetchingRecentLinks: false
			}
		case FETCH_SINGLE_LINK_REQUEST: 
			return {
				...state,
				isFetchingSingleLink: true
			}
		case FETCH_SINGLE_LINK_SUCCESS: 
			return {
				...state,
				isFetchingSingleLink: false,
				singleLink: action.responseObj.response
			}
		case CREATE_SHORT_LINK_SUCCESS:
			return {
				...state,
				newlyCreatedLink: action.payload
			}
		case UNSET_SELECTED_LINK: 
		
			return {
				...state,
				selectedLinkId: null,
				selectedLinkName: null
			}
		case SET_SELECTED_LINK: 
			console.log(action.payload)
			return {
				...state,
				selectedLinkId: action.payload.id,
				selectedLinkName: action.payload.title
				// shortCode + ":" + action.payload.notes
			}
		default:
			return state;
	}
}