import React from 'react'

const Welcome = props => {
	return (
		<div className="first-login__slide">
			<div className="first-login__image">
				<img 
					src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/newOnboarding/Onboarding_1.png" 
					style={{objectFit: 'contain', width: '100%', height: '100%'}}
				/>
			</div>
			<div className="first-login__content">
				<h1 className="first-login__title">Welcome to Beacon!</h1>
				<p className="first-login__subtitle margin-large">There are a few things you need to do to get the most out of Beacon, and we'll guide you through it now...</p>

				<div className="first-login__actions">
					<button className="button button--yellow" onClick={e => props.changeSlide()}>Next</button>
				</div>
			</div>
		</div>
	)
}

export default Welcome