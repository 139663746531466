import React from 'react';
import ToolTip from '../general/ToolTip';

const StarRating = props => {    
    let stars = '';

    let theme = '';
    props.theme === 'light' ? theme = 'star-rating__star--white' : theme = 'star-rating__star'

    for(let i = 1; i <= props.stars; i++){
        stars += '<i class="fas fa-star '+theme+'"></i>';
    }

    if(props.stars * 10 % 10 === 5){
        stars += '<i class="fas fa-star-half-alt '+theme+'"></i>';
    }

    for(let i = Math.ceil(props.stars); i < 5; i++){
        stars += '<i class="far fa-star '+theme+'"></i>';
    }

    return (
        <ToolTip 
        text={<div style={{minWidth:80}} dangerouslySetInnerHTML={{ __html: stars }} />} 
        tip={props.tipText} 
        />
    )
}

StarRating.defaultProps = {
    tipText: "Overall performance rating"
}

export default StarRating;