  
import { actionTypes } from "./constants";
import { createResourceFromQueries} from '../utils'
import axios from "axios";

export const createMediaSpendResource = ({timePeriod, filter, disableCache, cancelToken}) => {

    let graph_query, list_query, impressions_graph, clicks_graph, visits_graph, spend_graph
    switch (filter.type) {
        case "website":
        default: 
            graph_query = `website/${filter.payload.id}/${timePeriod}`
            impressions_graph = `website/${filter.payload.id}/graph/impressions/${timePeriod}`
            clicks_graph = `website/${filter.payload.id}/graph/clicks/${timePeriod}`
            visits_graph = `website/${filter.payload.id}/graph/visits/${timePeriod}`
            spend_graph = `website/${filter.payload.id}/graph/spend/${timePeriod}`
            break;
        case "campaign":
            graph_query = `campaign/${filter.payload.id}/channel/analytics/${timePeriod}/stack`
            impressions_graph = `campaign/${filter.payload.id}/graph/impressions/${timePeriod}`
            clicks_graph = `campaign/${filter.payload.id}/graph/clicks/${timePeriod}`
            visits_graph = `campaign/${filter.payload.id}/graph/visits/${timePeriod}`
            spend_graph = `campaign/${filter.payload.id}/graph/spend/${timePeriod}`
            break;
        case "channel":
            graph_query = `channel/${filter.payload.id}/analytics/${timePeriod}/graph`
            impressions_graph = `channel/${filter.payload.id}/graph/impressions/${timePeriod}`
            clicks_graph = `channel/${filter.payload.id}/graph/clicks/${timePeriod}`
            visits_graph = `channel/${filter.payload.id}/graph/visits/${timePeriod}`
            spend_graph = `channel/${filter.payload.id}/graph/spend/${timePeriod}`
            break;
        case "link":
            graph_query = `link/${filter.payload.id}/analytics/${timePeriod}/graph`
            impressions_graph = `link/${filter.payload.id}/graph/impressions/${timePeriod}`
            clicks_graph = `link/${filter.payload.id}/graph/clicks/${timePeriod}`
            visits_graph = `link/${filter.payload.id}/graph/visits/${timePeriod}`
            spend_graph = `link/${filter.payload.id}/graph/spend/${timePeriod}`
            break;
    }
    
    return {
        graph: [graph_query, null, {cancelToken: cancelToken.token}],
        impressions_graph: [impressions_graph, null, {cancelToken: cancelToken.token}],
        clicks_graph: [clicks_graph, null, {cancelToken: cancelToken.token}],
        visits_graph: [visits_graph, null, {cancelToken: cancelToken.token}],
        spend_graph: [spend_graph, null, {cancelToken: cancelToken.token}],
    }
}

export const setMediaSpendResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_MEDIA_SPEND_FETCHING,
            fetching: true
        })

        const previousToken = getState().mediaSpend.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("media spend request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_MEDIA_SPEND_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createMediaSpendResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_MEDIA_SPEND_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_MEDIA_SPEND_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_MEDIA_SPEND_FETCHING,
                        fetching: false
                    })
                }
            })
    }
}