import BeaconRequest from '../BeaconRequest';
import { addNotification } from './ui'
// import { getActiveWebsites } from './websites'

import { 
	FETCH_CUSTOMERS,
	SET_FIRST_LOGIN,
	SET_SELECTED_CUSTOMER,
	SET_CURRENT_USER,
	SET_USER_META
} from './actionTypes';

// after login
// fetch customers
// store __customers in state 
// for each customer ID in stored state, loop through endpoint /website/{whatever}/{customer_id}, 
// this will give list of websites for use in the UI
// when setting the website_id, also set the customer_id

export const fetchCustomers = (currentUser) => {
	return (dispatch, getState) => {
		const userId = currentUser || getState().login.user_id;

		return BeaconRequest.getNoChache('user/' + userId)
		.then(responseObj => {
			dispatch({
				type: FETCH_CUSTOMERS,
				allCustomers: responseObj.response.__customers,
				hasCompletedOnboarding: responseObj.response.hasCompletedOnboarding, // not ideal setting this here
				addedByTeamInvitation: responseObj.response.addedByTeamInvitation 
			})

			// Set the current user
			dispatch({
				type: SET_CURRENT_USER,
				payload: responseObj.response
			})

			dispatch(getUserMeta())

			return responseObj.response
		})
		.catch(error => { console.log(error) })
	}
}


export const getUserMeta = () => {
	return (dispatch, getState) => {
		return BeaconRequest.getNoChache(`user/${getState().login.currentUser.user_id}/meta`)
			.then(meta => {
				dispatch({
					type: SET_USER_META,
					meta
				})

				return meta
			})
			.catch(error => console.log(error))
	}
}

export const setUserMeta = (payload, callback) => {
	return (dispatch, getState) => {
		return BeaconRequest.post(`user/${getState().login.currentUser.user_id}/meta`, {}, payload)
			.then(success => dispatch(getUserMeta()))
			.then(data => data)
	}
}

export const fetchSingleCustomer = (customer_id) => {
	return (dispatch, getState) => {
		return BeaconRequest.get('customer/' + customer_id)
		.then(responseObj => {
			return responseObj.response
		})
		.catch(error => { console.log(error) })
	}
}

export const updateCustomer = (customer) => {
	return (dispatch) => {
		return BeaconRequest.post(
			'customer/' + customer.customer_id, {},
			{
				company:customer.company != null ? customer.company : "",
				first_name:customer.first_name != null ? customer.first_name : "",
				last_name:customer.last_name != null ? customer.last_name : "",
				website:customer.website != null ? customer.website : "",
				addr_1:customer.addr_1 != null ? customer.addr_1 : "",
				addr_2:customer.addr_2 != null ? customer.addr_2 : "",
				addr_city:customer.addr_city != null ? customer.addr_city : "",
				addr_country:customer.addr_country != null ? customer.addr_country : "",
				addr_postcode:customer.addr_postcode != null ? customer.addr_postcode : "",
				phone:customer.phone != null ? customer.phone : "",
			}
		)
		.then(responseObj => {
			const payload = {
				title: "Customer details updated successfully",
				theme: "positive"
			}
			dispatch(addNotification(payload))
			dispatch(setSelectedCustomer(responseObj.response.customer_id))
		})
		.catch(error => { console.log(error) })
	}
}

export const setFirstLogin = flag => {
	return {
		type: SET_FIRST_LOGIN,
		flag
	}
}

export const setSelectedCustomer = (customer_id) => {
	return (dispatch) => {
		return BeaconRequest.get('customer/' + customer_id)
		.then(responseObj => {
			dispatch({
				type: SET_SELECTED_CUSTOMER,
				customer:responseObj.response
			})

			return responseObj.response
		})
		.catch(error => { console.log(error) })
	}
}

export const setCustomerLogo = (customerLogo) => {
	return (dispatch, getState) => {
		const customer_id = getState().customers.selectedCustomer.customer_id

		return BeaconRequest.post(
			'customer/' + customer_id + '/meta', {}, 
			{
				name:'customer_logo',
				value:customerLogo
			},
		)
		.then(responseObj => {
			return responseObj
		})
		.catch(error => {
			throw error;
		})
	}
}

export const getCustomerLogo = () => {
	return (dispatch, getState) => {
		const customer_id = getState().customers.selectedCustomer.customer_id

		return BeaconRequest.get('customer/' + customer_id + '/meta/customer_logo')
		.then(responseObj => {
			return responseObj
		})
		.catch(error => {
			throw error;
		})
	}
}