import React, { Component } from 'react';
import { TwitterPicker, CustomPicker } from 'react-color';
import reactCSS from 'reactcss'
import * as colours from '../../colours';
import map from 'lodash/map'
import merge from 'lodash/merge'
import * as color from 'react-color/lib/helpers'
var { EditableInput,ChromePicker ,Saturation , Hue, Checkboard} = require('react-color/lib/components/common');


const ENTER = 13

export const Swatch = ({ color, style, onClick = () => {}, onHover, title = color,
  children, focus, focusStyle = {}, isUsed }) => {
  const transparent = color === 'transparent'
  const styles = reactCSS({
    default: {
      swatch: {
        background: color,
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        position: 'relative',
        outline: 'none',
        border: isUsed ? 'black 2px solid' : 'none',
        // opacity: isUsed ? 0.6 : 1,
        ...style,
        ...(focus ? focusStyle : {}),
      },
    },
  })

  const handleClick = e => onClick(color, e)
  const handleKeyDown = e => e.keyCode === ENTER && onClick(color, e)
  const handleHover = e => onHover(color, e)

  const optionalEvents = {}
  if (onHover) {
    optionalEvents.onMouseOver = handleHover
  }

  return (
    <div
      style={ styles.swatch }
      onClick={ handleClick }
      title={ title }
      tabIndex={ 0 }
      onKeyDown={ handleKeyDown }
      { ...optionalEvents }
    >
      { children }
      { transparent && (
        <Checkboard
          borderRadius={ styles.swatch.borderRadius }
          boxShadow="inset 0 0 0 1px rgba(0,0,0,0.1)"
        />
      ) }
      {isUsed && <span className="disable-swatch"/>}
    </div>
  )
}

const CustomColourPicker = CustomPicker(props => {
    const triangle =  'top-left'
    const styles = reactCSS(merge({
        'default': {
          card: {
            width: 276,
            background: '#fff',
            border: '0 solid rgba(0,0,0,0.25)',
            boxShadow: '0 1px 4px rgba(0,0,0,0.25)',
            borderRadius: '4px',
            position: 'relative',
          },
          saturation: {
            width: '100%',
            paddingBottom: '55%',
            position: 'relative',
            borderRadius: '2px 2px 0 0',
            overflow: 'hidden',
          },
          hue: {
            height: '10px',
            position: 'relative',
            marginBottom: '8px',
          },
          Hue: {
            radius: '2px',
          },
          picker: {
            width: '12px',
            height: '12px',
            borderRadius: '6px',
            transform: 'translate(-6px, -1px)',
            backgroundColor: 'rgb(248, 248, 248)',
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
          },
          body: {
            padding: '15px 9px 9px 15px',
          },
          label: {
            fontSize: '18px',
            color: '#fff',
          },
          triangle: {
            width: '0px',
            height: '0px',
            borderStyle: 'solid',
            borderWidth: '0 9px 10px 9px',
            borderColor: 'transparent transparent #fff transparent',
            position: 'absolute',
          },
          triangleShadow: {
            width: '0px',
            height: '0px',
            borderStyle: 'solid',
            borderWidth: '0 9px 10px 9px',
            borderColor: 'transparent transparent rgba(0,0,0,.1) transparent',
            position: 'absolute',
          },
          hash: {
            background: '#F0F0F0',
            height: '30px',
            width: '30px',
            borderRadius: '4px 0 0 4px',
            float: 'left',
            color: '#98A1A4',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          input: {
            width: '100px',
            fontSize: '14px',
            color: '#666',
            outline: 'none',
            height: '28px',
            boxShadow: 'inset 0 0 0 1px #F0F0F0',
            boxSizing: 'content-box',
            borderRadius: '0 4px 4px 0',
            float: 'left',
            paddingLeft: '8px',
          },
          swatch: {
            width: '30px',
            height: '30px',
            float: 'left',
            borderRadius: '4px',
            margin: '0 6px 6px 0',
          },
          message: {
            padding: '0 9px 9px 10px',
            color: 'red'
          },
          clear: {
            clear: 'both',
          },
        },
        'hide-triangle': {
          triangle: {
            display: 'none',
          },
          triangleShadow: {
            display: 'none',
          },
        },
        'top-left-triangle': {
          triangle: {
            top: '-10px',
            left: '12px',
          },
          triangleShadow: {
            top: '-11px',
            left: '12px',
          },
        },
        'top-right-triangle': {
          triangle: {
            top: '-10px',
            right: '12px',
          },
          triangleShadow: {
            top: '-11px',
            right: '12px',
          },
        },
      }, {}), {
        'hide-triangle': triangle === 'hide',
        'top-left-triangle': triangle === 'top-left',
        'top-right-triangle': triangle === 'top-right',
      })
    
    const handleChange = (hexcode, e) => {
        if (props.isUsedColour(hexcode)){
          return
        } 

        color.isValidHex(hexcode) && props.onChange({
            hex: hexcode,
            source: 'hex',
        }, e)
    }

    const onHueChange = (hsl, e) => {
        props.onChange({
            h: hsl.h,
            s: 0.5,
            l: 0.5,
            source: 'hsl',
        }, e)
    }
    
    return <div style={ styles.card } className={ `twitter-picker ${ '' }`}>
        <div style={ styles.triangleShadow } />
        <div style={ styles.triangle } />

        <div>
          <div style={ styles.hue }>
              <Hue
                hsl={props.hsl}
                style={ styles.Hue }
                pointer={ () => <div style={ styles.picker } />}
                onChange={ onHueChange }
              />
          </div>
          <div>
            <div style={ styles.body }>
                
                { map(props.colors, (c, i) => {
                return (
                    <Swatch
                    key={ i }
                    color={ c }
                    hex={ c }
                    isUsed={ props.isUsedColour(c)}
                    className="disable-swatch"
                    style={ styles.swatch }
                    onClick={ handleChange }
                    focusStyle={{
                        boxShadow: `0 0 4px ${ c }`,
                    }}
                    />)
                }) }
                <div style={ styles.hash }>#</div>
                <EditableInput
                    label={null}
                    style={{ input: styles.input }}
                    value={ props.hex.replace('#', '') }
                    onChange={ handleChange } 
                />
                <div style={ styles.clear } />
            </div>
            {props.isUsedColour(props.hex) &&
                <div  style={ styles.message }>
                    <div>{props.message}</div>
                </div>
            }
          </div>
        </div>
        
    </div>
})

class ColourPicker extends Component {
    state = {
        displayColorPicker: false,
        color: this.props.defaultColour
    };

    componentWillReceiveProps(nextProps){
        if(this.props.defaultColour !== nextProps.defaultColour){
            this.setState({
                color: nextProps.defaultColour
            })
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
      if (this.colourInUse(this.state.color.toUpperCase())) {
        this.setState({ displayColorPicker: false, color: this.props.defaultColour })
      }
      else {
        this.setState({ displayColorPicker: false })
      }
        
    };

    handleChange = (color) => {
        this.setState({ color: color.hex })
        this.props.selectedColour(color.hex)
    };

    colourInUse = (colour) => {
      return this.props.usedColours != null 
          && this.props.usedColours.map(color => color.toUpperCase()).includes(colour.toUpperCase()) 
          && colour.toUpperCase() != this.props.defaultColour.toUpperCase()
          
    }

    render() {
        const styles = reactCSS({
            'default': {
                color: {
                width: '45px',
                height: '23px',
                borderRadius: '2px',
                background: this.state.color,
                },
                swatch: {
                display: 'inline-block',
                cursor: 'pointer',
                },
                popover: {
                position: 'absolute',
                zIndex: '2',
                },
                cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                },
            },
        });
                  
        return (
            <React.Fragment>
                <div style={ styles.swatch } onClick={ this.handleClick }>
                <div style={ styles.color } />
                </div>
                { this.state.displayColorPicker ? <div style={ styles.popover }>
                <div style={ styles.cover } onClick={ this.handleClose }/>
                    <CustomColourPicker 
                        colors={colours.beaconColours} 
                        color={ this.state.color } 
                        isDefault={colour => colour.toUpperCase() != this.props.defaultColour.toUpperCase()}
                        onChange={ this.handleChange }
                        isUsedColour={ this.colourInUse }
                        message={"Colour in use, please select another colour."}
                    />
                </div> : null }
            </React.Fragment>
        )
    }
}

export default ColourPicker