import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchActiveCampaigns } from '../../actions/campaigns';
import CreateCampaignContainer from '../../containers/forms/CreateCampaignContainer';
import AddCampaignChannelsContainer from '../../containers/forms/AddCampaignChannelsContainer'
import { fetchCampaignChannels } from '../../actions/channels';
import ActiveCampaignsDropdown from '../campaign/ActiveCampaignsDropdown';
import CampaignChannelsDropdown from '../campaign/CampaignChannelsDropdown';
var _ = require('lodash')

class EditLink extends Component {

	constructor(){
		super();
		this.state = {
			isAddingCampaign: false,
			isAddingChannel: false
		}
		this.toggleCreateCampaign = this.toggleCreateCampaign.bind(this);
		this.toggleCreateChannel = this.toggleCreateChannel.bind(this);
	}

	componentDidMount(){
    	this.props.fetchActiveCampaigns();
	}

	toggleCreateCampaign(){
    	this.setState({ 
    		isAddingCampaign: !this.state.isAddingCampaign
    	})
	}
	
	toggleCreateChannel(channel){
    	this.setState({ 
    		isAddingChannel: !this.state.isAddingChannel
    	}, () => {
			this.props.setNewChannel(channel)
		})
    }

	render(){
		const tags = this.props.tags.map((tag, i) => {
			return <li key={i} className="tag"><span className="tag__title">{tag.title}</span><button className="tag__remove" type="button" onClick={() => this.props.handleRemoveTag(tag.title)}><i className="fas fa-times"></i></button></li>
		})

		return (
			<ul>
				<div className="form__two-column">
					<li className="form__item">
						<label className="form__label">Campaign</label>
						<ActiveCampaignsDropdown 
							campaigns={this.props.activeCampaigns} 
							selectedCampaignName={this.props.selectedCampaignName} 
							selectedCampaignId={this.props.selectedCampaignId}
							handleSelectedItem={this.props.setSelectedCampaign}
							isClearable={true}
							clearSelectedCampaign={this.props.clearSelectedCampaign}
							addCampaign={this.toggleCreateCampaign}
						/>

						{ this.state.isAddingCampaign &&
							<CreateCampaignContainer 
								callback={e => {
									this.props.fetchActiveCampaigns()
									this.props.setSelectedCampaign(e)
								}} 
								handleOpen={this.toggleCreateCampaign} 
							/> 
						}
					</li>
					<li className="form__item">
						<label className="form__label">Channel</label>
						{
							!this.props.selectedCampaignName ? <span>Select a campaign</span>
							:
							!_.isEmpty(this.props.campaignChannels) ?
							<CampaignChannelsDropdown
							channels={this.props.campaignChannels}
							selectedChannelName={this.props.selectedChannelName} 
							selectedChannelId={this.props.selectedChannelId}
							handleSelectedItem={this.props.setSelectedChannel}
							isClearable={true}
							clearSelectedChannel={this.props.clearSelectedChannel}
							isDisabled={this.props.disableChannelSelect}
							addChannel={this.toggleCreateChannel}
							/>
							:
							<button 
							className="button--dark-grey button"
							onClick={this.toggleCreateChannel}>
							Add a Channel
							</button>
						}

						{ this.state.isAddingChannel && 
							<AddCampaignChannelsContainer 
								campaignId={this.props.selectedCampaignId} 
								handleOpen={this.toggleCreateChannel}
								callback={e => {
									this.props.fetchCampaignChannels(this.props.selectedCampaignId)
									// this.props.setSelectedChannel(e)
								}}
							/> 
						}
					</li>
				</div>
				<li className="form__item">
					<label className="form__label">Description</label>
					<textarea className="form__input" value={this.props.note} onChange={this.props.handleNoteChange} />
				</li>
			</ul>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		activeCampaigns: state.campaigns.activeCampaigns
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchActiveCampaigns: () => dispatch(fetchActiveCampaigns()),
		fetchCampaignChannels: (campaign_id) => dispatch(fetchCampaignChannels(campaign_id))
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLink)