import React, { useState, useEffect } from 'react';
import produce from 'immer'
import ColourPicker from '../general/ColourPicker';
import { connect } from 'react-redux';
import BeaconRequest from '../../BeaconRequest'
import LoaderAlt from '../general/LoaderAlt'
import {beaconColours} from '../../colours'

const CreateCampaign = props => {
	const [selectedItem, setselectedItem] = useState(props.defaultType)
	const [inputLinks, setInputLinks] = useState(props.defaultSettings)
	const [showIdentifiedData, setShowIndentifiedData] = useState(props.defaultSID)
	const [usedColours, setUsedColours] = useState(null)
	const [error, setError] = React.useState(false)
	const usedColoursLoading = usedColours == null

	useEffect(() => {
		setError(false)
		BeaconRequest.get(`website/${props.website.website_id}/campaigns/coloursInUse`)
			.then(res => setUsedColours(res.response))
			.catch(err => setError(true))
	}, [])

	useEffect(() => {
		props.selectedType(selectedItem)
		props.settingsLinks(inputLinks)
		props.indetifiedData(showIdentifiedData)
	}, [inputLinks,  showIdentifiedData, selectedItem])
	
	return (
		<ul>
			<h2 className="form__title">Campaign Details</h2>
			<li className="form__item">
				<label className="form__label">Campaign name</label>
				<input 
				type="text"
				className="form__input" 
				value={props.campaignName} 
				onChange={props.handleChangeCampaignName} 
				autoFocus 
				required 
				style={{paddingLeft:58}}
				/>
				<div 
				style={{position:'absolute', 
				left:4, 
				bottom:"-1px" }}
				>

					{(usedColoursLoading || error) ? 
						<div style={{marginLeft: '15px'}}><LoaderAlt noHints /></div>
						:
						<ColourPicker 
							usedColours={usedColours} 
							defaultColour={beaconColours.filter(color => !usedColours.map(colour => colour.toUpperCase()).includes(color.toUpperCase()))[0]} 
							selectedColour={props.getSelectedColour} 
						/>
					}
				</div>
			</li>
			<li className="form__item">
				<label className="form__label">Description<span className="form__optional">Optional</span></label>
				<textarea className="form__input" value={props.campaignDescription} onChange={props.handleChangeCampaignDescription} />
			</li>
			<h2 className="form__title">Campaign Dates<span style={{fontSize:14}} className="form__optional">Optional</span></h2>
			<p className="margin-small">This option allows you to fine tune your campaign reporting to monitor all traffic received or include only traffic received during a given time period.</p>
			<li className="margin-medium">
				<div className="select-container">
					<select className="select" 
					onChange={props.handleChangeDatesVisible.bind(this)}>
						<option>All time</option>
						<option selected={props.datesVisible}>Specific dates</option>
					</select>
				</div>
			</li>
			{
				props.datesVisible &&
				<li className="form__two-column margin-medium" style={{gridTemplateColumns:"max-content max-content"}}>
					<div>
						<label className="form__label">From</label>
						<input className="form__input" defaultValue={props.startDate} 
						onChange={props.handleChangeStartDate}
						type="date" />
					</div>
					<div style={{display:"grid", gridTemplateColumns:"1fr 1fr", gridGap:10, alignItems:"end"}}>
						<div>
							<label className="form__label">To</label>
							<input className="form__input" defaultValue={props.endDate} 
							onChange={props.handleChangeEndDate}
							disabled={props.endDateDisabled}
							type="date" />
						</div>
						<label>
							<input 
							checked={!props.endDateDisabled}
							onChange={props.handleToggleEndDate}
							type="checkbox" 
							/> Set end date
						</label>
					</div>
					
				</li>
			}
			{!props.endDateDisabled && <div className="margin-medium">
				<label>
					<input 
					checked={!props.endDateDisabled}
					onChange={props.handleToggleEndDate}
					type="checkbox" 
					/> Create campaign report on end date
				</label>
			</div>

			}
			<h2 className="form__title">Campaign Options</h2>
			{/* <li> */}
				<div style={{ 'marginBottom': '10px'}}>
					<li style={{display: 'inline-block'}}>
						<h2 className="form__label">Record identifiable data from form fills?</h2>
					</li>
					<li style={{display: 'inline-block'}}>
						<div style={{marginLeft: '10px'}}>
							<h2 style={{display: 'inline-block', marginRight: '5px'}}>Yes</h2>
							<label className="radio-container">
								<input type="radio" name="radio" checked={props.showIdentifiedData} onChange={(e) => setShowIndentifiedData('true')}/>
								<span className="checkmark"></span>
							</label>
							<h2 style={{display: 'inline-block', marginRight: '5px'}}>No</h2>
							<label className="radio-container">
								<input type="radio" name="radio" checked={!props.showIdentifiedData} onChange={(e) => setShowIndentifiedData('false')}/>
								<span className="checkmark"></span>
							</label>


						</div>
					</li>
				</div>
			{/* </li> */}
			<li>
				{showIdentifiedData == 'true' &&
					<div className="margin-medium message message--notice">
						<span>By allowing Beacon to capture and record identifiable data, you agree to our <a href="https://www.thisisbeacon.com/gdpr-statement/" target="_blank">GDPR commitment</a> and confirm that you will act accordingly.</span>
					
					</div>
				}
			</li>
			<li style={{ 'marginBottom': '10px'}}>
			<h2 className="form__label">Goal Definition <a className="text-small" href="https://go.thisisbeacon.com/guides/setting-campaign-goals/" target="_blank">(Learn more about this including wildcards here)</a></h2>
				<div className="select-container" style={{ 'marginBottom': '5px'}}>
					<select 
						className="select" 
						onChange={e => {
							setselectedItem(e.target.value)
							setInputLinks([""])
						}} 
					>
						<option value="identified_visitor" selected={selectedItem === 'identified_visitor'}>Identified visitor from form fill</option>
						<option value="external_link" selected={selectedItem === 'external_link'}>Clicking on an external link</option>
						<option value="url_match" selected={selectedItem === 'url_match' || selectedItem === 'preg_match'}>Visiting an internal page</option>
						<option value="shopify_thank_you" selected={selectedItem === 'shopify_thank_you'}>Shopify: order thank you page</option>
					</select> 
				</div>
				
				{(selectedItem === 'external_link' || selectedItem === 'url_match' || selectedItem === 'preg_match') && <>
					{selectedItem === 'external_link' && 
						<div className="message message--notice margin-medium">
							{/* <h2 className="message__title">Please note</h2> */}
							<p className="margin-small">
							An external URL must be absolute and start with http:// or https://.
							Enter a link in the box below. You must input at least one link.
							Additional links can be added via the button.
							</p>
						</div>
					}
					{(selectedItem === 'url_match' || selectedItem === 'preg_match') && 
						<div className="margin-small">
							<div className="message message--notice margin-medium">
								{/* <h2 className="message__title">Please note</h2> */}
								<p className="margin-small">
								{/* An internal URL must be relative and start with a forward slash (/). */}
								Enter a link in the box below. You must input at least one link.
								Additional links can be added via the button.
								</p>
							</div>

							<div>
								<strong>This already supports wildcard matching.</strong><br />
								<input
									type="checkbox" 
									checked={selectedItem === 'preg_match'}
									onClick={e => {
										if (e.checked)
											setselectedItem('url_match');
										else 
											setselectedItem('preg_match');
									}} 
								/> 
								Check this box to support preg matching in URL.
							</div>
						</div>
					}

					{inputLinks.map((input, index) => {
							return <div key={index} style={{'marginTop': '10px'}}>
										{ (selectedItem === 'url_match' || selectedItem === 'preg_match') && <>
												<div style={{
														display: 'inline',
														backgroundColor: '#e6e6e6',
														borderRadius: '4px',
														padding: '6px 6px',
														border: '1px solid #E6EBED',
														borderRight: 'none',
														borderTopRightRadius: '0',
														borderBottomRightRadius: '0',
														paddingRight: '0',
														width: '40%'

													}}>
													{props.website.__urls.primaryUrl + "/"}
												</div>
												<input
													className="form__input" 
													onChange={e => {
														e.persist()
														setInputLinks(produce(prev => {prev[index] = e.target.value}))
													}} 
													value={input}
													style={{
														display: 'inline', 
														width: '45%',
														borderLeft: 'none',
														paddingLeft: '3px',
														borderTopLeftRadius: '0',
														borderBottomLeftRadius: '0',
													}}
												/>
											</>
										}

										{ selectedItem === 'external_link' && <>
												<input
													className="form__input" 
													onChange={e => {
														e.persist()
														setInputLinks(produce(prev => {prev[index] = e.target.value}))
													}} 
													value={input}
													style={{
														display: 'inline', 
														width: '85%',
													}}
												/>
										</>
										}
									<span style={{'display': 'inline-block', 'marginLeft': '5px', width: '10%'}}>
										<button
											className="button button--small button--dark-grey" 
											onClick={(e) => {
												e.persist()
												setInputLinks(produce(prev => { prev.splice(index, 1) } ))
											}}
											type="button"
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</span>
							</div>
						})
					}
					
					<button 
						type="button"
						style={{'marginTop': '5px'}} 
						className="button button--small button--dark-grey"
						onClick={e => {
							e.persist()
							setInputLinks(produce(prev => {prev[prev.length] = ""}))
						}}
					>
						Add Additional Link
					</button>
					
				</>}
				
			</li>
		</ul>
	)
}

const mapStateToProps = state => {
	return {
		// customer: state.customers.selectedCustomer,
		website: state.websites.selectedWebsite
	}
}

export default connect(mapStateToProps, null)(CreateCampaign)