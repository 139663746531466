import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPagesByPaging } from '../../actions/pages';
import { fetchLinksByPaging } from '../../actions/links';
import { fetchVisitsByPaging } from '../../actions/visits';

class Paging extends Component {
	constructor(){
		super();
		this.handlePaging = this.handlePaging.bind(this)
	}

	handlePaging(link){
		switch(this.props.for){
			case "pages":
				return this.props.fetchPagesByPaging(link)
			case "links":
				return this.props.fetchLinksByPaging(link)
			case "visits":
				return this.props.fetchVisitsByPaging(link)
			default:
				return null
		}
	}

	render(){
		if(this.props.paging){
			return (
				<ul className="paging">
					<li>Showing <strong>{this.props.paging.showingFrom} - {this.props.paging.showingTo}</strong> of <strong>{this.props.paging.totalResults}</strong> records</li>
					{ 
						this.props.paging.previous && 
						<li 
						className="button button--dark-grey hollow" 
						onClick={() => this.handlePaging(this.props.paging.previous)}>
						<i className="fas fa-caret-left"></i>
						</li> 
					}
					{
						this.props.paging.next && 
						<li className="button button--dark-grey hollow" 
						onClick={() => this.handlePaging(this.props.paging.next)}>
						<i className="fas fa-caret-right"></i>
						</li> 
					}
				</ul>
			)
		}
		return null
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchPagesByPaging: (link) => dispatch(fetchPagesByPaging(link)),
		fetchLinksByPaging: (link) => dispatch(fetchLinksByPaging(link)),
		fetchVisitsByPaging: (link) => dispatch(fetchVisitsByPaging(link))
	}
}

export default connect(null, mapDispatchToProps)(Paging);