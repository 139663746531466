import React, { Component } from 'react'
import { connect } from 'react-redux';
import { fetchEngagedVisitorsByCampaign, fetchEngagedVisitorsByChannel, fetchEngagedVisitorsByLink, fetchEngagedVisitorsByWebsite } from '../../actions/visitors'
import StarRating from '../general/StarRating'
import VisitorActions from '../visitor/VisitorActions'
import LoaderAlt from '../general/LoaderAlt'
import { Link } from 'react-router-dom'
import ToolTip from '../general/ToolTip'
import moment from 'moment-timezone'
import _ from 'lodash';
import { withLazyListLoaderHOC } from '../../hooks/withLazyListLoaderHOC';

class EngagedVisitorsList extends Component {
    state = {
        isFetching: true
    }

    componentDidMount(){
        if(this.props.for === "campaign"){
            this.props.fetchEngagedVisitorsByCampaign(this.props.id, this.props.timestamp)
            .then(responseObj => {
                this.props.lazyLoader.setInitialData({
                    data: responseObj.response, 
                    next: responseObj.paging.next,
                    dataLeft: responseObj.paging.totalResults
                })

                this.setState({
                    isFetching: false
                })
            })
        }

        if(this.props.for === "channel"){
            this.props.fetchEngagedVisitorsByChannel(this.props.id, this.props.timestamp)
            .then(responseObj => {
                this.props.lazyLoader.setInitialData({
                    data: responseObj.response, 
                    next: responseObj.paging.next,
                    dataLeft: responseObj.paging.totalResults
                })

                this.setState({
                    isFetching: false
                })
            })
        }

        if(this.props.for === "link"){
            this.props.fetchEngagedVisitorsByLink(this.props.id, this.props.timestamp)
            .then(responseObj => {
                this.props.lazyLoader.setInitialData({
                    data: responseObj.response, 
                    next: responseObj.paging.next,
                    dataLeft: responseObj.paging.totalResults
                })

                this.setState({
                    isFetching: false
                })
            })
        }

        if(this.props.for === "website"){
            this.props.fetchEngagedVisitorsByWebsite(this.props.id)
            .then(responseObj => {
                this.props.lazyLoader.setInitialData({
                    data: responseObj.response, 
                    next: responseObj.paging.next,
                    dataLeft: responseObj.paging.totalResults
                })

                this.setState({
                    isFetching: false
                })
            })
        }
    }

    render(){
        if(this.state.isFetching){
            return <LoaderAlt />
        }

        const {paging, onNext} = this.props.lazyLoader;

        const isFetchingNext = paging.isFetchingNext;
        const hasLoadedAll = paging.next == null;

        const visits = paging.data.map(visit => {
            const { 
                visit_id, totalTimeOnSite, 
                pageViews, 
                scoringActions,
                startOfVisitDate, 
                __link,
                added,
                __landingPage
            } = visit

            const { shortCode, originalUrl } = visit.__link

            return (
                <Link
                to={{pathname: "/visits/" + (visit.visit_id), 
                state: { modal: true, referrer: window.location.pathname } }} 
                key={visit_id} className="table__row table__row--link">
                    <div className="table__cell">
                        <div>
                            { 
                                visit.doNotTrack === 1 && 
                                <a rel="noopener noreferrer" href="https://www.thisisbeacon.com/resource/what-is-a-do-not-track-visit/" target="_blank" style={{paddingRight:5}}><ToolTip text={<i className="fas fa-ban"></i>} tip="Visitor has Do Not Track activated - click to find out more" /></a>
                            }
                            {moment(startOfVisitDate).format('DD MMM YYYY')} at {moment(added).format('LT')}
                        </div>
                        <Link 
                        title="View the details for this link"
                        className="text-small color-mid-grey"
                        to={{pathname: "/links/" + (visit.__link.link_id), 
                        state: { modal: true, referrer: window.location.pathname } }}>
                            <ToolTip text={"bcn.to/"+shortCode} tip={originalUrl} />
                        </Link>
                    </div>

                    <div className="table__cell" style={{maxWidth:200}}>
                        <div>
                        { 
                            !_.isEmpty(visit.__channel) ? 
                            <Link 
                            className="color-mid-grey"
                            to={{pathname: "/channels/" + (visit.__channel.channel_id), 
                            state: { modal: true, referrer: window.location.pathname } }}>
                            <span className="key-dot" style={{backgroundColor:visit.__channel.colour}}></span>
                            {visit.__channel.title}
                            </Link> 
                            : "No channel"     
                        }
                        </div>
                    </div>

                    <div className="table__cell">
                        {__landingPage}
                    </div>

                    <div className="table__cell" style={{maxWidth:200}}>
                        {__link.notes}
                    </div>

                    <div className="table__cell">
                        <StarRating stars={visit.star_rating} />
                    </div>
                    <div className="table__cell">
                        {
                            totalTimeOnSite > 3600 ? moment.utc(totalTimeOnSite*1000).format('HH:mm:ss') : moment.utc(totalTimeOnSite*1000).format('mm:ss')
                        }
                    </div>
                    <div className="table__cell">
                        {pageViews}
                    </div>
                    <div className="table__cell">
                        {
                            !_.isEmpty(scoringActions) && <VisitorActions actions={scoringActions} />
                        }
                    </div>
                </Link>
            )
        })

        return (<React.Fragment>
            <div className="table margin-large">
                <div className="table__row">
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Visit
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Channel
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Landing Page
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Link Description
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Rating
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Duration
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Page Views
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Visitor Actions
                    </div>
                </div>
                {visits}
            </div>
            <div style={{textAlign: "center"}}>
                {!hasLoadedAll && 
                    <>
                        {isFetchingNext ? (
                            <LoaderAlt />
                        )  : (
                            <button className="button button--no-border button--green" onClick={() => onNext()}>
                                {"Load More - (" + paging.dataLeft + " in total)"}
                            </button>
                        )}
                    </>
                }
            </div>
        </ React.Fragment>)
    }
}

const mapDispatchToProps = dispatch => {
	return {
        fetchEngagedVisitorsByCampaign: (campaign_id, timestamp) => dispatch(fetchEngagedVisitorsByCampaign(campaign_id, timestamp)),
        fetchEngagedVisitorsByChannel: (channel_id, timestamp) => dispatch(fetchEngagedVisitorsByChannel(channel_id, timestamp)),
        fetchEngagedVisitorsByLink: (link_id, timestamp) => dispatch(fetchEngagedVisitorsByLink(link_id, timestamp)),
        fetchEngagedVisitorsByWebsite: (website_id) => dispatch(fetchEngagedVisitorsByWebsite(website_id)),
	}
}
//Wrapped using the lazyListLoader so it also recives props from that
export default connect(null, mapDispatchToProps)(withLazyListLoaderHOC(EngagedVisitorsList));