import React, {useEffect, useMemo, useState, useRef} from 'react'
import Loader from '../../components/general/Loader';
import {withWidgetContainer} from '../withWidgetContainer'

import { connect } from 'react-redux';
import moment from 'moment-timezone'
import {
    ResponsiveContainer, LineChart, Line, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts';

import {setBotImpactGraphResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Skeleton from '@material-ui/lab/Skeleton'
import Card from '../../atoms/Card'
import Checkbox from '../../components/general/Checkbox';

const BotImpactGraph = (props) => {
    const resource = useSelector(({botImpactGraph}) => botImpactGraph.data)
    const isFetching = useSelector(({botImpactGraph}) => botImpactGraph.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setBotImpactGraphResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            {/* <div style={{borderRadius: '5px', position: 'absolute', left: 10, transform: 'translateY(-50%)', width: 172, height: 25, backgroundColor:'#5E6172'}} />  */}
            <Skeleton variant="rect" width='100%' height={326} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
        >
            <h3 className="stat-group__title" style={{zIndex: 0}}>{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
                <BotImpactGraphChild {...props} resource={resource}/>
            </div>
        </Card>
    )
}
    

const BotImpactGraphChild = (props) => {
    const [pointCursor, setPointCursor] = useState({})
    const [zoomed, setZoomed] = useState(false)
    const botGraph = useRef()
    const graph = props.resource.graph.response
  
    const zoomedPercentMax = useMemo(() => {
        const maxEntry = graph.graph.slice(1).reduce((acc, val) => acc.data > val.data ? acc : val, graph.graph[0]);
        return Math.ceil(maxEntry.data / 5) * 5;
    }, [graph.graph]);
    
    useEffect(() => {
        if (botGraph && botGraph.current) {
            setPointCursor(botGraph.current.props)
        }
    }, [botGraph, botGraph.current])

    let timeFormat = ''
    let prepend = ''
    switch(graph.collation){
        case "minute":
            timeFormat = 'h:mma'
            break;
        case "hour":
            timeFormat = 'ha'
            break;
        case "day":
        default:
            timeFormat = 'ddd Do'
            break;
        case "week":
            timeFormat = 'Do MMM'
            prepend = 'w/c '
            break;
        case "month":
            timeFormat = 'MMM YY'
            break;
    }
    
    const split = props.datetime.split(":")

    const isToday = props.datetime == "today" || (moment.unix(split[0]).unix() == moment().startOf('d').unix() && moment.unix(split[1]).unix() == moment().endOf('d').unix())
    const isOneDayPeriod = 
        props.datetime == "yesterday" 
        || moment.unix(split[1]).diff(moment.unix(split[0]), 'days') == 0
    
    if (isToday || isOneDayPeriod) {
        return <div style={{display: 'flex', fontSize: '25px', color: '#808080a6', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <i class="fas fa-exclamation-circle"></i>
        </div>
    }

    const startingDates = graph.graph.map((value, i) => {
        const dayOfMonth = moment.unix(value.start.timestamp).date()
        let previousValue = (i != 0) ?
             moment.unix(graph.graph[i - 1].start.timestamp).date()
            :
             35 //max
        
        if (dayOfMonth < previousValue) {
            return value.start.timestamp
        }
    })

    const CusomtisedXAxisTick = (props) => {
        const dayOfMonth = moment.unix(props.payload.value).date()
        
        // {prepend}
        return  <g>
            <text x={props.x} y={props.y} fill="#989898" dy={10} dx={-8.5}>
                {dayOfMonth.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}
            </text>
            {(dayOfMonth == 1 || startingDates.includes(props.payload.value)) &&
                <text x={props.x} y={props.y} fill="#989898" dy={30} dx={-8.5} >
                     {moment.unix(props.payload.value).format('MMM YY')}
                </text>
            }
        </g>
    }

    const CusomtisedYAxisTick = (props) => {
        return  <g>
            <text x={props.x} y={props.y} fill="#989898" dy={0} dx={5}>
                {props.payload.value}%
            </text>
        </g>
    }

    const CusomtisedToolTip = (props) => {
        const [translate, setTranslate] = useState({x: 0, y: 0})
        
        const _ref = React.useRef()

        useEffect(() => {
            if (props.active && props.payload && _ref.current) {
                setTranslate({x: _ref.current.offsetWidth / 2, y: 0})
            }
        }, [props.active, props.pointCursor, props.payload, _ref])

        const { active, payload, label } = props;

        if (active && payload && payload.length && pointCursor &&  pointCursor.points) {
            if (payload == null) return null; //when sync is off with bot impact graph
            const point = pointCursor.points.filter(point => point.payload.name == payload[0].payload.name)[0] 
            if (point == null) return null

            const {x,y} = point
            
            return <div 
                ref={_ref}
                className="tooltip-arrow"
                style={{
                    position: 'fixed',
                    top: y - 62,
                    left: x - translate.x,
                    background: 'rgb(255, 49, 144)',
                    color: 'white',
                    padding: '5px',
                    borderRadius: '5px',
                    whiteSpace: 'nowrap',
                    zIndex: 1200,
                    textAlign: 'center'
                }}
                
            >
                {/* {`${prepend + moment.unix(payload[0].payload.name).format(timeFormat)}: */}
                <p className="text-small" style={{fontWeight: 600, fontSize: '14px'}}> {`${payload[0].value}%`}</p>
                <div style={{borderBottom: '1px solid white', opacity: 0.6, width: '100%'}}/>
                <p className="text-small" style={{fontSize: '12px'}}> {moment.unix(label).format(timeFormat)}</p>
            </div>
        }
        return null
    }

    const CustomCursor = (props) => {
        if (pointCursor &&  pointCursor.points) {
        
            const { active, payload, label, width, height, left } = props;
            if (payload == null) return null; //when sync is off with bot impact graph
            const point = pointCursor.points.filter(point => point.payload.name == payload[0].payload.name)[0] 
            if (point == null) return null

            const {x,y} = point

            return <line 
                x1={x}
                y1={y} 
                x2={x} 
                y2={height + (height*0.1)} //apply gradient offset why?
                height={height}
                width={width}
                style={{
                    stroke: colour,strokeWidth:1, strokeDasharray: "5,5", opacity: 1, zIndex: 100
                }}
            />
        }

        return null
    }

    const colour = 'rgb(255, 49, 144)'

    return <div style={{width: '99.9%', height: '300px'}}>
    <div style={{position: 'absolute', top: '3px', right: 0, opacity: 0.8, transform: 'scale(0.7)'}}>
        <Checkbox checked={zoomed} handleToggle={() => setZoomed(currentZoomed => !currentZoomed)} text="Zoom" />
    </div>
     <ResponsiveContainer isAnimationActive={false}>
        <AreaChart 
            data={graph.graph.map((value, i) => {
                return {
                    name: value.start.timestamp,
                    'Bot impact': value.data,
                }
            })}
            margin={{left: 15, top: 20 }}
            syncId="mediaSpend"
        >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1.1">
                    <stop offset="10%" stopColor="#ff348d" stopOpacity={1} />
                    <stop offset="90%" stopColor="#ffd001" stopOpacity={1} />
                </linearGradient>
            </defs>
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis 
                dataKey="name" 
                axisLine={false} 
                tickLine={false}
                interval={0}
                preserveStart
                tick={<CusomtisedXAxisTick />}
                height={50}
            />
            <YAxis 
                orientation="right"
                domain={[0, zoomed ? zoomedPercentMax : 100]}
                axisLine={false} 
                tickLine={false} 
                tick={<CusomtisedYAxisTick />}
                
                verticalAnchor="start" 
            />
            <Tooltip 
                content={<CusomtisedToolTip/>}
                
                position={{ x: 0, y: 0 }} //no position set will follow the cursor
                cursor={<CustomCursor />}
            />

            <Area  
                dot={false} 
                ref={botGraph}
                activeDot={{ stroke: colour, fill: 'white' }} 
                fill="url(#colorUv)" 
                type="monotone" 
                stroke={colour} 
                strokeWidth={2}
                dataKey="Bot impact" 
                onMouseOver={(data) => {
                    setPointCursor(data)
                }}
                isAnimationActive={false}
            />
        </AreaChart>
    </ResponsiveContainer>
    </div>
}

const mapStateToProps = state => {
	return {
		website: state.websites.selectedWebsite,
        currency: state.websites.selectedWebsite.__meta
	}
}

// withWidgetContainer
export default connect(mapStateToProps, null)((BotImpactGraph))
