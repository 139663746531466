import {BeaconRequest} from '../BeaconRequest';
import React from 'react'

export const createResourceFromQueries = (resource, noCache,) => {
  return Promise.all(
      Object.values(resource)
        .map(query =>  {
          return noCache ? BeaconRequest.getNoChache(...query) : BeaconRequest.get(...query)
        })
      )
      .then((res) => {
          const obj = {}, keys = Object.keys(resource)
          res.forEach((result, i) => {
              obj[keys[i]] = result
          })
          return obj
      })
        
}


export const usePrevious = (value) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
