import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setOverlayRoot, setSelectedTab } from '../../actions/ui';
import MultiLinkCreator from '../link/MultiLinkCreator'
import { withRouter } from "react-router";
import PageSingleContainer from '../../containers/page/PageSingleContainer';
import VisitSingleContainer from '../../containers/visit/VisitSingleContainer';

class Overlay extends Component {
	constructor(){
		super();
		this.state = {
			leaving: false,
			leave: false,
			breadcrumb: null,
		}
		this.handleClose = this.handleClose.bind(this);
		this.renderContent = this.renderContent.bind(this);
		this.handleBack = this.handleBack.bind(this)
		this.close = this.close.bind(this)
	}
	
	componentDidMount(){
		// If the state is not undefined AND it has a referrer, but the referrer given in state is NOT the same as the referrer given in props
		// Set the overlay root as the referring state value
		// An example of this can be found in CampaignsList.js, noting the state: { referrer: val }
		// <Link to={{pathname: "/campaigns/" + (campaign.campaign_id), state: { modal: true, referrer: window.location.pathname } }} key={campaign.campaign_id}>
		if (this.props.selectedTab != null) {
			this.props.setSelectedTab(this.props.selectedTab)
		}
		else if( (this.props.location.state !== undefined) && 
		(this.props.location.state.referrer) && 
		(this.props.referrer !== this.props.location.state.referrer) ){
			this.props.setOverlayRoot(this.props.location.state.referrer)
		} else if ((this.props.location.state !== undefined) && (this.props.location.state.tab_id !== undefined)){
			// If a selected tab is passed in
			this.props.setSelectedTab(this.props.location.state.tab_id)
		} else {
			// If there is no root set, this means we are navigating through overlays
			// Therefore, we want to set the tab back to zero so it doesn't remember
			// the last selected tab on mounting of a new overlay
			this.props.setSelectedTab(0)
		}

		// Add a listener for esc key
		document.addEventListener("keydown", this.handleEscKey.bind(this));

		// Remove scroll from body when open
		// document.body.style.overflow = "hidden"; //grrrrrrr!
	}

	componentWillUnmount(){
		// Re-add scrollbar on close
		// document.body.style.overflow = "auto";
	}

	handleEscKey(e){
		if(e.keyCode === 27){
			this.handleClose()
		}
	}

	// Because this component is held within a <Route> we cannot use transitionLeave as it would just disappear without applying the animation
	// To get around this, set the state as leaving = true, to tell the component is exiting
	// Then, after 200 milliseconds go back to the referring page
	handleClose(){
		this.setState({ leaving: true })

		// if (this.props.for !== 'multiLinkCreator') { //confirm close, should be a feature in the future
		// 	this.close()
		// }
	}

	close() {
		if(window.history.state !== null){
			setTimeout(() => {
				this.setState({
					redirect: true
				})
			}, 200)
		} else {
			window.location.replace("/");
		}

		// Set the selected tab state back to 0 when this overlay is closed
		this.props.setSelectedTab(0)

		this.setState({leave: true})
	}


	handleBack(){
		this.setState({ leaving: true })
		// setTimeout(() => {
		// 	// window.history.back();
		// 	this.props.history.goBack()
		// }, 200)
	}

	// Based on the given props, decide which component to render
	// Then call this function in the render method

	renderContent(){
		const setBreadCrumb = (content) => {this.setState({breadcrumb: content})}
		switch(this.props.for){
			case "page": 
				return <PageSingleContainer page_id={this.props.match.params.id} setBreadCrumb={setBreadCrumb} />
			case "visit": 
				return <VisitSingleContainer visit_id={this.props.match.params.id} setBreadCrumb={setBreadCrumb}  />
			case "multiLinkCreator": 
				return <MultiLinkCreator 
							isClosing={this.state.leaving} 
							close={() => {
								this.setState({ leaving: true })
								{this.props.close && this.props.close()}
							}}
							cancel={() => {
								this.setState({leaving: true})
								{this.props.cancel && this.props.cancel()}
							}}
						/>
			default:
				return null
		}
	}

	render() {
		// If a referrer is set, redirect back to the referring page
		if(this.state.redirect){
			return <Redirect to={this.props.referrer} />
		}
		return (<>
			{this.props.for == "multiLinkCreator" ? 
				<div className="overlay">
						
					<div className={"overlay__mask "  + ( this.state.leave ? "leave" : "" ) }  onClick={this.handleClose}></div>

					<div className={"overlay__content printarea "  + ( this.state.leave ? "leave" : "" ) } 
						style={{ width: this.props.for === 'multiLinkCreator' && "500px" }}>
						<div className="scroll__view">
							<div style={{
								display: 'grid', gridTemplateColumns: 'auto max-content', top: 0, backgroundColor: 'white', zIndex: 5, position: 'sticky', padding: 5, borderBottom: '1px solid #E6EBED',
							}}>
								<h1 className="overlay__title">{this.state.breadcrumb}</h1>
								<ul className="overlay__tools">
									<li style={this.props.for === 'multiLinkCreator' ? {display: 'none'} : {}}>
										<button 
										type="button" 
										className="button button--light-grey" 
										onClick={this.handleBack}>
										<i className="fas fa-arrow-left"></i>
										</button>
									</li>
									<li>
										<button 
										type="button" 
										className="button button--light-grey" 
										onClick={this.handleClose}>
										<i className="fas fa-times"></i>
										</button>
									</li>
								</ul>
							</div>
							<div className="container">
									<div className="overlay" style={{padding: 10}}>
									<div className="scroll__view"> 
										<div className="overlay__header">
											<h1 className="overlay__title">{this.state.breadcrumb}</h1>
											<ul className="overlay__tools"></ul>
										</div>
										{this.renderContent()}
									</div> 
								</div>
							</div>
						</div>
					</div>
				</div>
				:
				<div className="overlay" style={{paddingTop: '40px'}}>
					{this.renderContent()}
				</div>
			}
			
		</>)		
	}
}

const mapStateToProps = state => {
	return {
		referrer: state.ui.overlayRoot
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setOverlayRoot: (referrer) => dispatch(setOverlayRoot(referrer)),
		setSelectedTab: (tabId) => dispatch(setSelectedTab(tabId))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Overlay));