import React, { Component } from 'react'
import { connect } from 'react-redux';
import { fetchLeadsByCampaign, fetchLeadsByChannel, fetchLeadsByLink, fetchLeadsByWebsite } from '../../actions/leads'
// import StarRating from '../general/StarRating'
import LoaderAlt from '../general/LoaderAlt'
import { Link } from 'react-router-dom'
import ToolTip from '../general/ToolTip'
import moment from 'moment-timezone'
import { withLazyListLoaderHOC } from '../../hooks/withLazyListLoaderHOC';
import BeaconRequest from '../../BeaconRequest'
import { redirectRouter } from '../../organisms/WidgetContainer/DropdownContainer';
var _ = require('lodash');

class LeadsList extends Component {
    state = {
        isFetching: true
    }

    componentDidMount(){
        BeaconRequest.get(`${this.props.for}/${this.props.id}/leads/${this.props.date}`, {})
        .then(responseObj => {
            this.props.lazyLoader.setInitialData({
                data: responseObj.response, 
                next: responseObj.paging.next
            })

            this.setState({
                isFetching: false
            })
        })
    }

    render(){
        if(this.state.isFetching){
            return <LoaderAlt />
        }

        const {paging, onNext} = this.props.lazyLoader;
        
        const isFetchingNext = paging.isFetchingNext;
        const hasLoadedAll = paging.next == null;

        const display_criteria = (goalsMet) => {
             switch (goalsMet.definition) {
                 case "url_match":
                 case "external_link":
                     return goalsMet.data.url
                 case "form_submission":
                     return "Completed form: " + goalsMet.data.form_id
                 case "identified_visitor":
                 default:
                     let fullname, email
                     if (goalsMet.data.visitor.__identities != null) {
                         fullname = goalsMet.data.visitor.__identities[0] != null ? goalsMet.data.visitor.__identities[0].fullname : "Unknown"
                         email =  goalsMet.data.visitor.__identities[0] != null ? goalsMet.data.visitor.__identities[0].email : "Unknown"
                     } else {
                         fullname = "Unknown";
                         email = "Unknown";
                     }
                     return `Identified visitor:   ${fullname}   ${email}`
                 
             }
         }

        const visits = paging.data.map(visit => {
            const { added, visit_id } = visit
            const { colour, title, channel_id } = visit.__channel
            const { notes, shortCode, originalUrl } = visit.__link
            
            return (
                <div 
                    className="table__row table__row--link"
                    onClick={e =>  {e.stopPropagation();this.props.history.push('/visits/' + visit_id)}}
                >
                    <div className="table__cell">
                        {display_criteria(visit.__goalsMet[0])}
                    </div>
                    <div 
                        className="table__cell"  
                        onClick={e => {
                            e.stopPropagation(); 
                            this.props.redirect('campaign', visit.__campaign.campaign_id, null, '/', 'analytics'); 
                            this.props.callback && this.props.callback();
                        }} 
                        style={{textDecoration: 'underline'}}
                    >
                        <span className="key-dot" style={{backgroundColor:visit.__campaign.colour}}></span>
                        {visit.__campaign.title}
                    </div>
                    <div 
                        className="table__cell" 
                        onClick={e => {
                            e.stopPropagation(); 
                            this.props.redirect('channel', channel_id, null, '/', 'analytics'); 
                            this.props.callback && this.props.callback();
                        }} 
                        style={{textDecoration: 'underline'}}
                    >
                        <span className="key-dot" style={{backgroundColor:colour}}></span>
                        {title}
                    </div>
                    <div className="table__cell">
                        <ToolTip 
                        hint={true}
                        text={"https://bcn.to/"+shortCode}
                        tip={notes}
                        />
                    </div>
                    <div className="table__cell">
                        {moment(added).format('DD MMM YYYY, h:mma')}
                    </div>
                </div>
            )
        })

        if(_.isEmpty(visits)){
            return null
        }
        return (<>
            <div className="table margin-medium">
                <div className="table__row">
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Criteria
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Campaign
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Channel
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Link
                    </div>
                    <div className="table__heading is-fixed" style={{top:-20}}>
                        Recorded
                    </div>
                </div>
                {visits}
            </div>
            <div style={{textAlign: "center"}}>
                {!hasLoadedAll && 
                    <>
                        {isFetchingNext ? (
                            <LoaderAlt />
                        )  : (
                            <button className="button button--no-border button--green" style={{marginTop: "10px"}} onClick={() => onNext()}>
                                Load More
                            </button>
                        )}
                    </>
                }
            </div>
        </>)
    }
}

const mapDispatchToProps = dispatch => {
	return {
        fetchLeadsByCampaign: (campaign_id, start, end) => dispatch(fetchLeadsByCampaign(campaign_id, start, end)),
        fetchLeadsByChannel: (channel_id) => dispatch(fetchLeadsByChannel(channel_id)),
        fetchLeadsByLink: (link_id) => dispatch(fetchLeadsByLink(link_id)),
        fetchLeadsByWebsite: (website_id) => dispatch(fetchLeadsByWebsite(website_id)),
	}
}

export default connect(null, mapDispatchToProps)(redirectRouter(withLazyListLoaderHOC(LeadsList)));