import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from "@material-ui/core/styles";


const styles = {
	tooltip: {
		color: '#fff',
		fontFamily: "'Lato', sans-serif",
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '21px',
		borderRadius: '5px'
	},
  };

class ToolTip extends Component {
	constructContent(){
		return (
			<span>
				{this.props.text}
				{ this.props.tip && <span className={"tooltip__tip " + this.props.position }>{this.props.tip}</span> }
			</span>
		)
	}
	
	constructToolTip(){
		if(this.props.path){
			return (
				<a 
				href={this.props.path} 
				target={this.props.target} a
				className="tooltip__text" title={"Visit " + this.props.path } 
				aria-label={this.props.tip}>
					{this.constructContent()}
				</a>
			)
		} else {
			return (
				
				<span className={"tooltip__text " + (this.props.hint && " tooltip__text--has-hint ") + (this.props.hasPointer && " tooltip__text--has-pointer ")}  aria-label={this.props.tip}>
						{this.constructContent()} 
				</span>
			)
		}
	}

	

	render() {
		
		return <Tooltip 
				title={this.props.tip} arrow placement="top" interactive
				classes={{
					tooltip: this.props.classes.tooltip
				}}
		>
				<span  className={(this.props.hint && " tooltip__text--has-hint ") + (this.props.hasPointer && " tooltip__text--has-pointer ") + ""}   style={{display: 'inline-block'}}>{this.props.text}</span>
		</Tooltip>
		
		// <span className="tooltip" style={this.props.onClick != null ? {cursor: 'pointer', display: 'inline-block'} : {display: 'inline-block'}} onClick={e => {if (this.props.onClick != null) this.props.onClick(e)}}>
		// 	{this.constructToolTip()}
			
		// </span>
	}
}

ToolTip.defaultProps = {
	position: 'top'
};

export default withStyles(styles)(ToolTip);
