import { 
	FETCH_CUSTOMERS,
	SET_FIRST_LOGIN,
	SET_SELECTED_CUSTOMER
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_CUSTOMERS:
			return {
				...state,
				allCustomers: action.allCustomers,
				hasCompletedOnboarding: action.hasCompletedOnboarding
			}
		case SET_SELECTED_CUSTOMER: 
			return {
				...state,
				selectedCustomer: action.customer
			}
		case SET_FIRST_LOGIN:
			return {
				...state,
				hasCompletedOnboarding: action.flag
			}
		default:
			return state;
	}
}