import BeaconRequest from '../BeaconRequest';
import moment from 'moment-timezone'
import { addNotification } from './ui'

import {
	FETCH_DEFAULT_CHANNELS_REQUEST,
	FETCH_DEFAULT_CHANNELS_SUCCESS,
	FETCH_CAMPAIGN_CHANNELS_REQUEST,
	FETCH_CAMPAIGN_CHANNELS_SUCCESS,
	SET_SELECTED_CHANNEL,
	UNSET_SELECTED_CHANNEL,
	CLEAR_CAMPAIGN_CHANNELS,
	FETCH_SINGLE_CHANNEL_REQUEST,
	FETCH_SINGLE_CHANNEL_SUCCESS
} from './actionTypes';

export const fetchDefaultChannels = () => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_DEFAULT_CHANNELS_REQUEST
		})
		return BeaconRequest.get('channel/default')
		.then(responseObj => {
			dispatch({
				type: FETCH_DEFAULT_CHANNELS_SUCCESS,
				defaultChannels: responseObj.response
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchCampaignChannels = (campaignId, timeperiod) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_CAMPAIGN_CHANNELS_REQUEST
		})
		
		let query = timeperiod != null ? 'campaign/'+campaignId+'/channels/dashboard/' + timeperiod : 'campaign/'+campaignId+'/channels/active'
		
		return BeaconRequest.getNoChache(query) //for updated list of channels
		.then(responseObj => {
			dispatch({
				type: FETCH_CAMPAIGN_CHANNELS_SUCCESS,
				campaignChannels: timeperiod != null ? responseObj.response.__channels : responseObj.response
			})
			return timeperiod != null ? responseObj.response.__channels : responseObj.response
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchBotsByChannel = (channelId) => {
	return (dispatch, getState) => {
		const query = 'channel/'+ channelId+'/bots/allTime'
		return BeaconRequest.get(query, {})
		.then(responseObj => {
			return responseObj
		})
	}
}

export const fetchSingleChannel = (channelId, startDate, endDate) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_SINGLE_CHANNEL_REQUEST,
		})
		const lifecycle_query = 'channel/' + channelId

		return BeaconRequest.get(lifecycle_query, {"__onlyCore": 1})
		.then(responseObj => {
			const channel = responseObj.response
			const start = BeaconRequest.startof_day(channel.__lifecycle.first.timestamp)
			let end = BeaconRequest.endof_day(channel.__lifecycle.last.timestamp)

			if (end == moment().endOf('day').unix() && moment.unix(channel.__lifecycle.first.timestamp).isBefore(moment().startOf('day')) ) {
				end = moment().subtract(1, 'd').endOf('day').unix()
			}

			return BeaconRequest.get(`channel/${channelId}/analytics/${start}:${end}/graph`)
			.then(res => {
				dispatch({
					type: FETCH_SINGLE_CHANNEL_SUCCESS,
					responseObj: res
				})
				return res
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const setSelectedChannel = (channelId) => {
	return (dispatch, getState) => {
		return BeaconRequest.get('channel/' + channelId)
		.then(responseObj => {
			dispatch({
				type: SET_SELECTED_CHANNEL,
				payload: responseObj.response
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const addChannels = (channels, campaignId) => {
	return (dispatch, getState) => {
		return BeaconRequest.post(
			'campaign/' + campaignId + '/channels', {}, channels
		)
		.then(responseObj => {
			const payload = {
				title: "Channel(s) added successfully",
				theme: "positive"
			}
			dispatch(addNotification(payload))
			dispatch(fetchCampaignChannels(campaignId))
			return responseObj
		})
		.catch(error => { return error })
	}
}

export const updateChannel = (data) => {
	return (dispatch, getState) => {
		let icon = data.channelIcon
		if(!data.channelIcon){
			icon = ""
		}
		return BeaconRequest.post(
			'channel/' + data.channelId, {},
			{
				title: data.channelName,
				// spend: parseInt(data.channelSpend, 10),
				icon: icon,
				colour:data.channelColour,
				// reportedClicks: parseInt(data.reportedClicks, 10)
				adChannels:data.adChannels,
			}
		)
		.then(responseObj => {
			const payload = {
				title: "Channel has been updated",
				theme: "positive"
			}
			dispatch(addNotification(payload))

			return responseObj
		})
		.catch(error => { return error })
	}
}

export const deleteChannel = (channel_id) => {
	return (dispatch, getState) => {
		return BeaconRequest.delete(
			'channel/' + channel_id, {}
		)
		.then(responseObj => {
			const payload = {
				title: "Channel has been deleted",
				theme: "positive"
			}
			dispatch(addNotification(payload))
		})
		.catch(
			error => { 
				if(error.code === 101){
					const payload = {
						title: "Cannot delete a channel that has links 😔",
						theme: "negative"
					}
					dispatch(addNotification(payload))
				}
			}
		)
	}
}

export const unsetSelectedChannel = () => {
	return {
		type: UNSET_SELECTED_CHANNEL
	}
}

export const clearCampaignChannels = () => {
	return {
		type: CLEAR_CAMPAIGN_CHANNELS
	}
}
