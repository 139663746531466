import React, { Component } from 'react';
import * as colours from '../../colours';
import { BarChart, Bar, ResponsiveContainer, Legend, Tooltip, YAxis, XAxis,Cell ,CartesianGrid} from 'recharts';
import BeaconRequest from '../../BeaconRequest';
import moment from 'moment-timezone'
import LoaderAlt from '../general/LoaderAlt';
import VisitsListLite from '../visit/VisitsListLite';
var _ = require('lodash');


class SingleTrafficGraph extends Component {
	constructor(){
		super()
		this.state = {
      		data: [],
			isFetching: true,
			contributingVisits: [],
			type: '',
			campaignKeys: []
		}
		this.handleClick = this.handleClick.bind(this)
		this.handleCloseContributingVisits = this.handleCloseContributingVisits.bind(this)
	}

	handleClick(data){
		if(data){
			let startDate = data.activePayload[0].payload.start.timestamp
			let endDate = data.activePayload[0].payload.end.timestamp

			let query = ''
			if(this.props.type === "campaign") {
				query = 'campaign/' + this.props.data.campaign_id + '/visits/' + startDate +  ":" + endDate
			} else if(this.props.type === "channel"){
				query = 'channel/' + this.props.data.channel_id + '/visits/' + startDate +  ":" + endDate
			} else if(this.props.type === "link"){
				query = 'link/' + this.props.data.link_id + '/visits/' + startDate +  ":" + endDate
			} 
			
			// else if(this.props.type === "page"){
			// 	query = 'page/' + this.props.data.page_id + '/visits/' + startDate +  ":" + endDate
			// }
			if (this.props.type !== "page") {
				this.setState({
					contributingVisits: query,
					contributingVisitsDateTime: data.activeLabel
				})
			}
			
		}
	}

	handleCloseContributingVisits(){
		this.setState({
		  contributingVisits:[]
		})
	  }

	componentDidMount(){
		// Set a blank time period then populate it from props
		let timePeriod = ''
		timePeriod = this.props.date;

		// Check the type of information being passed to this component
		// and determine the correct querying syntax
		let timePeriodQuery = ''

		// Do the same with the identified (i.e. link_id)
		let identifier = ''

		const return_startof_day = (time) => {
			return moment.unix(time).startOf('day').unix()
		}
		
		const return_endof_day = (time) => {
			return moment.unix(time).endOf('day').unix()
		}

		switch(this.props.type){
			case "link":
				timePeriodQuery = return_startof_day(this.props.data.__lifecycle.first.timestamp) + ':' + return_endof_day(this.props.data.__lifecycle.last.timestamp)
				identifier = this.props.data.link_id
				this.setState({type: "Link"})
				break;
			case "campaign":
				timePeriodQuery = return_startof_day(this.props.data.__lifecycle.first.timestamp) + ':' + return_endof_day(this.props.data.__lifecycle.last.timestamp)
				identifier = this.props.data.campaign_id
				this.setState({type: "Campaign"})
				break;
			case "channel":
				timePeriodQuery = return_startof_day(this.props.data.__lifecycle.first.timestamp) + ':' + return_endof_day(this.props.data.__lifecycle.last.timestamp)
				identifier = this.props.data.channel_id
				this.setState({type: "Channel"})
				break;
			case "page":
				timePeriodQuery = return_startof_day(this.props.data.__lifecycle.first.timestamp) + ':' + return_endof_day(this.props.data.__lifecycle.last.timestamp)
				identifier = this.props.data.page_id
				this.setState({type: "Page"})
				break;
			default:
				return
		}

		if (timePeriod) {
			timePeriodQuery = timePeriod
		}

		// Set the date as passed in from state
		this.setState({
			timePeriod: !timePeriod ? timePeriodQuery : timePeriod
		}, () => {
			// /campaign/1820/channel/analytics/1617595200:1618199999/stack?

			const call = (()  => {
				switch (this.props.type) {
					case "page":
						return BeaconRequest.get(`${this.props.type}/${identifier}/graph/${timePeriodQuery}`);
					case "campaign":
						return BeaconRequest.get(`${this.props.type}/${identifier}/channel/analytics/${timePeriodQuery}/stack`);
					default:
						return BeaconRequest.get(`${this.props.type}/${identifier}/analytics/${timePeriodQuery}/graph`);
				}
			})()
			
			
			this.props.type == "page"  
					? BeaconRequest.get(`${this.props.type}/${identifier}/graph/${timePeriodQuery}`) 
					:  BeaconRequest.get(`${this.props.type}/${identifier}/analytics/${timePeriodQuery}/graph`)

			return call
			.then(responseObj => {
				this.setState({
					visitsBeforeFirst: responseObj.response.__lifecycle.first.visitsBeforeFirst,
					visitsAfterLast: responseObj.response.__lifecycle.last.visitsAfterLast
				})
				let collation = responseObj.response.__analyticsTimeLimited.timePeriod.collation
				let timeFormat = ''
				switch(collation){
					case "minute":
						timeFormat = 'h:mma Do MMM YY'
						break;
					case "hour":
						timeFormat = 'ha'
						break;
					case "day":
						timeFormat = 'ddd Do MMM'
						break;
					case "week":
						timeFormat = 'Do MMM'
						break;
					case "month":
						timeFormat = 'MMM YY'
						break;
					default:
						return null
				}
				
				let dataPoints = []
				if(responseObj.response.__analyticsTimeLimited.has_data){
					dataPoints = responseObj.response.__analyticsTimeLimited.graph
				}

				let i = 0;
				for(i = 0; i < dataPoints.length; i++){
					let dateTime = moment.unix(dataPoints[i].start.timestamp).format(timeFormat)
					
					if (this.props.type == "campaign") {
						this.setState({ 
							data: [...this.state.data, { 
								...dataPoints[i].data,
								name: collation === 'week' ? 'w/c ' + dateTime : dateTime, 
								start: dataPoints[i].start,
								end: dataPoints[i].end
							}],
						})
					} else {
						this.setState({ //tf is this adding it 1 by 1
							data: [...this.state.data, { 
								name: collation === 'week' ? 'w/c ' + dateTime : dateTime, // if weekly, add "w/c" to string
								visits: dataPoints[i].core.visits, 
								start: dataPoints[i].start,
								end: dataPoints[i].end
							}],
						})
					}
					
				}
				console.log(responseObj.response.__channels)
				this.setState({
					campaignKeys: responseObj.response.__channels,
					colour: responseObj.response.colour,
					isFetching: false
				})
			})
		
		})
	}


	render() {

		const CustomTooltip = ({ active, payload, label }) => {
			if (active) 
				{
				const title = payload != null && payload.length > 0 && payload[0].payload.total != null ?  `${label} - ${payload[0].payload.total}` : `${label}` 
				return (
					<div className="custom-tooltip" style={{
						boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)'
					}}>
					<p className="label" style={{fontSize: "12px", lineHeight: '15px', fontWeight: "bold"}}>{title}</p>
					{payload != null && payload.slice(0).reverse().map((item, index ) => {
						return <p 
								key={index}
								className="intro" 
								style={{
									"color": item.fill, 
									fontSize: "12px",
									marginTop: "4px",
									fontWeight: "bold",
									lineHeight: '20px'
									}}>
									{`${item.name} : ${item.payload[item.name]}`}
							</p>
					})}
						   
					</div>
				);
				}
		  
			return null;
		};

		const max = Math.max(...this.state.data.map(data => data.visits))
		const bars = () => {
			if (this.props.type == "campaign") {
				return this.state.campaignKeys.map(e => {
					return <Bar
						dataKey={e.title}
						name={e.title}
						fill={e.colour}
						stackId="a"
					>
						{this.state.data.map((entry, index) => {
							return <Cell key={`cell-${index}`}/>
						})}
					</Bar>
				})
			} else {
				return <Bar
					dataKey="visits" 
					fill={this.state.colour || colours.blue}
				>
					{this.state.data.map((entry, index) => {
						return <Cell key={`cell-${index}`}/>
					})}
				</Bar>
			}
		}
        return (
			<div className="stat-group">
				<h2 className="stat-group__title">
						{this.state.type} Traffic
				</h2>
                <div className="stat-group__content" ref="chart">
                    {
                        this.state.isFetching ?
                        <LoaderAlt /> 
                        : 
						<>{(this.state.data != null && this.state.data.length > 0) ?
							<ResponsiveContainer width="100%" height={250}>
								<BarChart 
									data={this.state.data} 
									onClick={this.handleClick}
									cursor="pointer"
									margin={{bottom: 40}}
								>
									<CartesianGrid vertical={false} strokeDasharray="3 3" />
									<Tooltip content={<CustomTooltip />}/>
									{/* <Legend 
									wrapperStyle={{ paddingTop: 60 }}
									/> */}
									{bars()}
									
									<XAxis 
										dataKey="name" 
										axisLine={false} 
										interval={this.state.data.length >=30 ? 1 : 0}
										tickFormatter={(label) =>  `${label.split(',')[0]}`}
										tick={{ angle: -30, fontSize: '13px' }}
										textAnchor="end" 
									/>
									<YAxis 
										allowDecimals={false} 
										axisLine={false} 
									/>
								</BarChart>
							</ResponsiveContainer>
							:
							<div className="message message--notice margin-medium" style={{textAlign: 'left'}}>
								<h2 className="message__title">No activity</h2>
							</div>
						}</>
                        
					}
					
					{
					!_.isEmpty(this.state.contributingVisits)  &&
					<div style={{paddingTop:25}}>
						<h2 className="margin-small text-medium">
						Website visits on <strong>{this.state.contributingVisitsDateTime}</strong>
						<button className="button" onClick={this.handleCloseContributingVisits}>
							<i className="far fa-times-circle"></i>
						</button>
						</h2>
						<VisitsListLite 
						periodVisits={true} 
						query={this.state.contributingVisits}
						fromTop="86px"
						/>
					</div>
					}
					{
						((this.state.visitsBeforeFirst > 0 || this.state.visitsAfterLast > 0) && this.props.type !== "page") &&
							<p style={{textAlign:"left", marginTop:15, fontStyle:"italic"}}>
								Note: there were {this.state.visitsBeforeFirst > 0 ? this.state.visitsBeforeFirst + " visits before your campaign start date" : ""} 
								{(this.state.visitsBeforeFirst > 0 && this.state.visitsAfterLast > 0) ? " and " : " "}
								{this.state.visitsAfterLast > 0 ? this.state.visitsAfterLast + ' visits after your campaign end date. ' : ""} 
								Adjust your campaign start and end dates to view this traffic.
							</p>
					}
                </div>
            </div>
        )
	}
}

export default SingleTrafficGraph
