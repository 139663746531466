import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateWebsite } from '../../actions/websites';
// import MediaUpload from '../general/MediaUpload';
// import WebsiteImage from '../general/WebsiteImage';
import ElementLogo from '../general/ElementLogo';
import HelpContainer from '../../containers/general/HelpContainer'
import { fetchSupportedCurrencies } from '../../actions/websites';
import BeaconRequest from "../../BeaconRequest";
// import TextField from '@material-ui/core/TextField'
import produce from 'immer'

import WebsiteAliases from '../../organisms/WebsiteAliases'

var _ = require('lodash')


class EditWebsite extends Component {
    
    state = {
        websiteId:this.props.website.website_id,
        websiteUrl:this.props.website.__urls.primaryUrl,
        websiteName:this.props.website.name,
        leadsText:this.props.website.__meta.lead_text,
        timezone:this.props.website.__meta.timezone || '',
        enableTimezone: this.props.website.__meta.enable_timezone === "1",


        supportedCurrencies: [],
        websiteCurrency: this.props.website.__meta.currency_code,
        connectingToGoogle: false,
        selectedItem: this.props.website.__meta['default-campaign-goal'] == 'identified_visitor' ? '' :  
            JSON.parse(this.props.website.__meta['default-campaign-goal'] || JSON.stringify([{type: "identified_visitor"}]))[0].type 
                || 'identified_visitor',
        inputLinks: this.props.website.__meta['default-campaign-goal'] == 'identified_visitor' 
                        ? [] 
                        :  JSON.parse(this.props.website.__meta['default-campaign-goal'] 
                            || JSON.stringify([{settings: []}]))[0]
                                .settings.map(setting => {
                                    if (
                                        this.props.website.__meta['default-campaign-goal'] != 'identified_visitor' && 
                                        JSON.parse(this.props.website.__meta['default-campaign-goal'])[0].type == "url_match"
                                    ) {
                                        return setting.slice(1)
                                    } else {
                                        return setting
                                    }
                                }) 
                            || [],
    }
  

    handleUpdateWebsite(){
        if(!this.state.websiteName){
            alert("Website name cannot be empty")
        } else if (this.state.enableTimezone == "1" && this.state.timezone == "") {
            alert("Please select a timezone")
        } else {
            let valid_flag = true;
            let filteredSettingsLinks = this.state.inputLinks != null ? this.state.inputLinks.filter(temp => temp != "" && temp != '/') : []
            
            if (filteredSettingsLinks.length === 0 
                && this.state.selectedItem != 'identified_visitor'
                && this.state.selectedItem != 'shopify_thank_you'
            ) {
                alert('You have selected a lead definition that requires at least one URL')
                valid_flag = false
            } else {
                filteredSettingsLinks = filteredSettingsLinks.map(link => {
                    if (this.state.selectedItem == 'external_link') {
                        if (!(link.startsWith('http://') || link.startsWith('https://'))) {
                            alert(link + ' is not a valid URL, an external URL must be absolute and start with http:// or https://')
                            valid_flag = false
                        } 
                    } 
                    else if (this.state.selectedItem == 'url_match' || this.state.selectedItem === 'preg_match') {
                        let tmp = link
                        if (!(link.startsWith('/'))) {
                            tmp = "/" + link
                        }

                        if (link.endsWith('/')) {
                            tmp = tmp.substring(0, tmp.length - 1);
                        }

                        if (link.startsWith('http://') || link.startsWith('https://')) {
                            alert(link + ' is not a valid URL, an internal URL must be relative and not start with http:// or https://')
                            valid_flag = false
                        } 

                        return tmp
                    }
                    
                    return link
                })
            }

            if (valid_flag) {
                this.props.updateWebsite({...this.state, inputLinks: filteredSettingsLinks})
            }
        }
    }

    handleWebsiteNameChange(e){
        this.setState({
            websiteName: e.target.value
        })
    }

    handleChangeLeadsText(e){
		this.setState({ leadsText: e.target.value, alertOnClose: true  })
	}

	connectToGoogle(){
        this.setState({connectingToGoogle: true});
        BeaconRequestPHP8.get(
            `website/${this.state.websiteId}/connection/google/link`
        ).then(({response}) => {
            window.open(
                response,
                "GoogleConnector",
                "menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=500,height=800,left=100,top=100"
            );
        }, () => {});
    }

	handleGoogleConnection(e){
        if (e.origin === "https://static.thisisbeacon.com") {
            BeaconRequestPHP8.post(
                `website/${this.state.websiteId}/connection/google`,
                {},
                e.data,
            ).then(({response}) => {
                this.setState({connectingToGoogle: false});
            }, () => {});
        }
    }

    componentWillReceiveProps(nextProps){

        this.setState({
            websiteId:nextProps.website.website_id,
            websiteUrl:nextProps.website.__urls.primaryUrl,
            websiteName:nextProps.website.name,

            timezone:nextProps.website.__meta.timezone || '',
            enableTimezone: nextProps.website.__meta.enable_timezone === "1",
        })

        // Because this is a property not a node we need to search for it
        if(_.find(nextProps.website.__meta, function(o) { return o.name === "website_image" })){
            let websiteImage = _.find(nextProps.website.__meta, function(o) { return o.name === "website_image" })
            this.setState({
                websiteImage:websiteImage.value
            })
        }
    }

    componentDidMount() {
        this.props.fetchSupportedCurrencies()
            .then((responseObj) => {
                this.setState({ 
                    supportedCurrencies: responseObj, 
                })
            })
        
        window.addEventListener("message", this.handleGoogleConnection.bind(this), false);
    }

    render() {
        let isAdmin = false;
        if(this.props.customer.__user_role.role_id === 1 || this.props.customer.__user_role.role_id === 2 || this.props.customer.__user_role.role_id === 3){
            isAdmin = true
        }

        const currencies = this.state.supportedCurrencies.map(currency => {
            return (
                <option 
                key={currency.currency_id} 
                value={ currency.code}
                dangerouslySetInnerHTML={ { __html: currency.symbol + " (" + currency.code +")"}  }
                >
                </option>
            )
        })

        return (<div>
            <div className="card margin-large">
                <div className="card__header">
                    <h2 className="card__title">Website</h2>
                </div>
                <div className="card__content">
                    <ul>
                        <div className="form__two-column">
                            <li className="form__item">
                                <label className="form__label">Website URL</label>
                                <input 
                                type="text" 
                                className="form__input" 
                                value={this.state.websiteUrl}
                                disabled />
                            </li>
                            <li className="form__item">
                                <label className="form__label">Website Name</label>
                                <input 
                                type="text" 
                                className="form__input" 
                                value={this.state.websiteName} 
                                onChange={this.handleWebsiteNameChange.bind(this)}
                                />
                            </li>
                        </div>
                        <div className="form__two-column">
                            <li className="form__item">
                                <div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
                                    <label className="form__label">Leads/Other Outcomes name</label>
                                    <div><HelpContainer id={21} /></div>
                                </div>
                                
                                <input type="text" className="form__input" value={this.state.leadsText} onChange={this.handleChangeLeadsText.bind(this)} autoFocus required />
                            </li>
                            <li className="form__item">
                                <div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
                                    <label className="form__label">Website Currency</label>
                                    <div><HelpContainer id={19} /></div>
                                </div>
                                <div className="select-container" style={{width:"100%"}}>
                                    <select className="select" 
                                        onChange={e => this.setState({ websiteCurrency: e.target.value })}
                                        value={this.state.websiteCurrency}
                                    >
                                        {currencies}
                                    </select>
                                </div>
                            </li>
                        </div>
                        <div className="form__two-column">
                            <li className="form__item">
                                <label className="form__label">Logo</label>
                                <ElementLogo isAdmin={isAdmin} for="website" />
                            </li>
                        </div>
                        <div  className="form__one-column">
                            <h2 className="form__title">Default campaign goal</h2>
                                <li style={{ 'marginBottom': '10px'}}>
                                    <h2 className="form__label">Goal Definition <a className="text-small" href="https://go.thisisbeacon.com/guides/setting-campaign-goals/" target="_blank">(Learn more about this including wildcards here)</a></h2>
                                    <div className="select-container" style={{ 'marginBottom': '5px'}}>
                                        <select 
                                            className="select" 
                                            onChange={e => {
                                                this.setState({
                                                    selectedItem: e.target.value,
                                                    inputLinks: [""]
                                                })
                                            }} 
                                        >
                                            <option value="identified_visitor" selected={this.state.selectedItem === 'identified_visitor'}>Identified visitor from form fill</option>
                                            <option value="external_link" selected={this.state.selectedItem === 'external_link'}>Clicking on an external link</option>
                                            <option value="url_match" selected={this.state.selectedItem === 'url_match' || this.state.selectedItem === 'preg_match'}>Visiting an internal page</option>
                                            <option value="shopify_thank_you" selected={this.state.selectedItem === 'shopify_thank_you'}>Shopify: order thank you page</option>
                                        </select> 
                                    </div>
                                    
                                    {(this.state.selectedItem === 'external_link' || this.state.selectedItem === 'url_match'|| this.state.selectedItem==="preg_match") && <>
                                        {this.state.selectedItem === 'external_link' && 
                                            <div className="message message--notice margin-medium">
                                                {/* <h2 className="message__title">Please note</h2> */}
                                                <p className="margin-small">
                                                An external URL must be absolute and start with http:// or https://.
                                                Enter a link in the box below. You must input at least one link.
                                                Additional links can be added via the button.
                                                </p>
                                            </div>
                                        }
                                        {(this.state.selectedItem === 'url_match' || this.state.selectedItem === 'preg_match' ) && 
                                            <div className="margin-small">
                                                <div className="message message--notice margin-medium">
                                                    {/* <h2 className="message__title">Please note</h2> */}
                                                    <p className="margin-small">
                                                    {/* An internal URL must be relative and start with a forward slash (/). */}
                                                    Enter a link in the box below. You must input at least one link.
                                                    Additional links can be added via the button.
                                                    </p>
                                                </div>
                                                <div><b>This already supports wildcard matching.</b></div>
                                                <div>
                                                    
                                                <input
                                                    type="checkbox" 
                                                    name="preg_url_match"
                                                    onChange={e => {
                                                        if (e.target.checked){
                                                            this.setState({selectedItem: 'preg_match'})
                                                        }else {
                                                            this.setState({selectedItem: 'url_match'})
                                                        }
                                                    }}
                                                    checked={this.state.selectedItem === 'preg_match'}
                                                />Check this box to support preg matching in URL.
                                                </div>
                                            </div>
                                        }

                                        {this.state.inputLinks.map((input, index) => {
                                                return <div key={index} style={{'marginTop': '10px'}}>
                                                            { (this.state.selectedItem === 'url_match' || this.state.selectedItem === 'preg_match') && <>
                                                                    <div style={{
                                                                            display: 'inline',
                                                                            backgroundColor: '#e6e6e6',
                                                                            borderRadius: '4px',
                                                                            padding: '6px 6px',
                                                                            border: '1px solid #E6EBED',
                                                                            borderRight: 'none',
                                                                            borderTopRightRadius: '0',
                                                                            borderBottomRightRadius: '0',
                                                                            paddingRight: '0',
                                                                            width: '40%'

                                                                        }}>
                                                                        {this.props.website.__urls.primaryUrl + "/"}
                                                                    </div>
                                                                    <input
                                                                        className="form__input" 
                                                                        onChange={e => {
                                                                            e.persist()
                                                                            this.setState(produce(prev => {prev.inputLinks[index] = e.target.value}))
                                                                        }} 
                                                                        value={input}
                                                                        style={{
                                                                            display: 'inline', 
                                                                            width: '45%',
                                                                            borderLeft: 'none',
                                                                            paddingLeft: '3px',
                                                                            borderTopLeftRadius: '0',
                                                                            borderBottomLeftRadius: '0',
                                                                        }}
                                                                    />
                                                                </>
                                                            }

                                                            { this.state.selectedItem === 'external_link' && <>
                                                                    <input
                                                                        className="form__input" 
                                                                        onChange={e => {
                                                                            e.persist()
                                                                            this.setState(produce(prev => {prev.inputLinks[index] = e.target.value}))
                                                                        }} 
                                                                        value={input}
                                                                        style={{
                                                                            display: 'inline', 
                                                                            width: '85%',
                                                                        }}
                                                                    />
                                                            </>
                                                            }
                                                        <span style={{'display': 'inline-block', 'marginLeft': '5px', width: '10%'}}>
                                                            <button
                                                                className="button button--small button--dark-grey" 
                                                                onClick={(e) => {
                                                                    e.persist()
                                                                    this.setState(produce(prev => {prev.inputLinks.splice(index, 1)}))
                                                                }}
                                                                type="button"
                                                            >
                                                                <i className="fas fa-trash-alt"></i>
                                                            </button>
                                                        </span>
                                                </div>
                                            })
                                        }
                                        
                                        <button 
                                            type="button"
                                            style={{'marginTop': '5px'}} 
                                            className="button button--small button--dark-grey"
                                            onClick={e => {
                                                e.persist()
                                                this.setState(produce(prev => {prev.inputLinks[prev.inputLinks.length] = ""}))
                                            }}
                                        >
                                            Add Additional Link
                                        </button>
                                        
                                    </>}
                                    
                                </li>
                        </div>  
                        
                        {
                            !isAdmin ?
                            <div className="message message--notice">
                                <p>You do not have administration rights over this company so the details here cannot be updated.</p>
                            </div> 
                            :
                            <li className="form__item" style={{marginBottom:0}}>
                                <label className="form__label">&nbsp;</label>
                                <button 
                                className="button button--dark-grey"
                                onClick={this.handleUpdateWebsite.bind(this)}>
                                Update
                                </button>
                            </li>
                        }
                    </ul>
                </div>
               
            </div>
            <WebsiteAliases website={this.props.website}/>
        </div>)
    }
}

const mapDispatchToProps = dispatch => {
	return {
        updateWebsite: (website) => dispatch(updateWebsite(website)),
        fetchSupportedCurrencies: () => dispatch(fetchSupportedCurrencies())
	}
}

export default connect(null, mapDispatchToProps)(EditWebsite);