  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createBotActivityResource = ({timePeriod, filter, cancelToken}) => {

    let graph_query, blocked_bots,actual_clicks, actual_visits
    switch (filter.type) {
        case "website":
        default: 
            graph_query = `website/${filter.payload.id}/campaign/analytics/${timePeriod}/stack`
            blocked_bots = `website/${filter.payload.id}/blocks/${timePeriod}`
            actual_clicks = `website/${filter.payload.id}/graph/clicks/${timePeriod}`
            actual_visits = `website/${filter.payload.id}/graph/visits/${timePeriod}`
            break;
        case "campaign":
            graph_query = `campaign/${filter.payload.id}/channel/analytics/${timePeriod}/stack`
            blocked_bots = `campaign/${filter.payload.id}/blocks/${timePeriod}`
            actual_clicks = `campaign/${filter.payload.id}/graph/clicks/${timePeriod}`
            actual_visits = `campaign/${filter.payload.id}/graph/visits/${timePeriod}`
            break;
        case "channel":
            graph_query = `channel/${filter.payload.id}/analytics/${timePeriod}/graph`
            blocked_bots = `channel/${filter.payload.id}/blocks/${timePeriod}`
            actual_clicks = `channel/${filter.payload.id}/graph/clicks/${timePeriod}`
            actual_visits = `channel/${filter.payload.id}/graph/visits/${timePeriod}`
            break
        case "link":
            graph_query = `link/${filter.payload.id}/analytics/${timePeriod}/graph`
            blocked_bots = `link/${filter.payload.id}/blocks/${timePeriod}`
            actual_clicks = `link/${filter.payload.id}/graph/clicks/${timePeriod}`
            actual_visits = `link/${filter.payload.id}/graph/visits/${timePeriod}`
            break
    }
    
    return {
        graph: [graph_query, null, {cancelToken: cancelToken.token}], 
        blocked_bots: [blocked_bots, null, {cancelToken: cancelToken.token}],
        actual_clicks: [actual_clicks, null, {cancelToken: cancelToken.token}],
        actual_visits: [actual_visits, null, {cancelToken: cancelToken.token}]
    }
}

export const setBotActivityResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_BOT_ACTIVITY_FETCHING,
            fetching: true
        })

        const previousToken = getState().botActivity.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("bot impact graph requests cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_BOT_ACTIVITY_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createBotActivityResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_BOT_ACTIVITY_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_BOT_ACTIVITY_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_BOT_ACTIVITY_FETCHING,
                        fetching: false
                    })
                }
            })
    }
}
