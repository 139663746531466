import { addNotification } from './ui'
import BeaconRequest from '../BeaconRequest';

// Import action types
import {  
	CREATE_SHORT_LINK_SUCCESS,
	FETCH_LINKS_REQUEST,
	FETCH_LINKS_SUCCESS,
	FETCH_SINGLE_LINK_REQUEST,
	FETCH_SINGLE_LINK_SUCCESS,
	SET_SELECTED_LINK,
	UNSET_SELECTED_LINK
} from './actionTypes';
import moment from "moment-timezone";

export const createShortLink = (payload, supress_notification) => {
	return (dispatch, getState) => {
		const websiteId = getState().websites.selectedWebsiteId;
		// Set a default campaign ID
		let campaignId = 0, channelId = 0
		// If there is a campaign in state however, use this
		if(getState().campaigns.selectedCampaignId){
			campaignId = getState().campaigns.selectedCampaignId
		}

		if(getState().channels.selectedChannelId){
			channelId = getState().channels.selectedChannelId
		}

		return BeaconRequest.post(
			'link/', {},
			{
			    "url":payload.link,
			    "website_id":websiteId,
				"campaign_id": payload.selectedCampaignId != null ? payload.selectedCampaignId : campaignId ,
				"channel_id": payload.selectedChannelId != null ?  payload.selectedChannelId : channelId,
				"notes": payload.note
			}
		)
		.then(responseObj => {
			dispatch({
				type: CREATE_SHORT_LINK_SUCCESS,
				payload: responseObj.response // send the complete response to the reducer
			})
			dispatch(fetchLinks())

			if(!responseObj.response.isValidURL){
				const payload = {
					title: "Warning: we've shortened this URL, but we couldn't load it correctly so we cannot guarantee its validity.",
					theme: "negative"
				}
				if (supress_notification != null) {
					supress_notification(payload)
				} else {
					// dispatch(addNotification(payload))
				}
				
			}
			return responseObj;
		})
		.catch(error => {
			let payload = {
				title: `You cannot create Beacon tracked links for that website. 
				The websites you can create Beacon tracked links for are: ${getState().websites.selectedWebsite.__urls.allUrls.join(', ')}.`,
				theme: "negative"
			}
			// if(error.code === 4001){
			// 	payload.title = error.message
			// }

			if (supress_notification != null) {
				supress_notification(payload)
			} else {
				// dispatch(addNotification(payload))
			}
			throw error
		})
	}
}

export const fetchBotsByLink = (linkId) => {
	return (dispatch, getState) => {
		const query = 'link/'+ linkId+'/bots/allTime'
		return BeaconRequest.get(query, {})
		.then(responseObj => {
			return responseObj
		})
	}
}

export const updateLink = (payload) => {

	// Check for unset values and pass them through as zeros
	let campaignId = 0
	let channelId = 0
	if(payload.selectedCampaignId !== undefined){
		campaignId = payload.selectedCampaignId
	}
	if(payload.selectedChannelId !== undefined){
		channelId = payload.selectedChannelId
	}
	return (dispatch) => {
		return BeaconRequest.post(
			'link/' + payload.linkId, {},
			{
				url:payload.url,
				campaign_id:campaignId,
				channel_id:channelId,
				notes:payload.note,
				
			}
		)
		.then(responseObj => {
			dispatch(fetchLinks())
		})
	}
}

export const fetchLinks = (sortBy, direction, filterStars) => {
	
	
	return (dispatch, getState) => {
		if (getState().global.filter == null) return 
		
		dispatch({
			type: FETCH_LINKS_REQUEST,
		})
		// if a campaign is selected use this id otherwise use the website id when looking for links
		let query = ''
		
		let type = 'created';
		if(getState().ui.showLinksOnlyVisits){
			type = "active"
		}
	
		// if(getState().channels.selectedChannelId){
		// 	query = 'link/' + type + '/channel/' + getState().channels.selectedChannelId
		// } else if(getState().campaigns.selectedCampaignId){
		// 	query = 'link/' + type + '/campaign/' + getState().campaigns.selectedCampaignId
		// } else {
		// 	query = 'link/' + type + '/website/' + getState().websites.selectedWebsiteId
		// }
		
		query = `link/${type}/${getState().global.filter.type}/${getState().global.filter.payload.id}`

		// if(getState().dates.selectedDateValue){
		query = query + '/' + getState().dates.selectedDateValue
		// } else {
		// 	query = query + '/allTime'
		// }

		// let options = { order: "score:desc" }
		let options = {}
		// uncomment this when this has been done https://trello.com/c/J9MWiMx1/1304-add-sorting-filtering-to-beacon-links-page
		// if(sortBy && direction){
		// 	options = { ...options, order: sortBy + direction }
		// }

		// if(filterStars){
		// 	options =  { ...options, filterBy: 'star_rating:' + filterStars }
		// }

		return BeaconRequest.get(query, options)
		.then(responseObj => {
			dispatch({
				type: FETCH_LINKS_SUCCESS,
				responseObj
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchLinksByPaging = (link) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_LINKS_REQUEST,
		})
		return BeaconRequest.paging(link)
		.then(responseObj => {
			dispatch({
				type:FETCH_LINKS_SUCCESS,
				responseObj
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchSingleLink = (linkId, startDate, endDate) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_SINGLE_LINK_REQUEST,
		})

		const lifecycle_query = 'link/' + linkId

		return BeaconRequest.get(lifecycle_query, {"__onlyCore": 1})
		.then(responseObj => {
			const link = responseObj.response
			const start = BeaconRequest.startof_day(link.__lifecycle.first.timestamp)
			let end = BeaconRequest.endof_day(link.__lifecycle.last.timestamp)

			if (end == moment().endOf('day').unix() && moment.unix(link.__lifecycle.first.timestamp).isBefore(moment().startOf('day')) ) {
				end = moment().subtract(1, 'd').endOf('day').unix()
			}

			return BeaconRequest.get('link/' + linkId + '/analytics/' + start +  ":" + end + '/graph').then(res => {
				dispatch({
					type:FETCH_SINGLE_LINK_SUCCESS,
					responseObj: res
				})
				return res
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const setSelectedLink = (responseObj) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_SELECTED_LINK,
			payload: responseObj
		})
	}
}

export const unsetSelectedLink = () => {
	return (dispatch, getState) => {
		dispatch({
			type: UNSET_SELECTED_LINK,
		})
	}
}

export const fetchSingleLinkNoChache = (linkId) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_SINGLE_LINK_REQUEST,
		})

		const lifecycle_query = 'link/' + linkId

		return BeaconRequest.getNoChache(lifecycle_query, {"__onlyCore": 1})
		.then(responseObj => {
			const link = responseObj.response
			const start = BeaconRequest.startof_day(link.__lifecycle.first.timestamp)
			let end = BeaconRequest.endof_day(link.__lifecycle.last.timestamp)
			
			if (end == moment().endOf('day').unix() && moment.unix(link.__lifecycle.first.timestamp).isBefore(moment().startOf('day'))) {
				end = moment().subtract(1, 'd').endOf('day').unix()
			}

			return BeaconRequest.getNoChache('link/' + linkId + '/analytics/' + start +  ":" + end + '/graph').then(res => {
				dispatch({
					type:FETCH_SINGLE_LINK_SUCCESS,
					responseObj: res
				})
				return res
			})
		})
		.catch(error => { console.log(error) })
	}
}

const fetchLinksByModel = (model) => (id, startDate, endDate) => {
	return (dispatch, getState) => {
		let query = '';
		let type = 'created';
		const linkView = getState().ui.campaignLinkView;

		if(linkView === 0){
			type = "active"
		}
		if(startDate === 0 || startDate === null || linkView === 2) {
			startDate = 1
		}
		if(endDate === 0 || endDate === null || linkView === 2) {
			endDate = moment().unix()
		}

		query = `link/${type}/${model}/${id}/${startDate}:${endDate}`

		return BeaconRequest.get(query)
			.then(responseObj => {
				return responseObj
			})
			.catch(error => { console.log(error) })
	}
}

export const fetchLinksByCampaign = fetchLinksByModel('campaign')

export const fetchLinksByChannel = fetchLinksByModel('channel')
