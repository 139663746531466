import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createShortLink } from '../../actions/links';
import EditLinkContainer from '../../containers/forms/EditLinkContainer';
import Modal from '../../components/general/Modal';

import { fetchSingleLink, updateLink } from '../../actions/links'
import { addNotification } from '../../actions/ui';
import { fetchCampaignChannels } from '../../actions/channels';
import EditLink from '../../components/forms/EditLink';
import ToolTip from '../../components/general/ToolTip'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DocumentTitle from 'react-document-title';

import { fetchActiveCampaigns } from '../../actions/campaigns';
import CreateCampaignContainer from '../../containers/forms/CreateCampaignContainer';
import AddCampaignChannelsContainer from '../../containers/forms/AddCampaignChannelsContainer'
import DropdownList from '../../components/general/DropdownList'
import ActiveCampaignsDropdown from '../campaign/ActiveCampaignsDropdown';
import CampaignChannelsDropdown from '../campaign/CampaignChannelsDropdown';
import BeaconRequest from '../../BeaconRequest';

var _ = require('lodash')

class CreateShortLink extends Component { 
	constructor(){
		super();
		this.state = {
			link: '',
			isEditing: false,
			isCreating: false,
			modalState: 'creating', //creating, created 
			isAddingCampaign: false,
			isAddingChannel: false,

			linkId: 0,
			url:'',
			newTag: '',
			tags: [],
			selectedCampaignId: undefined,
			selectedCampaignName: undefined,
			note: '',
			selectedChannelId: undefined,
			selectedChannelName: undefined,
			formHint: false,
			disableChannelSelect: false,

			
		}
		this.handleLinkInput = this.handleLinkInput.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.toggleIsEditing = this.toggleIsEditing.bind(this)


		// this.handleCloseModal = this.handleCloseModal.bind(this)
		// this.handleEditTags = this.handleEditTags.bind(this)
		// this.handleUpdateLink = this.handleUpdateLink.bind(this)
		// this.handleAddTag = this.handleAddTag.bind(this)
		this.setSelectedCampaign = this.setSelectedCampaign.bind(this)
		this.clearSelectedCampaign = this.clearSelectedCampaign.bind(this)
		this.handleNoteChange = this.handleNoteChange.bind(this)
		// this.handleRemoveTag = this.handleRemoveTag.bind(this)
		this.setSelectedChannel = this.setSelectedChannel.bind(this)
		this.clearSelectedChannel = this.clearSelectedChannel.bind(this)
		this.copyToClipboard = this.copyToClipboard.bind(this)
		this.toggleCreateCampaign = this.toggleCreateCampaign.bind(this);
		this.toggleCreateChannel = this.toggleCreateChannel.bind(this);
	}


	handleNoteChange(e){
		this.setState({
			note: e.target.value
		})
	}

	componentDidMount(){
		if(this.props.first){
			this.setState({ link: this.props.selectedWebsite })
		}
		const callCampaign = (id) => {
			BeaconRequest.get('campaign/'+ id, {__onlyCore: 1})
				.then(responseObj => {
					this.setState({
						selectedCampaignId: responseObj.response.campaign_id,
						selectedCampaignName: responseObj.response.title
					})
				})
				.catch(error => { console.log(error) })
		}

		if (this.props.filter.type == "campaign") {
			callCampaign(this.props.filter.payload.id)
			this.props.fetchCampaignChannels(this.props.filter.payload.id)
		}
		if (this.props.filter.type == "channel") {
			BeaconRequest.get('channel/'+ this.props.filter.payload.id, {__onlyCore: 1})
				.then(responseObj => {
					this.setState({
						selectedChannelId: responseObj.response.channel_id,
						selectedChannelName: responseObj.response.title
					})
					this.props.fetchCampaignChannels(responseObj.response.campaign_id)
					callCampaign(responseObj.response.campaign_id)
				})
				.catch(error => { console.log(error) })
		}
	}

	handleLinkInput(e){
		this.setState({ link: e.target.value })
	}

	copyToClipboard(e){
    	e.preventDefault();
		const payload = {
			title:"Copied to clipboard",
			theme:"positive"
		}
		this.props.addNotification(payload)
	}

	toggleCreateCampaign(){
    	this.setState({ 
    		isAddingCampaign: !this.state.isAddingCampaign
    	})
	}
	
	toggleCreateChannel(channel){
    	this.setState({ 
    		isAddingChannel: !this.state.isAddingChannel
		}, 
		// () => {
		// 	this.props.setNewChannel(channel)
		
		// }
		)
    }


	handleSubmit(e){
		e.preventDefault(); // prevent form submission

		this.setState({ create_error: null })

		// create the shortlink then reset the form and close the modal
		// then show the created link

		// TODO: move to on shorten
		// if(this.state.link.includes("goo.gl") || this.state.link.includes("bit.ly") || this.state.link.includes("owl.ly") || this.state.link.includes("ow.ly") || this.state.link.includes("owl.li") || this.state.link.includes("bcn.to") || this.state.link.includes("buff.ly")){
		// 	alert("It looks like you're trying to use a link that's already been shortened. This affects our tracking code, so please use the original URL instead.")
		// 	// this.setState({
		// 	// 	isCreating: false
		// 	// })
		// } 
		
		if (this.state.selectedCampaignId == null || this.state.selectedChannelId == null) {
			this.setState({create_error: {title: "A campaign and channel must be selected before you can create your link."}})
		}
		else if(this.state.link == "") {
			this.setState({create_error: {title: "Please enter a link"}})
		}
		else if (this.state.link != "" && (this.state.link.includes("goo.gl") || this.state.link.includes("bit.ly") || this.state.link.includes("owl.ly") || this.state.link.includes("ow.ly") || this.state.link.includes("owl.li") || this.state.link.includes("bcn.to") || this.state.link.includes("buff.ly"))) {
			this.setState({create_error: {title: "It looks like you're trying to use a link that's already been shortened. This affects our tracking code, so please use the original URL instead."}})
		}
		else {
			// this.setState({
			// 	link: '',
				
			// 	// We updated the method to return an array to support multi-link generation
			// 	// Because only one link is returned in this instance, step right into the first
			// 	// element of the array [0]
			// 	// newLink: responseObj.response.links[0],
			// 	isEditing: true,
			// 	// isCreating: false
			// })

			this.props.createShortLink(this.state, (e) => {this.setState({create_error: e})})
			.then(responseObj => {
				this.setState({
					link: "",
					
					// We updated the method to return an array to support multi-link generation
					// Because only one link is returned in this instance, step right into the first
					// element of the array [0]
					newLink: responseObj.response.links[0],
					modalState: 'created',
					// isEditing: true,
					// isCreating: false
				})
			})
			.catch(error => {
				console.log(error)
				// this.setState({
				// 	isCreating: false
				// })
			})
		}

		
	}

	toggleIsEditing(state){
    	this.setState({
    		isEditing: !this.state.isEditing
    	})
	}

	setSelectedCampaign(campaign){
	   this.setState({
		   selectedCampaignId: campaign.id,
		   selectedCampaignName: campaign.title,
	   }, () => {
		   this.clearSelectedChannel()
	   })
	   this.props.fetchCampaignChannels(campaign.id).then(response => {
		   if (response.length == 1) {
				this.setSelectedChannel({id: response[0].channel_id, title: response[0].title})
		   }
	   })
	   this.setState({
		   disableChannelSelect: false
	   })
   }

   clearSelectedCampaign(){
	   this.setState({
		   selectedCampaignId: undefined,
		   selectedCampaignName: undefined,
	   }, () => {
		   this.clearSelectedChannel()
		   this.setState({
			   disableChannelSelect: true
		   })
	   })
   }

   setSelectedChannel(channel){
	   this.setState({
		   selectedChannelId: channel.id,
		   selectedChannelName: channel.title
	   })
   }

   clearSelectedChannel(){
	   this.setState({
		   selectedChannelId: undefined,
		   selectedChannelName: undefined
	   })
   }


	render() {
		if(this.props.selectedCustomer.__user_role.role_id === 4){
			return null
		}
		return (
			<React.Fragment>
				
					<Modal
						title={"New: Beacon Short Link"}
						saveLabel={this.state.modalState === 'creating' ? 'Create' : ''}
						closeAction={e => {this.setState({isEditing: false, modalState: 'creating'}); this.props.close()}}
						// disableClose={true}
						disableSave={this.state.modalState == 'created'}
						saveAction={e => {
							if (this.state.modalState === 'creating') {
								
								this.handleSubmit(e)
							} 
						}}
						
						// alertOnClose={this.state.alertOnClose}
					>
						{this.state.modalState == 'creating' ?
							<ul>
								
								
								<li className="form__item">
									<label className="form__label">Link</label>
									<input 
										className="form__input" 
										type="text"
										value={this.state.link} 
										onChange={this.handleLinkInput} 
									/>
								</li>
								<div className="form__two-column">
									<li className="form__item">
										<label className="form__label">Campaign</label>
										<ActiveCampaignsDropdown 
											campaigns={this.props.activeCampaigns} 
											selectedCampaignName={this.state.selectedCampaignName} 
											selectedCampaignId={this.state.selectedCampaignId}
											handleSelectedItem={this.setSelectedCampaign}
											isClearable={false}
											clearSelectedCampaign={this.clearSelectedCampaign}
											addCampaign={this.toggleCreateCampaign}
										/>
				
										{ this.state.isAddingCampaign &&
											<CreateCampaignContainer 
												callback={e => {
													this.props.fetchActiveCampaigns()
													this.setSelectedCampaign(e)
												}} 
												handleOpen={this.toggleCreateCampaign} 
												channelsCallback={(e, id) => {
													this.props.fetchCampaignChannels(id)
													this.setSelectedChannel({id: e.channel_id, title: e.title})
												}}
											/> 
										}
									</li>
									<li className="form__item">
										<label className="form__label">Channel</label>
										{
											!this.state.selectedCampaignName ? <span>Select a campaign</span>
											:
											!_.isEmpty(this.props.campaignChannels) ?
											<CampaignChannelsDropdown
												channels={this.props.campaignChannels}
												selectedChannelName={this.state.selectedChannelName} 
												selectedChannelId={this.state.selectedChannelId}
												handleSelectedItem={this.setSelectedChannel}
												isClearable={false}
												clearSelectedChannel={this.clearSelectedChannel}
												isDisabled={this.props.disableChannelSelect}
												addChannel={this.toggleCreateChannel}
											/>
											:
											<button 
												className="button--dark-grey button"
												onClick={this.toggleCreateChannel}>
												Add a Channel
											</button>
										}
				
										{ this.state.isAddingChannel && 
											<AddCampaignChannelsContainer 
												campaignId={this.state.selectedCampaignId} 
												handleOpen={this.toggleCreateChannel}
												callback={e => {
													this.props.fetchCampaignChannels(this.state.selectedCampaignId)
													this.setSelectedChannel({id: e.channel_id, title: e.title})
												}}
											/> 
										}
									</li>
								</div>
								<li className="form__item">
									<label className="form__label">Description</label>
									<textarea className="form__input" value={this.state.note} onChange={this.handleNoteChange} />
								</li>

								{this.state.create_error != null &&
									<li className="form__item">
										<div className="message message--warning margin-large">
											<p class="margin-small">{this.state.create_error.title}</p>
										</div>
									</li>
								}
							</ul>
						:
							<div className="shortlink-new message message--notice margin-large">
								<DocumentTitle title="Veracity Link Generated" />
								<h2 className="shortlink-new__title margin-small">Veracity Link Generated</h2>
								<p className="shortlink-new__original margin-large"><a rel="noopener noreferrer" href={this.state.newLink.originalUrl} target="_blank">{this.state.newLink.originalUrl}</a></p>
								<label
								className="form__label">
								<ToolTip text="Tracked Short URL" tip="Perfect for social media and instances where you require a shorter, more memorable URL." />
								</label>
								<div className="shortlink-new__short">
									<input 
									className="shortlink-new__input" 
									type="text" 
									value={'https://bcn.to/'+(this.state.newLink.shortCode)} 
									readOnly />
									<CopyToClipboard text={'https://bcn.to/'+(this.state.newLink.shortCode)}>
										<button 
										type="button" 
										title="Copy to clipboard" 
										className="button shortlink-new__copy" 
										onClick={this.copyToClipboard}>
										Copy
										</button>
									</CopyToClipboard>
								</div>
							</div>
						}
					</Modal>
				{/* } */}
			</React.Fragment>
		)
	}
}

CreateShortLink.propTypes = {
	close: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		selectedCustomer: state.customers.selectedCustomer,
		selectedWebsite: state.websites.selectedWebsite.__urls.primaryUrl,
		activeCampaigns: state.campaigns.activeCampaigns,
		campaignChannels: state.channels.campaignChannels,
		selectedCampaignName: state.campaigns.selectedCampaignName,
		selectedCampaignId: state.campaigns.selectedCampaignId,
		filter: state.global.filter
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		createShortLink: (payload, suppress_notification) => dispatch(createShortLink(payload, suppress_notification)),
		fetchActiveCampaigns: () => dispatch(fetchActiveCampaigns()),
		fetchCampaignChannels: (campaign_id) => dispatch(fetchCampaignChannels(campaign_id)),
		fetchSingleLink: (linkId) => dispatch(fetchSingleLink(linkId)),
		updateLink: (payload) => dispatch(updateLink(payload)),
		addNotification: (payload) => dispatch(addNotification(payload))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateShortLink);
