import React from 'react';
import Logout from './Logout';
import InitiateFirstSubscription from './InitiateFirstSubscription';

const TrialExpired = props => {
	return (
		<div className="takeover">
			<div className="takeover__content">
				<h1 className="takeover__title margin-large">Trial expired <span role="img" aria-label="Sad face">😢</span></h1>
				<p className="margin-medium">Your trial for <strong>{props.customer.company}</strong> has now expired. Please <a href="https://www.thisisbeacon.com/contact/">contact us</a> if you would like to reactive or extend your trial.</p>
				<div style={{textDecoration:'underline',cursor:'pointer'}}><Logout /></div>
			</div>
		</div>
	)
}

export default TrialExpired;