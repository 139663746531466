import React from 'react';
import HelpContainer from '../../containers/general/HelpContainer';

const AddTeamMember = (props) => {
	return (
		<form onSubmit={props.handleSubmit} className="add-team-member">
			<h2 className="add-team-member__title">Invite somebody to this team</h2>
			<div style={{display:"grid", gridTemplateColumns:"7fr 3fr", gridGap:20, alignItems:"center"}}>
				<div>
					<label className="form__label" htmlFor="invite">Email Address</label>
					<input type="email" className="form__input margin-small" id="invite" value={props.email} onChange={props.handleChangeEmail} required />
				</div>
				<label className="form__label">
					<input type="checkbox" id="readOnly" onChange={props.handleChangeReadOnly} />Read-only access <HelpContainer id={18} />
				</label>
			</div>
            <button type="submit" className="button button--green">Send invite</button>
		</form>
	)
}

export default AddTeamMember