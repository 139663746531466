  
import { actionTypes } from "./constants";
import { createResourceFromQueries} from '../utils'
import moment from 'moment-timezone'
import {setInvalidState} from '../../organisms/WidgetContainer/actions';
import axios from "axios";

export const createBarChartResource = ({timePeriod, filter, disableCache, cancelToken}) => {
    let prev = "last30days"

    switch (timePeriod) {
        case "this_year":
            prev = moment().startOf('month').subtract(2, 'months') + ":" + moment().startOf('month').subtract(1, 'months')
            break;
        case "last_month":
            prev = moment().startOf('month').subtract(2, 'months') + ":" + moment().startOf('month').subtract(1, 'months')
            break;
        case "this_month":
            prev = moment().startOf('month').subtract(1, 'month') + ":" + moment().startOf('month')
            break;
        case "last90days":
            prev = moment().subtract(179, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(90, "days").unix()
            break;
        case "last60days":
            prev = moment().subtract(119, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(60, "days").unix()
            break;
        case "last30days":
            prev = moment().subtract(59, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(30, "days").unix()
            break;
        case "last14days":
            prev = moment().subtract(27, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(14, "days").unix()
            break;
        case "last7days":
            prev = moment().subtract(13, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(7, "days").unix()
            break;
        case "last3days":
            prev = moment().subtract(5, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(3, "days").unix()
            break;
        case "last2days":
            prev = moment().subtract(3, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(2, "days").unix()
            break;
        case "yesterday":
            prev = moment().subtract(1, "days").startOf("day").unix() + ":" + moment().endOf('day').subtract(1, "days").unix()
            break;
        case "today":
            prev = moment().startOf("day").unix() + ":" + moment().unix()
            break;
        case "today":
            prev = "1:" + moment().unix()
            break;
        default: //custom
            const start = timePeriod.split(":")[0], end = timePeriod.split(":")[1], diff = moment.unix(end).diff(moment.unix(start), 'days') + 1
            prev = moment.unix(start).subtract(diff, "days").startOf("day").unix() + ":" + moment.unix(start).subtract(1, 'days').endOf('day').unix()
            break;
    }

    let graph_query
    let queryprev
    switch (filter.type) {
        case "website":
        default: 
            graph_query = `website/${filter.payload.id}/campaign/analytics/${timePeriod}/stack`
            queryprev = `website/${filter.payload.id}/${prev}`
            break;
        case "campaign":
            graph_query = `campaign/${filter.payload.id}/channel/analytics/${timePeriod}/stack`
            queryprev = `campaign/${filter.payload.id}/${prev}`
            break;
        case "channel":
            graph_query = `channel/${filter.payload.id}/analytics/${timePeriod}/graph`
            queryprev = `channel/${filter.payload.id}/${prev}`
            break
        case "link": 
            graph_query = `link/${filter.payload.id}/analytics/${timePeriod}/graph`
            queryprev = `link/${filter.payload.id}/${prev}`
    }
    
    return {
        graph: [graph_query, null, {cancelToken: cancelToken.token}],
        previous: [queryprev, null, {cancelToken: cancelToken.token}],
    }
}


export const setBarChartResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_BAR_CHART_FETCHING,
            fetching: true
        })

        const previousToken = getState().barChart.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("media spend request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_BAR_CHART_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createBarChartResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_BAR_CHART_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_BAR_CHART_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_BAR_CHART_FETCHING,
                        fetching: false
                    })
                }
            })
    }
}
