import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPages } from '../../actions/pages';
import PagesList from '../../components/page/PagesList';
import Loader from '../../components/general/Loader'

class PagesListContainer extends Component {
	constructor(){
        super();
        this.state = {
            isFetching: true,
            sortBy: null,
            direction: ':asc'
        }
        this.handleSortBy = this.handleSortBy.bind(this)
	}

	handleSortBy(sortBy){
        this.setState({
            sortBy: sortBy,
            direction: this.state.direction === ':asc' ? ':desc' : ':asc'
        }, () => {
            this.props.fetchPages(this.state.sortBy, this.state.direction, this.state.filterStars)
        })
    }
	
	componentWillReceiveProps(nextProps){
		if(this.props.pagesType !== nextProps.pagesType){
			this.props.fetchPages();
        }
    }

    componentDidMount(){
    	this.props.fetchPages();
    }

	render() {
		if(this.props.isFetching){
			return <Loader />
		}
		if(this.props.pages){
			return (
				<>
					<div className="message message--notice margin-medium">
						<p>Below is a list of pages that have received traffic through your Veracity Links.</p>
					</div>
					<PagesList 
					pages={this.props.pages} 
					handleSortBy={this.handleSortBy} 
					/>
				</>
			)
		}
		return null
	}
}

const mapStateToProps = (state) => {
	return {
		pages: state.pages.pageList,
		pagesType: state.pages.pagesType,
		isFetching: state.pages.isFetchingPages
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
        fetchPages: (sortBy, direction) => dispatch(fetchPages(sortBy, direction))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PagesListContainer);