import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSingleLink, updateLink } from '../../actions/links'
import { addNotification } from '../../actions/ui';
import { fetchCampaignChannels } from '../../actions/channels';
import Modal from '../../components/general/Modal';
import EditLink from '../../components/forms/EditLink';
import ToolTip from '../../components/general/ToolTip'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DocumentTitle from 'react-document-title';
var _ = require('lodash')

class EditLinkContainer extends Component {
	constructor(){
		super()
		this.state = {
			linkId: 0,
			url:'',
			newTag: '',
			tags: [],
			selectedCampaignId: undefined,
			selectedCampaignName: undefined,
			note: '',
			selectedChannelId: undefined,
			selectedChannelName: undefined,
			formHint: false,
			disableChannelSelect: false
		}
		this.handleCloseModal = this.handleCloseModal.bind(this)
		this.handleEditTags = this.handleEditTags.bind(this)
		this.handleUpdateLink = this.handleUpdateLink.bind(this)
		this.handleAddTag = this.handleAddTag.bind(this)
		this.setSelectedCampaign = this.setSelectedCampaign.bind(this)
		this.clearSelectedCampaign = this.clearSelectedCampaign.bind(this)
		this.handleNoteChange = this.handleNoteChange.bind(this)
		this.handleRemoveTag = this.handleRemoveTag.bind(this)
		this.setSelectedChannel = this.setSelectedChannel.bind(this)
		this.clearSelectedChannel = this.clearSelectedChannel.bind(this)
		this.copyToClipboard = this.copyToClipboard.bind(this)
	}

 	handleNoteChange(e){
 		this.setState({
 			note: e.target.value
 		})
 	}

 	setSelectedCampaign(campaign){
		this.setState({
			selectedCampaignId: campaign.id,
			selectedCampaignName: campaign.title,
		}, () => {
			this.clearSelectedChannel()
		})
		this.props.fetchCampaignChannels(campaign.id)
		this.setState({
			disableChannelSelect: false
		})
	}

	clearSelectedCampaign(){
		this.setState({
			selectedCampaignId: undefined,
			selectedCampaignName: undefined,
		}, () => {
			this.clearSelectedChannel()
			this.setState({
				disableChannelSelect: true
			})
		})
	}

	setSelectedChannel(channel){
		this.setState({
			selectedChannelId: channel.id,
			selectedChannelName: channel.title
		})
	}

	clearSelectedChannel(){
		this.setState({
			selectedChannelId: undefined,
			selectedChannelName: undefined
		})
	}

    handleEditTags(e){
    	this.setState({
    		newTag: e.target.value,
    		formHint: true
    	})
    }

    handleAddTag(e){
    	if(e.which === 13){
    		if(e.target.value){
    			let obj = { title: e.target.value }
    			this.setState({
	    			tags: [...this.state.tags, obj],
	    			newTag: '',
	    			formHint: false
	    		})
    		}
    	}
    }

    handleRemoveTag(tag){
    	this.setState({
    		tags: this.state.tags.filter(t => { 
				return t.title !== tag; 
			})
    	})
    }

    handleUpdateLink(e){
			if(!this.state.selectedCampaignId || (!this.state.selectedChannelId) ){
				alert("To accurately measure your digital marketing please ensure your link is saved inside a campaign and a channel.")
			} else {
				e.preventDefault();
				this.props.updateLink(this.state)
				.then(() => {
					// after link is successfully updated, re-request the link and do the close function
					this.props.fetchSingleLink(this.state.linkId)
				this.props.handleOpen()

				// Close the modal from the first login screen
				if(this.props.closeModal){
					this.props.closeModal()
				}
				
				const payload = {
					title:"Link successfully saved",
					theme:"positive"
				}
				this.props.addNotification(payload)
				})
			}
    }

    handleCloseModal(){
    	this.props.handleOpen()
    }

    copyToClipboard(e){
    	e.preventDefault();
		const payload = {
			title:"Copied to clipboard",
			theme:"positive"
		}
		this.props.addNotification(payload)
	}

	componentWillReceiveProps(nextProps){
		if(this.props.selectedCampaignId !== nextProps.selectedCampaignId){
			this.setState({
				selectedCampaignId: nextProps.selectedCampaignId,
				selectedCampaignName: nextProps.selectedCampaignName
			})
		}
	}

	componentDidMount(){
		this.props.fetchCampaignChannels(this.props.link.campaign_id)
		this.setState({
			linkId: this.props.link.link_id
		})

		if(this.props.link.notes !== null){
			this.setState({
				note: this.props.link.notes
			})
		}

		// Check that this link has a campaign
		if(this.props.link.__campaign !== null){
			this.setState({
				selectedCampaignId: this.props.link.__campaign.campaign_id,
				selectedCampaignName: this.props.link.__campaign.title,
			}, () => {
				// re-fetch the channels 
				this.props.fetchCampaignChannels(this.props.link.__campaign.campaign_id)
			})
		}

		// Check that this link has a channel
		if(this.props.link.__channel !== null){
			this.setState({
				selectedChannelId: this.props.link.__channel.channel_id,
				selectedChannelName: this.props.link.__channel.title,
			})
		}

		if(!_.isEmpty(this.props.link.__tags)){
			let existingTags = this.props.link.__tags;
			let i = 0;
			let updatedExistingTags = []
			for(i=0; i < existingTags.length; i++){
				updatedExistingTags = [...updatedExistingTags, { title: existingTags[i].title }]
			}
			this.setState({
				tags:updatedExistingTags
			})
		}
	}

	setNewChannel(channel){
		if(channel != null && !_.isTypedArray(channel)){
			this.setState({
				selectedChannelId: channel.channel_id,
				selectedChannelName: channel.title,
			})
		}
	}

	render() {
		return (
			<Modal 
			title={this.props.new ? "Veracity Link Generated" : "Edit Veracity Link"}
			saveLabel="Save"
			closeAction={this.props.handleCloseModal}
			// disableClose={true}
			saveAction={this.handleUpdateLink}
			alertOnClose={this.state.alertOnClose}
			>



			{
				// If this is a newly created link 
				this.props.new &&
				<div className="shortlink-new message message--notice margin-large">
					<DocumentTitle title="Veracity Link Generated" />
 					<h2 className="shortlink-new__title margin-small">Veracity Link Generated</h2>
					<p className="shortlink-new__original margin-large"><a rel="noopener noreferrer" href={this.props.link.originalUrl} target="_blank">{this.props.link.originalUrl}</a></p>
					<label
					className="form__label">
					<ToolTip text="Tracked Short URL" tip="Perfect for social media and instances where you require a shorter, more memorable URL." />
					</label>
					<div className="shortlink-new__short">
						<input 
						className="shortlink-new__input" 
						type="text" 
						value={'https://bcn.to/'+(this.props.link.shortCode)} 
						readOnly />
						<CopyToClipboard text={'https://bcn.to/'+(this.props.link.shortCode)}>
							<button 
							type="button" 
							title="Copy to clipboard" 
							className="button shortlink-new__copy" 
							onClick={this.copyToClipboard}>
							Copy
							</button>
						</CopyToClipboard>
					</div>
				</div>
			}

			<EditLink
				link={this.props.link}
				handleEditTags={this.handleEditTags}
				handleAddTag={this.handleAddTag}
				newTag={this.state.newTag}
				tags={this.state.tags}
				setSelectedCampaign={this.setSelectedCampaign}
				clearSelectedCampaign={this.clearSelectedCampaign}
				selectedCampaignName={this.state.selectedCampaignName}
				selectedCampaignId={this.state.selectedCampaignId}
				handleNoteChange={this.handleNoteChange}
				note={this.state.note}
				handleRemoveTag={this.handleRemoveTag}
				campaignChannels={this.props.campaignChannels}
				setSelectedChannel={this.setSelectedChannel}
				selectedChannelName={this.state.selectedChannelName}
				selectedChannelId={this.state.selectedChannelId}
				clearSelectedChannel={this.clearSelectedChannel}
				formHint={this.state.formHint}
				disableChannelSelect={this.state.disableChannelSelect}
				setNewChannel={this.setNewChannel.bind(this)}
			/> 
			</Modal>
		)
	}
}

EditLinkContainer.propTypes = {
	handleOpen: PropTypes.func,
	handleCloseModal: PropTypes.func,
	link: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		campaignChannels: state.channels.campaignChannels,
		selectedCampaignName: state.campaigns.selectedCampaignName,
		selectedCampaignId: state.campaigns.selectedCampaignId,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchSingleLink: (linkId) => dispatch(fetchSingleLink(linkId)),
		updateLink: (payload) => dispatch(updateLink(payload)),
		fetchCampaignChannels: (campaignId) => dispatch(fetchCampaignChannels(campaignId)),
		addNotification: (payload) => dispatch(addNotification(payload))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLinkContainer);
