import React from 'react';
import { connect } from 'react-redux';
import VisitsListContainer from '../containers/visit/VisitsListContainer';
import Paging from '../components/general/Paging';
import DocumentTitle from 'react-document-title';

const VisitsListPage = props => {

	const visitList = (
		<>
			<div className="filters margin-medium">
				<div className="filters__content">
					<Paging paging={props.paging} for="visits" />
				</div>
			</div>

			<div className="margin-medium">
				<VisitsListContainer datetime={props.datetime} noContainer={props.noContainer}/>
			</div>
			
			<div className="filters">
				<div className="filters__content">
					<Paging paging={props.paging} for="visits" />
				</div>
			</div>
		</>
	)

	if (props.noContainer) {
		return visitList
	}

	return (
		<React.Fragment>
			<DocumentTitle title="Visits" />
			
			
			<div className="container">

				<div className="card" style={{padding: '10px', marginBottom: '20px', minHeight: '400px'}}>
					{visitList}
				</div>
			</div>

			
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		paging: state.visits.paging,
		userMeta: state.login.userMeta,
		selectedCampaignId: state.campaigns.selectedCampaignId,
		websiteId: state.websites.selectedWebsiteId,
	}
}

export default connect(mapStateToProps, null)(VisitsListPage)
