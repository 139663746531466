import React, {useState, useEffect, useRef} from 'react'
import Loader from '../../components/general/Loader';
import { connect } from 'react-redux';
import moment from 'moment-timezone'
import LastImportNotification from '../../components/general/LastImportNotification'
import {
    ResponsiveContainer, LineChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Area, AreaChart
} from 'recharts';
import BeaconRequest from '../../BeaconRequest'
import {setMediaSpendResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Grid from '@material-ui/core/Grid'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const MediaSpend = (props) => {
    const resource = useSelector(({mediaSpend}) => mediaSpend.data)
    const isFetching = useSelector(({mediaSpend}) => mediaSpend.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setMediaSpendResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Grid container spacing={2}>
                <Grid item xs={3} spacing={3}>
                    <Skeleton variant="rect" width='100%' height={116} style={{borderRadius: '5px'}} /> 
                </Grid>
                <Grid item xs={3} spacing={3}>
                    <Skeleton variant="rect" width='100%' height={116} style={{borderRadius: '5px'}} /> 
                </Grid>
                <Grid item xs={3} spacing={3}>
                    <Skeleton variant="rect" width='100%' height={116} style={{borderRadius: '5px'}} /> 
                </Grid>
                <Grid item xs={3} spacing={3}>
                    <Skeleton variant="rect" width='100%' height={116} style={{borderRadius: '5px'}} /> 
                </Grid>
            </Grid>
        </div>
    }
    

    return (
        <Card 
            style={{
                background: 'transparent', 
                border: 'none', 
                position: 'relative',
                boxShadow: 'none'
            }}
        >
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: 0,
                paddingTop: 0,
                filter: isFetching && 'blur(1px)' 
            }}>
                <MediaSpendChild {...props} resource={resource}/>
            </div>
        </Card>
    )
}


const decode_html_entity =  (str) => {
    return str.replace(/&#(\d+);/g, function(match, dec) {
        return String.fromCharCode(dec);
    });
}

const MediaSpendChild = (props) => {
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const [dailyBreakdown, setDailyBreakdown] = useState(false)
    const [spendData, setSpendData] = useState([])
    const [loadingSpend, setLoadingSpend] = useState(true)

    const [pointCursorImpressions, setPointCursorImpressions] = useState({})
    const [pointCursorClicks, setPointCursorClicks] = useState({})
    const [pointCursorVisitor, setPointCursorVisitor] = useState({})
    const [pointCursorSpend, setPointCursorSpend] = useState({})

    const impressionsGraph = useRef()
    const clicksGraph = useRef()
    const visitorGraph = useRef()
    const spendGraph = useRef()

    useEffect(() => {
        setSelected(null)
    }, [props.filter.type])

    useEffect(() => {
        if(dailyBreakdown) {
            setLoadingSpend(true)
            if (props.filter.type == "campaign") {
                setLoading(true)
                BeaconRequest.get(`campaign/${props.filter.payload.id}/spend/tab/${props.datetime}`)
                    .then(e => {
                        setSpendData(e.response)
                    })
                .finally(e => setLoadingSpend(false))
            } else if (props.filter.type == "channel") {
                BeaconRequest.get(`channel/${props.filter.payload.id}/spend/tab/${props.datetime}`)
                .then(e => {
                    setSpendData(e.response)
                })
                .finally(e => setLoadingSpend(false))
            } else if (props.filter.type == "link") {
                BeaconRequest.get(`link/${props.filter.payload.id}/${props.datetime}`)
                .then(e => {
                    setSpendData(e.response)
                })
                .finally(e => setLoadingSpend(false))
            }
        }
    }, [dailyBreakdown])

    useEffect(() => {
        if (impressionsGraph && impressionsGraph.current && props.resource != null) {
            setPointCursorImpressions(impressionsGraph.current.props)
        }
    }, [impressionsGraph, impressionsGraph.current, props.resource])

    useEffect(() => {
        if (clicksGraph && clicksGraph.current && props.resource != null) {
            setPointCursorClicks(clicksGraph.current.props)
        }
    }, [clicksGraph, clicksGraph.current, props.resource])

    useEffect(() => {
        if (visitorGraph && visitorGraph.current && props.resource != null) {
            setPointCursorVisitor(visitorGraph.current.props)
        }
    }, [visitorGraph, visitorGraph.current, props.resource])

    useEffect(() => {
        if (spendGraph && spendGraph.current && props.resource != null) {
            setPointCursorSpend(spendGraph.current.props)
        }
    }, [spendGraph, spendGraph.current, props.resource])

    // if (props.resource == null) return <Loader/>
    const graph = selected == null ? props.resource.graph.response : selected
    const impressions_graph = props.resource.impressions_graph.response
    const clicks_graph = props.resource.clicks_graph.response
    const visits_graph = props.resource.visits_graph.response
    const spend_graph = props.resource.spend_graph.response
   
    let timeFormat = ''
    let prepend = ''
    switch(impressions_graph.collation){
        case "minute":
            timeFormat = 'h:mma'
            break;
        case "hour":
            timeFormat = 'ha'
            break;
        case "day":
        default:
            timeFormat = 'ddd Do'
            break;
        case "week":
            timeFormat = 'Do MMM'
            prepend = '[w]/c '
            break;
        case "month":
            timeFormat = 'MMM YY'
            break;
    }

    const sum_predicate = (acc, val) => acc + val.data
    const sum_clicks = clicks_graph.graph.reduce(sum_predicate, 0)
    const sum_visits = visits_graph.graph.reduce(sum_predicate, 0)
    const cpc = sum_clicks == 0 ? '0.00' : ((graph.__scoring.spend.has_data ? graph.__scoring.spend.spend : 0)/sum_clicks).toFixed(2)
    const cpv = sum_visits == 0 ? '0.00' : ((graph.__scoring.spend.has_data ? graph.__scoring.spend.spend : 0)/sum_visits).toFixed(2)

    const split = props.datetime.split(":")

    const isToday = props.datetime == "today" || (moment.unix(split[0]).unix() == moment().startOf('d').unix() && moment.unix(split[1]).unix() == moment().endOf('d').unix())
    const isOneDayPeriod = 
        props.datetime == "yesterday" 
        || moment.unix(split[1]).diff(moment.unix(split[0]), 'days') == 0
    

        
    if (isToday) {
        return <div style={{display: 'flex', fontSize: '25px', color: '#808080a6', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <i class="fas fa-exclamation-circle"></i>
        </div>
    }

    const CusomtisedToolTip = (props) => {
        const [translate, setTranslate] = useState({x: 0, y: 0})
        
        const _ref = React.useRef()

        useEffect(() => {
            if (props.active && props.payload && _ref.current) {
                setTranslate({x: _ref.current.offsetWidth / 2, y: 0})
            }
        }, [props.active, props.payload, _ref])

        const { active, payload, label, pointCursor } = props;

        if (active && payload && payload.length && pointCursor &&  pointCursor.points) {
            if (payload == null) return null; //when sync is off with bot impact graph
            const point = pointCursor.points.filter(point => point.payload.name == payload[0].payload.name)[0] 
            if (point == null) return null

            const {x,y} = point
            
            return <div 
                ref={_ref}
                className="tooltip-arrow-blue"
                style={{
                    position: 'fixed',
                    top: y - 40,
                    left: x - translate.x,
                    background: props.colour,
                    color: 'white',
                    padding: '5px',
                    borderRadius: '5px',
                    whiteSpace: 'nowrap',
                    zIndex: 100,
                    minWidth: 30,
                    textAlign: 'center'
                }}
                
            >
                <p className="text-small" style={{fontWeight: 600}}> {`${props.formatter ? props.formatter(payload[0].value) : payload[0].value}`}</p>
                <span
                />
            </div>
        }
        return null
    }

    const CustomCursor = (props) => {
        const text = useRef()

        if (props.pointCursor &&  props.pointCursor.points) {
        
            const { payload, width, height, left, colour, pointCursor } = props;
            if (payload == null) return null;
            const point = pointCursor.points.filter(point => point.payload.name == payload[0].payload.name)[0] 
            if (point == null) return null

            const {x,y} = point
            const y2 = height + (height*0.1); //apply offset why? might be top padding
            // const testWidth = (text.current && text.current.offsetWidth + 5) || 0
            // const textPosX = width < (x + testWidth) ? x - testWidth : x + 5
            return <g>
                <line 
                    x1={x}
                    y1={y} 
                    x2={x} 
                    y2={y2} 
                    height={height}
                    width={width}
                    style={{
                        stroke: colour,strokeWidth:1, strokeDasharray: "5,5", opacity: 1, zIndex: 100
                    }}
                />
                {/* <text ref={text} x={textPosX} y={y2-testWidth} style={{fill: colour, fontWeight: 'bold', fontSize: '10px'}}>{payload[0].payload.name}</text> */}
            </g>
        }

        return null
    }
    const colour = '#643086'

    return <div style={{display: 'grid', gridTemplateColumns: 'auto'}}>
        
        {/* <div style={{marginRight: '10px', paddingRight: '10px', minWidth: '240px'}}>
            <div style={{padding: '5px'}}>
                {
                graph.lastAdDataAdded && <LastImportNotification date={graph.lastAdDataAdded} />
                }
            </div>
            {props.filter.type != "website" && 
                <div style={{display: 'grid', gridTemplateColumns: 'auto max-content', padding: '3px'}}>
                    {dailyBreakdown && <Modal
                        title={"Daily spend breakdown"}
                        closeAction={() => setDailyBreakdown(false)}
                        disableSave="true"
                    >
                        {loadingSpend ? 
                            <Loader />
                            :
                            <LinkSpend spendData={spendData} />
                        }
                        
                    </Modal>
                    }
                    
                    <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={e => {setDailyBreakdown(true)}}>View daily breakdown</div>
                </div>
            }
            
        </div> */}

        <Grid container spacing={2}>
            <Grid item xs={3} spacing={3}>
                <div className="stat-group" style={{height: '150px', background: 'white', zIndex: 0}}>
                    {/* <h1 className="stat-group__title">Impressions</h1> */}
                    <div style={{width: '99.9%', height: '100%', padding: '0', paddingTop: '55px'}}>
                        {isOneDayPeriod ? 
                            <div style={{display: 'flex', fontSize: '25px', color: '#808080a6', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            :
                            <>
                                <h3 
                                    className="text-large"
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px'
                                    }}
                                >
                                    {Number(graph.__scoring.spend.has_data ? graph.__scoring.spend.impressions : 0).toLocaleString('en')} 
                                    <div className="text-small color-mid-grey">Impressions</div>
                                </h3>
                                <ResponsiveContainer isAnimationActive={false}>
                                    <AreaChart 
                                        isAnimationActive={false}
                                        margin={{
                                            top: 0, left: 0, right: 0, bottom: 0
                                        }}
                                        data={impressions_graph.graph.map(value => {
                                            return {
                                                name: moment.unix(value.start.timestamp).format(prepend + timeFormat),
                                                'Impressions': value.data
                                            }
                                        })}
                                        syncId="mediaSpend"
                                       
                                    >
                                        <defs>
                                            <linearGradient id="msImpressions" x1="0" y1="0" x2="0" y2="1.1">
                                                <stop offset="10%" stopColor="#643086" stopOpacity={1} />
                                                <stop offset="90%" stopColor="#d5298d" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <Tooltip 
                                            content={
                                                <CusomtisedToolTip 
                                                    colour={colour} 
                                                    pointCursor={pointCursorImpressions} 
                                                    formatter={value => Number(value).toLocaleString('en')}
                                                />
                                            }
                                            position={{ x: 0, y: 0 }}
                                            cursor={<CustomCursor colour={colour} pointCursor={pointCursorImpressions}/>}
                                        />
                                        <Area  
                                            dot={false} 
                                            ref={impressionsGraph}
                                            activeDot={{ stroke: colour, fill: 'white' }} 
                                            type="monotone"
                                            dataKey="Impressions"
                                            stroke="#643086"
                                            fill="url(#msImpressions)" 
                                            strokeWidth={2}
                                            isAnimationActive={false}
                                            onMouseOver={(data) => {
                                                setPointCursorImpressions(data)
                                            }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </>
                        }
                        
                    </div>
                </div>
            </Grid>

            <Grid item xs={3} spacing={3}>
                <div className="stat-group" style={{height: '150px', background: 'white', zIndex: 0}}>
                    {/* <h1 className="stat-group__title">Clicks</h1> */}
                    <div style={{width: '99.9%', height: '100%', paddingTop: '55px'}}>
                        {isOneDayPeriod ? 
                            <div style={{display: 'flex', fontSize: '25px', color: '#808080a6', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            :
                            <>
                                <h3 
                                    className="text-large"
                                    style={{
                                        // color: '#28AAE1',
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px'
                                    }}
                                >
                                    {Number(sum_clicks).toLocaleString('en')}
                                    <div className="text-small color-mid-grey">Clicks</div>
                                </h3>
                                <ResponsiveContainer isAnimationActive={false}>
                                    <AreaChart 
                                        isAnimationActive={false} 
                                        data={clicks_graph.graph.map(value => {
                                            return {
                                                name: moment.unix(value.start.timestamp).format(prepend + timeFormat),
                                                'Clicks': value.data
                                            }
                                        })}
                                        margin={{left: 0, right: 0, bottom: 0}}
                                        syncId="mediaSpend"
                                    >
                                        <defs>
                                            <linearGradient id="msClicks" x1="0" y1="0" x2="0" y2="1.1">
                                                <stop offset="10%" stopColor="#643086" stopOpacity={1} />
                                                <stop offset="90%" stopColor="#d5298d" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <Tooltip 
                                            content={
                                                <CusomtisedToolTip 
                                                    colour={colour} 
                                                    pointCursor={pointCursorClicks} 
                                                    formatter={value => Number(value).toLocaleString('en')}
                                                />
                                            }
                                            position={{ x: 0, y: 0 }}
                                            cursor={<CustomCursor colour={colour} pointCursor={pointCursorClicks}/>}
                                        />
                                        <Area  
                                            dot={false} 
                                            activeDot={{ stroke: colour, fill: 'white' }} 
                                            ref={clicksGraph}
                                            type="monotone" 
                                            dataKey="Clicks" 
                                            fill="url(#msClicks)"
                                            stroke="#643086" 
                                            strokeWidth={2} 
                                            isAnimationActive={false}
                                            onMouseOver={(data) => {
                                                setPointCursorClicks(data)
                                            }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </>  
                        }
                    </div>
                </div>
            </Grid>

            <Grid item xs={3} spacing={3}>
                <div className="stat-group" style={{height: '150px', background: 'white', zIndex: 0}}>
                    {/* <h1 className="stat-group__title">Visitors</h1> */}
                    <div style={{width: '99.9%', height: '100%', paddingTop: '55px'}}>
                        {isOneDayPeriod ? 
                            <div style={{display: 'flex', fontSize: '25px', color: '#808080a6', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                <i class="fas fa-exclamation-circle"></i>
                            </div>
                            :
                            <>
                                <h3 
                                    className="text-large"
                                    style={{
                                        position: 'absolute',
                                        top: '10px',
                                        left: '10px'
                                    }}
                                >
                                    {Number(sum_visits).toLocaleString('en')} 
                                    <div className="text-small color-mid-grey">Visitors</div>
                                </h3>
                                <ResponsiveContainer isAnimationActive={false} isAnimationActive={false}>
                                    <AreaChart 
                                        data={visits_graph.graph.map(value => {
                                            return {
                                                name: moment.unix(value.start.timestamp).format(prepend + timeFormat),
                                                'Visitors': value.data
                                            }
                                        })}
                                        margin={{left: 0, right: 0, bottom: 0}}
                                        syncId="mediaSpend"
                                    >
                                        <defs>
                                            <linearGradient id="msSpend" x1="0" y1="0" x2="0" y2="1.1">
                                                <stop offset="10%" stopColor="#643086" stopOpacity={1} />
                                                <stop offset="90%" stopColor="#d5298d" stopOpacity={1} />
                                            </linearGradient>
                                        </defs>
                                        <Tooltip 
                                            content={
                                                <CusomtisedToolTip 
                                                    colour={colour} 
                                                    pointCursor={pointCursorVisitor} 
                                                    formatter={value => Number(value).toLocaleString('en')}
                                                />
                                            }
                                            position={{ x: 0, y: 0 }}
                                            cursor={<CustomCursor colour={colour} pointCursor={pointCursorVisitor}/>}
                                        />
                                        <Area 
                                            dot={false} 
                                            activeDot={{ stroke: colour, fill: 'white' }} 
                                            ref={visitorGraph}
                                            type="monotone" 
                                            dataKey="Visitors" 
                                            stroke="#643086" 
                                            fill="url(#msSpend)"
                                            strokeWidth={2}
                                            isAnimationActive={false}
                                            onMouseOver={(data) => {
                                                setPointCursorVisitor(data)
                                            }}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </>
                        }
                    </div>
                </div>
            </Grid>

            <Grid item xs={3} spacing={3}>
                <div className="stat-group" style={{height: '150px', background: 'white', zIndex: 0}}>
                    {/* <h1 className="stat-group__title">Spend</h1> */}
                    <div style={{width: '99.9%', height: '100%', paddingTop: '55px'}}>
                        {isOneDayPeriod ? 
                                <div style={{display: 'flex', fontSize: '25px', color: '#808080a6', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                    <i class="fas fa-exclamation-circle"></i>
                                </div>
                                :
                                <>
                                    <h3 
                                        className="text-large"
                                        style={{
                                            // color: '#643086',
                                            position: 'absolute',
                                            top: '10px',
                                            left: '10px'
                                        }}
                                    >
                                        {decode_html_entity(props.currency.currency_symbol)+Number(graph.__scoring.spend.has_data ? graph.__scoring.spend.spend : 0).toLocaleString('en', {minimumFractionDigits: 2})}
                                        <div className="text-small color-mid-grey">Spend</div>
                                    </h3>
                                    <ResponsiveContainer  isAnimationActive={false}>
                                        <AreaChart 
                                            isAnimationActive={false} 
                                            data={spend_graph.graph.map(value => {
                                                return {
                                                    name: moment.unix(value.start.timestamp).format(prepend + timeFormat),
                                                    'Spend': value.data
                                                }
                                            })}
                                            margin={{left: 0, right: 0, bottom: 0}}
                                            syncId="mediaSpend"
                                        >
                                            <defs>
                                                <linearGradient id="msVisitors" x1="0" y1="0" x2="0" y2="1.1">
                                                <stop offset="10%" stopColor="#643086" stopOpacity={1} />
                                                <stop offset="90%" stopColor="#d5298d" stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <Tooltip 
                                                content={
                                                    <CusomtisedToolTip 
                                                        colour={colour} 
                                                        pointCursor={pointCursorSpend} 
                                                        formatter={value => decode_html_entity(props.currency.currency_symbol)+Number(value).toLocaleString('en', {minimumFractionDigits: 2})}
                                                    />
                                                }
                                                position={{ x: 0, y: 0 }}
                                                cursor={<CustomCursor colour={colour} pointCursor={pointCursorSpend}/>}
                                            />
                                            <Area  
                                                dot={false} 
                                                activeDot={{ stroke: colour, fill: 'white' }} 
                                                ref={spendGraph}
                                                type="monotone" 
                                                dataKey="Spend" 
                                                stroke="#643086" 
                                                strokeWidth={2}
                                                fill="url(#msVisitors)"
                                                isAnimationActive={false}
                                                onMouseOver={(data) => {
                                                    setPointCursorSpend(data)
                                                }}
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </>
                        }
                    </div>
                </div>
            </Grid>

        </Grid>
    </div>
}

const mapStateToProps = state => {
	return {
		website: state.websites.selectedWebsite,
        currency: state.websites.selectedWebsite.__meta
	}
}

// withWidgetContainer
export default connect(mapStateToProps, null)((MediaSpend))
