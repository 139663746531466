import React from 'react';
import InitiateFirstSubscription from './InitiateFirstSubscription';
import moment from 'moment-timezone';

const CustomerSubscription = props => {
	
	const { is_trial, cancellation_date, currently_active, __package_details, daysLeftInTrial, subscription_commencement, next_planned_renewal } = props.customer.__subscription

	// If the customer is not on a trial but currently active, this means they have a payment plan
	if(!is_trial && currently_active){
		return (
			<div>
				<p className="margin-medium">You are currently on the <strong>{__package_details.package_name} plan</strong>, setup on <strong>{moment(subscription_commencement).format("MMM Do YYYY")}</strong>. Your next payment date is <strong>{moment(next_planned_renewal).format("MMM Do YYYY")}</strong>.</p>
				<p>If you wish to change this plan please <a href="mailto:support@thisisbeacon.com">contact support</a>.</p>
			</div>
		)
	}

	// If the customer is not on a trial
	if(is_trial && cancellation_date){
		return (
			<div>
				<p className="margin-medium">Your trial has expired, use the button below to choose a payment plan.</p>
				<InitiateFirstSubscription />
			</div>
		)
	}	

	// If the customer is on trial
	if(is_trial){
		return (
			<div>
				<p className="margin-medium">
					{ 
						daysLeftInTrial > 1 ? <span>You are currently on a trial with <strong>{daysLeftInTrial}</strong>, use the button below to choose a payment plan.</span> : <span>You are on the last day of your trial. Use the button below to choose a payment plan.</span>
					}
				</p>
				<InitiateFirstSubscription />
			</div>
		)
	}
}

export default CustomerSubscription;