import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSingleVisitor } from '../../actions/visitors';
import Loader from '../../components/general/Loader';

class VisitorSingleContainer extends Component {
	constructor(){
		super();
		this.state = {
			notFound: false
		}
    }
    
	componentDidMount(){
		this.props.fetchSingleVisitor(this.props.visitor_id).then(response => {
			if(response === undefined){
				this.setState({
					notFound: true
				})
			}
		})
    }
    
	componentWillReceiveProps(nextProps){
		if (this.props.visitor_id !== nextProps.visitor_id){
			this.props.fetchSingleVisitor(this.props.visitor_id);
        }
    }

	render() {
		if(this.state.notFound){
			return (
				<div className="not-found">
					<h1>Visitor information not available</h1>
					<p>This visitor can't be viewed at the moment. It may be that the visitor does not exist, or you do not have permission to see it.</p>
				</div>
			)
		}

		if(this.props.isFetching){
			return <Loader />
		}

		if(this.props.visitor){
			const identities = this.props.visitor.__identities[0]
			

			return (
				<div className="card">
                    <div className="card__header">
						<div className="card__title">
                            <h3 style={{float:"left"}}>Visitor Identity</h3>
							<i style={{float:"right"}} className="fas fa-user-check"></i>
						</div>
					</div>
                    <div className="card__content">
                        <dl>
							<div className="form__two-column">
								<div className="definition-list__item">
									<dt className="definition-list__title">Email address</dt>
									<dd className="definition-list__value">{ identities == null || identities.email === "" ? "Not provided" : identities.email }</dd>
								</div>
								<div className="definition-list__item">
									<dt className="definition-list__title">Name</dt>
									<dd className="definition-list__value">{ identities == null || identities.fullname === ""? "Not provided" : identities.fullname }</dd>
								</div>
							</div>
							<div className="form__two-column">
								<div className="definition-list__item">
									<dt className="definition-list__title">Organisation</dt>
									<dd className="definition-list__value">{ identities == null || identities.organisation === "" ? "Not provided" : identities.organisation }</dd>
								</div>
		
								{ (identities == null || identities.phone === "") ?
									<div className="definition-list__item">
									<dt className="definition-list__title">Mobile</dt>
									<dd className="definition-list__value">{ identities == null || identities.mobile === "" ? "Not provided" : identities.mobile }</dd>
									</div>
									:
									<>
									{ (identities != null && identities.mobile !== "") &&
										<div className="definition-list__item">
										<dt className="definition-list__title">Mobile</dt>
										<dd className="definition-list__value">{ identities == null || identities.mobile === "" ? "Not provided" : identities.mobile }</dd>
										</div>
									}
									<div className="definition-list__item">
									<dt className="definition-list__title">Telephone</dt>
									<dd className="definition-list__value">{ identities == null || identities.phone === "" ? "Not provided" : identities.phone }</dd>
									</div>
									</>
								}
								
							</div>
                        </dl>
                    </div>
                </div>
			)
		}

		return null
	}
}

const mapStateToProps = state => {
	return {
		visitor: state.visitors.singleVisitor,
		isFetching: state.visitors.isFetchingSingleVisitor
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchSingleVisitor: (visitorId) => dispatch(fetchSingleVisitor(visitorId)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitorSingleContainer);