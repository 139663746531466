import React, { useState } from 'react';
import { connect } from 'react-redux';
import EditWebsiteConnections from "../forms/EditWebsiteConnections";
import LoaderAlt from '../general/LoaderAlt';
import Modal from "../general/Modal";

const mapStateToProps = state => {
	return {
		websites: state.websites.activeWebsites,
		selectedWebsite: state.websites.selectedWebsite
	}
}

const ConnectAds = connect(mapStateToProps, null)((props) => {
	// 0: No connections, 1: Connection made, account not selected, 2: Connection made + account selected
	const [hasConnection, setHasConnection] = useState({Google: 0, Facebook: 0, LinkedIn: 0, Microsoft: 0})
    const [message, setMessage] = useState("");

	const fluxConnections = Object.entries(hasConnection).reduce((acc, [k, v]) => (
		v === 1 ? [...acc, k] : acc
	), []).sort();

	const change = (e, override) => {
		if(override || (hasConnection.Google !== 1 && hasConnection.Facebook !== 1 && hasConnection.LinkedIn !== 1 && hasConnection.Microsoft !== 1)) {
			props.changeSlide(3)
		} else {
			setMessage(
				"Leaving this page will cancel connecting your "
				+ fluxConnections.join(" and ")
				+ " ad account" + (fluxConnections.length === 1 ? "" : "s")
				+ ", are you sure you want to continue?"
			)
		}
	}

	return <>
		{message.length > 0 && <Modal
			closeAction={() => change(null, true)}
			saveAction={() => setMessage("")}
			closeLabel="Continue Anyway"
			saveLabel="Finish Connecting"
			title="Your connections aren't saved"
			animateSave
		>
			{message}
		</Modal>}
		<div className="first-login__slide">
			<div className="first-login__image">
				<img 
					src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/newOnboarding/Onboarding_3.png" 
					style={{objectFit: 'contain', width: '100%', height: '100%'}}
				/>
			</div>
			<div className="first-login__content">
				<h1 className="first-login__title">Link your ad accounts</h1>
				<div className="margin-medium">
					<label className="form__label">Link your ad accounts to allow Beacon to import campaign data, including impressions, clicks & spend, and to automatically update your bot exclusion lists. </label>
				</div>
				{props.selectedWebsite == null ?
					<LoaderAlt />
					:
					<EditWebsiteConnections website={props.selectedWebsite} hasConnection={setHasConnection} isNewWebsite/>
				}
				<div className="first-login__actions">
					{hasConnection.Google === 2 || hasConnection.Facebook === 2 || hasConnection.LinkedIn === 2 || hasConnection.Microsoft === 2 ?
						<button onClick={change} className="button button--yellow">Next</button>
						:
						<button onClick={change} style={{
							border: 'none',
							background: 'white',
							// textDecoration: 'underline',
							fontSize: '13px',
							cursor: 'pointer'
						}}>I'll decide later</button>
					}
				</div>
			</div>
		</div>
	</>
})

export default ConnectAds
