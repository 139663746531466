import React from 'react';
import { Link } from 'react-router-dom';
import ToolTip from '../general/ToolTip'
import moment from 'moment-timezone';

const PagesList = props => {
	const pages = props.pages.map(page => {
		return (
			<div className="table__row" key={page.page_id}>
				<div className="table__cell">
					<a rel="noopener noreferrer" href={(page.host + page.path)} target="_blank">{page.host + page.path}</a>
				</div>
				<div className="table__cell">
					{moment(page.updated).fromNow()}
				</div>
				<div className="table__cell">
					{page.viewsAsLandingPage}
				</div>
				<div className="table__cell">
					<Link
					to={{pathname: "/pages/" + (page.page_id), state: { modal: true, referrer: window.location.pathname } }}
					className="button button--small button--green">View page details</Link>
				</div>
			</div>
		)
	})

	if(props.pages.length === 0){
		return (
			<div>
				<h1 className="text-large margin-medium">No pages found</h1>
				<p>Pages will appear here once you start sending traffic to your website through your Veracity Links.</p>
			</div>
		)
	}

	return (
		<div className="table">
			<div className="table__row">
				<div className="table__heading is-fixed">Page</div>
				<div className="table__heading is-fixed">
					<span 
                        className="is-sortable" 
                        onClick={() => props.handleSortBy("updated")}>
                        Last Activity
                    </span>
				</div>
				<div className="table__heading is-fixed">
					<ToolTip
						tip="Views as a landing page from your Veracity Links"
						text={<span 
							className="is-sortable" 
							onClick={() => props.handleSortBy("viewsAsLandingPage")}>
							Views From Links
						</span>}
					/>
				</div>
				<div className="table__heading is-fixed"></div>
			</div>
			{pages}
		</div>
	)
}

export default PagesList;