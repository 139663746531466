import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../general/Loader';
import { getActiveWebsites, setSelectedWebsite, fetchCustomerWebsites } from '../../actions/websites';
import Logout from '../customer/Logout'
import BeaconRequest from '../../BeaconRequest'
import {setUserMeta, getUserMeta, setSelectedCustomer, fetchCustomers} from '../../actions/customers'
import moment from 'moment-timezone'
import {fetchTeamUsers} from '../../actions/teams'
import CustomerTeamContainer from '../../containers/customer/CustomerTeamContainer';
import Modal from '../../components/general/Modal'
import EditCustomerContainer from '../../containers/forms/EditCustomerContainer'
import CreateWebsiteContainer from '../../containers/forms/CreateWebsiteContainer'

const Avatar = ({text, width, height, index, src}) => {
	return <div style={{width, height, backgroundColor: '#bdbdbd', overflow: 'hidden', color: 'white', display: 'flex', alignItems: 'center',  justifyContent: 'center', border: '1px solid white', borderRadius: '50%', marginLeft: index != 0 && '-5px', zIndex: 100-(index)}}>
	 {(src == null) ?
		text
		:
		<img src={src} style={{objectFit: 'contain', width: width -4, height: height -4}}/>
	 }
	</div>
}

let timeout_timer;
class SplashWebsiteSelector extends Component {

	// Setup a contructor and bind the functions
	constructor(){
		super();
		this.state =  { 
			timeout: false,
			selectedCustomer: null,
			editCustomer: false,
			createWebsite: false,
			viewTeams: false,
		}
		this.setSelectedWebsite = this.setSelectedWebsite.bind(this);
		this.setTimeout = this.setTimeout.bind(this)
		// this.fetchCustomers = this.fetchCustomers.bind(this)
	}

	componentDidMount(){
		this.props.getActiveWebsites()
		if (this.props.activeWebsites && this.props.selectedCustomer) {
			const customer = this.props.activeWebsites.filter(customer => customer.customer_id == this.props.selectedCustomer.customer_id)[0]
			this.setState({selectedCustomer: customer})
			if (this.props.selectedCustomer.customer_id) {
				this.props.fetchCustomerWebsites(this.props.selectedCustomer.customer_id); 
			}
		} 
	}
	
	setTimeout () {
		this.setState({timeout: true})
	  }

	componentWillUnmount() {
		window.clearTimeout(timeout_timer)
	}

	componentWillReceiveProps(nextProps){
		// Build an array of websites for this customer
		if (this.props.activeWebsites != nextProps.activeWebsites) {
			let customers = nextProps.activeWebsites;

			let i = 0;
			let websites = []
			for(i = 0; i < customers.length; i++){
				websites = websites.concat(customers[i].__websites)
			}

			// If there is only one website, auto-select it and goto the dashboard
			if(customers.length === 1){
				window.clearTimeout(timeout_timer)
				if (customers[0] != null) {
					this.props.setSelectedCustomer(customers[0].customer_id)
						.then(customer => {
							this.setState({selectedCustomer: customer});
							this.props.fetchCustomerWebsites(customers[0].customer_id);
						});
				}
			}
		}
		
		window.clearTimeout(timeout_timer)
	}

	// Set the selected website based on the website ID provided by the event handler
	// Also fetch associate social accounts
	setSelectedWebsite(website){
		this.props.setSelectedWebsite(website);
	}

	// Render this component
	render() {
		if(this.props.isFetching || this.props.isFetchingSelectedWebsite){
			// return (
			// 	<React.Fragment>
			// 		<div className="external__logout"><Logout /></div>
			// 		<Loader colour="light" />
			// 	</React.Fragment>
			if (timeout_timer == null) {
				timeout_timer = window.setTimeout(() => {this.setTimeout()}, 30000);
			}
			
			return (
				<div className="external">
				<div className="external__logout"><Logout /></div>
				{this.state.timeout ? <>
					<div style={{color: 'white', width: '500px', fontSize: '22px', textAlign: 'center', lineHeight: '1.5'}}>
					Something may have gone wrong, please click the logout button top right of this screen. 
					If this happens more than once, please see <a style={{color: 'white', fontSize: '22px',}} href="https://www.thisisbeacon.com/guides/browser-cache-issues/" target="_blank">https://www.thisisbeacon.com/guides/browser-cache-issues/</a> for further assistance.
					</div>
					</>:
					<Loader colour="light" />
				}
				</div>
			)
			// )
		}
	
		if(this.props.activeWebsites.length >= 1){
			const submitSelectedWebsite = (e) => {
				BeaconRequest.get(`website/${this.state.website}`, {__onlyCore: 1})
					.then(({response}) => {
						this.setSelectedWebsite(response);
					})
					.catch(err => {
						console.error(err);
					})
				e.preventDefault();
			}

			return (
				<React.Fragment>
					<div className="external__logout"><Logout /></div>
					{this.state.selectedCustomer == null && 
						<div className="card login-select" style={{padding: '20px'}}>
							<h1 className="login-select__intro margin-large" style={{margin: '50px 0', marginTop: '50px'}}>Select a company</h1>

							<ul className="dropdown__menu light" style={{width: '100%', boxShadow: 'none', marginTop: 0}}>
								{
									this.props.activeWebsites
										.map((customer, index) => {
										return <li
											className="dropdown__item" 
											onClick={() => {
												this.props.setSelectedCustomer(customer.customer_id)
													.then(customer => {
														this.setState({
															selectedCustomer: customer
														})
														this.props.fetchCustomerWebsites(customer.customer_id); 
													})
											}}
											style={{
												padding: '5px 5px 5px 10px', 
												display: 'grid',
												gridTemplateColumns: 'auto max-content max-content', 
												height: '56px',
												fontSize: '13px', 
												width: '100%',
												borderBottom: 'solid 1px rgb(208 208 208)',
												borderTop: index == 0 && 'solid 1px rgb(208 208 208)',
											}}
										>
											<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '30px'}}>
												<span style={{fontWeight: 700}}>{customer.company}</span>
												<span style={{fontSize: '10px'}}> Last active: {moment(customer.lastActive).format("MMM Do YYYY hh:mm")}</span>
											</div>
											<div style={{width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginRight: '30px'}}>
												<div style={{display: 'flex', flexDirection: 'row'}}>
													
													{customer.__websites.map((website,index) => {
														if (index > 3) return 
														return <Avatar index={index} src={website.__meta.website_logo} text={website.name.slice(0,1).toUpperCase()} width={35} height={35}/>
													})}
													{customer.__websites.length > 4 &&
														<Avatar text={"+" + customer.__websites.slice(3,customer.__websites.length - 1).length} width={35} height={35}/>
													}
													
												</div>
											</div>
											<div style={{textDecoration: 'underline', display: 'flex', alignItems: 'center'}}>select</div>
										</li>
									})
								}		
							</ul>
													
						</div>

					}

					{this.state.selectedCustomer && <>
						<div className="card login-select" style={{padding: '20px'}}>
						
							<h1 className="login-select__intro" style={{margin: '20px 0', marginTop: '50px'}}>{this.state.selectedCustomer.company}</h1>
							
							<div className="margin-large" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
								{(this.state.selectedCustomer.__user_role && this.state.selectedCustomer.__user_role.role_id !== 4 && this.state.selectedCustomer.__user_role.role_id !== 3) && <>
									<button className="button button--dark-grey hollow" style={{marginRight: '5px'}} onClick={e => this.setState({editCustomer: true})}><i className="fas fa-cogs" style={{marginRight: '10px'}}></i> Manage company</button>
									<button className="button button--dark-grey hollow" onClick={e => this.setState({viewTeams: true})}><i className="fas fa-users" style={{marginRight: '5px'}}></i> Manage team</button>
								</>}
							</div>

							<ul className="login-select__list">
								{
									this.props.activeWebsites
										.filter(customer => this.state.selectedCustomer && customer.customer_id == this.state.selectedCustomer.customer_id)
										.map(customer => {
											let website = customer.__websites.map((website, index) => {
												return (
													<li 
														className="dropdown__item" 
														onClick={() => this.setSelectedWebsite(website)}
														style={{
															padding: '5px 5px 5px 10px', 
															display: 'grid',
															gridTemplateColumns: 'max-content auto max-content', 
															fontSize: '13px', 
															width: '100%',
															borderBottom: 'solid 1px rgb(208 208 208)',
															borderTop: index == 0 && 'solid 1px rgb(208 208 208)',
														}}
													> 
														<div className="website-image" style={{borderRadius: '50%', backgroundColor: 'white', maxWidth: '35px', width: '35px', height: '35px', margin: '5px 10px'}} >
															{website.__meta.website_logo ?
																<img  
																	style={{objectFit: 'contain'}} 
																	src={website.__meta.website_logo} 
																/>
																:
																<h1 style={{width: '100%', height: '100%', fontSize: '100%', padding: '20%', textAlign: 'center', color: '#5E6172', fontWeight: '900'}}>{website.name.substring(0, 1).toUpperCase()}</h1>
															}
															
														</div>
														<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '20px'}}>
															<span style={{fontWeight: 700}}>{website.name}</span>
															<span style={{fontSize: '10px'}}>{website.base_host} {website.lastActive && <>• Last active: {moment(website.lastActive).format("MMM Do YYYY hh:mm")}</>}</span>
														</div>
														<div style={{textDecoration: 'underline', display: 'flex', alignItems: 'center'}}>view</div>
													</li>
												)
											})

										return (
											<div key={customer.customer_id}>
												<ul className="dropdown__menu light" style={{width: '100%', boxShadow: 'none', marginTop: 0, maxHeight: '2000px'}}>
													{website}
													{(this.state.selectedCustomer.__user_role && (this.props.currentUser.isAdmin === 1 || (this.state.selectedCustomer.__user_role.role_id !== 4 && this.state.selectedCustomer.__user_role.role_id !== 3))) && 
														<li 
															className="dropdown__item" 
															onClick={() => {this.setState({createWebsite: true})}}
															style={{
																padding: '5px 5px 5px 10px', 
																display: 'grid',
																gridTemplateColumns: 'max-content auto', 
																fontSize: '13px', 
																width: '100%',
																borderBottom: 'solid 1px rgb(208 208 208)',
																borderTop: customer.__websites.length == 0 && 'solid 1px rgb(208 208 208)',
															}}
														> 
															<div style={{maxWidth: '35px', width: '35px', height: '35px', margin: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
																<i class="fas fa-plus"></i>
															</div>
															<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '20px'}}>
																<span style={{fontWeight: 700}}>Create new website</span>
															</div>
														</li>
													}
													{this.props.activeWebsites.length > 1 &&  
													<li 
														className="dropdown__item" 
														onClick={e => this.setState({selectedCustomer: null})}
														style={{
															padding: '5px 5px 5px 10px', 
															display: 'grid',
															gridTemplateColumns: 'max-content auto', 
															fontSize: '13px', 
															width: '100%',
															borderBottom: 'solid 1px rgb(208 208 208)',
															borderTop: customer.__websites.length == 0 && 'solid 1px rgb(208 208 208)',
														}}
													> 
														<div style={{maxWidth: '35px', width: '35px', height: '35px', margin: '5px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
															<i class="fas fa-building"></i>
														</div>
														
															<div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '20px'}} >
																<span style={{fontWeight: 700}}>Change company</span>
															</div>
													</li>
													}
												</ul>
												
											</div>
										)
									})

								}
							</ul>
						</div>
						

						{(this.state.viewTeams && (this.state.selectedCustomer.__user_role && this.state.selectedCustomer.__user_role.role_id !== 4 && this.state.selectedCustomer.__user_role.role_id !== 3)) && 
							<Modal
								closeAction={() => this.setState({viewTeams: false})}
								disableSave={true}
								isWide
								title="Team"
							>
								<CustomerTeamContainer customer={Object.assign(this.state.selectedCustomer, {__user: this.props.user.currentUser})} user={this.props.user} />
							</Modal>
						}

						{(this.state.editCustomer && (this.state.selectedCustomer.__user_role && this.state.selectedCustomer.__user_role.role_id !== 4 && this.state.selectedCustomer.__user_role.role_id !== 3)) && 
							<Modal
								closeAction={() => this.setState({editCustomer: false})}
								disableSave={true}
								isWide
								title="Company"
							>
								<EditCustomerContainer customer={this.props.selectedCustomer} />
							</Modal>
						}

						{(this.state.createWebsite && (this.props.currentUser.isAdmin === 1 || (this.state.selectedCustomer.__user_role.role_id !== 4 && this.state.selectedCustomer.__user_role.role_id !== 3))) && 
							<CreateWebsiteContainer handleOpen={() => this.setState({createWebsite: false})} /> 
						}
						</>
					}
					
					{
						this.props.currentUser != null && this.props.currentUser.isAdmin === 1  && 
							<div className="stat-group" style={{marginTop: '30px'}}>
								<h2 className="stat-group__title" style={{zIndex: 0}}>Admin tools</h2>
								<div className="stat-group__content" >
									<h2 className="form__label">Enter website id</h2>
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<input 
											className="form__input" 
											type="text" 
											style={{
												width: "69%", 
												display: "inline-block", 
												marginRight: "10px"
											}} 
											onKeyUp={e => {
												if (e.key === "Enter") {
													e.preventDefault();
													submitSelectedWebsite(e);
												}
											}}
											onChange={e => this.setState({website: e.target.value})}
										/> 
										<button 
											onClick={submitSelectedWebsite} 
											className="button button--dark-grey" 
											style={{whiteSpace: 'nowrap'}} 
											type="submit"
										>
											Enter website
										</button>
									</div>
								</div>
							</div>	
					}
						
					
				</React.Fragment>
			)	
		}
		
		// If for some reason there are no customers attached to this user
		if(this.props.activeWebsites.length === 0) {
			return (
				<div className="message message--notice" style={{backgroundColor:'#ffffff'}}>
					<p>You don't appear to have any websites or customers listed.</p>
					<p>Please <a href="mailto:support@thisisbeacon.com">contact support</a> or <span style={{textDecoration:'underline',cursor:"pointer"}}><Logout /></span>.</p>
				</div>
			)
		}

		return null
	}
}

// Map the list of websites to this component by pulling them in from the store state and assigning to activeWebsites
// Also get the selected website and pass to the value field in the select so it remains selected
const mapStateToProps = (state) => {
	return {
		user: state.login.currentUser,
		isFetching: state.websites.isFetching,
		isFetchingSelectedWebsite: state.websites.isFetchingSelectedWebsite,
		activeWebsites: state.websites.activeWebsites,
		currentUser: state.login.currentUser,
		selectedCustomer: state.customers.selectedCustomer,
		customerWebsites: state.websites.customerWebsites
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
        getActiveWebsites: () => dispatch(getActiveWebsites()),
		setSelectedWebsite: (website) => dispatch(setSelectedWebsite(website)),
		getUserMeta: () => dispatch(getUserMeta()),
        setUserMeta: (meta) => dispatch(setUserMeta(meta)),
		fetchTeamUsers: (customer_id) => dispatch(fetchTeamUsers(customer_id)),
		setSelectedCustomer: (customer_id) => dispatch(setSelectedCustomer(customer_id)),
		fetchCustomers: (currentUser) =>  dispatch(fetchCustomers(currentUser)),
		fetchCustomerWebsites: (customer_id) => dispatch(fetchCustomerWebsites(customer_id))
    }
}

SplashWebsiteSelector.defaultProps = {
	activeWebsites:0
}

export default connect(mapStateToProps, mapDispatchToProps)(SplashWebsiteSelector)