import React, {useState, useEffect} from 'react'
import Loader from '../../components/general/Loader';
import {withWidgetContainer} from '../withWidgetContainer'

import { connect } from 'react-redux';

import {setVisitorMetricsResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import FormatNumber from '../../components/general/FormatNumber'
import ToolTip from '../../components/general/ToolTip'
import * as colours from '../../colours'
import {usePrevious} from '../utils'
import {redirectRouter} from '../../organisms/WidgetContainer/DropdownContainer'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const VisitorMetrics = (props) => {
    const resource = useSelector(({visitorMetrics}) => visitorMetrics.data)
    const isFetching = useSelector(({visitorMetrics}) => visitorMetrics.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setVisitorMetricsResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Skeleton variant="rect" width='100%' height={362} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
        >
            <h3 className="stat-group__title" style={{zIndex:0}}>{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
                <VisitorMetricsChild  {...props} resource={resource}/>
            </div>
        </Card>
    )
}

const MetricTab = ({value, label, formatArgs = {}, percentBar, help}) => {
    return <div  style={{backgroundColor: 'rgb(236 238 239)', margin: '5px', padding: '5px', borderRadius: '5px', display: 'grid', gridTemplateColumns: 'max-content auto'}}>
        <div style={{position: 'relative', borderRadius: '10px', marginRight: '10px', height: '100%', width: '5px', backgroundColor: 'rgb(212 214 228)'}}>
            {percentBar &&
                <span  style={{position: 'absolute', width: '100%', borderRadius: '5px', bottom: 0, background: percentBar.colour, height: '100%'}}></span>
            }
        </div>
        <div style={{position: "relative"}}>
            {help &&
                <span style={{position: 'absolute', right: '0px', top: '-4px'}}>
                    <ToolTip 
                        text={help.text}
                        tip={help.tip}
                    />
                </span>
            }
            <div className="text-medium">
                <FormatNumber val={value} {...formatArgs}/>
            </div>
            <div className="text-small">
                {label}
            </div>
        </div>
    </div>
}

const VisitorMetricsChild = (props) => {
    if (props.resource == null) return <Loader/>
    const metrics = props.resource.metrics.response
  

    const domain = {
        good: [0, 0.02, 0.08],
        bad: [0.8, 0.5, 0],
      }
  
    const getColour = (percent, key) => {
        if (percent >= domain[key][2]) {
          return colours.green;
        } else if (percent >= domain[key][1] && percent < domain[key][2]) {
          return colours.yellow;
        } else if (percent  >= domain[key][0] && percent < domain[key][1]) {
          return colours.red;
        } else {
          return colours.red;
        }
    }
    const getColourInverse = (percent, key) => {
         if (percent >= domain[key][2] && percent < domain[key][1]) {
          return colours.green;
        } else if (percent < domain[key][0] && percent >= domain[key][1]) {
            return colours.yellow;
        } else if (percent > domain[key][0] && percent <= 1) {
            return colours.red;
        } else {
          return colours.red;
        }
    }
    
    return <>

        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <div style={{padding: '10px',}}>
                <span >Out of </span>
                <span className="text-medium" style={{marginRight: '5px'}}><FormatNumber val={metrics.visits}/></span>
                <span>visits</span>
            </div>
        </div>
        <div style={{}}>
            <div style={{padding: '5px',display: 'grid', gridTemplateColumns: "50% 50%"}}>
                <MetricTab 
                    value={metrics.highlyRatedPercentage/100} 
                    formatArgs={({percent: true})} 
                    // metrics.highlyRatedPercentage
                    percentBar={({colour: getColour(metrics.highlyRatedPercentage/100, 'good'), value: metrics.highlyRatedPercentage + "%"})} 
                    label={"Highly rated"}
                    help={({
                        text: <i class="fas fa-question-circle"></i>,
                        tip: "Visitors that are rated 3/4/5 stars"
                    })}
                />
                <MetricTab 
                    value={metrics.manyPagesPercentage/100} 
                    formatArgs={({percent: true})} 
                    label={"Page turners"}
                    percentBar={({colour: getColour(metrics.manyPagesPercentage/100, 'good'), value: metrics.manyPagesPercentage + "%"})} 
                    help={({
                        text: <i class="fas fa-question-circle"></i>,
                        tip: "Visitors that view 6 or more page"
                    })}
                />
                <MetricTab 
                    value={metrics.longDurationPercentage/100} 
                    formatArgs={({percent: true})} 
                    label={"Long stayers"}
                    percentBar={({colour: getColour(metrics.longDurationPercentage/100, 'good'), value: metrics.longDurationPercentage + "%"})} 
                    help={({
                        text: <i class="fas fa-question-circle"></i>,
                        tip: "Visitors that are active 5 mins or more"
                    })}
                />
                <MetricTab 
                    value={metrics.didActionPercentage/100} 
                    formatArgs={({percent: true})} 
                    label={"Highly engaged"}
                    percentBar={({colour: getColour(metrics.didActionPercentage/100, 'good'), value: metrics.didActionPercentage + "%"})} 
                    help={({
                        text: <i class="fas fa-question-circle"></i>,
                        tip: "Visitors that do one or more engagement actions (phone, email, download, watch a video, fill out a form, become a lead)"
                    })}
                   
                />
            </div>
            <div style={{padding: '5px', marginTop: '5px',display: 'grid', gridTemplateColumns: "50% 50%"}}>
                <MetricTab 
                    value={metrics.zeroDurationPercentage/100} 
                    formatArgs={({percent: true})} 
                    label={"Phantoms"}
                    percentBar={({colour: getColourInverse(metrics.zeroDurationPercentage/100, 'bad'), value: metrics.zeroDurationPercentage + "%"})} 
                    help={({
                        text: <i class="fas fa-question-circle"></i>,
                        tip: "Visitors who have zero seconds duration"
                    })}
                />
                <MetricTab 
                    value={metrics.onePagePercentage/100} 
                    formatArgs={({percent: true})} 
                    label={"Fly by-ers"}
                    percentBar={({colour: getColourInverse(metrics.onePagePercentage/100, 'bad'), value: metrics.onePagePercentage + "%"})} 
                    help={({
                        text: <i class="fas fa-question-circle"></i>,
                        tip: "Visitors who only view 1 page"
                    })}
                />
            </div>
            <div style={{textAlign: 'center', marginTop: '5px'}}>
                <button className="button button--green" onClick={e => {
                    props.redirect(null,null,null,null,'visits')

                }}>View visits</button>
            </div>
        </div>
    </>
}

const mapStateToProps = state => {
	return {
		website: state.websites.selectedWebsite,
        currency: state.websites.selectedWebsite.__meta
	}
}


export default connect(mapStateToProps, null)(redirectRouter(VisitorMetrics))
