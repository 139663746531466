import React from 'react';
import moment from 'moment-timezone';
import StarRating from '../general/StarRating';
import VisitorActions from '../visitor/VisitorActions';
import { redirectRouter } from '../../organisms/WidgetContainer/DropdownContainer';
import VisitorSingleContainer from '../../containers/visitor/VisitorSingleContainer'
import BeaconRequest from '../../BeaconRequest';
import _ from 'lodash';

const VisitDetail = props => {

    const { 
        visit_id,
        star_rating, 
        totalTimeOnSite, 
        // startOfVisitTime,
        startOfVisitDate,
        // endOfVisitTime,
        __campaign,
        __channel,
        __link,
        scoringActions,
        visitorIdentified,
        added,
        lead
    } = props.visit

    return (
        <div>
            <div className="card">
                <div className="card__header">
                    <h2 className="card__title">Visit #{visit_id}</h2>
                </div>
                <div className="card__content">
                    <dl className="definition-list" style={{display:"grid", gridTemplateColumns:(visitorIdentified === 1 ? "3fr 3fr 4fr" : "1fr 1fr"), gridColumnGap:20}}>
                        <div>
                            <div className="definition-list__item">
                                <dt className="definition-list__title">Duration</dt>
                                <dd className="definition-list__value">
                                    {totalTimeOnSite > 3600 ? moment.utc(totalTimeOnSite*1000).format('HH:mm:ss') : moment.utc(totalTimeOnSite*1000).format('mm:ss')} <span className="text-small">({moment(startOfVisitDate).format('DD MMM YYYY')} at {moment(added).format('LT')})</span>
                                </dd>
                            </div>
                            <div className="definition-list__item">
                                <dt className="definition-list__title">Referring Link</dt>
                                <dd className="definition-list__value">
                                    <p className="margin-small">{ __link.notes }</p>
                                    <p>
                                        <button className="button button--small button--green" onClick={e => {
                                            BeaconRequest.get(`link/${__link.link_id}`, {__onlyCore: 1})
                                                .then(responseobj => 
                                                    props.redirect(
                                                        "link", 
                                                        __link.link_id, 
                                                        responseobj.response.__lifecycle.first.timestamp + ":" + responseobj.response.__lifecycle.last.timestamp, 
                                                        '/',
                                                        'analytics'
                                                    )
                                                )
                                        }}>Link details</button>
                                        <span className="text-small color-mid-grey" style={{marginLeft:10}}>
                                            { __campaign.title ? __campaign.title : "No campaign" } / { __channel.title ? __channel.title : "No channel" }
                                        </span>
                                    </p>
                                </dd>
                            </div>
                        </div>
                        
                        <div>
                            <div className="definition-list__item">
                                <dt className="definition-list__title">Rating</dt>
                                <dd className="definition-list__value">
                                    <StarRating stars={star_rating} />
                                </dd>
                            </div>

                            <div className="definition-list__item">
                                <dt className="definition-list__title">Key Visitor Actions</dt>
                                <dd className="definition-list__value" style={{marginTop:8}}>
                                    { !_.isEmpty(scoringActions) ? <VisitorActions lead={lead} actions={scoringActions} /> : "No actions" }
                                </dd>
                            </div>
                        </div>

                        {
                            visitorIdentified === 1 &&
                            <VisitorSingleContainer visitor_id={props.visit.accVisitor_id} />

                            // <Link to={{pathname: "/visitors/" + (props.visit.accVisitor_id), state: { modal: true } }} className="visitor-card">
                            //     <div className="visitor-card__detail">
                            //         <i className="visitor-card__icon fas fa-user-check"></i>
                            //         <h3>Identified Visitor</h3>
                            //         <h4>View their profile &rarr;</h4>
                            //     </div>
                            // </Link>
                        }

                    </dl>
                </div>
            </div>
        </div>
    )
}

export default redirectRouter(VisitDetail)