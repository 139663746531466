// Import action types
import { 
	FETCH_PAGES_REQUEST,
	FETCH_PAGES_SUCCESS,
	FETCH_SINGLE_PAGE_REQUEST,
	FETCH_SINGLE_PAGE_SUCCESS,
	SET_PAGES_TYPE
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_PAGES_REQUEST:
			return {
				...state,
				isFetchingPages: true
			}
		case FETCH_PAGES_SUCCESS:
			return {
				...state,
				pageList: action.responseObj.response,
				paging: action.responseObj.paging,
				isFetchingPages: false
			}
		case FETCH_SINGLE_PAGE_REQUEST:
			return {
				...state,
				isFetchingSinglePage: true
			}
		case FETCH_SINGLE_PAGE_SUCCESS:
			return {
				...state,
				singlePage: action.payload,
				isFetchingSinglePage: false
			}
		case SET_PAGES_TYPE: 
			return {
				...state,
				pagesType: action.pagesType
			}
		default:
			return state;
	}
}