import React, {useEffect, useState, useRef} from 'react'
import {
    ResponsiveContainer, BarChart as Chart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import moment from 'moment-timezone'

import { withWidgetContainer } from '../withWidgetContainer';
import Loader from '../../components/general/Loader';

import {redirectRouter} from '../../organisms/WidgetContainer/DropdownContainer'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ToolTip from '../../components/general/ToolTip'
import FormatNumber from '../../components/general/FormatNumber'
import * as colours from '../../colours'

import {setBarChartResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const BarChart = (props) => {
    const resource = useSelector(({barChart}) => barChart.data)
    const isFetching = useSelector(({barChart}) => barChart.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setBarChartResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Skeleton variant="rect" width='100%' height={301} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
            
        >
            <h3 className="stat-group__title" >{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
                <BarChartChild {...props} resource={resource}/>
            </div>
        </Card>
    )
}

const calculateBrushStartIndex = (x, len) => {
    return parseInt(len-(len*(x*0.25)))
}

const ComparisonFob = (props) => {

    const colour = props.increase ? colours.blue : colours.pink
    const icon = props.invert 
    ? props.increase ? <i className="fas fa-arrow-circle-down text-ms" /> :<i className="fas fa-arrow-circle-up text-ms" />
    : props.increase ? <i className="fas fa-arrow-circle-up text-ms" /> : <i className="fas fa-arrow-circle-down text-ms" />

    return <span style={{borderRadius: '5px', backgroundColor: colour, color: '#fff', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '1px',paddingTop: '1px',}}>
        {icon}{' '}
        { props.invert ? 
            props.increase ? "-" : "+" : 
            props.increase ? "+" : "-" 
        }
        {props.value}
    </span>
    
}

const BarChartChild = (props) => {
    const [barData, setBarData] = useState(null)
    const [type, setType] = useState("campaigns")

    useEffect(() => {
      const tmp = props.resource.graph
      if (props.filter.type === "website") {
        setType("campaigns")
      } else if (props.filter.type === "campaign") {
        setType("channels")
      } else if (props.filter.type === "channel") {
        setType("links")
      }
    
    }, [props.resource])

    const fetch_graph = props.resource.graph
    
    const analytics = fetch_graph.response.__analyticsTimeLimited

    let timeFormat = ''
    let prepend = ''
    switch(analytics.timePeriod.collation){
        case "minute":
            timeFormat = 'h:mma'
            break;
        case "hour":
            timeFormat = 'ha'
            break;
        case "day":
        default:
            timeFormat = 'ddd Do'
            break;
        case "week":
            timeFormat = 'Do MMM'
            prepend = 'w/c '
            break;
        case "month":
            timeFormat = 'MMM YY'
            break;
    }

    const getPath = (x, y, width, height, radius) => {
        return "M" + (x + radius) + "," + y + "h" + (width - 2*radius) 
        + "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius + "v" + 
        (height - 2*radius) + "v" + radius + "h" + -radius + "h" + 
        (2*radius - width) + "h" + -radius + "v" + -radius + "v" + 
        (2*radius - height) + "a" + radius + "," + radius + " 0 0 1 " 
        + radius + "," + -radius + "z";
    }

    const RoundedBar = (props) => {
        const {
            fill, x, y, width, height, radius, value, total
        } = props;

        if (height !== 0) {
            if (value[1] == total) {
                return <path d={getPath(x, y, width, height, radius)} stroke="none" fill={fill} />
            } else {
                return <rect x={x}  y={y}  width={width} height={height} fill={fill} stroke="none"/>
            }
        } else {
            return null
        }
    };

    const CusomtisedYAxisTick = (props) => {
        return  <g>
            <text x={props.x} y={props.y} fill="#989898" dy={0} dx={5}>
                {`${Number(props.payload.value).toLocaleString('en')}`}
            </text>
        </g>
    }

    const create_graph_data = () => {
        if (analytics.keys == null ) {
            
            return analytics.has_data === false ? [] : analytics.graph.map(node => 
                {
                    return {...node.core, start: node.start, end: node.end, 
                    name: node.start.timestamp
                }})
        } 
        else {
            return analytics.has_data === false ? [] : analytics.graph.map(node => {
                return {
                ...node.data, start: node.start, end: node.end, 
                name: node.data.name
             }})
        }
    }

    const graph_data = analytics.has_data === false  ? [{visits: 0, start: moment().unix(), end: moment().unix(), name:  timeFormat}] : create_graph_data()
    const create_bars = () => {
        if (analytics.keys != null) {
            
            return analytics.keys.map((key, index) =>  {
                // radius={index == analytics.keys.length -1 ? [5, 5, 0, 0] : [0, 0, 0, 0] }
                return <Bar key={key} dataKey={key} axisLine={false} isAnimationActive={false} stackId="a" fill={ analytics.swatch[key].colour} maxBarSize={65} />
            })
        } else {
            return <Bar dataKey={"visits"} axisLine={false} isAnimationActive={false} fill={fetch_graph.response.colour || fetch_graph.response.__channel.colour} maxBarSize={65} radius={[5, 5, 0, 0]}/>
        }
    }

    let bars = analytics.has_data === false  ? <Bar dataKey={"visits"} isAnimationActive={false} axisLine={false} /> : create_bars()   
    
    const CustomTooltip = ({ active, payload, label }) => {
        if (active) 
            {
            const title = payload != null && payload.length > 0 && payload[0].payload.total != null ?  `${moment.unix(label).format(timeFormat)} - ${payload[0].payload.total}` : `${moment.unix(label).format(timeFormat)}` 
            return (
                <div className="custom-tooltip" style={{
                    border: '1px solid #E6EBED', boxShadow: '0 1px 3px rgb(0 0 0 / 10%)', borderRadius: '5px'
                }}>
                <p className="label" style={{fontSize: "12px", lineHeight: '15px', fontWeight: "bold"}}>{title}</p>
                {payload != null && payload.slice(0).reverse().map((item, index ) => {
                    return <p 
                            key={index}
                            className="intro" 
                            style={{
                                "color": item.fill, 
                                fontSize: "12px",
                                marginTop: "4px",
                                fontWeight: "bold",
                                lineHeight: '20px'
                                }}>
                                {`${item.name} : ${item.payload[item.name]}`}
                        </p>
                })}
                       
                </div>
            );
            }
      
        return null;
    };

    const current = fetch_graph.response
    const previous = props.resource.previous.response

    const {
        visits,
        average_dwell_time,
        average_page_views,
        engaged_visitors,
        leads,
        new_to_link,
    } = current.__scoring.visits.has_data ? current.__scoring.visits : {
        visits: 0,
        average_dwell_time: 0,
        average_page_views: 0,
        engaged_visitors: 0,
        leads: 0,
        average_star_rating: 0,
        new_to_link: 0,
    }
    const repeat_to_link = visits - new_to_link;
    const { hard_bounce_rate, soft_bounce_rate, multi_page_rate } = (current.__scoring.visits.has_data? current.__scoring.visits.bounce_rates:{hard_bounce_rate:0, soft_bounce_rate:0, total_bounce_rate:0, multi_page_rate:0})

    const page_diff = (previous.__scoring.visits.has_data ? previous.__scoring.visits.average_page_views : 0) - average_page_views
    const bounce_diff = (previous.__scoring.visits.has_data ? previous.__scoring.visits.bounce_rates.hard_bounce_rate : 0) - hard_bounce_rate
    const dwell_diff = (previous.__scoring.visits.has_data ? previous.__scoring.visits.average_dwell_time : 0) - average_dwell_time
    const lead_diff = (previous.__scoring.visits.has_data ? previous.__scoring.visits.leads  : 0) - leads 

    const new_to_link_diff = (previous.__scoring.visits.has_data ? previous.__scoring.visits.new_to_link  : 0) - new_to_link
    const repeat_to_link_diff = (previous.__scoring.visits.has_data ? previous.__scoring.visits.visits-previous.__scoring.visits.new_to_link  : 0) - repeat_to_link

    const bounceRateToolTip = (
        <dl className="tooltip__list">
            <div className="tooltip__list-item">
                <dt><span className="key-dot red"></span>Hard rate:</dt>
                <dd>{hard_bounce_rate}%</dd>
            </div>
            <div className="tooltip__list-item">
                <dt><span className="key-dot yellow"></span>Soft rate:</dt>
                <dd>{soft_bounce_rate}%</dd>
            </div>
            <div className="tooltip__list-item">
                <dt><span className="key-dot green"></span>Multi-page rate:</dt>
                <dd>{multi_page_rate}%</dd>
            </div>
        </dl>
    )

    return <>
        <div style={{display: 'grid', gridTemplateRows: 'auto max-content', height: '100%'}}>
            <div style={{ width: '100%', height: '100%', position: 'relative' }}> 
                <div
                    style={{
                    width: '80%',
                    height: '100%',
                    position: 'absolute',
                    display: 'inline-block',
                    top: 0,
                    left: 0
                    }}
                >
                    <ResponsiveContainer isAnimationActive={false}>
                        <Chart
                            data={graph_data}
                            margin={{left: 0, bottom: 40 }}
                            onClick={data => {
                                if (data && analytics.has_data) {
                                    props.setVisitListModal({
                                        datetime: data.activePayload[0].payload.start.timestamp + ":" + data.activePayload[0].payload.end.timestamp,
                                        dateLabel: data.activeLabel
                                    })
                                }
                            }}
                            isAnimationActive={false}
                            margin={{bottom: 0, top: 15, left: 10, right: 0}}
                        >
                            <CartesianGrid vertical={false} strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="name" 
                                stackId
                                axisLine={false} 
                                tickLine={false}
                                tickFormatter={(label) =>  `${moment.unix(label).format(timeFormat)}`}
                                style={{
                                    fontSize: '13px',
                                    fill: '#989898'
                                }}
                            />
                            <YAxis 
                                orientation="right"
                                // domain={[0, 100]}
                                axisLine={false} 
                                tickLine={false} 
                                tick={<CusomtisedYAxisTick />}
                                
                                verticalAnchor="start" 
                            />
                            <Tooltip 
                                content={<CustomTooltip />}
                            />
                        
                            {bars}          
                        </Chart>
                    </ResponsiveContainer>
                </div>
                <div style={{maxWidth: '20%', height: '200px',float: 'right',display: 'inline-block',marginTop: '-15px'}}>
                    {analytics.has_data === false ? 
                        <div class="message message--notice margin-large" style={{height: '200px', margin: '10px'}}>
                            <h2 class="margin-small"><strong>No visits during this time period</strong></h2>
                            <p class="margin-small">None of your campaigns received traffic during the selected time period. Try changing the date range at the top of this screen. </p>

                        </div>
                    :
                    <List style={{overflow: 'auto', height: '200px' }}>
                    {analytics.keys != null ? 
                        analytics.keys.map(e => {
                            const swatch = fetch_graph.response.__analyticsTimeLimited.swatch[e]
                            
                            return (<div key={`legend-${e}`} onClick={() => {
                                props.redirect(type.substring(0, type.length -1), swatch.id)
                            }} 
                                style={{
                                    cursor: 'pointer', 
                                    fontSize: '13px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    padding: '5px'
                                }}>
                                <span className="key-dot" style={{border: 'none',backgroundColor: swatch.colour, minWidth: '11px'}}></span> {e}
                            </div>)
                        })
                        :
                        <ListItem onClick={() =>  {}} style={{fontSize: '13px'}}>
                            <span className="key-dot" style={{border: 'none',backgroundColor: fetch_graph.response.colour || fetch_graph.response.__channel.colour , minWidth: '11px'}}></span> {fetch_graph.response.title || fetch_graph.response.notes}
                        </ListItem>
                    }
                    
                    </List>
                    }
                </div>
            </div>
            <div style={{
                display: 'grid', 
                gridTemplateColumns: 'repeat(auto-fit, minmax(50px, 1fr))',
                borderTop: 'rgb(212 214 228) solid 1px',
                paddingTop: '10px'
                }}
            >
                    <div style={{ display: 'grid', gridTemplateColumns: 'auto', borderRight: 'rgb(212 214 228) solid 1px', paddingLeft: '10px' }}>
                        
                        <div>
                            <h3 className="text-medium"><FormatNumber val={new_to_link} /></h3>
                           
                            <h4 className="text-small">New visits</h4>
                            {new_to_link_diff !== 0 &&
                                <>
                                    {new_to_link_diff >0 ?
                                        <ComparisonFob decrease value={Math.abs(new_to_link_diff)}/>
                                        :  <ComparisonFob increase value={Math.abs(new_to_link_diff)}/>
                                    }
                                </>
                            }
                         </div>
                    </div>

                    <div style={{ display: 'grid', gridTemplateColumns: 'auto', borderRight: 'rgb(212 214 228) solid 1px', paddingLeft: '10px'}}>
                        
                        <div>
                            <h3 className="text-medium"><FormatNumber val={repeat_to_link} /></h3>
                            <h4 className="text-small" >Return visits</h4>
                            {repeat_to_link_diff !== 0 &&
                            <>
                                {repeat_to_link_diff >0 ?
                                    <ComparisonFob decrease value={Math.abs(repeat_to_link_diff)}/>
                                    :  <ComparisonFob increase value={Math.abs(repeat_to_link_diff)}/>
                                }
                            </>
                            }
                         </div>
                    </div> 


                    <div style={{ display: 'grid', gridTemplateColumns: 'auto', borderRight: 'rgb(212 214 228) solid 1px', paddingLeft: '10px'}}>
                        
                        <div>
                            <span style={{marginRight: '5px'}}>
                                {leads === 0 ? 
                                    <h3 className="text-medium" style={{display: 'inline'}}>{leads} </h3>
                                    : <ToolTip 
                                        hasPointer={true}
                                        hint={true}
                                        text={
                                            <h3 
                                            className="text-medium"
                                            onClick={() => props.setLeadsModal({datetime: props.datetime, page: 0,rowsPerPage: 10})}
                                            >
                                                <FormatNumber val={leads} />
                                            </h3>
                                            
                                        }
                                        style={{marginRight: '5px'}}
                                        tip={"Click to see "+ leads + " " + props.meta.lead_text.toLowerCase()}
                                    />
                                }
                            </span>

                            <h4 className="text-small">{props.meta.lead_text}</h4>

                            {lead_diff !== 0 &&
                                <>
                                    {lead_diff > 0 ?
                                        <ComparisonFob decrease value={Math.abs(lead_diff)}/>
                                        :  <ComparisonFob increase value={Math.abs(lead_diff)}/>
                                    }
                                </>
                            }
                        </div>
                    </div>

                    <div style={{display: 'grid', gridTemplateColumns: 'auto', borderRight: 'rgb(212 214 228) solid 1px', paddingLeft: '10px'}}>
                        
                        <div>
                            <span style={{marginRight: '5px'}}>
                                <ToolTip 
                                    hint={true}
                                    text={<h3 className="text-medium">{hard_bounce_rate}%</h3>}
                                    tip={bounceRateToolTip}
                                    
                                />
                            </span>
                            <h4 className="text-small">Bounce Rate</h4>
                            {bounce_diff !== 0 &&
                                <>
                                    {bounce_diff < 0 ?
                                         <ComparisonFob decrease invert value={Math.abs(bounce_diff.toFixed(1)) + "%"}/>
                                         :  <ComparisonFob increase invert value={Math.abs(bounce_diff.toFixed(1)) + "%"}/>
                                    }
                                </>
                            }  
                        </div> 
                    </div>

                    <div style={{display: 'grid', gridTemplateColumns: 'auto', borderRight: 'rgb(212 214 228) solid 1px', paddingLeft: '10px' }}>
                        
                        <div  style={{
                                whiteSpace:'nowrap',
                                overflow:'hidden',
                                textOverflow:'ellipsis',
                            }}>
                            <h3 className="text-medium" style={{display: 'inline', marginRight: '5px'}}>{moment.utc(average_dwell_time*1000).format('mm:ss')} </h3>
                            <h4 className="text-small">Avg. Time on Site</h4>
                            {dwell_diff !== 0 &&
                                <>
                                    {/* <h3 className="text-ms" style={{display: 'inline'}}>{moment.utc(Math.abs(dwell_diff)*1000).format('mm:ss')} </h3> */}
                                    {dwell_diff >0 ?
                                        <ComparisonFob decrease value={moment.utc(Math.abs(dwell_diff)*1000).format('mm:ss')}/>
                                        :  <ComparisonFob increase value={moment.utc(Math.abs(dwell_diff)*1000).format('mm:ss')}/>
                                    }
                                </>
                            }
                        </div>
                    </div>

                    <div style={{display: 'grid', gridTemplateColumns: 'auto', paddingLeft: '10px'}}>
                        
                        <div>
                            <h3 className="text-medium" style={{display: 'inline', marginRight: '5px'}}>{average_page_views} </h3>
                            <h4 className="text-small"> Pageviews</h4>
                            {page_diff !== 0 &&
                                <>
                                    {page_diff > 0 ?
                                        <ComparisonFob decrease value={Math.abs(page_diff.toFixed(2))}/>
                                        :  <ComparisonFob increase value={Math.abs(page_diff.toFixed(2))}/>
                                    }
                                </>
                            }
                        </div>
                    </div>
            </div>
        </div>
    </>
}

export default redirectRouter(BarChart)