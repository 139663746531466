// Import action types
import {
	TOGGLE_CREATE_LINK_MODAL,
	TOGGLE_NEWLY_CREATED_LINK_MODAL,
	// TOGGLE_CREATE_POST_MODAL,
	ADD_NOTIFICATION,
	REMOVE_NOTIFICATION,
	TOGGLE_DATE_RANGE_SELECT,
	TOGGLE_ELEMENT_DATE_RANGE_SELECT,
	SNOOZE_TRACKING_CODE_REMINDER,
    SET_CAMPAIGN_LINK_VIEW,
	SET_OVERLAY_ROOT,
	SET_SELECTED_TAB,
	TOGGLE_LINKS_ONLY_VISITS,
	TOGGLE_REPORT_LESSONS
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case TOGGLE_CREATE_LINK_MODAL:
			return {
				...state,
				showCreateLinkModal: !state.showCreateLinkModal
			}
		case TOGGLE_NEWLY_CREATED_LINK_MODAL:
			return {
				...state,
				showNewlyCreatedLinkModal: !state.showNewlyCreatedLinkModal
			}
		case TOGGLE_DATE_RANGE_SELECT: 
			return {
				...state,
				showDateRangeSelect: !state.showDateRangeSelect
			} 
		case TOGGLE_ELEMENT_DATE_RANGE_SELECT: 
			return {
				...state,
				showElementDateRangeSelect: !state.showElementDateRangeSelect
			} 
		case ADD_NOTIFICATION:
			return {
				...state,
				notifications: [...state.notifications, action.payload]
			}
		case REMOVE_NOTIFICATION: 
			// help for this here https://stackoverflow.com/questions/49617371/remove-item-from-redux-array-by-index
			return {
				...state,
				notifications: state.notifications.filter((item, index) => action.id !== index)
			}
		case SNOOZE_TRACKING_CODE_REMINDER: 
			return {
				...state,
				trackingCodeReminderSnoozed: action.payload
			}
		case SET_CAMPAIGN_LINK_VIEW:
			return {
				...state,
				campaignLinkView: action.viewId
			}
		case SET_OVERLAY_ROOT: 
			return {
				...state,
				overlayRoot: action.referrer
			}
		case SET_SELECTED_TAB: 
			return {
				...state,
				selectedTab: action.tabId
			}
		case TOGGLE_LINKS_ONLY_VISITS: 
			return {
				...state,
				showLinksOnlyVisits: !state.showLinksOnlyVisits
			} 
		case TOGGLE_REPORT_LESSONS:
			return {
				...state,
				showReportLessons: action.payload != null ? action.payload : !state.showReportLessons	
			}
		default:
			return state;
	}
}
