import React from 'react';
import DropdownList from '../general/DropdownList';

const DateFilter = props => {

	// Prepare the props for passing into the presentational component (Dropdown)
	let items = props.periods.map(period => {
		return { id: period.value, title: period.label }
	})

	// Check if this can contain a lifetime period
	// if(props.hasLifeTime) {
	// 	items.push( { id: '', title: "Lifetime" } )
	// }

	// Pass this into the menu object
	const menu = {
		title: props.selectedDateName,
		items: items
	}

	return (<div style={{fontSize: '9.5pt'}}>
		<DropdownList 
		menuSide={props.menuSide}
		clearSelectedItem={props.clearSelectedDate}
		selectItem={props.setSelectedDate} 
		isClearable={props.isClearable} 
		selectedItem={props.selectedDateValue} 
		menu={menu} 
		isDisabled={props.isDisabled}
		theme={props.theme || "dark"}
		/>
	</div>)
}

DateFilter.defaultProps = {
	selectedDateName: "Lifetime"
}

export default DateFilter;