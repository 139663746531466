var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducer from "./reducers/index";
import ReduxThunk from "redux-thunk";
import { loadState, saveState } from "./localStorage";
import Body from "./atoms/Body";
import { enableMapSet } from "immer";
enableMapSet();
var GuestApp = React.lazy(function () { return import("./GuestApp"); });
// Load in the persisted state (may be empty)
// Note that we are also storing initialState in "localStorage.js" as a catch all if no state is found
var persistedState = loadState();
export var store = createStore(reducer, persistedState, applyMiddleware(ReduxThunk));
// Save the state on change
store.subscribe(function () {
    saveState(store.getState());
});
if (window.location.hostname.indexOf("analytics.thisisbeacon.com") == 0 ||
    window.location.hostname.indexOf("qa.thisisbeacon.com") == 0 ||
    window.location.hostname.indexOf("localhost") == 0 ||
    window.location.hostname.indexOf("insights.thisisbeacon.com") == 0 ||
    window.location.hostname.indexOf("demo.thisisbeacon.com") == 0) {
    ReactDOM.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(Body, { children: _jsx(App, {}) }) })), document.getElementById("root"));
}
else {
    ReactDOM.render(_jsx(Suspense, __assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(GuestApp, {}) })), document.getElementById("root"));
}
(function () {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("/service-worker.js");
    }
})();
