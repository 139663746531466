import React, { Component } from 'react';
import Help from '../../components/general/Help';
import {help} from '../../constants';
import { TransitionGroup } from 'react-transition-group';

class HelpContainer extends Component {
	constructor(){
		super()
		this.state = {
			isOpen: false
		}
		this.openHelp = this.openHelp.bind(this);
		this.closeHelp = this.closeHelp.bind(this);
	}

	openHelp(){
		this.setState({ isOpen: true })
	}

	closeHelp(){
		this.setState({ leaving: true })
		setTimeout( () => {
			this.setState({
				isOpen: false,
				leaving: false
			})
		}, 450)
	}

	render() {
		if(this.state.isOpen){
			return (
				<React.Fragment>
					<TransitionGroup
					transitionName="help-overlay"
					transitionAppear={true}
					transitionEnter={false}
					transitionLeave={false}
					transitionAppearTimeout={200}>

						<div className={"help-overlay "  + ( this.state.leaving ? "leave" : "" ) } onClick={this.closeHelp}></div>

					</TransitionGroup>

					<TransitionGroup
					transitionName="help"
					transitionAppear={true}
					transitionEnter={false}
					transitionLeave={false}
					transitionAppearTimeout={200}>
					
						<div className={"help "  + ( this.state.leaving ? "leave" : "" ) }>
							<Help closeHelp={this.closeHelp} content={help[this.props.id]} /> 
						</div>

					</TransitionGroup>

					<button tabIndex="-1" type="button" className="button button--small button--transparent"><i className="fas fa-info-circle"></i></button>
				</React.Fragment>
			)
		}
		return <button tabIndex="-1" type="button" onClick={this.openHelp} className="button button--small button--transparent"><i className="fas fa-info-circle"></i></button>
	}
}

export default HelpContainer;