  
import { actionTypes } from "./constants";

export const setFilter = (payload) => ({
    type: actionTypes.SET_FILTER,
    payload
})

export const setInvalidState = (payload) => ({
    type: actionTypes.SET_INVALID_STATE,
    payload
})

export const setPerformanceTabularView = (payload) => ({
    type: actionTypes.SET_PERFORMANCE_TABULAR_VIEW,
    payload
})

export const adConnections = () => ({
    
})

// export const fetchCampaignsList = (payload) => ({
//     type: actionTypes.SET,
//     payload
// })


// export const fetchChannelsList = (payload) => ({
//     type: actionTypes.SET_FILTER,
//     payload
// })

// export const fetchLinksList = (payload) => ({
//     type: actionTypes.SET_FILTER,
//     payload
// })