  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createBrowserProfileResource = ({timePeriod, filter, disableCache,cancelToken}) => {

    let browser
    switch (filter.type) {
        case "website":
        default: 
            browser = `website/${filter.payload.id}/browser/${timePeriod}`
            break;
        case "campaign":
            browser = `campaign/${filter.payload.id}/browser/${timePeriod}`
            break;
        case "channel":
            browser = `channel/${filter.payload.id}/browser/${timePeriod}`
            break
        case "link":
            browser = `link/${filter.payload.id}/browser/${timePeriod}`
            break
    }
    
    return {
        browser: [browser, null, {cancelToken: cancelToken.token}],
    }
}


export const setBrowserProfileResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_BROWSER_PROFILE_FETCHING,
            fetching: true
        })

        const previousToken = getState().browserProfile.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("media spend request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_BROWSER_PROFILE_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createBrowserProfileResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_BROWSER_PROFILE_DATA,
                    payload: payload
                })
                
                dispatch({
                    type: actionTypes.IS_BROWSER_PROFILE_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_BROWSER_PROFILE_FETCHING,
                        fetching: false
                    })
                } 
            })
    }
}