import React, { Component } from 'react'
import Flatpickr from 'react-flatpickr'
import { toggleDateRangeSelect, addNotification } from '../../actions/ui'
import { connect } from 'react-redux';
import { setSelectedDate } from '../../actions/dates';
import moment from 'moment-timezone'
import { redirectRouter } from '../../organisms/WidgetContainer/DropdownContainer';
import ReactDOM from 'react-dom'

class DateRangeSelect extends Component {
	constructor(){
		super();
		this.state = {
			leaving: false,
			id: ""
		}
		this.handleChangeDate = this.handleChangeDate.bind(this)
		this.handleClose = this.handleClose.bind(this)
	}

	componentDidMount() {
		document.body.style.overflow = 'hidden';
	} 

	componentWillUnmount() {
		document.body.style.overflow = 'unset'
	}

	handleChangeDate(date){
		const timeZoneOffset = date.date[0].getTimezoneOffset() + moment(date.date[0]).utcOffset()

		const dateFrom = moment(date.date[0]).subtract(timeZoneOffset, 'minutes').startOf('day')
        const dateTo = moment(date.date[1]).subtract(timeZoneOffset, 'minutes').endOf('day')

		const title = moment(dateFrom).format("DD MMM YY") + ' - ' + moment(dateTo).format("DD MMM YY")

		const dateFromUnix = moment(dateFrom).unix()
		let dateToUnix = moment(dateTo).unix()
		this.setState({
			dateFrom: dateFrom,
			dateTo: dateTo,

			title: title,
			id: dateFromUnix + ':' + dateToUnix
		})
	}

	handleClose(action){
		this.setState({ 
			leaving: true
		})
		setTimeout(() => {
			this.props.toggleDateRangeSelect()

			if(action !== 'cancel'){
				if(this.state.dateFrom != this.props.dateFrom || this.state.dateTo != this.props.dateTo) {
					this.props.redirect(null, null, this.state.id)
				}
			}

			this.setState({
				leaving: false,
				title: null
			})

			document.body.style.overflow = 'unset'
		}, 200)
	}

	render() {
		return (
			ReactDOM.createPortal(
				<>
					<React.Fragment>
						<div style={{zIndex: 1300}} className={"date-range-select-mask " + (this.state.leaving ? "leave" : "")} onClick={() => this.handleClose('cancel')}></div>
						<div style={{zIndex: 1300}} className={"date-range-select " + (this.state.leaving ? "leave" : "")}>
							<div className="date-range-select__picker">
								<Flatpickr
								value={this.props.selectedDates}
								options={ { mode:'range', defaultHour: 0, inline: true, maxDate: moment().subtract(1 ,'day').startOf('day').toDate() } }
								onChange={date => { this.handleChangeDate({date}) }} 
								onDayCreate={function(dObj, dStr, fp, dayElem){
								}}
								/>
							</div>
							<ul className="date-range-select__tools">
								<li className="date-range-select__dates">{this.state.title}</li>
								<li><button className="button button--small button--white hollow" onClick={() => this.handleClose('cancel')}>Cancel</button></li>
								<li><button className="button button--small button--yellow" onClick={this.handleClose}>Set Dates</button></li>
							</ul>
						</div>
					</React.Fragment>
				</>,
				document.getElementById('modal-root')
			)
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isOpen: state.ui.showDateRangeSelect,
		selectedDates: state.dates.selectedDates
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setSelectedDate: (date) => dispatch(setSelectedDate(date)),
		toggleDateRangeSelect: () => dispatch(toggleDateRangeSelect()),
		addNotification: (payload) => dispatch(addNotification(payload))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(redirectRouter(DateRangeSelect));
