import React, { Component } from 'react';
import { connect } from 'react-redux';
import {S3Client , PutObjectCommand, PutObjectAclCommand} from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity"; 
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

class MediaUpload extends Component {
	constructor(props){
		super(props)
		this.handleFileUpload = this.handleFileUpload.bind(this);

		if(this.props.aspectRatio){
			if(this.props.aspectRatioWidth < 1 && props.aspectRatioHeight < 1){
				console.error("Aspect Ratio is enabled but width &/ height were not specified so has been turned off");
				this.props.aspectRatio = false;
			}
		}
	}

	testImageAspect(file){
		let self = this;
		return new Promise((success, fail) => {
			let img = new Image();
			let objectUrl = URL.createObjectURL(file);
			img.onload = function () {
				let imgAspect = (img.width / img.height).toFixed(2);;
				if((self.props.aspectRatioWidth / self.props.aspectRatioHeight).toFixed(2) === imgAspect){
					URL.revokeObjectURL(objectUrl);
					success()
				}else{
					URL.revokeObjectURL(objectUrl);
					fail();
				}
			};
			img.src = objectUrl;
		});
	}

	testImageWidth(file){
		let self = this;
		return new Promise((success, fail) => {
			let img = new Image();
			let objectUrl = URL.createObjectURL(file);
			img.onload = function () {
				if(typeof self.props.minWidth !== "undefined"){
					if(img.width < self.props.minWidth) fail("Image width smaller than "+self.props.minWidth);
				}
				
				if(typeof self.props.maxWidth !== "undefined"){
					if(img.width > self.props.maxWidth) fail("Image width larger than "+self.props.maxWidth);
				}
				success();
			};
			img.src = objectUrl;
		});
	}

	testImageHeight(file){
		let self = this;
		return new Promise((success, fail) => {
			let img = new Image();
			let objectUrl = URL.createObjectURL(file);
			img.onload = function () {
				if(typeof self.props.minHeight !== "undefined"){
					if(img.height < self.props.minHeight) fail("Image height smaller than "+self.props.minHeight);
				}
				
				if(typeof self.props.maxHeight !== "undefined"){
					if(img.height > self.props.maxHeight) fail("Image height larger than "+self.props.maxHeight);
				}
				success();
			};
			img.src = objectUrl;
		});
	}

	async checkImageAspect(file){
		if(typeof this.props.aspectRatio !== "undefined"){
			let state;
			await this.testImageAspect(file).then(()=>{state = true;}).catch(()=>{state = false;});
			return state;
		}else{
			return true;
		}
	}

	async checkImageWidth(file){
		if(typeof this.props.minWidth !== "undefined" || typeof this.props.maxWidth !== "undefined"){
			let state;
			await this.testImageWidth(file).then(()=>{state = true;}).catch((reason)=>{state = reason;});
			return state;
		}else{
			return true;
		}
	}

	async checkImageHeight(file){
		if(typeof this.props.minHeight !== "undefined" || typeof this.props.maxHeight !== "undefined"){
			let state;
			await this.testImageHeight(file).then(()=>{state = true;}).catch((reason)=>{state = reason;});
			return state;
		}else{
			return true;
		}
	}

	async handleFileUpload(){
		// Require sha for encoding the new filename
		let sha1 = require('sha1');

		const client = new S3Client({ 
            region: "eu-west-1",
            credentials: new fromCognitoIdentityPool({
				client: new CognitoIdentityClient({ region: "eu-west-1" }),
                identityPoolId: 'eu-west-1:9027dcac-4de2-45b3-b988-ffcf4697c80d'
            }),
        });

		let gcd = (a, b) => {
            return (b == 0) ? a : gcd (b, a%b);
        }

		// 
		let files = document.getElementById('photoupload').files;

		if (!files.length) {
			return alert('Please choose a file to upload first.');
		}

		for(let i = 0; i < files.length; i++){
			// get the individual file
			let file = files[i];

			// let checkAspect = await this.checkImageAspect(file);
			// if(checkAspect === false){
			// 	let ratio = gcd(this.props.aspectRatioWidth, this.props.aspectRatioHeight);
			// 	let ratioWidth = this.props.aspectRatioWidth / ratio;
			// 	let ratioHeight = this.props.aspectRatioHeight / ratio;
			// 	let aspectPercent = ((this.props.aspectRatioWidth/this.props.aspectRatioHeight)*100).toFixed(2);
			// 	return alert(`There was an error uploading your media: Image Aspect Ratio incorrect it must be '${ratioWidth}:${ratioHeight}(${aspectPercent}%)`);
			// }

			// let checkWidth = await this.checkImageWidth(file);
			// if(checkWidth !== true){
			// 	return alert(`There was an error uploading your media: ${checkWidth}`);
			// }

			// let checkHeight = await this.checkImageHeight(file);
			// if(checkHeight !== true){
			// 	return alert(`There was an error uploading your media: ${checkHeight}`);
			// }
			// split the file at the extension
			let fileNameParts = file.name.split('.');

			// actually get the extension
			let fileExtension = fileNameParts[fileNameParts.length - 1];

			// check the file is allowed to be uploaded by extension
			// if( ( this.state.imageFormats.includes(fileExtension) && (fileSize < this.state.imageSize) ) || ( this.state.videoFormats.includes(fileExtension) && (fileSize < this.state.videoSize) )){
			// create a new random string
			let random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

			// sha1 encode the random
			random = sha1(random.toString())

			// construct the filename
			let filePath = 'website_' + this.props.selectedWebsite + '/' + random + '.' + fileExtension
		
			const Bucket = 'beacon.public-upload';

			const putCommand = new PutObjectCommand({
				Bucket,
				Key: filePath,
				Body: file,
			});

			const aclCommand = new PutObjectAclCommand({
				ACL: "public-read",
				Bucket,
				Key: filePath,
			});

			try {
				await client.send(putCommand);
				await client.send(aclCommand);
				const location = `https://s3.eu-west-1.amazonaws.com/${Bucket}/${filePath}` //getResourceUrl instead

				this.props.handleUploadMedia(location)
			} catch (err) {
				console.log(err)
				return alert('There was an error uploading your media: ', err.message);
			} 
		}

	}

	render() {
		return (
			<React.Fragment>
				<label 
					htmlFor="photoupload" 
					className="button button--large button--green"
					style={{
						background: this.props.disabled && 'lightgray',
						color: this.props.disabled && 'gray',
					}}
					onClick={e => {
						if (this.props.disabled) {
							e.preventDefault();
							e.stopPropagation();
							return;
						}
					}}
				>
					<i className="fas fa-camera" style={{paddingRight:8}}></i>Select file
				</label>
				<input 
					style={{display:"none"}} 
					onChange={this.handleFileUpload} 
					type="file" 
					id="photoupload" 
					accept="image/png, image/jpeg, image/jpg, image/TIFF"
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
  return {
    selectedWebsite: state.websites.selectedWebsiteId // check if a website has been selected and assign to props
  }
}

export default connect(mapStateToProps, null)(MediaUpload)