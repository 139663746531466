import BeaconRequest from '../BeaconRequest';
import { addNotification } from './ui'

// Import action types
import { 
	FETCH_TEAM_USERS
} from './actionTypes';

export const fetchTeamUsers = (customer_id) => {
	return (dispatch) => {
		return BeaconRequest.getNoChache('customer/' + customer_id + '/team', {})
		.then(responseObj => {
			dispatch({
				type: FETCH_TEAM_USERS,
				teamUsers: responseObj.response
			})
			return responseObj
		})
	}
}

export const addUserToTeam = (payload) => {
	return (dispatch) => {
		let userRole = 3
		if(payload.readOnly){
			userRole = 4
		}
		return BeaconRequest.post(
			'customer/' + payload.customer_id + '/team', {},
			{
				"email":payload.email,
				"role_id":userRole
			}
		)
		.then(responseObj => {
			const payload = {
				title: "Your team member has been added and notified by email",
				theme: "positive"
			}
			dispatch(addNotification(payload))
		})
		.catch(error => {
			// If the person already exists on this team
			if(error.code === 1422){
				const payload = {
					title: "The person you invited already exists on this team",
					theme: "negative"
				}
				dispatch(addNotification(payload))
			}
		})
	}
}

export const removeUserFromTeam = (customer_id, user_id) => {
	return (dispatch) => {
		return BeaconRequest.delete('customer/' + customer_id + '/team/' + user_id, {})
		.then(responseObj => {
			const payload = {
				title: "Person successfully removed from your team and notified by email",
				theme: "positive"
			}
			dispatch(addNotification(payload))
		})
		.catch(error => {
			console.log(error)
		})
	}
}

export const updateUser = (customer_id, email, role_id) => {
	return (dispatch) => {
		let role = null
		if(role_id === 3){ role = 4 } else { role = 3 }
		return BeaconRequest.post(
			'customer/' + customer_id + '/team', {},
			{
				"email":email,
				"role_id":role
			}
		)
		.then(responseObj => {
			const payload = {
				title: "Your team member has been updated and notified by email",
				theme: "positive"
			}
			dispatch(addNotification(payload))
		})
		.catch(error => { console.log(error) })
	}
}