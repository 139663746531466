import React from 'react'
import BarChart from './BarChart'
// import ReportsList from './ReportsList'
import ConversionChart from './ConversionChart'
// import LinkSuite from './LinkSuite'
import BotActivity from './BotActivity'
// import BreakdownStats from './BreakdownStats'
import CampaignsList from './CampaignsList'
import BrowserProfile from './BrowserProfile'
import VisitorProfile from './VisitorProfile'
import MediaSpend from './MediaSpend'
// import SkeletonLoader from './BarChart/SkeletonLoader'
import BotImpactGraph from './BotImpactGraph'
import BudgetEffectiveness from './BudgetEffectiveness'
import PerformanceList from './PerformanceList'
import VisitorMetrics from './VisitorMetrics'

export const widgets_list = [


    {
        icon: <i class="fas fa-chart-bar"></i>,
        id: 'bot_impact_widget',
        title: 'Bot impact',
        description: '',
        content: (props) => <BotImpactGraph  {...props}/>,
        zIndex: 1,
        layout: {
            minW: 1,
            minH: 1,
            w: 2,
            h: 1,
        },
    },


    {
        icon: <i class="fas fa-chart-bar"></i>,
        id: 'website_traffic_widget',
        title: 'Website Traffic',
        description: '',
        content: (props) => <BarChart  {...props}/>,
        zIndex: 1,
        layout: {
            minW: 1,
            minH: 1,
            w: 2,
            h: 1,
        },
    },
    // {
    //     icon: <i class="fas fa-chart-line"></i>,
    //     id: 'breakdown_stats_widget',
    //     title: 'Summary', //Engagement Analytics
    //     description: '',
    //     content: (props) => <BreakdownStats  {...props}/>,
    //     layout: {
    //         minW: 1,
    //         minH: 1,
    //         w: 1,
    //         h: 1,
    //     },
    // },
    
    {
        icon: <i class="fas fa-chart-line"></i>,
        id: 'media_spend_widget',
        title: 'Media Spend', //Engagement Analytics
        description: '',
        content: (props) => <MediaSpend  {...props}/>,
        layout: {
            minW: 1,
            minH: 1,
            w: 1,
            h: 1,
        },
    },
    // {
    //     icon: <i class="fas fa-file-pdf"></i>,
    //     id: 'reports_list_widget',
    //     title: 'Reports',
    //     description: '',
    //     content: (props) => <ReportsList {...props}/>,
    //     layout: {
    //         minW: 2,
    //         minH: 1,
    //         w: 2,
    //         h: 1,
    //     },
    // },
    
    {
        icon: <i class="fas fa-chart-pie"></i>,
        id: 'conversion_funnel_widget',
        title: 'Conversion Funnel', 
        description: '',
        funnelId: 'dashboard',
        content: (props) => <ConversionChart {...props}/>,
        layout: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
        },
    },

    {
        icon: <i class="fas fa-star-half-alt"></i>,
        id: 'browser_profile_widget',
        title: 'Visitor Profile',
        description: '',
        content: (props) => <BrowserProfile {...props}/>,
        layout: {
            minW: 1,
            minH: 1,
            w: 1,
            h: 1,
        },
    },
    // {
    //     icon: <i class="fas fa-share-alt"></i>,
    //     id: 'links_suite_widget',
    //     title: 'Link Suite',
    //     description: '',
    //     content: (props) => <LinkSuite {...props}/>,
    //     layout: {
    //         minW: 1,
    //         minH: 1,
    //         w: 1,
    //         h: 1,
    //     },
    // },
    {
        icon: <i class="fas fa-robot" />,
        id: 'bot_activity_widget',
        title: 'Bot Protection',
        description: '',
        content: (props) => <BotActivity {...props}/>,
        
        layout: {
            minW: 1,
            minH: 1,
            w: 1,
            h: 1,
        },
    },
    {
        icon: <i class="far fa-list-alt"></i>,
        id: 'campaigns_list_widget',
        title: 'Campaigns',
        description: '',
        content: (props) => <CampaignsList {...props} />,
        layout: {
            minW: 1,
            minH: 1,
            w: 2,
            h: 1,
        },
        overflow: 'visible',
        noCanvas: true, 
        noPadding: true, 
        hiddenTitle: true,
    },
    {
        icon: <i class="far fa-list-alt"></i>,
        id: 'performance_list_widget',
        title: 'Campaigns',
        description: '',
        content: (props) => <PerformanceList {...props} />,
        layout: {
            minW: 1,
            minH: 1,
            w: 2,
            h: 1,
        },
        overflow: 'auto',
        noCanvas: true, 
        noPadding: true, 
        hiddenTitle: true,
    },
    {
        icon: <i class="far fa-list-alt"></i>,
        id: 'visitor_metrics_widget',
        title: 'Visit Quality',
        description: '',
        content: (props) => <VisitorMetrics {...props} />,
        layout: {
            minW: 1,
            minH: 1,
            w: 2,
            h: 1,
        },
    },
    {
        icon: <i class="far fa-list-alt"></i>,
        id: 'visitor_profile_widget',
        title: 'Visitor Locations',
        description: '',
        content: (props) => <VisitorProfile {...props} />,
        layout: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
        },
        overflow: 'hidden',
        noPadding: true,
    },

    {
        icon: <i class="far fa-list-alt"></i>,
        id: 'budget_effectiveness_widget',
        title: 'Budget Effectiveness',
        description: '',
        content: (props) => <BudgetEffectiveness {...props} />,
        layout: {
            minW: 2,
            minH: 1,
            w: 2,
            h: 1,
        },
        // overflow: 'hidden',
        // noPadding: true,
    },
    // {
    //     id: 'new_start_progress_widget',
    //     title: 'Progress List',
    //     description: '',
    //     content: (props) => null
    // },
    // {
    //     id: 'wasted_spend_widget',
    //     title: 'Wasted Spend Graph',
    //     description: 'Wasted vs Effect spend',
    //     content: (props) => <SkeletonLoader />
    // },

]

