import React, {useEffect, useState, useRef} from 'react'
import FunnelGraph from './js/main'
import Loader from '../../components/general/Loader';
import {withWidgetContainer} from '../withWidgetContainer'
import { withRouter } from "react-router";
import ResizeSensor  from 'css-element-queries/src/ResizeSensor'

import * as colours from '../../colours'

import {setConversionChartResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const ConversionChart = (props) => {
    const resource = useSelector(({conversionChart}) => conversionChart.data)
    const isFetching = useSelector(({conversionChart}) => conversionChart.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const containerRef = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setConversionChartResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter]) 

    if (resource == null) {
        return <div style={{position: 'relative'}}>
            <Skeleton variant="rect" width='100%' height={301} style={{borderRadius: '5px'}} /> 
        </div>
    }

    return (
        <Card 
            style={{
                background: props.noCanvas ? 'transparent' : null, 
                border: props.noCanvas ? 'none' : null, 
                position: 'relative',
            }}
            ref={containerRef}
        >
            <h3 className="stat-group__title" >{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                padding: props.noPadding ? 0 : '1%',
                paddingTop: props.noPadding ? 0 :  '20px',
                overflow: props.overflow || "visible", 
                filter: isFetching && 'blur(1px)' 
            }}>
                <ConversionChartChild containerRef={containerRef} {...props} resource={resource}/>
            </div>
        </Card>
    )
}

const ConversionChartChild = (props) => {
    const settings = {
        container: `#funnel-${props.funnelId}`,
        displayPercent: false,
        gradientDirection: props.direction || 'vertical',
        direction: props.direction || 'vertical',
        subLabelValue: "number",
        data: {
            labels: [ 'All visits', 'Engaged visits', props.meta.lead_text],
            onLabelClick: [null, null, null,],
            onLabelHover: [ null,null, null,],
            subLabels: ["", "", ""],
            colors: [ "", "", ""],
            values: [[0,0,0], [0,0,0], [0,0,0]]
        },
    }

    const [graph, setGraph] = useState(new FunnelGraph(settings))

    const ref = useRef(null)
    
    useEffect(() => {
        if (ref != null) {
            graph.draw() 
        }
    }, [ref])

    useEffect(() => {
        if (ref != null) {
            const cwt = tmp = props.resource.campaigns_with_traffic.response
            if (cwt == null || cwt.sublabels.length == 0) {
                graph.update(settings);
            } else {
                settings.data.onLabelClick = [null, 
                    () => props.setEngagementModal({datetime: props.datetime, page: 0,rowsPerPage: 5}), 
                    () => {props.setLeadsModal({datetime: props.datetime, page: 0,rowsPerPage: 5})}]
                settings.data.subLabels = cwt.sublabels
                // settings.data.labels = cwt.labels
                settings.data.colors = cwt.colours
                settings.data.values = cwt.values
                graph.update(settings);
            }
        }
    }, [props.resource, ref])

    useEffect(() => {
        let sensor = new ResizeSensor(props.containerRef.current, () => {
            const width = props.containerRef.current.offsetWidth
            if (props.direction == 'horizontal') {
                graph.updateWidth(width - 60)
                graph.updateHeight(props.containerRef.current.offsetHeight - 120)
            } else {
                graph.updateWidth(width -140)
                graph.updateHeight(props.containerRef.current.offsetHeight - 120)
            }
        })
        return () => {
            sensor.detach()
        }
    }, [props.containerRef])


    if (props.resource == null) return <Loader/>
    let tmp = props.resource.campaigns_with_traffic.response 

    return <div style={{padding: '0 20px'}}><div ref={ref} id={`funnel-${props.funnelId}`} style={{width: '100%', height: '95%',}}></div></div>
    
}

export default withRouter(ConversionChart)