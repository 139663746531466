import React, { Component, useEffect, } from 'react';
import { connect } from 'react-redux';
import DateFilter from '../../components/date/DateFilter';
import { setSelectedDate, unsetSelectedDate } from '../../actions/dates';
import { toggleDateRangeSelect } from '../../actions/ui'
import { periods } from '../../constants'
import BeaconRequest from '../../BeaconRequest';
import moment from 'moment-timezone'

import {redirectRouter} from '../../organisms/WidgetContainer/DropdownContainer'
import {setInvalidState} from '../../organisms/WidgetContainer/actions'
import { useSelector, useDispatch } from 'react-redux'

const DateFilterContainer = (props) => {
	// const invalidState = useSelector(({global}) => global.invalidState)
	const dispatch = useDispatch()

	const queryDate = (date) => {
		const urlParams = new URLSearchParams(window.location.search);

		dispatch(setInvalidState(false))
		if (props.filter.type == "website") {
			props.redirect(null, null, date.id)
		} else {
			props.redirect(urlParams.get('model'), urlParams.get('id'), date.id)
		}
	}

	const setSelectedDate = (date) => {
		/**
			Date may come through with an id or it may be blank
			if it comes through with an id we need to check the id and
			if it's not custom, set the selected date. if it is custom
			open a calendar
		*/
		if(date.id === "custom"){
			props.toggleDateRangeSelect()
		} else {
			const tmp = date
			tmp.id = date.id.replace("last", "previous")
			queryDate(tmp)
		}
	}

	useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const period = urlParams.get('period');

		if (period != null) {
			try {
				if (period.includes(":")) {
					const split = period.split(":")
					props.setSelectedDate({title: moment.unix(split[0]).format("MMM Do YY") + " - " + moment.unix(split[1]).format("MMM Do YY"), id: period})
				} else {
					const id = period.replace("previous", "last")
					props.setSelectedDate({title: periods.filter(item => item.value == id)[0].label, id})
				}
			} catch (e) {
				console.log(e)
				dispatch(setInvalidState(true))
			}
			
		}
    }, [props.location])

	const clearSelectedDate = () => {
		props.unsetSelectedDate()
	}

	return (
		<DateFilter 
			clearSelectedDate={clearSelectedDate}
			selectedDateValue={props.selectedDateValue} 
			selectedDateName={props.selectedDateName}
			periods={periods} 
			setSelectedDate={setSelectedDate} 
			isDisabled={props.isDisabled}
			theme={props.theme || "dark"}
		/>
	)
	
}

const mapStateToProps = (state) => {
	return {
		selectedDateValue: state.dates.selectedDateValue,
		selectedDateName: state.dates.selectedDateName,
		filter: state.global.filter
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
        setSelectedDate: (date) => dispatch(setSelectedDate(date)),
        unsetSelectedDate: () => dispatch(unsetSelectedDate()),
        toggleDateRangeSelect: () => dispatch(toggleDateRangeSelect())
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(redirectRouter(DateFilterContainer));