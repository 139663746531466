  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createPerformanceListResource = ({filter, timePeriod, paging, disableCache, cancelToken}) => {
  
    let query, graph_query
    let rating_query
    let qs
      switch (filter.type) {
          case "website":
          default: 
              graph_query = `website/${filter.payload.id}/pd/campaigns/${timePeriod}`
              
              break;
          case "campaign":
              graph_query = `campaign/${filter.payload.id}/pd/channels/${timePeriod}`
              break;
          case "channel":
              graph_query = `channel/${filter.payload.id}/pd/links/${timePeriod}`
              break
    }

    return {
        graph: [graph_query, null, {cancelToken: cancelToken.token}],
        onlyCore: [`${filter.type}/${filter.payload.id}`, {__onlyCore: 1}, {cancelToken: cancelToken.token}]
    }
}

export const setPerformanceListResource = (args) => {
  return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_PERFORMANCE_LIST_FETCHING,
            fetching: true
        })

        const previousToken = getState().performanceList.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("performance list request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_PERFORMANCE_LIST_CANCEL_TOKEN,
            previousRequestSource: source
        })

      createResourceFromQueries(createPerformanceListResource({...args, cancelToken: source}), args.disableCache)
        .then(payload => {
            dispatch({
                type: actionTypes.SET_PERFORMANCE_LIST_DATA,
                payload: payload
            })
            
            dispatch({
                type: actionTypes.IS_PERFORMANCE_LIST_FETCHING,
                fetching: false
            })
        })
        .catch((thrown) => {
            if (axios.isCancel(thrown)) {
            }

            dispatch({
                type: actionTypes.IS_PERFORMANCE_LIST_FETCHING,
                fetching: false
            })
        })
  }
}