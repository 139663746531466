import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchVisits } from '../../actions/visits';
import VisitsList from '../../components/visit/VisitsList'
import Loader from '../../components/general/Loader';

class VisitsListContainer extends Component {
    constructor(){
        super();
        this.state = {
            isFetching: true,
            sortBy: null,
            direction: ':asc',
            filterStars: null
        }
        this.handleSortBy = this.handleSortBy.bind(this)
        this.handleFilterStars = this.handleFilterStars.bind(this)
        this.clearStarRating = this.clearStarRating.bind(this)
    }

    componentWillReceiveProps(nextProps){
        if(this.props.website !== nextProps.website || this.props.campaign !== nextProps.campaign || this.props.channel !== nextProps.channel || this.props.datetime !== nextProps.datetime || this.props.filter != nextProps.filter){
            this.setState({
                isFetching: true,
            })
            this.props.fetchVisits(null, null, null, nextProps.datetime)
                .then( () => {
                    this.setState({
                        isFetching: false,
                    })
                })
            this.clearStarRating()
        }
    }

    componentDidMount(){
        this.props.fetchVisits(null, null, null, this.props.datetime)
        .then( () => {
            this.setState({
                isFetching: false,
            })
        })
    }

    handleSortBy(sortBy){
        this.setState({
            sortBy: sortBy,
            direction: this.state.direction === ':asc' ? ':desc' : ':asc'
        }, () => {
            this.props.fetchVisits(this.state.sortBy, this.state.direction, this.state.filterStars, this.props.datetime)
        })
    }

    handleFilterStars(number){
        this.setState({
          filterStars: number.id  
        }, () => {
            this.props.fetchVisits(this.state.sortBy, this.state.direction, this.state.filterStars, this.props.datetime)
        })
    }

    clearStarRating(){
        this.setState({
            filterStars: null
        }, () => {
            this.handleFilterStars(0)
        })
    }

	render() {
		if(this.state.isFetching){
            return <Loader />
        }
        return (
            <React.Fragment>
                <VisitsList 
                visits={this.props.visits} 
                handleSortBy={this.handleSortBy} 
                handleFilterStars={this.handleFilterStars}
                starRating={this.state.filterStars}
                clearStarRating={this.clearStarRating}
                noContainer={this.props.noContainer}
                />
            </React.Fragment>
        )
	}
}

const mapStateToProps = state => {
	return {
        visits: state.visits.allVisits,
        website: state.websites.selectedWebsiteId,
        campaign: state.campaigns.selectedCampaignId,
		selectedCampaignName: state.campaigns.selectedCampaignName,
		channel: state.channels.selectedChannelId,
		selectedChannelName: state.channels.selectedChannelName,
        filter: state.global.filter,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		fetchVisits: (sortBy, direction, filterStars, datetime) => dispatch(fetchVisits(sortBy, direction, filterStars, datetime))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitsListContainer)