import React, { Component } from 'react';
import { connect } from 'react-redux'
import { startFirstSubscription } from '../../actions/payments';
import { setSelectedCustomer } from '../../actions/customers'
// import { BeaconNotifications } from '../../BeaconNotifications';

// Reusable component to start first subscription
class InitiateFirstSubscription extends Component {
	constructor(){
		super();
		this.state = {
			url: '', 
			loading: false
		}
		this.handleStartCustomerSubscription = this.handleStartCustomerSubscription.bind(this)
	}

	handleStartCustomerSubscription(){
		this.setState({
			loading: true
		})

		this.props.startFirstSubscription()
		.then(url => {
			this.setState({
				url: url,
				loading: false
			})
		})

		setTimeout(() => {
			return window.open(this.state.url, "Setup a payment plan", 'height=750, width=700');
		}, 1000)

		// let success = BeaconNotifications.registerNotificationHandler('customer_subscibe_success_' + this.props.selectedCustomer.customer_id, (e) => {
		// 	alert("Awesome! Your payment plan has been updated successfully.")
		// 	this.props.setSelectedCustomer(this.props.selectedCustomer.customer_id)
		// 	BeaconNotifications.deregisterNotificationHandler(success);	    
		// });

		// let failure = BeaconNotifications.registerNotificationHandler('customer_subscibe_failure_' + this.props.selectedCustomer.customer_id, (e) => {
		// 	alert("Something went wrong with setting up your payment plan. Please contact support at support@thisisbeacon.com or try again.")
		// 	BeaconNotifications.deregisterNotificationHandler(failure);	    
		// });
	}

	render() {
		return (
			<React.Fragment>
				<button onClick={this.handleStartCustomerSubscription} className="button button--green">{this.props.buttonText}</button>
				{ 
					this.state.loading &&
					<div style={{display:"inline-block", marginLeft:8}}>Please wait...</div>
				}
			</React.Fragment>
		)
	}
}

InitiateFirstSubscription.defaultProps = {
	buttonText: "Choose a payment plan"
}

const mapStateToProps = (state) => {
	return {
		selectedCustomer: state.customers.selectedCustomer,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		startFirstSubscription: () => dispatch(startFirstSubscription()),
		setSelectedCustomer: (customer_id) => dispatch(setSelectedCustomer(customer_id))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InitiateFirstSubscription);