  
import { actionTypes } from "./constants";
import { createResourceFromQueries} from '../utils'
import axios from "axios";
import {setInvalidState} from '../../organisms/WidgetContainer/actions';

export const createActionBarResource = ({timePeriod, filter, disableCache,cancelToken}) => {
    return {
        graph: [`${filter.type}/${filter.payload.id}`, null,  {cancelToken: cancelToken.token}],
    }
}

export const clearResource = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_ACTION_BAR_DATA,
            payload: null
        })
    }
}

export const setActionBarResource = (args) => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_ACTION_BAR_FETCHING,
            fetching: true
        })

        const previousToken = getState().actionBar.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("action bar request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_ACTION_BAR_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createActionBarResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_ACTION_BAR_DATA,
                    payload: payload
                })
                
                dispatch({
                    type: actionTypes.IS_ACTION_BAR_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_ACTION_BAR_FETCHING,
                        fetching: false
                    })
                }
            })
    }
}
