import styled, { css } from 'styled-components'
import defaultStyles from '../../defaultStyles'

const Card = styled.div`
    background-color: ${defaultStyles.white};
    border-radius: 5px;
    box-shadow: ${defaultStyles.boxShadow};
    height: 100%;
    width: 100%;

    ${props => props.backgroundColour && css`background-color: ${props.backgroundColour};`}
    ${props => props.colour && css`color: ${props.colour};`}
`;

export default Card