import { actionTypes } from "./constants";

export const initialState = {
    filter: null,   
    invalidState: false,
    performanceTabularView: false,
}

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILTER:
            return {
                ...state,
                filter: action.payload
            }
        case actionTypes.SET_INVALID_STATE:
            return {
                ...state,
                invalidState: action.payload
            }
        case actionTypes.SET_PERFORMANCE_TABULAR_VIEW:
            return {
                ...state,
                performanceTabularView: action.payload
            }
        default:
            return state;
    }
} 