import { fetchCustomers } from './customers'

// Import action types
import { 
	DO_LOGIN_SUCCESS,
	SET_FIRST_DASHBOARD,
	SET_DASHBOARD
} from './actionTypes';


// Handle the user login request
// Initial request is made in the login component, code provided by Martin
export const login = (userDetails) => {
	return (dispatch) => {
		dispatch({
			type: DO_LOGIN_SUCCESS,
			userDetails
		})
		dispatch(fetchCustomers()) // fetch customers so we can give a list of websites
	}
}

export const setFirstDashboard = flag => {
	return {
		type: SET_FIRST_DASHBOARD, 
		flag
	}
}

export const setDashboardTab = tab => {
	return {
		type: SET_DASHBOARD,
		tab
	}
}