// Applicable action types. Defined here to avoid typos elsewhere.

// Websites
export const FETCH_ACTIVE_WEBSITES_REQUEST = 'FETCH_ACTIVE_WEBSITES_REQUEST';
export const FETCH_ACTIVE_WEBSITES_SUCCESS = 'FETCH_ACTIVE_WEBSITES_SUCCESS';
export const FETCH_ALL_WEBSITES_REQUEST = 'FETCH_ALL_WEBSITES_REQUEST';
export const FETCH_ALL_WEBSITES_SUCCESS = 'FETCH_ALL_WEBSITES_SUCCESS';
export const SET_SELECTED_WEBSITE_REQUEST = 'SET_SELECTED_WEBSITE_REQUEST';
export const SET_SELECTED_WEBSITE_SUCCESS = 'SET_SELECTED_WEBSITE_SUCCESS';
export const SET_SELECTED_WEBSITE_ID = 'SET_SELECTED_WEBSITE_ID';
export const CLEAR_SELECTED_WEBSITE = 'CLEAR_SELECTED_WEBSITE';
export const FETCH_CUSTOMER_WEBSITES = 'FETCH_CUSTOMER_WEBSITES';
export const FETCH_WEBSITE_VISITS = 'FETCH_WEBSITE_VISITS';
export const SET_LAST_TRACKING_CODE_SEEN = 'SET_LAST_TRACKING_CODE_SEEN';

// Visits
export const FETCH_SINGLE_VISIT_REQUEST = 'FETCH_SINGLE_VISIT_REQUEST';
export const FETCH_SINGLE_VISIT_SUCCESS = 'FETCH_SINGLE_VISIT_SUCCESS';
export const FETCH_VISITS = 'FETCH_VISITS';
export const FETCH_VISITS_BY_LINK = 'FETCH_VISITS_BY_LINK';
export const FETCH_VISITS_BY_CAMPAIGN = 'FETCH_VISITS_BY_CAMPAIGN';
export const FETCH_VISITS_BY_CHANNEL = 'FETCH_VISITS_BY_CHANNEL';

// Visitors
export const FETCH_SINGLE_VISITOR_REQUEST = 'FETCH_SINGLE_VISITOR_REQUEST';
export const FETCH_SINGLE_VISITOR_SUCCESS = 'FETCH_SINGLE_VISITOR_SUCCESS';

// Campaigns
export const FETCH_ACTIVE_CAMPAIGNS_REQUEST = 'FETCH_ACTIVE_CAMPAIGNS_REQUEST';
export const FETCH_ACTIVE_CAMPAIGNS_SUCCESS = 'FETCH_ACTIVE_CAMPAIGNS_SUCCESS';
export const FETCH_ALL_CAMPAIGNS_REQUEST = 'FETCH_ALL_CAMPAIGNS_REQUEST';
export const FETCH_ALL_CAMPAIGNS_SUCCESS = 'FETCH_ALL_CAMPAIGNS_SUCCESS';
export const FETCH_SINGLE_CAMPAIGN_REQUEST = 'FETCH_SINGLE_CAMPAIGN_REQUEST';
export const FETCH_SINGLE_CAMPAIGN_SUCCESS = 'FETCH_SINGLE_CAMPAIGN_SUCCESS';
export const SET_SELECTED_CAMPAIGN = 'SET_SELECTED_CAMPAIGN';
export const UNSET_SELECTED_CAMPAIGN = 'UNSET_SELECTED_CAMPAIGN';
export const SET_SELECTED_CAMPAIGN_NAME = 'SET_SELECTED_CAMPAIGN_NAME'
export const CLEAR_ACTIVE_CAMPAIGNS = 'CLEAR_ACTIVE_CAMPAIGNS'

// Channels
export const FETCH_DEFAULT_CHANNELS_REQUEST = 'FETCH_DEFAULT_CHANNELS_REQUEST';
export const FETCH_DEFAULT_CHANNELS_SUCCESS = 'FETCH_DEFAULT_CHANNELS_SUCCESS';
export const FETCH_CAMPAIGN_CHANNELS_REQUEST = 'FETCH_CAMPAIGN_CHANNELS_REQUEST';
export const FETCH_CAMPAIGN_CHANNELS_SUCCESS = 'FETCH_CAMPAIGN_CHANNELS_SUCCESS';
export const SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL';
export const UNSET_SELECTED_CHANNEL = 'UNSET_SELECTED_CHANNEL';
export const CLEAR_CAMPAIGN_CHANNELS = 'CLEAR_CAMPAIGN_CHANNELS';
export const FETCH_SINGLE_CHANNEL_REQUEST = 'FETCH_SINGLE_CHANNEL_REQUEST';
export const FETCH_SINGLE_CHANNEL_SUCCESS = 'FETCH_SINGLE_CHANNEL_SUCCESS';

// Login
export const DO_LOGIN_SUCCESS = 'DO_LOGIN_SUCCESS';
export const DO_LOGIN_REFRESH = 'DO_LOGIN_REFRESH';
export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
export const SET_FIRST_DASHBOARD = 'SET_FIRST_DASHBOARD';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_META = 'SET_USER_META'
export const SET_DASHBOARD = 'SET_DASHBOARD'

// Team
export const FETCH_TEAM_USERS = 'FETCH_TEAM_USERS';

// Customers
export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';

// Pages
export const FETCH_PAGES_REQUEST = 'FETCH_PAGES_REQUEST';
export const FETCH_PAGES_SUCCESS = 'FETCH_PAGES_SUCCESS';
export const FETCH_SINGLE_PAGE_REQUEST = 'FETCH_SINGLE_PAGE_REQUEST';
export const FETCH_SINGLE_PAGE_SUCCESS = 'FETCH_SINGLE_PAGE_SUCCESS';
export const SET_PAGES_TYPE = 'SET_PAGES_TYPE';

// Dates
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const UNSET_SELECTED_DATE = 'UNSET_SELECTED_DATE';
export const SET_ELEMENT_SELECTED_DATE = 'SET_ELEMENT_SELECTED_DATE';
export const UNSET_ELEMENT_SELECTED_DATE = 'UNSET_ELEMENT_SELECTED_DATE';

// Links
export const FETCH_LINKS_REQUEST = 'FETCH_LINKS_REQUEST';
export const FETCH_LINKS_SUCCESS = 'FETCH_LINKS_SUCCESS';
export const FETCH_SINGLE_LINK_REQUEST = 'FETCH_SINGLE_LINK_REQUEST';
export const FETCH_SINGLE_LINK_SUCCESS = 'FETCH_SINGLE_LINK_SUCCESS';
export const CREATE_SHORT_LINK_SUCCESS = 'CREATE_SHORT_LINK_SUCCESS';
export const FETCH_RECENT_LINKS_REQUEST = 'FETCH_RECENT_LINKS_REQUEST';
export const FETCH_RECENT_LINKS_SUCCESS = 'FETCH_RECENT_LINKS_SUCCESS';
export const SET_SELECTED_LINK = 'SET_SELECTED_LINK';
export const UNSET_SELECTED_LINK = 'UNSET_SELECTED_LINK';

// UI
export const TOGGLE_CREATE_LINK_MODAL = 'TOGGLE_CREATE_LINK_MODAL';
export const TOGGLE_NEWLY_CREATED_LINK_MODAL = 'TOGGLE_NEWLY_CREATED_LINK_MODAL';
export const TOGGLE_CREATE_POST_MODAL = 'TOGGLE_CREATE_POST_MODAL';
export const TOGGLE_DATE_RANGE_SELECT = 'TOGGLE_DATE_RANGE_SELECT';
export const TOGGLE_ELEMENT_DATE_RANGE_SELECT = 'TOGGLE_ELEMENT_DATE_RANGE_SELECT';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SNOOZE_TRACKING_CODE_REMINDER = 'SNOOZE_TRACKING_CODE_REMINDER';
export const SET_CAMPAIGN_LINK_VIEW = 'SET_CAMPAIGN_LIST_VIEW';
export const SET_OVERLAY_ROOT = 'SET_OVERLAY_ROOT';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const TOGGLE_LINKS_ONLY_VISITS = 'TOGGLE_LINKS_ONLY_VISITS';
export const TOGGLE_REPORT_LESSONS = 'TOGGLE_REPORT_LESSONS'

// Payments
export const START_FIRST_SUBSCRIPTION = 'START_FIRST_SUBSCRIPTION';

// Export
// export const EXPORT_DATA = 'EXPORT_DATA';
