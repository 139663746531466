import React from 'react';
import { Link } from 'react-router-dom'

const Notifications = props => {
	const notifications = props.notifications.map((n, i) => {
		return (
			<li className={"notifications__item " + (n.theme)} 
			key={i} 
			onLoad={props.removeNotification(i)}
			style={{zIndex: 3100}}
			>
				{n.title}

				{
					n.link &&
					<Link to={n.link} style={{marginTop:10}} className="button button--yellow button--small">Click to view</Link>
				}
			</li>
		)
	})
	return (
		<ul className="notifications">
			{notifications}
		</ul>
	)
}

export default Notifications;