import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserMeta, setUserMeta } from '../../actions/customers';
import { addNotification } from '../../actions/ui';
import PropTypes from 'prop-types';

class EditAccount extends Component {
	constructor(){
		super()
		
		this.state = {
			firstName: '',
			lastName: '',
			emailAddress: '',


			timezone: '',
			enableTimezone: false
		}
		this.handleChangeFirstName = this.handleChangeFirstName.bind(this)
		this.handleChangeLastName = this.handleChangeLastName.bind(this)
		this.handleChangeEmailAddress = this.handleChangeEmailAddress.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	componentDidMount(){
		const timezone = this.props.userMeta.filter(i => i.name === 'timezone')[0]
		const enableTimezone = this.props.userMeta.filter(i => i.name === 'enable_timezone')[0]
		
		this.setState({
			firstName: this.props.user.first_name,
			lastName: this.props.user.last_name,
			emailAddress: this.props.user.email,
			timezone: timezone != null ? timezone.value : '',
			enableTimezone: enableTimezone != null ? enableTimezone.value == "1" : false
		})
	}

	handleChangeFirstName(e){
		this.setState({
			firstName: e.target.value
		})
	}

	handleChangeLastName(e){
		this.setState({
			lastName: e.target.value
		})
	}

	handleChangeEmailAddress(e){
		this.setState({
			emailAddress: e.target.value
		})
	}

	handleSubmit(){
		// API call to update the record here, then notification to confirm (or error)

		 if (this.state.enableTimezone == "1" && this.state.timezone == "") {
            alert("Please select a timezone")
        } else {
            this.props.setUserMeta([
				{name: "timezone", value: this.state.timezone},
				{name: "enable_timezone", value: this.state.enableTimezone},
			])
			this.props.addNotification({title: 'Updated account details', theme: 'positive'})
        }
	}

	render() {
		const get_default = () => {
			if (this.props.userMeta.filter(i => i.name === 'timezone')[0])
				return this.props.userMeta.filter(i => i.name === 'timezone')[0].value

			return ''
		}

		return (
			<div className="card">
				<div className="card__header">
					<h2 className="card__title">Account Details</h2>
				</div>
				<div className="card__content">
					<ul>
						<li className="form__item">
			              <label className="form__label" htmlFor="first_name">First Name</label>
			              <input type="text" className="form__input" id="first_name" value={this.state.firstName} onChange={this.handleChangeFirstName} disabled />
			            </li>
			            <li className="form__item">
			              <label className="form__label" htmlFor="surname">Surname</label>
			              <input type="text" className="form__input" id="surname" value={this.state.lastName} onChange={this.handleChangeLastName} disabled />
			            </li>
			            <li className="form__item">
			              <label className="form__label" htmlFor="email">Email Address</label>
			              <input type="email" className="form__input" id="email" value={this.state.emailAddress} onChange={this.handleChangeEmailAddress} disabled />
			            </li>
			            <li className="margin-large">
			              {/*<button type="button" onClick={this.handleSubmit} className="button button--green">Save details</button>*/}
			              <div className="message message--notice">
		                    <p>If you wish to change your details please <a href="mailto:support@thisisbeacon.com">contact support</a></p>
		                  </div>
			            </li>

						{/* <Divider /> */}
						{/* <div className="form__two-column">
							<li className="form__item" style={{marginTop: '10px'}}>
								<div style={{display:"grid", gridTemplateColumns:"repeat(2, max-content)", alignItems:"center"}}>
									<label className="form__label">Time zone</label>
									<div><HelpContainer id={22} /></div>
								</div>
								<Toggle 
									// defaultChecked={this.state.enableTimezone}
									checked={this.state.enableTimezone}
                                    handleToggle={e => this.setState({enableTimezone: e.target.checked})} 
                                    text="Enable/disable timezone" 
                                />
								<Autocomplete
									id="combo-box-demo"
									options={moment.tz.names()}
									getOptionLabel={(option) => option}
									defaultValue={get_default()}
									inputValue={this.state.timezone}
									onInputChange={(event, newInputValue) => {
										this.setState({timezone: newInputValue})
									}}
									renderInput={(params) => (
										<div style={{width: '100%'}} ref={params.InputProps.ref}>
											<input {...params.inputProps} type="text" className="form__input" disabled={!this.state.enableTimezone} />
										</div>
									)}
								/>
							</li>
						</div> */}
						
						{/* <li className="form__item" style={{marginBottom:0}}>
							<label className="form__label">&nbsp;</label>
							<button 
							className="button button--dark-grey"
							onClick={this.handleSubmit}>
							Update
							</button>
						</li> */}
			        </ul>
			    </div>
			</div>
		)
	}
}

EditAccount.propTypes = {
	user: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {
		userMeta: state.login.userMeta
	}
}

const mapDispatchToProps = dispatch => {
	return {
        getUserMeta: () => dispatch(getUserMeta()),
		setUserMeta: (meta) => dispatch(setUserMeta(meta)),
		addNotification: (payload) => dispatch(addNotification(payload))
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount);
