import React from 'react'
import { Link } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import { appVer } from '../../API';

const LoginFooter = () => {
	return (
		<div className="login__footer">
			<ul className="login__links">
				<li><ResetPassword /></li>
				<li>Version {appVer}</li>
			</ul>
		</div>
	)
}

export default LoginFooter