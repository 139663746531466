import React, {useState, useEffect, Suspense} from 'react'
import Loader from '../../components/general/Loader';
import {withWidgetContainer} from '../withWidgetContainer'
import {
    ResponsiveContainer, PieChart, Pie, Sector, Cell, Label,BarChart,Bar,
    XAxis, YAxis, CartesianGrid, Tooltip,
  } from 'recharts';

import { connect } from 'react-redux';
import moment from 'moment-timezone'
import FormatNumber from '../../components/general/FormatNumber'

import {setBudgetEffectivenessResource} from './actions'
import { useSelector, useDispatch } from 'react-redux'
import {usePrevious} from '../utils'
import Card from '../../atoms/Card'
import Skeleton from '@material-ui/lab/Skeleton'

const BudgetEffectiveness = (props) => {
    const resource = useSelector(({budgetEffectiveness}) => budgetEffectiveness.data)
    const isFetching = useSelector(({budgetEffectiveness}) => budgetEffectiveness.fetching)
    const prevDatetime = usePrevious(props.datetime)
    const prevFilter = usePrevious(props.filter)
    const dispatch = useDispatch()

    useEffect(() => {
        if (prevDatetime != props.datetime || prevFilter.type != props.filter.type || prevFilter.payload.id != props.filter.payload.id) {
            dispatch(setBudgetEffectivenessResource({timePeriod: props.datetime, filter: props.filter}))
        }
    }, [props.datetime, props.filter])

    if (resource == null) {
        return <div style={{display: 'grid', gridTemplateColumns: 'max-content auto', height: '440px'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '250px', marginRight: '10px'}}>
                <Skeleton variant="rect" width='100%' height="100%" style={{borderRadius: '5px',marginBottom: '15px'}} /> 
                <Skeleton variant="rect" width='100%' height="100%" style={{borderRadius: '5px',marginBottom: '15px'}} /> 
                <Skeleton variant="rect" width='100%' height="100%" style={{borderRadius: '5px',}} /> 
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', marginRight: '10px'}}>
                <Skeleton variant="rect" width='100%' height="100%" style={{borderRadius: '5px',marginBottom: '15px'}} /> 
                <Skeleton variant="rect" width='100%' height="100%" style={{borderRadius: '5px',}} /> 
            </div>
        </div>
    }

    return (
        <Card 
            style={{
                background:'transparent', 
                border: 'none', 
                position: 'relative',
                boxShadow: 'none'
            }}
            
        >
            <h3 className="stat-group__title" >{props.title}</h3>
            {isFetching && <Loader />}
            <div style={{ 
                height: '100%', width: '100%', 
                filter: isFetching && 'blur(1px)' 
            }}>
                <BudgetEffectivenessChild {...props} resource={resource}/>
            </div>
        </Card>
    )
}

const getTable = (res) => res.map(item => {
    const id = () => {
        if (props.filter.type === "website") return "campaign_id"
        else if (props.filter.type === "campaign") return "channel_id"
        else return "link_id"
    }
    const name = () => {
      if (props.filter.type === "website") return "campaign_name"
      else if (props.filter.type === "campaign") return "channel_name"
      else return "link_name"
    }
    const idForTable = () => {
      if (option.id == "links") {
        return item["link_id"] 
      } else if (option.id == "channels") {
        return item["channel_id"] 
      } else {
        return item[`${id()}`]
      }
    }
    const getColour = () => {
      if (option.id == "links" || type == "links") {
        return null
      } else if (option.id == "channels"|| type == "channels") {
        return item.channel_colour
      } else {
        return item.campaign_colour
      }
    }
    const getIcon = () => {
      if (option.id == "links"|| type == "links") {
        return null
      } else if (option.id == "channels"|| type == "channels") {
        return item.channel_icon
      } else {
        return null
      }
    }
    const getName = () => {
      if (option.id == "links"|| type == "links") {
        return item.notes || item.link_notes || "No description"
      } else if (option.id == "channels"|| type == "channels") {
        return item.channel_name || item.name
      } else {
        return item[name()] || item.name
      }
    }
    return {
        id: idForTable(),
        name: getName(),
        icon: getIcon(),
        ad_channels: item.ad_channels,

        impressions: item.impressions,
        visits: item.visits,
        clicks: item.clicks,
        bot: item.bots,
        bot_percent: item.bot_percentage,
        leads: item.leads,
        clicks_percent: item.clicks/item.impressions,
        wasted_spend: item.wasted_spend,
        colour: getColour(),
        spend: item.spend,

        cpc: item.clicks == 0 ? 0 : parseFloat((item.spend/item.clicks).toFixed(2)),
        cpv: item.visits == 0 ? 0 : parseFloat((item.spend/item.visits).toFixed(2)),
        cpl: item.leads == 0 || item.leads == null ? 0 : parseFloat((item.spend/item.leads).toFixed(2)),
        ...(collation.id == "daily" ? {start_date: item.start_date, end_date: item.end_date} : {})
    }
})

const BudgetEffectivenessChild = (props) => {
    const graph = props.resource.graph.response;

    //const campaigns = getTable(graph);
    /*let result =   campaigns.reduce((acc, row) => {
        return {
          'spend': acc.spend+row.spend,
          'wasted_spend': acc.wasted_spend+row.wasted_spend, 
        }
      }, {
        'spend': 0,
        'wasted_spend': 0,
      });*/
      let results = {
        'spend': 0,
        'wasted_spend': 0,
      };

    return <div>
        
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
            <div className="card stat-group" style={{marginRight:'10px'}}>
                <h1 className="stat-group__title">Budget effectiveness</h1>
                <div style={{width: '99%', height: '100px', padding: '10px', display: 'flex'}}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'max-content auto', margin: 'auto'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}>
                            <span style={{
                                background: '#25cccf3b', borderRadius: '50%', width: '40px', height: '40px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <span style={{color: 'rgb(37, 204, 207)', fontSize:'22px'}}><i class="fas fa-tachometer-alt"></i></span>
                            </span>
                        </div>
                        <div>
                            <h3 className="text-large"><FormatNumber val={graph.overview.effective_spend_percentage/100} percent /></h3>
                            <h4 className="text-small" >Effective</h4>
                         </div>
                    </div> 
                </div>
            </div>

            <div className="card stat-group" style={{marginRight:'10px'}}>
                <div style={{width: '99%', height: '100px', padding: '10px', display: 'flex'}}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'max-content auto', margin: 'auto'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}>
                            <span style={{
                                background: '#25cccf3b', borderRadius: '50%', width: '40px', height: '40px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <span style={{color: 'rgb(37, 204, 207)', fontSize:'22px'}}><i class="fas fa-wallet"></i></span>
                            </span>
                        </div>
                        <div>
                            <h3 className="text-large"><FormatNumber val={graph.overview.effective_spend} money /></h3>
                            <h4 className="text-small" >Effective spend</h4>
                         </div>
                    </div> 
                </div>
            </div>


            <div className="card stat-group">
                <div style={{width: '99%', height: '100px', padding: '10px', display: 'flex'}}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'max-content auto', margin: 'auto'}}>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px' }}>
                            <span style={{
                                background: 'rgb(207 37 147 / 23%)', borderRadius: '50%', width: '40px', height: '40px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center'
                            }}>
                                <span style={{color: 'rgb(255, 49, 144)', fontSize:'22px'}}><i class="fas fa-robot"></i></span>
                            </span>
                        </div>
                        <div>
                            <h3 className="text-large"><FormatNumber val={graph.overview.wasted_spend} money /></h3>
                            <h4 className="text-small" >Wasted spend</h4>
                         </div>
                    </div> 
                </div>
            </div>
        </div>

        <div style={{display: 'grid', gridTemplateRows: '1fr 1fr'}}>

        </div>
    </div>
}

const mapStateToProps = state => {
	return {
		website: state.websites.selectedWebsite,
        currency: state.websites.selectedWebsite.__meta
	}
}

// withWidgetContainer
export default connect(mapStateToProps, null)((BudgetEffectiveness))
