import BeaconRequest from '../BeaconRequest';
import { fetchActiveCampaigns, fetchAllCampaigns, unsetSelectedCampaign, clearActiveCampaigns } from './campaigns';
import { setSelectedCustomer } from './customers';
import { fetchLinks } from './links'
import { fetchPages } from './pages'
import { addNotification } from './ui'
import { fetchDefaultChannels, clearCampaignChannels, unsetSelectedChannel } from './channels'
import { setFilter } from '../organisms/WidgetContainer/actions'
import { fetchCustomers } from './customers'

// Import action types
import { 
	FETCH_ACTIVE_WEBSITES_REQUEST,
	FETCH_ACTIVE_WEBSITES_SUCCESS,
	SET_SELECTED_WEBSITE_REQUEST,
	SET_SELECTED_WEBSITE_SUCCESS,
	SET_SELECTED_WEBSITE_ID,
	CLEAR_SELECTED_WEBSITE,
	FETCH_CUSTOMER_WEBSITES,
	SET_LAST_TRACKING_CODE_SEEN
} from './actionTypes';
import {oneOneEightID} from "../constants";

// Get all the active websites for all customers
export const getActiveWebsites = () => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_ACTIVE_WEBSITES_REQUEST,
		})

		if(!getState().customers.allCustomers){
			setTimeout(() => {
				dispatch(getActiveWebsites())
			}, 250)
			
		} else {
			const customers = getState().customers.allCustomers
			const userId = getState().login.currentUser.user_id
			if (userId === oneOneEightID) { // 118 HACK
				customers.map(customer => {
					customer.__websites = customer.__websites.filter(({website_id}) => website_id === 35)
					return customer
				})
			}
			dispatch({
				type: FETCH_ACTIVE_WEBSITES_SUCCESS,
				payload: customers
			})
		}
	}
}

export const fetchCustomerWebsites = (customer_id) => {
	return (dispatch, getState) => {
		return BeaconRequest.getNoChache('website/all/' + customer_id, { __onlycore:'1' })
		.then(responseObj => {
			const userId = getState().login.currentUser.user_id
			if (userId === oneOneEightID) { // 118 HACK
				responseObj.response = responseObj.response.filter(({website_id}) => website_id === 35)
			}
			dispatch({
				type: FETCH_CUSTOMER_WEBSITES,
				payload: responseObj.response
			})
		})
		.catch(error => { console.log(error) })
	}
}

// Set the active website
export const setSelectedWebsite = (website) => {
	// Because there are two instances of passing in the website we need to check it
	// The first instance we pass the website object which includes the id from the API as "website_id"
	// The second instance, using the dropdown menu, sends the id as simply "id", so we must check for both
	let websiteId = ''
	if(website.id){
		websiteId = website.id
	} else {
		websiteId = website.website_id
	}
	return (dispatch, getState) => {
		dispatch({
			type: SET_SELECTED_WEBSITE_ID,
			payload: websiteId
		})
		dispatch({
			type: SET_SELECTED_WEBSITE_REQUEST, // used to show a loader
		})
		return BeaconRequest.get('website/' + websiteId)
		.then(responseObj => {
			dispatch({
				type: SET_SELECTED_WEBSITE_SUCCESS,
				responseObj // send the complete response to the reducer
			})
			// dispatch(setFilter({type: 'website', payload: {id: websiteId, name: responseObj.name}}))
			dispatch(setSelectedCustomer(responseObj.response.customer_id))
			dispatch(fetchCustomerWebsites(responseObj.response.customer_id))
			dispatch(fetchActiveCampaigns())
			// dispatch(fetchAllCampaigns())
			// dispatch(fetchPages())
			// dispatch(fetchLinks())
			// dispatch(clearCampaignChannels())
			dispatch(fetchDefaultChannels())
			// dispatch(clearActiveCampaigns())
			dispatch(getActiveWebsites())
		})
		.catch(error => { console.log(error) })
	}
}

export const fetch_website_by_period = () => {
	return (dispatch, getState) => {
		return BeaconRequest.get('website/' + getState().websites.selectedWebsiteId + '/campaign/analytics/' + getState().dates.selectedDateValue + '/stack')
		.then(responseObj => {
			dispatch({
				type: 'SET_SELECTED_WEBSITE_PERIOD',
				responseObj
			})

			return responseObj
		})
	}
}

export const clearSelectedWebsite = () => {
	return (dispatch) => {
		dispatch({
			type: CLEAR_SELECTED_WEBSITE,
		})
		dispatch(unsetSelectedCampaign())
		dispatch(unsetSelectedChannel())
	}
}

export const createWebsite = data => {
	return (dispatch, getState) => {
		const customer_id = getState().customers.selectedCustomer.customer_id


		
		let currencySymbol = ''
		switch(data.websiteCurrency){
			case "GBP":
				currencySymbol = "£"
				break;
			case "EUR":
				currencySymbol = "€"
				break;
			case "USD":
				currencySymbol = "$"
				break;
			case "AUD":
				currencySymbol = "A$"
				break;
			case "SGD":
				currencySymbol = "S$"
				break;
			case "CAD":
				currencySymbol = "C$"
				break;
			default:
				currencySymbol = "£"
		}

		return BeaconRequest.post(
			'website/', {}, 
			{
				customer_id:customer_id,
				url:data.websiteUrl,
				name:data.websiteName,
				timezone: data.timezone,
			},
		)
		.then(responseObj => {
			return BeaconRequest.post(
				'website/' + responseObj.response.website_id + '/meta', {}, 
				[
					{ name: "currency_code", value: data.websiteCurrency},
					{ name: "currency_symbol", value:  to_html_entity(currencySymbol)},
					{ name: "lead_text", value: data.leadsText || 'Leads'}
				],
			)
			.then(e => {
				dispatch(fetchCustomers(getState().login.currentUser.user_id))
				dispatch(fetchCustomerWebsites(responseObj.response.customer_id))
				dispatch(getActiveWebsites())
				dispatch(setSelectedWebsite(responseObj.response))

				
				const payload = {
					title: "A new website has been successfully added",
					theme: "positive"
				}
				dispatch(addNotification(payload))

				return responseObj
			})
			.catch(error => {
				throw error;
			})
		})
		.catch(error => { 
			console.log(error)
			const code = error.code
			let msg = '';
    		if(code === 1411){
    			msg = "It looks like you've already registered this website! If you need help, you can contact us at support@thisisbeacon.com"
    		} else if(code === 1412){
    			msg = "It looks like this website has already been registered to another customer. If you need help, you can contact us at support@thisisbeacon.com"
    		} else if(code === 3002){
    			msg = "Hmm, it doesn't look like that website exists. Please check the address, and if the issue persists contact us at support@thisisbeacon.com"
    		} else if(code === 2000){
    			msg = "Something went wrong - please check that you entered a valid URL and try again. If the issue persists contact us at support@thisisbeacon.com"
    		}
			const payload = {
				title: msg,
				theme: "negative"
			}
			// dispatch(addNotification(payload))
			throw error
		})
	}
}

export const createFirstWebsite = data => {
	return (dispatch, getState) => {
		const customer_id = getState().customers.allCustomers[0].customer_id

		let currencySymbol = ''
		switch(data.websiteCurrency){
			case "GBP":
				currencySymbol = "£"
				break;
			case "EUR":
				currencySymbol = "€"
				break;
			case "USD":
				currencySymbol = "$"
				break;
			case "AUD":
				currencySymbol = "A$"
				break;
			case "SGD":
				currencySymbol = "S$"
				break;
			case "CAD":
				currencySymbol = "C$"
				break;
			default:
				currencySymbol = "£"
		}
		return BeaconRequest.post(
			'website/', {}, 
			{
				customer_id:customer_id,
				url:data.websiteUrl,
				name:data.websiteName,
				timezone: data.timezone,
			},
		)
		.then(responseObj => {
			return BeaconRequest.post(
				'website/' + responseObj.response.website_id + '/meta', {}, 
				[
					{ name: "currency_code", value: data.websiteCurrency},
					{ name: "currency_symbol", value:  to_html_entity(currencySymbol)},
					{ name: "lead_text", value: data.leadsText || 'Leads'},
				],
			)
			.then(e => {
				dispatch(fetchCustomerWebsites(responseObj.response.customer_id))
				dispatch(getActiveWebsites())
				dispatch(setSelectedWebsite(responseObj.response))

				
				const payload = {
					title: "A new website has been successfully added",
					theme: "positive"
				}
				dispatch(addNotification(payload))

				return responseObj
			})
			.catch(error => {
				throw error;
			})

		})
		.catch(error => {
			throw error;
		})
	}
}

export const fetchLastTrackingCodeSeen = () => {
	return (dispatch, getState) => {
		return BeaconRequest.get('website/' + getState().websites.selectedWebsiteId)
		.then(responseObj => {
			dispatch({
				type: SET_LAST_TRACKING_CODE_SEEN,
				payload: responseObj.response.lastTrackingCodeSeen
			})
		})
		.catch(error => { console.log(error) })
	}
}

const to_html_entity = function(str) {
	var buf = [];
	
	for (var i=str.length-1;i>=0;i--) {
		buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
	}
	
	return buf.join('');
}
export const updateWebsite = data => {
	return (dispatch, getState) => {
		const customer_id = getState().customers.selectedCustomer.customer_id

		let currencySymbol = ''
		switch(data.websiteCurrency){
			case "GBP":
				currencySymbol = "£"
				break;
			case "EUR":
				currencySymbol = "€"
				break;
			case "USD":
				currencySymbol = "$"
				break;
			case "AUD":
				currencySymbol = "A$"
				break;
			case "SGD":
				currencySymbol = "S$"
				break;
			case "CAD":
				currencySymbol = "C$"
				break;
			default:
				currencySymbol = "£"
		}

		return BeaconRequest.post(
			'website/' + data.websiteId, {}, 
			{
				customer_id:customer_id,
				url:data.websiteUrl,
				name:data.websiteName,
				__meta: [
					{
					  	name: "lead_text", value: data.leadsText || 'Leads'
					},
					{ 
						name: "currency_code", value: data.websiteCurrency
					},
					{ 
						name: "currency_symbol", value: to_html_entity(currencySymbol)
					},
					{ 
						name: "timezone", value: data.timezone
					},
					{ 
						name: "enable_timezone", value: data.enableTimezone
					},
					{
						name: "default-campaign-goal", 
						value:  (() => {
							switch (data.selectedItem) {
								case 'identified_visitor':
								default:
									return data.selectedType;
								case 'shopify_thank_you':
									return JSON.stringify([{"type":"shopify_thank_you","settings":["*/checkouts/*/thank_you"]}]);
								case 'url_match':
								case 'external_link':
								case 'preg_match':
									return JSON.stringify([{"type": data.selectedItem, "settings": data.inputLinks}]);
							}
						})()
					}
				]
			},
		)
		.then(responseObj => {
			dispatch(setSelectedWebsite(responseObj.response))
			const payload = {
				title: "Website successfully updated",
				theme: "positive"
			}
			dispatch(addNotification(payload))

			return responseObj
		})
		.then(response => response)
		.catch(error => {
			throw error;
		})
	}
}

export const fetchSupportedCurrencies = () => {
	return (dispatch, getState) => {
		return BeaconRequest.get('website/supportedCurrencies')
		.then(responseObj => {
			return responseObj.response
		})
		.catch(error => { console.log(error) })
	}
}

export const setWebsiteLogo = (websiteLogo) => {
	return (dispatch, getState) => {
		const website_id = getState().websites.selectedWebsiteId

		return BeaconRequest.post(
			'website/' + website_id + '/meta', {}, 
			{
				name:'website_logo',
				value:websiteLogo
			},
		)
		.then(responseObj => {
			return responseObj
		})
		.catch(error => {
			throw error;
		})
	}
}

export const getWebsiteLogo = () => {
	return (dispatch, getState) => {
		const website_id = getState().websites.selectedWebsiteId

		return BeaconRequest.get('website/' + website_id + '/meta/website_logo')
		.then(responseObj => {
			return responseObj
		})
		.catch(error => {
			throw error;
		})
	}
}