var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var Tab;
(function (Tab) {
    Tab[Tab["Customer"] = 0] = "Customer";
    Tab[Tab["Campaigns"] = 1] = "Campaigns";
    Tab[Tab["Ads"] = 2] = "Ads";
    Tab[Tab["Keywords"] = 3] = "Keywords";
    Tab[Tab["SearchTerms"] = 4] = "SearchTerms";
    Tab[Tab["DynamicSearchTerms"] = 5] = "DynamicSearchTerms";
    Tab[Tab["BiddingStrategies"] = 6] = "BiddingStrategies";
})(Tab || (Tab = {}));
export var DisplayTabs = [
    Tab.Customer,
    Tab.Campaigns,
    Tab.Ads,
    Tab.Keywords,
    Tab.SearchTerms,
    Tab.DynamicSearchTerms,
    Tab.BiddingStrategies
];
export var TabDetails = new Map([
    [
        Tab.Customer,
        {
            name: "Customer",
            endpoint: "customer",
            key: function (column) { return "customer_".concat(column["date"]); }
        }
    ],
    [
        Tab.Campaigns,
        {
            name: "Campaigns",
            endpoint: "campaigns",
            key: function (column) { return "campaign_".concat(column["date"], "_").concat(column["id"]); }
        }
    ],
    [
        Tab.Ads,
        {
            name: "Ads",
            endpoint: "ads",
            key: function (column) { return "ad_".concat(column["date"], "_").concat(column["id"]); }
        }
    ],
    [
        Tab.Keywords,
        {
            name: "Keywords",
            endpoint: "keywords",
            key: function (column) { return "keyword_".concat(column["date"], "_").concat(column["id"]); }
        }
    ],
    [
        Tab.SearchTerms,
        {
            name: "Search Terms",
            endpoint: "search-terms",
            key: function (column) { return "searchterm_".concat(column["date"], "_").concat(column["id"]); }
        }
    ],
    [
        Tab.DynamicSearchTerms,
        {
            name: "Dynamic Search Terms",
            endpoint: "dynamic-search-terms",
            key: function (column) { return "dynamicsearchterm_".concat(column["date"], "_").concat(column["id"]); }
        }
    ],
    [
        Tab.BiddingStrategies,
        {
            name: "Bidding Strategies",
            endpoint: "bidding-strategies",
            key: function (column) { return "biddingstrategy_".concat(column["date"], "_").concat(column["id"]); }
        }
    ]
]);
export function TabBar(_a) {
    var activeTab = _a.activeTab, setActiveTab = _a.setActiveTab;
    return (_jsx("div", __assign({ style: {
            zIndex: 11,
            margin: "10px 0",
            gap: 10,
            display: "grid",
            gridTemplateColumns: "max-content auto",
            borderBottom: "1px solid #E6EBED",
            backgroundColor: "white",
            position: "sticky",
            top: "99px"
        } }, { children: _jsx("div", __assign({ style: {
                display: "flex",
                flexDirection: "row",
                height: "100%"
            } }, { children: DisplayTabs.map(function (tab) { return (_jsx("span", __assign({ style: {
                    padding: "10px",
                    borderBottom: tab === activeTab ? "3px solid #ffd200" : null,
                    marginRight: "20px",
                    cursor: "pointer",
                    fontSize: "16px"
                }, onClick: function () { return setActiveTab(tab); } }, { children: TabDetails.get(tab).name }))); }) })) })));
}
