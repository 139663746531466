import React, { Component } from 'react';
import { login } from '../../actions/login';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoginFooter from './LoginFooter';
import Logout from '../customer/Logout';
import Loader from '../general/Loader';
import BeaconRequest from '../../BeaconRequest';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

class LoginForm extends Component {
	constructor(){
		super();
		this.state = {
			email: '',
			password: '',
			displayPassword: false,
			error:false,
			errorMsg:null,
			ip:"",
			recapture:"",
			apiIsOnline: false
		}
		this.captcha = null;
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handlePasswordDisplay = this.handlePasswordDisplay.bind(this)
		this.handleRecaptureComplete = this.handleRecaptureComplete.bind(this);

		axios.get("https://tracker.thisisbeacon.com/tracker/ip/").then(resp => {
			this.setState({ip:resp.data});
		})
	}


  handleSubmit(e){
  	e.preventDefault();
	
		BeaconRequest.login(this.state.email, this.state.password, this.state.recapture, this.state.ip)
		.then(response => {
			this.props.login(response)
		})
		.catch(error => {
			let errorMsg = ''
			if(error.response){
				errorMsg = ''
				let code = error.response.data.code
				switch(code){
					case 1310: 
						errorMsg = 'Please confirm your humanity by checking the "I\'m not a robot" box.';
						break;
					case 1301:
					case 1311: 
					case 1312:
						errorMsg = 'The email address and password combination you\'ve entered aren\'t correct, please try again.';
						break;
					case 1313: 
						errorMsg = 'Your account has been locked. Check your email to reset your password.';
						break;
					default:
						errorMsg = 'Unfortunately something went wrong, please try again.'
				}
			} else {
				errorMsg = 'Unfortunately something went wrong, please try again.'
			}
			this.captcha.reset();
			
			this.setState({
				error:true, 
				errorMsg: errorMsg
			})
		})

  }

  // Watch changes to the email and password fields
  handleEmailChange(e){
  	this.setState({ email: e.target.value })
  }

  handlePasswordChange(e){
  	this.setState({ password: e.target.value })
  }

  handlePasswordDisplay(){
  	this.setState({ displayPassword: !this.state.displayPassword })
  }

  handleRecaptureComplete(value){
	  this.setState({recapture:value})
  }
  
  handleEyeChange(){
  	if(this.state.displayPassword){
  		return <i className="fas fa-eye-slash"></i>
  	} else {
  		return <i className="fas fa-eye"></i>
  	}
  }

  componentDidMount(){
	BeaconRequest.testAPI().then(responseObj => {
		if(responseObj.response === "online"){
			this.setState({
				apiIsOnline: true
			})
		}
	})
  }

  render(){
	if(this.state.ip === "" || !this.state.apiIsOnline){
		return (
			<React.Fragment>
				<div className="external__logout"><Logout /></div>
				<Loader colour="light" />
			</React.Fragment>
		)
	}

	if(this.state.apiIsOnline){
		return (
			<div className="login">

				<div className="login__content">
					<img src="https://s3.eu-west-1.amazonaws.com/beacon.assets/publicapp/assets/Veracity-Horizontal-white-50px.png" alt="Beacon" className="login__logo" />
					<h1 className="login__title">Welcome to Beacon</h1>
					<form onSubmit={this.handleSubmit} className="login__form form">
						{ 
							this.state.error &&
							<div className="login__error">
								<i className="fas fa-exclamation-circle error-icon"></i>
								<p>{this.state.errorMsg}</p>
							</div>
						}

						<div className="form__item">
							<input type="email" placeholder="Email address" value={this.state.email} onChange={this.handleEmailChange} autoFocus className="form__input" />
						</div>

						<div className="form__item" style={{position:"relative"}}>
							<input type={this.state.displayPassword ? 'text' : 'password'} placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} className="form__input" />
							<button type="button" onClick={this.handlePasswordDisplay} key={Math.random()} className="form__password-eye">
								{this.handleEyeChange()}
							</button>
						</div>
						
						<div className="form__item" style={{position:"relative"}}>
							<ReCAPTCHA sitekey="6Le75mwUAAAAACkr_0q3-xAbxAukRv2QQGSxQH_U" onChange={this.handleRecaptureComplete} ref={el =>{this.captcha = el;}} />
						</div>

						<button type="submit" className="button button--yellow">Login to Beacon</button>
					</form>
				</div>

				<LoginFooter />
			</div>
		)
	} else {
		return null
	}
  }
}

// Map the login action to the component and pass it in as a prop
// Then access it using this.props.login
const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		login
	}, dispatch)
}

export default connect(null, mapDispatchToProps)(LoginForm);