var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { widgets_list } from '../../molecules/widgets';
import { addNotification } from '../../actions/ui';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles, createStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) { return createStyles({
    item: {
        paddingBottom: '20px !important'
    },
}); });
var PerformanceDashboard = function (props) {
    var _a, _b, _c, _d;
    var _e = useState(widgets_list), items = _e[0], setItems = _e[1];
    var classes = useStyles(); //to remove
    var createItem = function (id) {
        var findById = items.filter(function (item) { return item.id == id; })[0];
        return items.length > 0 &&
            findById
                .content(__assign(__assign({}, findById), { datetime: props.datetime, websiteId: props.websiteId, currentUser: props.currentUser, filter: props.filter, setFilter: props.setFilter, meta: props.websiteMeta }));
    };
    if (props.filter == null)
        return null;
    var botImpactWidget = createItem("bot_impact_widget");
    var botActivityWidget = createItem("bot_activity_widget");
    var mediaSpendWidget = createItem("media_spend_widget");
    var budgetEffectivenessWidget = createItem("budget_effectiveness_widget");
    var performanceListWidget = props.filter.type === "link" ? null : createItem("performance_list_widget");
    var spendGraph = (_d = (_c = (_b = (_a = props.mediaSpend) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.spend_graph) === null || _c === void 0 ? void 0 : _c.response) === null || _d === void 0 ? void 0 : _d.graph;
    var spendSum = props.mediaSpend.fetching === true || !(spendGraph instanceof Array) ? -1 : spendGraph.reduce(function (acc, val) { return acc + val.data; }, 0);
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true, spacing: 2, style: { display: spendSum === 0 ? "none" : null } }, { children: [_jsx(Grid, __assign({ item: true, xs: 8 }, { children: botImpactWidget })), _jsx(Grid, __assign({ item: true, xs: 4 }, { children: botActivityWidget })), _jsx(Grid, __assign({ item: true, xs: 12, classes: classes }, { children: mediaSpendWidget })), _jsx(Grid, __assign({ item: true, xs: 12, classes: classes }, { children: budgetEffectivenessWidget })), props.filter.type !== "link" &&
                        _jsx(Grid, __assign({ item: true, xs: 12, classes: classes }, { children: performanceListWidget }))] })), _jsxs("div", __assign({ className: "message message--notice", style: { display: spendSum !== 0 ? "none" : null } }, { children: [_jsx("h2", __assign({ className: "message__title" }, { children: "No performance data available" })), _jsxs("p", __assign({ className: "margin-small" }, { children: ["There is no performance data available for this ", props.filter.type, " for the time period selected."] }))] }))] }));
};
var mapStateToProps = function (state) {
    return {
        websiteId: state.websites.selectedWebsiteId,
        websiteMeta: state.websites.selectedWebsite.__meta,
        currentUser: state.login.currentUser,
        customer: state.customers.selectedCustomer,
        date: state.dates.selectedDateValue,
        timezone: state.websites.selectedWebsite.timezone,
        websiteName: state.websites.selectedWebsite.name,
        showDataExport: state.websites.selectedWebsite.showDataExport,
        activeCampaigns: state.campaigns.activeCampaigns,
        mediaSpend: state.mediaSpend,
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        addNotification: function (payload) { return dispatch(addNotification(payload)); }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PerformanceDashboard);
