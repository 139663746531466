import React, { Component } from 'react';
import { connect } from 'react-redux';
import Notifications from '../../components/general/Notifications';
import { removeNotification } from '../../actions/ui'

class NotificationsContainer extends Component {
	constructor(){
		super();
		this.handleRemoveNotification = this.handleRemoveNotification.bind(this)
	}

	handleRemoveNotification(id){
		setTimeout(() => {
			this.props.removeNotification(id)
		}, 6000)
	}

	render() {
		return (
			<Notifications 
			notifications={this.props.notifications} 
			removeNotification={this.handleRemoveNotification}
			/>
		)
	}
}
// after 3 seconds, fire an action to  1) remove the notification from the state tree and 2) close the box

const mapStateToProps = state => {
	return {
		notifications: state.ui.notifications
	}
}

const mapDispatchToProps = dispatch => {
	return {
		removeNotification: (id) => dispatch(removeNotification(id)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);