import BeaconRequest from '../BeaconRequest';

import {  
	FETCH_SINGLE_VISITOR_REQUEST,
	FETCH_SINGLE_VISITOR_SUCCESS,
} from './actionTypes';

export const fetchSingleVisitor = visitor_id => {
	return (dispatch) => {
		dispatch({
			type: FETCH_SINGLE_VISITOR_REQUEST,
		})
		return BeaconRequest.get('visitor/' + visitor_id)
		.then(responseObj => {
			dispatch({
				type: FETCH_SINGLE_VISITOR_SUCCESS,
				responseObj
			})
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchEngagedVisitorsByCampaign = (campaign_id, timestamp) => {
	return (dispatch) => {
		return BeaconRequest.get(`campaign/${campaign_id}/engagedVisitors/${timestamp}`)
		.then(responseObj => {
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchEngagedVisitorsByChannel =  (channel_id, timestamp) => {
	return (dispatch) => {
		return BeaconRequest.get(`channel/${channel_id}/engagedVisitors/${timestamp}`)
		.then(responseObj => {
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchEngagedVisitorsByLink =  (link_id, timestamp) => {
	return (dispatch) => {
		return BeaconRequest.get(`link/${link_id}/engagedVisitors/${timestamp}`)
		.then(responseObj => {
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchEngagedVisitorsByWebsite = website_id => {
	return (dispatch, getState) => {
		return BeaconRequest.get('website/' + website_id + '/engagedVisitors/' + getState().dates.selectedDateValue)
		.then(responseObj => {
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}
