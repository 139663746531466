import React from 'react'

const Checkbox = props => {
    return (
        <label className={"checkbox " + (props.disabled && "disabled")} onChange={props.handleToggle}>
            <input 
            type="checkbox" 
            defaultChecked={props.defaultChecked} 
            checked={props.checked}
            disabled={props.disabled} />
            <span className="checkbox__switch"></span>
            <span className="checkbox__label">{props.text}</span>
        </label>
    )
}

export default Checkbox
