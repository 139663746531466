import PropTypes from "prop-types";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router";
import { addNotification } from "../../actions/ui";
import BeaconRequest from "../../BeaconRequest";
import BeaconRequestPHP8 from "../../BeaconRequestPHP8";
import DropdownList from "../general/DropdownList";

function flattenGoogleAccounts(accounts) {
  const internalFlatten = (accounts, prefix) =>
    accounts.reduce((acc, val) => {
      if (!val.manager) {
        return [...acc, { name: val.name, id: val.id, loginId: val.loginId }];
      }

      return [...acc, ...internalFlatten(val.children, [...prefix, val.name])];
    }, []);

  return internalFlatten(accounts, [])
    .reduce((acc, value) => {
      const title = `${value.name} (${value.id})`;
      const unique = acc.findIndex(test => test.title === title);
      if (unique !== -1) {
        if (value.loginId === null) {
          acc[unique] = { title, value };
        }
        return acc;
      }
      return [...acc, { title, value }];
    }, [])
    .sort((a, b) => a.title.localeCompare(b.title));
}

const initialConnectionState = {
  connection: null,
  fetching: true,
  linking: false,
  accounts: [],
  fetchingAccounts: false,
  selectedAccount: { id: null, loginId: null },
  updatingAccount: false
};

function connectionReducer(state, { type, payload }) {
  switch (type) {
    case "SET_CONNECTION":
      const id =
        payload === null || payload.account_id === null
          ? null
          : parseInt(payload.account_id, 10);
      const loginId =
        payload === null || payload.login_id === null
          ? null
          : parseInt(payload.login_id, 10);

      return {
        ...state,
        connection:
          payload === null
            ? null
            : {
                ...payload,
                account_id: id,
                login_id: loginId
              },
        selectedAccount: { id, loginId }
      };
    case "SET_FETCHING":
      return {
        ...state,
        fetching: payload
      };
    case "SET_LINKING":
      return {
        ...state,
        linking: payload
      };
    case "SET_ACCOUNTS":
      return {
        ...state,
        accounts: payload
      };
    case "SET_FETCHING_ACCOUNTS":
      return {
        ...state,
        fetchingAccounts: payload
      };
    case "SET_SELECTED_ACCOUNT":
      return {
        ...state,
        selectedAccount: payload
      };
    case "SET_UPDATING_ACCOUNT":
      return {
        ...state,
        updatingAccount: payload
      };
    default:
      return state;
  }
}

function isProviderValid(provider) {
  return (
    ["google", "facebook", "linkedin", "microsoft"].indexOf(provider) !== -1
  );
}

function updateConnection(dispatch, provider, websiteId, noCache = false) {
  if (!isProviderValid(provider)) {
    throw new Error("Invalid Provider.");
  }

  dispatch("SET_FETCHING", true);

  const url = `website/${websiteId}/connection/${provider}`;
  const promise = noCache
    ? BeaconRequestPHP8.getNoChache(url)
    : BeaconRequestPHP8.get(url);
  promise
    .then(({ response }) => {
      if (
        response === null ||
        response.token_set === false ||
        response.invalidated === 1
      ) {
        dispatch({ type: "SET_CONNECTION", payload: null });
      } else {
        dispatch({ type: "SET_CONNECTION", payload: response });
      }
    })
    .catch(error => {
      alert(
        "There was an error finding your " +
          provider.charAt(0).toUpperCase() +
          provider.slice(1) +
          " connection"
      );
    })
    .finally(() => {
      dispatch({ type: "SET_FETCHING", payload: false });
    });
}

function updateAccounts(dispatch, connection) {
  dispatch({ type: "SET_FETCHING_ACCOUNTS", payload: true });

  BeaconRequestPHP8.get(`connection/${connection.connection_id}/accounts`)
    .then(({ response }) => {
      dispatch({ type: "SET_ACCOUNTS", payload: response });
    })
    .catch(e => {
      console.error(e);
      alert(
        "There was an error finding your " +
          connection.token_for.charAt(0).toUpperCase() +
          connection.token_for.slice(1) +
          " accounts"
      );
    })
    .finally(() => {
      dispatch({ type: "SET_FETCHING_ACCOUNTS", payload: false });
    });
}

function linkProvider(dispatch, provider, websiteId) {
  if (!isProviderValid(provider)) {
    throw new Error("Invalid Provider.");
  }

  BeaconRequest.post(
    "connection",
    {},
    {
      website_id: websiteId,
      token_for: provider
    }
  ).then(({ response }) => {
    window.location = response;
  });
}

function updateAccountDetails(dispatch, connection, selectedAccount) {
  dispatch({ type: "SET_UPDATING_ACCOUNT", payload: true });

  BeaconRequestPHP8.post(
    `connection/${connection.connection_id}`,
    {},
    {
      website_id: connection.website_id,
      token_for: connection.token_for,
      account_id: selectedAccount.id,
      login_id: selectedAccount.loginId
    }
  )
    .then(({ response }) => {
      if (response !== true) {
        throw new Error();
      }
      updateConnection(
        dispatch,
        connection.token_for,
        connection.website_id,
        true
      );
    })
    .catch(error => {
      console.error(error);
      alert("There was an error updating the selected account.");
    })
    .finally(() => {
      dispatch({ type: "SET_UPDATING_ACCOUNT", payload: false });
    });
}

function disconnectAccount(dispatch, connection) {
  const connectionName =
    connection.token_for === "linkedin"
      ? "LinkedIn"
      : connection.token_for.charAt(0).toUpperCase() +
        connection.token_for.slice(1);
  if (
    !window.confirm(
      "Are you sure you want disconnect your " + connectionName + " account?"
    )
  ) {
    return;
  }

  dispatch({ type: "SET_UPDATING_ACCOUNT", payload: true });

  BeaconRequestPHP8.delete(`connection/${connection.connection_id}`)
    .then(({ response }) => {
      updateConnection(
        dispatch,
        connection.token_for,
        connection.website_id,
        true
      );
    })
    .catch(() => {
      alert(`There was an error disconnecting your ${connectionName} account.`);
    })
    .finally(() => {
      dispatch({ type: "SET_UPDATING_ACCOUNT", payload: false });
    });
}

export const EditWebssiteConnectionsWrapper = props => {
  return (
    <div className="card">
      <div className="card__header">
        <h2 className="card__title">Website Ad Network Connections</h2>
      </div>
      <div className="card__content">
        <p>
          Connecting your ad accounts to Beacon allows us to automatically pull
          ad data (spend, impressions & clicks) into the Performance Dashboard.
          This happens daily at 1am your website local time.
        </p>
        <br />
        <div style={{ maxWidth: "580px" }}>
          <EditWebsiteConnections {...props} />
        </div>
      </div>
    </div>
  );
};

function EditWebsiteConnections({ website, isNewWebsite, hasConnection }) {
  const [googleState, googleDispatch] = useReducer(
    connectionReducer,
    initialConnectionState
  );
  const [facebookState, facebookDispatch] = useReducer(
    connectionReducer,
    initialConnectionState
  );
  const [linkedInState, linkedInDispatch] = useReducer(
    connectionReducer,
    initialConnectionState
  );
  const [microsoftState, microsoftDispatch] = useReducer(
    connectionReducer,
    initialConnectionState
  );
  const [fluxConnections, setFluxConnections] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    updateConnection(googleDispatch, "google", website.website_id, true);
    updateConnection(facebookDispatch, "facebook", website.website_id, true);
    updateConnection(linkedInDispatch, "linkedin", website.website_id, true);
    updateConnection(microsoftDispatch, "microsoft", website.website_id, true);
  }, [website]);

  useEffect(() => {
    if (
      googleState.connection !== null &&
      googleState.connection.account_id === null
    ) {
      updateAccounts(googleDispatch, googleState.connection);
    }
  }, [googleState.connection]);

  useEffect(() => {
    if (
      facebookState.connection !== null &&
      facebookState.connection.account_id === null
    ) {
      updateAccounts(facebookDispatch, facebookState.connection);
    }
  }, [facebookState.connection]);

  useEffect(() => {
    if (
      linkedInState.connection !== null &&
      linkedInState.connection.account_id === null
    ) {
      updateAccounts(linkedInDispatch, linkedInState.connection);
    }
  }, [linkedInState.connection]);

  useEffect(() => {
    if (
      microsoftState.connection !== null &&
      microsoftState.connection.account_id === null
    ) {
      updateAccounts(microsoftDispatch, microsoftState.connection);
    }
  }, [microsoftState.connection]);

  useEffect(() => {
    const ret = { Google: 0, Facebook: 0, LinkedIn: 0, Microsoft: 0 };

    if (
      googleState.connection instanceof Object &&
      googleState.connection.token !== null
    ) {
      if (googleState.connection.account_id === null) {
        ret.Google = 1;
      } else {
        ret.Google = 2;
      }
    }

    if (
      facebookState.connection instanceof Object &&
      facebookState.connection.token !== null
    ) {
      if (facebookState.connection.account_id === null) {
        ret.Facebook = 1;
      } else {
        ret.Facebook = 2;
      }
    }

    if (
      linkedInState.connection instanceof Object &&
      linkedInState.connection.token !== null
    ) {
      if (linkedInState.connection.account_id === null) {
        ret.LinkedIn = 1;
      } else {
        ret.LinkedIn = 2;
      }
    }

    if (
      microsoftState.connection instanceof Object &&
      microsoftState.connection.token !== null
    ) {
      if (microsoftState.connection.account_id === null) {
        ret.Microsoft = 1;
      } else {
        ret.Microsoft = 2;
      }
    }

    if (typeof hasConnection === "function") {
      hasConnection(ret);
    }

    setFluxConnections(
      Object.entries(ret)
        .reduce((acc, [k, v]) => (v === 1 ? [...acc, k] : acc), [])
        .sort()
    );
  }, [
    hasConnection,
    googleState.connection,
    facebookState.connection,
    linkedInState.connection,
    microsoftState.connection
  ]);

  useEffect(() => {
    if (
      window.location.hash.length > 0 &&
      (window.location.hash.includes("facebook") ||
        window.location.hash.includes("google") ||
        window.location.hash.includes("linkedin") ||
        window.location.hash.includes("microsoft"))
    ) {
      const platform = window.location.hash.includes("facebook")
        ? "Facebook"
        : window.location.hash.includes("google")
        ? "Google"
        : window.location.hash.includes("linkedin")
        ? "LinkedIn"
        : window.location.hash.includes("microsoft")
        ? "Microsoft"
        : "Unknown provider";
      if (window.location.hash.includes("-success")) {
        window.location.replace("#");
        window.history.replaceState({}, "", window.location.href.slice(0, -1));
        dispatch(
          addNotification({
            title: `${platform} connected succesfully`,
            theme: "positive"
          })
        );
      } else if (window.location.hash.includes("-error")) {
        const [, ...message] = window.location.hash.split("=");
        window.location.replace("#");
        window.history.replaceState({}, "", window.location.href.slice(0, -1));
        dispatch(
          addNotification({
            title:
              `Error connecting ${platform} account: ` +
              decodeURIComponent(message).replace(/\+/g, " "),
            theme: "negative"
          })
        );
      }
    }
  }, [dispatch]);

  return (
    <>
      <ul>
        <div>
          <li>
            <div
              className="card margin-small text-ms"
              text
              style={{
                padding: "0",
                display: "grid",
                gridTemplateColumns: "40px auto max-content",
                boxShadow: "none"
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                <span style={{ margin: "0px 10px" }}>
                  <i className="fab fa-google"></i>
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                {googleState.connection === null ? (
                  <span>Google</span>
                ) : googleState.connection.account_id !== null ? (
                  <span>
                    {googleState.connection.display_name} (
                    {googleState.connection.account_id})
                  </span>
                ) : (
                  <DropdownList
                    maxTextLength={isNewWebsite ? 20 : undefined}
                    menuSide="left"
                    selectItem={({ value }) => {
                      googleDispatch({
                        type: "SET_SELECTED_ACCOUNT",
                        payload: value
                      });
                    }}
                    isClearable={false}
                    isItemSelected={({ value }) =>
                      value.id === googleState.selectedAccount?.id
                    }
                    maxWidth={true}
                    theme={"light"}
                    menu={{
                      title:
                        googleState.fetchingAccounts ||
                        googleState.linking ||
                        googleState.updatingAccount
                          ? "Loading..."
                          : googleState.selectedAccount.id === null
                          ? "Select an account..."
                          : (
                              (
                                flattenGoogleAccounts(googleState.accounts) ??
                                []
                              ).find(({ value }) => {
                                return (
                                  value.id === googleState.selectedAccount.id &&
                                  value.loginId ===
                                    googleState.selectedAccount.loginId
                                );
                              }) ?? { title: "Select an account..." }
                            ).title,
                      items: flattenGoogleAccounts(googleState.accounts)
                    }}
                    isDisabled={
                      googleState.fetchingAccounts ||
                      googleState.linking ||
                      googleState.updatingAccount
                    }
                  />
                )}
              </div>
              {googleState.connection === null ||
              googleState.connection.invalidated === true ? (
                <button
                  style={{
                    backgroundColor: "#25CCCF",
                    color: "white",
                    borderRadius: 0,
                    padding: "10px 20px",
                    border: "none",
                    fontSize: "13px",
                    cursor: "pointer",
                    height: "100%"
                  }}
                  disabled={googleState.linking || googleState.fetching}
                  onClick={e => {
                    linkProvider(googleDispatch, "google", website.website_id);
                  }}
                >
                  Connect
                </button>
              ) : (
                <span>
                  {googleState.connection.account_id === null && (
                    <button
                      style={{
                        backgroundColor: "#25CCCF",
                        color: "white",
                        borderRadius: 0,
                        padding: "10px 20px",
                        border: "none",
                        fontSize: "13px",
                        cursor: "pointer",
                        height: "100%"
                      }}
                      disabled={
                        googleState.fetchingAccounts ||
                        googleState.linking ||
                        googleState.updatingAccount
                      }
                      onClick={() =>
                        updateAccountDetails(
                          googleDispatch,
                          googleState.connection,
                          googleState.selectedAccount
                        )
                      }
                    >
                      Confirm
                    </button>
                  )}

                  <button
                    style={{
                      backgroundColor: "#5E6172",
                      color: "white",
                      borderRadius: 0,
                      padding: "10px 20px",
                      border: "none",
                      fontSize: "13px",
                      cursor: "pointer",
                      height: "100%"
                    }}
                    disabled={
                      googleState.fetchingAccounts ||
                      googleState.linking ||
                      googleState.updatingAccount
                    }
                    onClick={() =>
                      disconnectAccount(googleDispatch, googleState.connection)
                    }
                  >
                    Disconnect
                  </button>
                </span>
              )}
            </div>
          </li>
        </div>

        <div>
          <li>
            <div
              className="card margin-small text-ms"
              text
              style={{
                display: "grid",
                gridTemplateColumns: "40px auto max-content",
                boxShadow: "none"
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                <span style={{ margin: "0px 10px" }}>
                  <i className="fab fa-facebook-f"></i>
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                {facebookState.connection === null ? (
                  <span>Facebook</span>
                ) : facebookState.connection.account_id !== null ? (
                  <span>
                    {facebookState.connection.display_name} (
                    {facebookState.connection.account_id})
                  </span>
                ) : (
                  <DropdownList
                    maxTextLength={isNewWebsite ? 20 : undefined}
                    menuSide="left"
                    theme="light"
                    selectItem={({ value }) => {
                      facebookDispatch({
                        type: "SET_SELECTED_ACCOUNT",
                        payload: {
                          id: parseInt(value.id, 10),
                          loginId: null
                        }
                      });
                    }}
                    isItemSelected={({ value }) =>
                      Number(value.id) ===
                      Number(facebookState.selectedAccount?.id)
                    }
                    menu={{
                      title:
                        facebookState.fetchingAccounts ||
                        facebookState.linking ||
                        facebookState.updatingAccount
                          ? "Loading..."
                          : facebookState.selectedAccount.id === null
                          ? "Select an account..."
                          : (
                              (facebookState.accounts ?? [])
                                .map(value => ({
                                  title: `${value.name} (${value.id})`,
                                  value
                                }))
                                .find(
                                  ({ value }) =>
                                    parseInt(value.id, 10) ===
                                    facebookState.selectedAccount.id
                                ) ?? { title: "Select an account..." }
                            ).title,
                      items: facebookState.accounts.map(value => ({
                        title: `${value.name} (${value.id})`,
                        value
                      }))
                    }}
                    isDisabled={
                      facebookState.fetchingAccounts ||
                      facebookState.linking ||
                      facebookState.updatingAccount
                    }
                    maxWidth
                    sort
                  />
                )}
              </div>
              {facebookState.connection === null ||
              facebookState.connection.invalidated === true ? (
                <button
                  style={{
                    backgroundColor: "#25CCCF",
                    color: "white",
                    borderRadius: 0,
                    padding: "10px 20px",
                    border: "none",
                    fontSize: "13px",
                    cursor: "pointer",
                    height: "100%"
                  }}
                  disabled={facebookState.linking || facebookState.fetching}
                  onClick={() =>
                    linkProvider(
                      facebookDispatch,
                      "facebook",
                      website.website_id
                    )
                  }
                >
                  Connect
                </button>
              ) : (
                <span>
                  {facebookState.connection.account_id === null && (
                    <button
                      style={{
                        backgroundColor: "#25CCCF",
                        color: "white",
                        borderRadius: 0,
                        padding: "10px 20px",
                        border: "none",
                        fontSize: "13px",
                        cursor: "pointer"
                      }}
                      disabled={
                        facebookState.fetchingAccounts ||
                        facebookState.linking ||
                        facebookState.updatingAccount
                      }
                      onClick={() =>
                        updateAccountDetails(
                          facebookDispatch,
                          facebookState.connection,
                          facebookState.selectedAccount
                        )
                      }
                    >
                      Confirm
                    </button>
                  )}

                  <button
                    style={{
                      backgroundColor: "#5E6172",
                      color: "white",
                      borderRadius: 0,
                      padding: "10px 20px",
                      border: "none",
                      fontSize: "13px",
                      cursor: "pointer",
                      height: "100%"
                    }}
                    disabled={
                      facebookState.fetchingAccounts ||
                      facebookState.linking ||
                      facebookState.updatingAccount
                    }
                    onClick={() =>
                      disconnectAccount(
                        facebookDispatch,
                        facebookState.connection
                      )
                    }
                  >
                    Disconnect
                  </button>
                </span>
              )}
            </div>
          </li>
        </div>

        <div>
          <li>
            <div
              className="card margin-small text-ms"
              text
              style={{
                display: "grid",
                gridTemplateColumns: "40px auto max-content",
                boxShadow: "none"
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                <span style={{ margin: "0px 10px" }}>
                  <i className="fab fa-linkedin-in"></i>
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                {linkedInState.connection === null ? (
                  <span>LinkedIn</span>
                ) : linkedInState.connection.account_id !== null ? (
                  <span>
                    {linkedInState.connection.display_name} (
                    {linkedInState.connection.account_id})
                  </span>
                ) : (
                  <DropdownList
                    maxTextLength={isNewWebsite ? 20 : undefined}
                    menuSide="left"
                    theme="light"
                    selectItem={({ value }) => {
                      linkedInDispatch({
                        type: "SET_SELECTED_ACCOUNT",
                        payload: {
                          id: parseInt(value.id, 10),
                          loginId: null
                        }
                      });
                    }}
                    isItemSelected={({ value }) =>
                      Number(value.id) ===
                      Number(linkedInState.selectedAccount?.id)
                    }
                    menu={{
                      title:
                        linkedInState.fetchingAccounts ||
                        linkedInState.linking ||
                        linkedInState.updatingAccount
                          ? "Loading..."
                          : linkedInState.selectedAccount.id === null
                          ? "Select an account..."
                          : (
                              (linkedInState.accounts ?? [])
                                .map(value => ({
                                  title: `${value.name} (${value.id})`,
                                  value
                                }))
                                .find(
                                  ({ value }) =>
                                    parseInt(value.id, 10) ===
                                    linkedInState.selectedAccount.id
                                ) ?? { title: "Select an account..." }
                            ).title,
                      items: linkedInState.accounts.map(value => ({
                        title: `${value.name} (${value.id})`,
                        value
                      }))
                    }}
                    isDisabled={
                      linkedInState.fetchingAccounts ||
                      linkedInState.linking ||
                      linkedInState.updatingAccount
                    }
                    maxWidth
                    sort
                  />
                )}
              </div>
              {linkedInState.connection === null ||
              linkedInState.connection.invalidated === true ? (
                <button
                  style={{
                    backgroundColor: "#25CCCF",
                    color: "white",
                    borderRadius: 0,
                    padding: "10px 20px",
                    border: "none",
                    fontSize: "13px",
                    cursor: "pointer",
                    height: "100%"
                  }}
                  disabled={linkedInState.linking || linkedInState.fetching}
                  onClick={() =>
                    linkProvider(
                      linkedInDispatch,
                      "linkedin",
                      website.website_id
                    )
                  }
                >
                  Connect
                </button>
              ) : (
                <span>
                  {linkedInState.connection.account_id === null && (
                    <button
                      style={{
                        backgroundColor: "#25CCCF",
                        color: "white",
                        borderRadius: 0,
                        padding: "10px 20px",
                        border: "none",
                        fontSize: "13px",
                        cursor: "pointer"
                      }}
                      disabled={
                        linkedInState.fetchingAccounts ||
                        linkedInState.linking ||
                        linkedInState.updatingAccount
                      }
                      onClick={() =>
                        updateAccountDetails(
                          linkedInDispatch,
                          linkedInState.connection,
                          linkedInState.selectedAccount
                        )
                      }
                    >
                      Confirm
                    </button>
                  )}

                  <button
                    style={{
                      backgroundColor: "#5E6172",
                      color: "white",
                      borderRadius: 0,
                      padding: "10px 20px",
                      border: "none",
                      fontSize: "13px",
                      cursor: "pointer",
                      height: "100%"
                    }}
                    disabled={
                      linkedInState.fetchingAccounts ||
                      linkedInState.linking ||
                      linkedInState.updatingAccount
                    }
                    onClick={() =>
                      disconnectAccount(
                        linkedInDispatch,
                        linkedInState.connection
                      )
                    }
                  >
                    Disconnect
                  </button>
                </span>
              )}
            </div>
          </li>
        </div>

        <div>
          <li>
            <div
              className="card margin-small text-ms"
              text
              style={{
                display: "grid",
                gridTemplateColumns: "40px auto max-content",
                boxShadow: "none"
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                <span style={{ margin: "0px 10px" }}>
                  <i className="fab fa-microsoft"></i>
                </span>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", margin: "5px" }}
              >
                {microsoftState.connection === null ? (
                  <span>Microsoft</span>
                ) : microsoftState.connection.account_id !== null ? (
                  <span>
                    {microsoftState.connection.display_name} (
                    {microsoftState.connection.account_id})
                  </span>
                ) : (
                  <DropdownList
                    maxTextLength={isNewWebsite ? 20 : undefined}
                    menuSide="left"
                    theme="light"
                    selectItem={({ value }) => {
                      microsoftDispatch({
                        type: "SET_SELECTED_ACCOUNT",
                        payload: {
                          id: parseInt(value.id, 10),
                          loginId: null
                        }
                      });
                    }}
                    isItemSelected={({ value }) =>
                      Number(value.id) ===
                      Number(microsoftState.selectedAccount?.id)
                    }
                    menu={{
                      title:
                        microsoftState.fetchingAccounts ||
                        microsoftState.linking ||
                        microsoftState.updatingAccount
                          ? "Loading..."
                          : microsoftState.selectedAccount.id === null
                          ? "Select an account..."
                          : (
                              (microsoftState.accounts ?? [])
                                .map(value => ({
                                  title: `${value.name} (${value.id})`,
                                  value
                                }))
                                .find(
                                  ({ value }) =>
                                    parseInt(value.id, 10) ===
                                    microsoftState.selectedAccount.id
                                ) ?? { title: "Select an account..." }
                            ).title,
                      items: microsoftState.accounts.map(value => ({
                        title: `${value.name} (${value.id})`,
                        value
                      }))
                    }}
                    isDisabled={
                      microsoftState.fetchingAccounts ||
                      microsoftState.linking ||
                      microsoftState.updatingAccount
                    }
                    maxWidth
                    sort
                  />
                )}
              </div>
              {microsoftState.connection === null ||
              microsoftState.connection.invalidated === true ? (
                <button
                  style={{
                    backgroundColor: "#25CCCF",
                    color: "white",
                    borderRadius: 0,
                    padding: "10px 20px",
                    border: "none",
                    fontSize: "13px",
                    cursor: "pointer",
                    height: "100%"
                  }}
                  disabled={microsoftState.linking || microsoftState.fetching}
                  onClick={() =>
                    linkProvider(
                      microsoftDispatch,
                      "microsoft",
                      website.website_id
                    )
                  }
                >
                  Connect
                </button>
              ) : (
                <span>
                  {microsoftState.connection.account_id === null && (
                    <button
                      style={{
                        backgroundColor: "#25CCCF",
                        color: "white",
                        borderRadius: 0,
                        padding: "10px 20px",
                        border: "none",
                        fontSize: "13px",
                        cursor: "pointer"
                      }}
                      disabled={
                        microsoftState.fetchingAccounts ||
                        microsoftState.linking ||
                        microsoftState.updatingAccount
                      }
                      onClick={() =>
                        updateAccountDetails(
                          microsoftDispatch,
                          microsoftState.connection,
                          microsoftState.selectedAccount
                        )
                      }
                    >
                      Confirm
                    </button>
                  )}

                  <button
                    style={{
                      backgroundColor: "#5E6172",
                      color: "white",
                      borderRadius: 0,
                      padding: "10px 20px",
                      border: "none",
                      fontSize: "13px",
                      cursor: "pointer",
                      height: "100%"
                    }}
                    disabled={
                      microsoftState.fetchingAccounts ||
                      microsoftState.linking ||
                      microsoftState.updatingAccount
                    }
                    onClick={() =>
                      disconnectAccount(
                        microsoftDispatch,
                        microsoftState.connection
                      )
                    }
                  >
                    Disconnect
                  </button>
                </span>
              )}
            </div>
          </li>
        </div>
      </ul>
      {isNewWebsite || fluxConnections.length === 0 || (
        <Prompt
          message={
            "Leaving this page will cancel connecting your " +
            fluxConnections.join(" and ") +
            " ad account" +
            (fluxConnections.length === 1 ? "" : "s") +
            ", are you sure you want to continue?"
          }
        />
      )}
    </>
  );
}

EditWebsiteConnections.propTypes = {
  website: PropTypes.object,
  isNewWebsite: PropTypes.bool
};

EditWebsiteConnections.defaultProps = {
  isNewWebsite: false
};

export default EditWebsiteConnections;
