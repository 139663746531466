var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useMemo, useRef, useState } from "react";
import { Importer } from "../../utils/fileImporter";
import * as _ from "lodash";
import BeaconRequest from "../../BeaconRequest";
import moment from "moment-timezone";
import CurrencySymbol from "../general/CurrencySymbol";
import ToolTip from "../general/ToolTip";
function Option(_a) {
    var option = _a.option;
    var isManual = option === "Manual CSV";
    return (_jsx("option", __assign({ className: "dropdown__item ".concat(isManual ? "dropdown__item--add" : ""), style: {
            color: isManual ? "#FFD200" : "white",
            background: "#5E6172"
        }, value: option }, { children: option })));
}
function ImportTools(props) {
    var _this = this;
    var _a, _b, _c, _d;
    var selectRef = useRef(null);
    var _e = useState(""), importMode = _e[0], setImportMode = _e[1];
    var _f = useState("existing"), viewMode = _f[0], setViewMode = _f[1];
    var _g = useState(false), fileSelector = _g[0], setFileSelector = _g[1];
    var _h = useState(null), selectedFile = _h[0], setSelectedFile = _h[1];
    var _j = useState({
        data: []
    }), payload = _j[0], setPayload = _j[1];
    var _k = useState(false), canSave = _k[0], setCanSave = _k[1];
    var _l = useState(false), saving = _l[0], setSaving = _l[1];
    var importer = useMemo(function () { return new Importer(); }, []);
    var options = useMemo(function () {
        return importer
            .GetSupportedImportsModes()
            .map(function (item) { return _jsx(Option, { option: item }, item); });
    }, [importer]);
    var selectImportMethod = useCallback(function (event) {
        if (fileSelector === false ||
            confirm("This will clear your current upload. Continue?")) {
            setImportMode(event.target.value);
            if (selectRef.current !== null) {
                selectRef.current.value = null;
            }
            setFileSelector(false);
            setSelectedFile(null);
            setPayload({ data: [] });
        }
    }, [fileSelector]);
    var selectViewMode = useCallback(function (event) {
        setViewMode(event.target.value);
    }, []);
    var handleImport = useCallback(function (selector) { return __awaiter(_this, void 0, void 0, function () {
        var data_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, importer.ReadFile(selector, importMode)];
                case 1:
                    data_1 = _a.sent();
                    setPayload({
                        data: data_1.rows,
                        start: _.head(data_1.rows).date,
                        end: _.last(data_1.rows).date,
                        flattened: data_1.flags.flattened
                    });
                    setCanSave(true);
                    return [2 /*return*/, true];
                case 2:
                    error_1 = _a.sent();
                    alert("There was a problem importing your file, please refer to https://www.thisisbeacon.com/guides/importing-click-data/.\nIf the problem persists then please contact support@thisisbeacon.com.");
                    return [2 /*return*/, false];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [payload, importMode]);
    var handleFileSelected = useCallback(function (event) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!event.target.files[0]) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleImport(event.target)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        setFileSelector(event.target);
                        setSelectedFile(event.target.files[0].name);
                        setViewMode("import");
                        return [2 /*return*/];
                    }
                    _a.label = 2;
                case 2:
                    setFileSelector(false);
                    setSelectedFile(null);
                    return [2 /*return*/];
            }
        });
    }); }, [handleImport]);
    var handleDeleteImport = useCallback(function () {
        var _a;
        if (window.confirm("Are you sure you want to delete this import?")) {
            setSaving(true);
            return BeaconRequest.delete("link/".concat((_a = props.spendData) === null || _a === void 0 ? void 0 : _a.link_id, "/import"), {}, {})
                .then(function (responseObj) {
                var _a, _b, _c, _d;
                alert("Your previous import has now been removed.");
                (_a = props.fetchSingleLinkNoChache) === null || _a === void 0 ? void 0 : _a.call(props, (_b = props.spendData) === null || _b === void 0 ? void 0 : _b.link_id);
                (_c = props.fetchSpendData) === null || _c === void 0 ? void 0 : _c.call(props, (_d = props.spendData) === null || _d === void 0 ? void 0 : _d.link_id);
            })
                .catch(function (error) {
                alert("Unfortunately there was a problem deleting the import.");
            })
                .finally(function () {
                setSaving(false);
            });
        }
    }, [
        (_a = props.spendData) === null || _a === void 0 ? void 0 : _a.link_id,
        props.fetchSingleLinkNoChache,
        props.fetchSpendData
    ]);
    var handleSaveImport = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var error_2;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    if (((_b = (_a = props.spendData) === null || _a === void 0 ? void 0 : _a.__spendData) === null || _b === void 0 ? void 0 : _b.has_data) &&
                        !confirm("Uploading an import will overwrite current spend data. Do you want to continue?")) {
                        return [2 /*return*/];
                    }
                    setSaving(true);
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, BeaconRequest.post("link/" + ((_c = props.spendData) === null || _c === void 0 ? void 0 : _c.link_id) + "/import", {}, {
                            ad_channel: importMode,
                            data: payload.data,
                            start: payload.start,
                            end: payload.end
                        })];
                case 2:
                    _h.sent();
                    (_d = props.fetchSingleLinkNoChache) === null || _d === void 0 ? void 0 : _d.call(props, (_e = props.spendData) === null || _e === void 0 ? void 0 : _e.link_id);
                    (_f = props.fetchSpendData) === null || _f === void 0 ? void 0 : _f.call(props, (_g = props.spendData) === null || _g === void 0 ? void 0 : _g.link_id);
                    alert("Thank you, the import has been saved.");
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _h.sent();
                    alert("Unfortunately there was a problem with saving your import, please refer to https://www.thisisbeacon.com/guides/importing-click-data/.\nIf the problem persists then please contact support@thisisbeacon.com.");
                    console.log(error_2);
                    return [3 /*break*/, 4];
                case 4:
                    setSaving(false);
                    return [2 /*return*/];
            }
        });
    }); }, [
        payload,
        (_b = props.spendData) === null || _b === void 0 ? void 0 : _b.link_id,
        props.fetchSingleLinkNoChache,
        props.fetchSpendData
    ]);
    var hasExistingSpend = useMemo(function () { var _a; return (_a = props.spendData) === null || _a === void 0 ? void 0 : _a.__spendData.has_data; }, [(_c = props.spendData) === null || _c === void 0 ? void 0 : _c.__spendData.has_data]);
    var data = useMemo(function () {
        var _a, _b;
        if ((viewMode === "existing" && hasExistingSpend) ||
            (viewMode === "import" && fileSelector === false)) {
            return (_b = (_a = props.spendData) === null || _a === void 0 ? void 0 : _a.__spendData.data) !== null && _b !== void 0 ? _b : [];
        }
        else {
            return payload.data;
        }
    }, [
        viewMode,
        hasExistingSpend,
        fileSelector,
        (_d = props.spendData) === null || _d === void 0 ? void 0 : _d.__spendData.data,
        payload.data
    ]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ style: {
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10
                }, className: "margin-medium" }, { children: [_jsx("div", __assign({ className: "select-container", style: { flex: "none" } }, { children: _jsxs("select", __assign({ onChange: selectImportMethod, value: importMode, className: "select dropdown", disabled: saving }, { children: [_jsx("option", __assign({ hidden: true, value: "" }, { children: "Select a Source" })), options] })) })), _jsx("label", __assign({ htmlFor: "file", className: "button button--dark-grey ".concat(importMode === "" || saving ? "disabled" : ""), style: {
                            display: "flex",
                            alignItems: "center",
                            transition: "flex 0s",
                            flex: fileSelector === false ? "unset" : 1,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis"
                        } }, { children: selectedFile ? (selectedFile) : (_jsxs(_Fragment, { children: [_jsx("i", { className: "fas fa-upload", style: { marginRight: "5px" } }), "Select a File"] })) })), _jsx("input", { id: "file", style: { display: "none" }, onChange: handleFileSelected, ref: selectRef, type: "file", accept: ".csv", disabled: importMode === "" || saving })] })), _jsxs("div", __assign({ style: {
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 10
                }, className: "margin-medium" }, { children: [hasExistingSpend && canSave && (_jsx("div", __assign({ className: "select-container", style: { flex: "none" } }, { children: _jsxs("select", __assign({ onChange: selectViewMode, value: viewMode, className: "select dropdown", disabled: saving }, { children: [_jsx("option", __assign({ value: "existing" }, { children: "Existing Spend" })), _jsx("option", __assign({ value: "import" }, { children: "Import" }))] })) }))), hasExistingSpend &&
                        (viewMode === "existing" || fileSelector === false) && (_jsx("button", __assign({ style: { whiteSpace: "nowrap" }, className: "button button--red", onClick: handleDeleteImport, disabled: saving }, { children: "Delete Existing Spend" }))), fileSelector !== false &&
                        (viewMode === "import" || !hasExistingSpend) && (_jsx("button", __assign({ style: { whiteSpace: "nowrap" }, className: "button button--green", onClick: handleSaveImport, disabled: !canSave || saving }, { children: saving ? "Saving..." : "Save Import" })))] })), (hasExistingSpend || fileSelector !== false) && (_jsxs("div", __assign({ className: "table margin-medium" }, { children: [_jsxs("div", __assign({ className: "table__row" }, { children: [_jsx("div", __assign({ className: "table__heading" }, { children: "Date" })), _jsx("div", __assign({ className: "table__heading" }, { children: "Clicks" })), _jsx("div", __assign({ className: "table__heading" }, { children: "CPC" })), _jsx("div", __assign({ className: "table__heading" }, { children: "Impressions" })), _jsx("div", __assign({ className: "table__heading" }, { children: "Spend" }))] })), data.map(function (item, i) {
                        var clicks = item.clicks, CPC = item.CPC, date = item.date, day = item.day, reported_CPC = item.reported_CPC, impressions = item.impressions, spend = item.spend;
                        var cost_per_click = CPC || reported_CPC || 0;
                        return (_jsxs("div", __assign({ className: "table__row" }, { children: [_jsx("div", __assign({ className: "table__cell" }, { children: moment((date || day).substring(0, 10)).format("DD MMM YYYY") })), _jsx("div", __assign({ className: "table__cell" }, { children: clicks })), _jsx("div", __assign({ className: "table__cell" }, { children: payload.flattened && !cost_per_click ? (_jsx("a", __assign({ href: "https://www.thisisbeacon.com/guides/imported-cpc-displaying-as-0-00/", target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(ToolTip, { hint: true, hasPointer: true, tip: "Click for explanation", text: "N/a" }) }))) : (_jsxs("span", { children: [_jsx(CurrencySymbol, {}), !cost_per_click ? (_jsx("a", __assign({ href: "https://www.thisisbeacon.com/guides/imported-cpc-displaying-as-0-00/", target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(ToolTip, { hint: true, hasPointer: true, tip: "Click for explanation", text: cost_per_click }) }))) : (cost_per_click)] })) })), _jsx("div", __assign({ className: "table__cell" }, { children: impressions })), _jsxs("div", __assign({ className: "table__cell" }, { children: [_jsx(CurrencySymbol, {}), spend.toFixed(2)] }))] }), i));
                    })] })))] }));
}
export default ImportTools;
