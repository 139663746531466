// SEE BOTTOM OF FILE FOR NEW IDEAS 

import React from 'react';
import ToolTip from '../general/ToolTip';

const VisitorActions = props => {

    const actions = props.actions.map( (item, i) => {    
        // console.log(item)
        return (
            <li key={i} className="visitor-actions__item">
                {
                    item === 'ecom_basket_add' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-cart-plus"></i>}
                    tip="Item added to basket" />
                }
                {
                    item === 'ecom_basket_rm' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fa-cart-arrow-down"></i>}
                    tip="Remove from basket" />
                }
                {
                    item === 'ecom_payment_gateway' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-coins"></i>}
                    tip="Payment started" />
                }
                {
                    item === 'ecom_purchase_fail' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon visitor-actions__icon--red fas fa-shopping-cart"></i>}
                    tip="Payment failed" />
                }
                {
                    item === 'ecom_purchase_success' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon visitor-actions__icon--green fas fa-shopping-basket"></i>}
                    tip="Purchase made" />
                }
                {
                    item === 'page_load' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-file-download"></i>}
                    tip="Page loaded" />
                }
                {
                    item === 'internal_link' && <></>
                    // <ToolTip 
                    // text={<i class="visitor-actions__icon fas fa-link"></i>}
                    // tip="Internal link engaged" />
                }
                {
                    item === 'external_link' && 
                    <ToolTip 
                    text={<i class="visitor-actions__icon fas fa-external-link-alt"></i>}
                    tip="External link engaged" />
                }
                {
                    item === 'mailto' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-envelope"></i>}
                    tip="Email link engaged" />
                }
                {
                    item === 'tel' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-phone"></i>}
                    tip="Telephone number engaged" />
                }
                {
                    item === 'download' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-download"></i>}
                    tip="Download occurred" />
                }
                {
                    item === 'anchor' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-hashtag"></i>}
                    tip="Page anchor hit" />
                }
                {
                    item === 'canvas_interaction' && <div></div>
                }
                {
                    item === 'click' && <div></div>
                }
                {
                    item === 'form_submit' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-file-alt"></i>}
                    tip="Form submitted" />
                }
                {
                    item === 'input_specified' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-pen-alt"></i>}
                    tip="Form field populated" />
                }
                {
                    item === 'hit_top' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-long-arrow-alt-up"></i>}
                    tip="Scrolled to top" />
                }
                {
                    item === 'hit_bottom' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-long-arrow-alt-down"></i>}
                    tip="Scrolled to bottom" />
                }
                {
                    item === 'scroll_up' && <div></div>
                }
                {
                    item === 'scroll_down' && <div></div>
                }
                {
                    item === 'dialog_opened' && <div></div>
                }
                {
                    item === 'modal_opened' && <div></div>
                }
                {
                    item === 'dialog_close' && <div></div>
                }
                {
                    item === 'audio_play' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-play"></i>}
                    tip="Audio played" />
                }
                {
                    item === 'audio_pause' && <div>audio_pause</div>
                }
                {
                    item === 'audio_seek' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
                    tip="Audio engaged" />
                }
                {
                    item === 'audio_finished' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
                    tip="Audio engaged" />
                }
                {
                    item === 'audio_volume_changed' && <div></div>
                }
                {
                    item === 'audio_buffering' && <div></div>
                }
                {
                    item === 'video_play' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-video"></i>}
                    tip="Video played" />
                }
                {
                    item === 'video_pause' && <div></div>
                }
                {
                    item === 'video_seek' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
                    tip="Video engaged" />
                }
                {
                    item === 'video_finished' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
                    tip="Video finished" />
                }
                {
                    item === 'video_volume_changed' && <div></div>
                }
                {
                    item === 'video_buffering' && <div></div>
                }
                {
                    item === 'multi_pager_plus' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-file"></i>}
                    tip="Visitor viewed 6+ pages" />
                }
                {
                    item === 'we_know_where_you_live' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon visitor-actions__icon--green fas fa-user-check"></i>}
                    tip="Visitor self-identified" />
                }
                {
                    item === 'long_browser' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-user-clock"></i>}
                    tip="Visitor active 5+ minutes" />
                }
                {
                    item === 'link_revisitor' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-link"></i>}
                    tip="Repeat visitor through link" />
                }
                {
                    item === 'site_fanboi' && 
                    <ToolTip 
                    text={<i className="visitor-actions__icon fas fa-thumbs-up"></i>}
                    tip="Repeat visitor" />
                }
            </li>
        )
    })

    return (
        <ul className="visitor-actions">
            {
                props.lead > 0 &&
                <li>
                    <ToolTip 
                    text={<i className="visitor-actions__icon visitor-actions__icon--gold fas fa-trophy"></i>}
                    tip="Goal achieved" />
                </li>
            }
            {actions}
        </ul>
    )
}

export default VisitorActions;







// import React from 'react';
// import ToolTip from '../general/ToolTip';

// const VisitorActions = props => {
//     const actions = props.actions.map( (item, i) => {
//         let description = ''
//         let icon = ''
//         // We may want to decide here what the key visitor actions are and only display them if they really are key
//         // Otherwise this list can get quite long and bulky
//         switch(item){
//             case "hit_bottom": description = "Hit bottom"; icon = "fas fa-long-arrow-alt-down"
//             break;
//             case "hit_top": description = "Hit top"; icon = "fas fa-long-arrow-alt-up"
//             break;
//             case "ecom_basket_add": description = "Add to basket"; icon = "fas fa-cart-plus"
//             break;
//             case "ecom_purchase_success": description = "Checkout success"; icon = "fas fa-shopping-cart"
//             break;
//             case "ecom_payment_gateway": description = "Payment gateway"; icon = "fas fa-shopping-cart"
//             break;
//             case "ecom_purchase_fail": description = "Payment failed"; icon = "fas fa-shopping-cart"
//             break;
//             case "ecom_basket_rm": description = "Remove from basket"; icon = "fas fa-shopping-cart"
//             break;
//             case "page_lod": description = "Page loaded"; icon = "fas fa-file-download"
//             break;
//             case "internal_link": description = "Internal link"; icon = "fas fa-link"
//             break;
//             case "external_link": description = "External link"; icon = "fas fa-link"
//             break;
//             case "mailto": description = "Email link"; icon = "fas fa-envelope"
//             break;
//             case "tel": description = "Tel number"; icon = "fas fa-phone"
//             break;
//             case "dpwnload": description = "Download occurred"; icon = "fas fa-download"
//             break;
//             case "anchor": description = "Page anchor"; icon = "fas fa-hashtag"
//             break;
//             case "form_submit": description = "Form submitted"; icon = "fas fa-file-alt"
//             break;
//             case "input_specified": description = "Form field populated"; icon = "fas fa-pen-alt"
//             break;
//             case "audio_play": description = "Audio played"; icon = "fas fa-play"
//             break;
//             case "click": description = "Click"; icon = "fas fa-mouse-pointer"
//             break;
//         }
//         return (
//             <li key={i} className="visitor-actions__item">
//                 <span className="visitor-actions__item tag"><i className={"visitor-actions__icon " + icon} ></i>{description}</span>
//                 {/*

                
//                 {
//                     item === 'canvas_interaction' && <div></div>
//                 }

//                 {
//                     item === 'scroll_up' && <div></div>
//                 }
//                 {
//                     item === 'scroll_down' && <div></div>
//                 }
//                 {
//                     item === 'dialog_opened' && <div></div>
//                 }
//                 {
//                     item === 'modal_opened' && <div></div>
//                 }
//                 {
//                     item === 'dialog_close' && <div></div>
//                 }
                
//                 {
//                     item === 'audio_pause' && <div>audio_pause</div>
//                 }
//                 {
//                     item === 'audio_seek' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
//                     tip="Audio engaged" />
//                 }
//                 {
//                     item === 'audio_finished' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
//                     tip="Audio engaged" />
//                 }
//                 {
//                     item === 'audio_volume_changed' && <div></div>
//                 }
//                 {
//                     item === 'audio_buffering' && <div></div>
//                 }
//                 {
//                     item === 'video_play' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-video"></i>}
//                     tip="Video played" />
//                 }
//                 {
//                     item === 'video_pause' && <div></div>
//                 }
//                 {
//                     item === 'video_seek' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
//                     tip="Video engaged" />
//                 }
//                 {
//                     item === 'video_finished' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-step-forward"></i>}
//                     tip="Video finished" />
//                 }
//                 {
//                     item === 'video_volume_changed' && <div></div>
//                 }
//                 {
//                     item === 'video_buffering' && <div></div>
//                 }
//                 {
//                     item === 'multi_pager_plus' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-file"></i>}
//                     tip="Visitor viewed 6+ pages" />
//                 }
//                 {
//                     item === 'we_know_where_you_live' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-user-check"></i>}
//                     tip="Visitor self-identified" />
//                 }
//                 {
//                     item === 'long_browser' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-user-clock"></i>}
//                     tip="Visitor active 5+ minutes" />
//                 }
//                 {
//                     item === 'link_revisitor' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-link"></i>}
//                     tip="Repeat visitor through link" />
//                 }
//                 {
//                     item === 'site_fanboi' && 
//                     <ToolTip 
//                     text={<i className="visitor-actions__icon fas fa-thumbs-up"></i>}
//                     tip="Repeat visitor" />
//                 } */}
//             </li>
//         )
//     })

//     return <ul className="visitor-actions">{actions}</ul>
// }

// export default VisitorActions;