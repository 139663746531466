import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'

import { makeStyles,createMuiTheme,ThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import Toggle from '../general/Toggle'
import ToolTip from '../general/ToolTip'
import MediaUpload from '../general/MediaUpload'
import DropdownList from '../general/DropdownList'
import Flatpickr from 'react-flatpickr'
import produce from 'immer'
import BeaconRequest from '../../BeaconRequest'
import moment from 'moment'
import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';
import {addNotification} from '../../actions/ui'
import LoaderAlt from '../general/LoaderAlt'

import {HelpDialog, Expandable} from '../../organisms/ReportingPresets'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
      // textAlign: 'end'
    },
    resetContainer: {
      padding: theme.spacing(3),
      
    },
    stepperRoot: {
      backgroundColor: 'transparent'
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      color: '#5E6172',
      fontFamily: "Lato', sans-serif"

    },
  }));
  

  const theme = createMuiTheme({
      typography: {
        fontFamily: 'Lato',
        
      },
      palette: {
          primary: {
              main: '#25CCCF',
              contrastText: '#fff'
          }
      }
    });

  function getSteps() {
    return ['Schedule a report', /*'Notification settings'*/ 'Report settings', 'Set a password', 'Review'];
  }
  
  const getStepsEdit = () => {
    return [/*'Notification settings'*/ 'Edit report settings', 'Set a new password', 'Review'];
  }

const Section = (props) => {
  const {section, selectedConfig, setSelectedConfig} = props

  return <div>
      <div style={{margin: '10px'}}>
          <div>
              <Toggle 
                  checked={selectedConfig.config.template.sections[section].included}
                  text="Include overview section" 
                  handleToggle={e => {
                      const val = e.target.checked; 
                      setSelectedConfig(produce(draft => {draft.config.template.sections[section].included = val}))
                  }}
              />
          </div>
          {selectedConfig.config.template.sections[section].included && 
              <div>
                <TextareaAutosize
                    rowsMin={1}
                    rowsMax={2}
                    className="form__input margin-small" style={{resize: 'vertical', minHeight: '100px', maxHeight: '300px'}}
                    value={selectedConfig.config.template.sections[section].description}
                    onChange={e => {
                        const val = e.target.value; 
                        setSelectedConfig(produce(draft => {draft.config.template.sections[section].description = val}))
                    }}
                />
                <div style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                    <div style={{textAlign: 'right'}}>
                        <HelpDialog/>
                    </div>
                </div>
                
            </div>
          }
      </div>
  </div>
}


const overview_desc = `This campaign has generated {{visits}} real human visits from {{clicks}} natural clicks; this is a ratio of approximately 1 visitor for every {{visits_average}} clicks. Beacon has determined that {{engaged_visitor_percent}}% of your visitors are engaged (meaning that they have spent some time looking at your site). On average, visitors viewed {{avg_page_views}} pages and stayed on the site for {{avg_time_on_site}} seconds. The last visit from a link in this campaign was on {{campaign_last_visit}}.  The average star rating for visits to this campaign is {{campaign_score}}. `
const spend_desc = `You have spent GBP {{campaign_spend}} in this campaign. Your average CPV (cost per visit) was GBP {{cpv}}.   You paid for {{clicks}} clicks, but received {{visits}} visits. {{bots_percent}}% of your paid clicks were not from real humans.  You wasted GBP {{wasted_spend}} on bots. `
const no_star_rating = `This campaign has generated {{visits}} real human visits from {{clicks}} natural clicks; this is a ratio of approximately 1 visitor for every {{visits_average}} clicks. Beacon has determined that {{engaged_visitor_percent}}% of your visitors are engaged (meaning that they have spent some time looking at your site). On average, visitors viewed {{avg_page_views}} pages and stayed on the site for {{avg_time_on_site}} seconds. The last visit from a link in this campaign was on {{campaign_last_visit}}.`
const no_wastage = `You have spent GBP {{campaign_spend}} in this campaign. Your average CPV (cost per visit) was GBP {{cpv}}.   You paid for {{clicks}} clicks, but received {{visits}} visits. {{bots_percent }}% of your paid clicks were not from real humans.`


const ReportSettings = (props) => {
    const [presets, setPresets] = useState([{title: 'Default', config: props.reportConfig}]) //NOTE TITLE NOT NAME cause of dropdown ...
    const [selectedConfig, setSelectedConfig] = useState(presets[0])
    const [editConfig, setEditConfig] = useState(false)

    const get_presets = async () => {
      await BeaconRequest.getNoChache(`reporttemplate/all/${props.website.website_id}`)
      .then(e => {
          if (e.response.length > 0) {
              const process = e.response.map(i => {
                  const template = i.values
                  const conf = JSON.parse(template.content)[0]
                  
                  return {
                      template_id: template.template_id, 
                      title: conf.name,
                      config: conf.config
                  }
              })

              if (process.filter(t => t.title == "Default").length == 0) {
                  process.unshift({title: 'Default', config: props.reportConfig})
              } else { //move to top
                const index = process.map(e => e.title).indexOf('Default')
                const element = process[index];
                process.splice(index, 1);
                process.splice(0, 0, element)
              }

              if (props.edit) {
                process.unshift({title: 'Current', config: {template: JSON.parse(props.edit.template_json)}})
              }

              setPresets(process)
              setSelectedConfig(process[0])
              
          }
      })
      .catch(e => {
          console.log(e)
      })

  }

    useEffect(() => {
        get_presets()

        if (props.edit) {
          setEditConfig(true)
        }
    }, [])

    
    const handle_next = () => {
        props.setReportConfig(produce(draft => {
            draft.template = Object.assign(draft.template, selectedConfig.config.template)
            draft.template.reportType = 'single'
        }))
    }
    

    return <>
        {props.edit ?
        <p className="text-small">
          You can edit the report customisation settings below, and you can also switch to another preset.
          <br />
          You can create and edit global presets (which can also be used in the Report Builder) at <a >(/reporting)</a>
        </p>
        : 
        <p className="text-small">
          Select a preset, these presets allow you to quickly customise a report with ease.
          You can create and edit global presets (which can also be used in the Report Builder) at <a >(/reporting)</a>
          <br />
          To fine tune or edit your preset for this report only, click <em>'Edit preset'</em>.
        </p>
        }
        
        <div  style={{margin: '10px'}}>
          <DropdownList
            selectItem={setSelectedConfig} 
            selectedItem={selectedConfig} 
            menu={{
              title: selectedConfig.title,
              items: presets
            }} 
          />
        </div>
        <Divider />
        {!props.edit && 
            <span className="text-small" style={{fontStyle: 'italic', cursor: 'pointer'}} onClick={e => setEditConfig(!editConfig)}>{editConfig ? "Collapse" : "Edit preset"}</span>
        }
        
        <Fade in={editConfig} timeout={300}>
          <div>
            {editConfig && <>
              <div className="text-small">(changes made here will only affect this report, and will not modify this preset)</div>

              <div style={{margin: '10px'}}>
              <div >
                  <Toggle
                      checked={selectedConfig.config.template.hasWastage}
                      text="Include wasted spend in report" 
                      handleToggle={e => {
                          const val = e.target.checked; //define here, so that synthetic event doesnt get lost on editing draft
                          if (window.confirm("Toggling 'Include wasted spend in report' will replace the spend description. are you sure?")) {
                            setSelectedConfig(produce(draft => {
                                draft.config.template.hasWastage = val
                                draft.config.template.sections.spend.description = val ? spend_desc : no_wastage
                            }))
                          }
                      }}
                  />
              </div>
              <div>
                  <Toggle 
                      checked={selectedConfig.config.template.hasStarRating}
                      text="Include star ratings in report" 
                      handleToggle={e => {
                          const val = e.target.checked; 
                          if (window.confirm("Toggling 'Include star ratings in report' will replace the overview description. are you sure?")) {
                            setSelectedConfig(produce(draft => {
                                draft.config.template.hasStarRating = val
                                draft.config.template.sections.overview.description = val ? overview_desc : no_star_rating
                            }))
                          }
                        
                      }}
                  />
              </div>
              <div>
                  <Toggle 
                      checked={selectedConfig.config.template.displayLinks}
                      text="Include active links in report" 
                      handleToggle={e => {
                          const val = e.target.checked; 
                          setSelectedConfig(produce(draft => {
                              draft.config.template.displayLinks = val
                          }))
                      }}
                  />
              </div>
          </div>

          <div className="form__item margin-large" style={{width: '400px', margin: '10px'}}>
              <label className="form__label">
                  Custom banner (minimum size <ToolTip text="400px x 200px" tip="and an aspect ratio of 2:1" hint={true} />)
              </label>
              { selectedConfig.config.template.images.banner !== ""? (<>
                  <span className="report__custom-banner" style={{width: '100%'}}>
                      
                      <img src={selectedConfig.config.template.images.banner} alt="Report Banner" style={{objectFit: selectedConfig.config.template.images.bannerFit || "cover", maxHeight: 'none', width: '100%', height: '200px'}}/>
                      <button 
                          className="report__custom-banner-remove button button--small button--red" 
                          onClick={e => setSelectedConfig(produce(draft => {draft.config.template.images.banner = ""}))}
                      >
                          <i className="fas fa-times"></i>
                      </button>
                  </span>

                  <div style={{ 'marginBottom': '10px'}}>
                        <li style={{display: 'inline-block'}}>
                            <div style={{marginLeft: '10px'}}>
                                <h2 style={{display: 'inline-block', marginRight: '5px'}}>Cover</h2>
                                <label className="radio-container">
                                    <input type="radio" name="radio" checked={selectedConfig.config.template.images.bannerFit == "cover"} onChange={(e) => {
                                        setSelectedConfig(produce(draft => {draft.config.template.images.bannerFit = "cover"}))
                                    }}/>
                                    <span className="checkmark"></span>
                                </label>
                                <h2 style={{display: 'inline-block', marginRight: '5px'}}>Contain</h2>
                                <label className="radio-container">
                                    <input type="radio" name="radio" checked={selectedConfig.config.template.images.bannerFit == "contain"} onChange={(e) => {
                                        setSelectedConfig(produce(draft => {draft.config.template.images.bannerFit = "contain"}))
                                    }}/>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </li>
                    </div>
              </>
              ) : (<>
                  
                  <img src={"https://s3.eu-west-1.amazonaws.com/beacon.assets/summary_banner.png"} alt="Report Banner"  width="100%" height="100%"/>
                  
                  <MediaUpload aspectRatio={true} aspectRatioWidth={600} aspectRatioHeight={300} minWidth={360} 
                      handleUploadMedia={e => {
                        setSelectedConfig(produce(draft => {draft.config.template.images.banner = e}))
                      }
                  } />
              </>) }
          </div>

          <div style={{margin: '10px'}}>
              <div  style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                  <label className="form__label">
                  Executive Summary
                  </label>
                  <div style={{textAlign: 'right'}}>
                      <HelpDialog/>
                  </div>
              </div>
              <div>
                  <textarea
                    value={selectedConfig.config.template.executiveSummary} 
                    onChange={e => {
                    const val = e.target.value; 
                    setSelectedConfig(produce(draft => {draft.config.template.executiveSummary = val}))
                }}
                    className="form__input"
                  />
              </div>
          </div>

          <Expandable title="Overview" style={{margin:'10px'}} content={
              <Section section="overview" {...{presets, setPresets, selectedConfig, setSelectedConfig}}/>
          }/>
          <Expandable title="Spend" style={{margin:'10px'}} content={
              <Section section="spend" {...{presets, setPresets, selectedConfig, setSelectedConfig}}/>
          }/>
          <Expandable title="Channels" style={{margin:'10px'}} content={
              <Section section="channels" {...{presets, setPresets, selectedConfig, setSelectedConfig}}/>
          }/>
          <Expandable title="Leads" style={{margin:'10px'}} content={
              <Section section="leads" {...{presets, setPresets, selectedConfig, setSelectedConfig}}/>
          }/>
          </>}
        </div>
      </Fade>
      {props.actions(false, handle_next)}
    </>
} 

const ScheduleReport = (props) => {
  const options = [
    {id: 1, title: 'Schedule a report when the campaign ends'},
    {id: 2, title: 'Schedule reccuring reports'},
    {id: 3, title: 'Schedule a report once'}
  ]

  const r_options = [
    {id: 1, title: 'Daily', name: 'Day'},
    {id: 2, title: 'Every 7 days', name: 'Week'},
    {id: 3, title: 'Every 14 days', name: 'Fortnight'},
    {id: 3, title: 'Every 4 weeks', name: '4Weekly'},
    {id: 4, title: 'Last calendar month', name: 'Monthly'},
  ] 

  // const e_options = [
  //   {id: 'today', title: 'Today', name: 'today'},
  //   {id: 'yesterday', title: 'Yesterday', name: 'yesterday'},
  //   {id: 'last2days', title: 'Last 2 Days', name: 'last2days'},
  //   {id: 'last3days', title: 'Last 3 Days', name: 'last3days'},
  //   {id: 'last7days', title: 'Last 7 Days', name: 'last7days'},
  //   {id: 'last14days', title: 'Last 14 Days', name: 'last14days'},
  //   {id: 'last30days', title: 'Last 30 Days', name: 'last30days'},
  //   // {id: 'custom', title: 'Custom'},
  // ] 

  const [option, setOption] = useState(options[1])
  const [frequency, setFrequency] = useState(r_options[0])
  const [activeCampaigns, setActiveCampaigns] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  // const [range, setRange] = useState(e_options[4])
  const [creationDate, setCreationDate] = useState(null)

  useEffect(() => {
    BeaconRequest.get(`website/${props.website.website_id}/campaigns/active`)
      .then(responseObj => {
        setActiveCampaigns(responseObj.response)
      })
      .catch(error => { console.log(error) })
      
      setOption(props.ui.option || options[1]); 
      setFrequency(props.ui.frequency ||r_options[0] ); 
      setActiveCampaigns(props.ui.activeCampaigns || [])
      setSelectedCampaign(props.ui.selectedCampaign || null); 
      setCreationDate(props.ui.creationDate || null)
      
  }, [])

  const save_ui = () => {
    props.setUi(produce(draft => {
        Object.assign(draft, {
          option, frequency, selectedCampaign, creationDate, activeCampaigns
        })
    }))
  }

  // useEffect(() => {
  //   if (selectedCampaign != null || selectedCampaign == 0) {
  //     if (getCampaign(selectedCampaign.id).endDate) {
  //       setOption(options[0])
  //     } else {
  //       setOption(options[2])
  //     }
  //   }
  // }, [selectedCampaign])

  // useEffect(() => { //reset
    // setFrequency(r_options[0]); 
  //   setCreationDate(null)
  // }, [option])

  const getCampaign = (id) => {
      if (id == null) {
        return 
      }

      return activeCampaigns.filter(i => i.campaign_id === id)[0]
  }

  const is_disable_next = () => {
    switch (option.id) {
      case 1:
        return !(selectedCampaign && getCampaign(selectedCampaign.id).endDate != null && getCampaign(selectedCampaign.id).endDate != 0)
      case 2:
        // && moment(creationDate).isAfter(moment())
        return !(selectedCampaign && creationDate  )
      case 3:
        return !(selectedCampaign && creationDate && creationDate.length > 1)
      default:
        throw 'Invalid option'
    }
  }

  const handle_next = () => {
      props.setReportConfig(produce(draft => {
          draft.campaign_id = selectedCampaign.id
          draft.website_id = props.website.website_id
          draft.timezone = BeaconRequest.getTimezone() //they need to select a timezone instead, 

          switch (option.id) {
            case 1:
              draft.frequency = "CampaignEnd"
              break;
            case 3:
              draft.frequency = 'Single'
              draft.startFrom = moment(creationDate[0]).utc().format('Y-M-D HH:mm:ss')
              draft.endOn = moment(creationDate[1]).utc().format('Y-M-D HH:mm:ss')
              break;
            case 2:
              draft.frequency = frequency.name
              draft.nextDue = moment(creationDate[0]).utc().format('Y-M-D HH:mm:ss') //will create on the next day so it covers today as well
              break
            default:
              throw 'Invalid option'
          }
      }))

      save_ui() //so we can go back
  }

  const get_calendar_options = () => {
    switch (option.id) {
      case 2:
        return { 
          mode: 'single',
          defaultHour: 0, 
          inline: true, 
          minDate: moment(new Date()).add(1, 'day').startOf('day').toDate(),
        };
      case 3:
        return {
          mode: 'range',
          defaultHour: 0,
          inline: true, 
          minDate: null
        }
      default:
        throw 'Invalid option'
    }
  }

  return <>
    <div  className="margin-small">
      <p>Select a campaign</p>
      <DropdownList
        selectItem={setSelectedCampaign} 
        selectedItem={selectedCampaign} 
        menu={{
          title: selectedCampaign == null ? 'Select a campaign' : selectedCampaign.title,
          items: activeCampaigns.map(c => {return {id: c.campaign_id, title: c.title}})
        }} 
        sort
      />
    </div>

    {selectedCampaign != null && <>
            <div className="margin-medium">
            <p>Select a date to schedule the report</p>
            <DropdownList 
              selectItem={setOption} 
              selectedItem={option} 
              menu={{
                title: option.title,
                items: options
              }} 
            />

            {option.id === 1 && <div>{
              getCampaign(selectedCampaign.id).endDate != null && getCampaign(selectedCampaign.id).endDate != 0?
                'Report creating on: ' + moment.unix(getCampaign(selectedCampaign.id).endDate).format('D/M/Y') 
                : 'You have selected a campaign with no end date, please change to either a manually selected end date, or reccuring'
              }
              </div>
            }
          </div>
        
          {option.id === 2 && <div className="margin-medium">
            <h1 className="form__label" style={{textAlign: 'left'}}>Select the frequency to generate reports</h1>
            <DropdownList 
              selectItem={setFrequency} 
              selectedItem={frequency} 
              menu={{
                title: frequency.title,
                items: r_options
              }} 
            />
            </div>
          }
          {(option.id === 2 || option.id === 3) && <div>
              {(option.id === 2 && frequency.id === 4) ?
                    <div>
                      <h1 className="form__label" style={{textAlign: 'left'}}>Select the month you want your report to start generating</h1>
                      <p className="text-small"><i>Note: You must select a month in the future</i></p>
                      <input  type="month" 
                        min={moment().endOf('month').format("YYYY-MM")} 
                        onChange={e => { setCreationDate([moment(e.target.value).add(1, 'month').startOf('month').toDate()]) }}
                        /> 
                        {moment(creationDate).isBefore(moment()) && <p className="text-small">You have selected a date in the past</p>}
                    </div>
                    :
                    <div className="form__item">
                      
                      {option.id === 2 && <>
                        <h1 className="form__label" style={{textAlign: 'left'}}>Select the day you want your report to start generating</h1>
                        <p className="text-small"><i>Note: You must select a date in the future</i></p>
                      </>}
                      {option.id === 3 && <>
                        <h1 className="form__label" style={{textAlign: 'left'}}>Select the time period you want the report to cover</h1>
                        <p className="text-small"><i>Your report will be created at the end of the time period, or as soon as possible, please two dates from the calendar</i></p>
                      </>}

                      <div className="hide-datepicker-input">
                          <div className="date-range-select__picker">
                              <Flatpickr
                                  value={creationDate}
                                  options={get_calendar_options()}
                                  onChange={date => setCreationDate(date)} 
                              />
                          </div>
                      </div>
                  </div>
              }
            </div>
          }
     </> 
    }
    {props.actions(is_disable_next(), handle_next)}
     
  </>
}

const Email = (props) => {
  const [emails, setEmails] = useState([])
  const [addEmail, setAddEmail] = useState("")
  const [invalidEmail, setInvalidEmail] = useState(false)

  const validate_email = (mail) => {
    const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return mail.match(mailformat)
  }
  
  const handle_next = () => {
    props.setReportConfig(produce(draft => {
        draft.template.notify = emails
    }))
  }

  return <div>
      <div className="margin-medium">
        <input
            className="form__input" 
            onChange={e => {
              setAddEmail(e.target.value)
            }} 
            value={addEmail}
            style={{
              display: 'inline', 
              width: '78%',
            }}
          />
          <span style={{'display': 'inline-block', 'marginLeft': '5px', width: '20%'}}>
            <button 
                type="button"
                // style={{'marginTop': '5px'}} 
                className="button button--small button--dark-grey"
                onClick={e => {
                  if (addEmail != "") {
                    if (validate_email(addEmail)) {
                      setEmails(produce(prev => {prev.push(addEmail)}))
                      setAddEmail("")
                      setInvalidEmail(false)
                    } else {
                      setInvalidEmail(true)
                    }
                    
                  }
                }}
              >
            
              Add email
            </button>
        </span>
      </div>

      {invalidEmail &&
          <div className="form__item">
              <div className="message message--notice margin-large">
                  <p class="margin-small">Please enter a valid email</p>
              </div>
          </div>
      }
      <Divider />

      <div>
        {emails.map((input, index) => {
            return <Chip
              label={input}
              onDelete={e => setEmails(produce(prev => { prev.splice(index, 1) } ))}
              style={{margin: '5px'}}
            />
          })
        }
      </div>
      {props.actions(emails.length === 0, handle_next)}
  </div>
}


const GuestAccessPassword = (props) => {
  const [password, setPassword] = useState("")

  useEffect(() => {
    if (props.reportConfig.template.password) {
      setPassword(props.reportConfig.template.password)
    } 
  }, [])

  const handle_next = () => {
    props.setReportConfig(produce(draft => {
        draft.template.password = password
    }))
  }

  return <div>
    <input
      className="form__input" 
      type="password"
      onChange={e => {
        setPassword(e.target.value)
      }} 
      value={password}
      
    />
    {props.actions(password == "", handle_next)}
  </div>
}

// const Review = (props) => {
//   return <div>
//       <p className="text-small">
//         Your report will be created on: {moment.unix(props.reportConfig.createdate).format('DD/MM/YYYY')}<br />
//         {props.reportConfig.type == 'recurring' 
//               ? <div>{props.reportConfig.frequency}<br/></div> 
//               : <div>Covering: {`${moment.unix(props.reportConfig.startdate).format('DD/MM/YYYY')} to ${moment.unix(props.reportConfig.endDate).format('DD/MM/YYYY')}`}<br /></div>
//         }
        
//         Reporting on campaign:<br /><br />

//         Notifying emails: {props.reportConfig.emails.toString()} <br />
//         Preset: Default <br />
//         Guest Access password: <br /> 
//       </p>
//       {props.actions(false)}
//     </div>
// }

  function getStepContent(props) {
    switch (props.activeStep) {
      case 0:
        return <div>
            <h1>Schedule a report</h1>
            <p className="text-small margin-small">
              The report scheduler allows you to prepare and recieve reports covering dates in the future. 
              To get started select the campaign and the time period your report is going to cover. 
            </p>
            <ScheduleReport {...props}/>
        </div>;
      // case 1:
      //   return <div>
      //       <h1>Notifications</h1>
      //       <p className="text-small margin-small">
      //         To get notified of when your report is prepared and ready, enter the email(s) below and click 'Add email'. 
      //         You can also skip this optional step.
      //       </p>
      //       <Email {...props}/>
      //   </div>;
      case 1:
          return <div>
              <p>Customise your report</p>
              <ReportSettings {...props}/>
          </div>
       case 2:
          return <div>
              <h1>Set a password for guest access</h1>
              <p className="text-small margin-small">Guests can download your report via a generated link, protected by the password set.</p>
              <GuestAccessPassword {...props}/>
          </div>;
        case 3: 
          return <div>
            <h1>All steps completed</h1>
            <p className="text-small margin-small">
                If you would like to make changes, click the 'back' button. 
                You can also make changes after you have sceduled a report, simply click on the 'Scheduled Reports' tab (in /report) and click the edit button.

                Click finish to create your scheduled report.
              </p>
            {props.actions(false)}
            {/* <Review {...props}/> */}
          </div>
      default:
        return 'Error, unknown step'
    }
  }

  function getStepContentEdit(props) {
    switch (props.activeStep) {
      case 0:
          return <div>
              <p>Customise your report</p>
              <ReportSettings {...props}/>
          </div>
       case 1:
          return <div>
              <h1>Set a new password for guest access</h1>
              <p className="text-small margin-small">Guests can download your report via a generated link, protected by the password set.</p>
              <GuestAccessPassword {...props}/>
          </div>;
        case 2: 
          return <div>
            <h1>All steps completed</h1>
            <p className="text-small margin-small">
                If you would like to make changes, click the 'back' button. 
                You can also make changes after you have sceduled a report, simply click on the 'Scheduled Reports' tab (in /report) and click the edit button.

                Click finish to save your changes.
              </p>
            {props.actions(false)}
            {/* <Review {...props}/> */}
          </div>
      default:
        return 'Error, unknown step'
    }
  }


const ScheduledReport = (props) => {

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [reportConfig, setReportConfig] = useState({
      
      template: {
        reportType: 'single', // multi_campaign, multi_period, single
        // campaigns: [],
        // title: "My Report",
        hasWastage: true,
        hasStarRating: true,
        executiveSummary: "",
        images: { banner: "", bannerFit: "cover", website: "", client: "" },
        notify: [],
        displayLinks: false,
        sections: {
            overview: {
                description: `This campaign has generated {{visits}} real human visits from {{clicks}} natural clicks; this is a ratio of approximately 1 visitor for every {{visits_average}} clicks. Beacon has determined that {{engaged_visitor_percent}}% of your visitors are engaged (meaning that they have spent some time looking at your site). On average, visitors viewed {{avg_page_views}} pages and stayed on the site for {{avg_time_on_site}} seconds. The last visit from a link in this campaign was on {{campaign_last_visit}}.  The average star rating for visits to this campaign is {{campaign_score}}.`,
                included: true,
                autoGenerated: true
            },
            spend: {
                description: `You have spent GBP {{campaign_spend}} in this campaign. Your average CPV (cost per visit) was GBP {{cpv}}.   You paid for {{clicks}} clicks, but received {{visits}} visits. {{bots_percent}}% of your paid clicks were not from real humans.  You wasted GBP {{wasted_spend}} on bots. `,
                included: true,
                autoGenerated: true
            },
            leads: {
                description: "",
                included: true,
                autoGenerated: true
            },
            channels: {
                description: "",
                included: true,
                autoGenerated: true
            }
        },
      }
  })
  const [createError, setCreateError] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const [ui, setUi] = useState({

  })

  
  useEffect(() => {
    if (props.edit) {
      console.log(props.edit)
      setReportConfig(produce(draft => {
        draft.template = JSON.parse(props.edit.template_json)
      }))
    }
    return () => {
    }
  }, [])

  const steps = props.edit ? getStepsEdit() : getSteps();

  const isStepOptional = (step) => {
    return step === -1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
        setCreateError(null)
        setSubmitting(true)
        if (props.edit) {
          const finalised = {
            ...props.edit,
            template_json: JSON.stringify(reportConfig.template),
            campaign_id: props.edit.campaign_id + "",
            website_id: props.edit.website_id + "",
          }
          delete finalised.running
          delete finalised.updated
          delete finalised.schedule_id
          delete finalised.added

          BeaconRequest.post(`reportscheduler/${props.edit.schedule_id}/template`, {}, finalised)
          .then(e => {
            props.addNotification({
              title: `Changes made successfully`,
              theme: "positive"
            })
            props.saved()
            props.close()
            
          })
          .catch(e => setCreateError(e))
          .finally(e => setSubmitting(false))
        } else {

          const tmp = {...reportConfig}
          const finalised = Object.assign(tmp, {
            template_json: JSON.stringify(tmp.template)
          })
          delete finalised.template

          BeaconRequest.post(`reportscheduler/${props.website.website_id}/create`, {}, finalised)
          .then(e => {
            props.addNotification({
              title: `Scheduled report`,
              theme: "positive"
            })
            props.saved()
            props.close()
          })
          .catch(e => setCreateError(e))
          .finally(e => setSubmitting(false))
        }
        

        
    } else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const actions = ( disableNext, nextCallback, skipCallback, backCallback,) => {
    return <div className={classes.actionsContainer} style={{marginTop: '10px', fontSize: '13px'}}>
      <button 
        disabled={activeStep === 0} 
        onClick={e => {if (backCallback) {backCallback()};handleBack()}} 
        className="button button--dark-grey"
      >
          Back
      </button>
      {isStepOptional(activeStep) && (
          <button
          variant="contained"
          color="primary"
          onClick={e => {if (skipCallback) {skipCallback()};handleSkip()}}
          className="button button--dark-grey"
          style={{marginLeft: '10px'}}
          >
          Skip
          </button>
      )}

      <button
          variant="contained"
          color="primary"
          onClick={e => { if (nextCallback) {nextCallback()}; handleNext()}}
          className="button button--green"
          style={{marginLeft: '10px'}}
          disabled={disableNext || submitting}
      >
          {activeStep === steps.length - 1 ? 
            'Finish'
            : 'Next'
          }
      </button>
  </div>
  }

  return (
    <ThemeProvider
        theme={theme}
    >
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
              <StepContent>
                    <Typography className={classes.instructions}>
                       {
                         props.edit 
                         ?
                          getStepContentEdit({
                            activeStep, 
                            reportConfig, 
                            setReportConfig, 
                            setUi, 
                            ui,
                            edit: props.edit,
                            website: props.website,
                            actions: actions
                          })
                         :
                          getStepContent({
                            activeStep, 
                            reportConfig, 
                            setReportConfig, 
                            setUi, 
                            ui,
                            website: props.website,
                            actions: actions
                          })
                       }
                       {submitting && <LoaderAlt /> }
                    </Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
      {createError && 
        <div className="message message--warning">
            <h2 className="message__title">Unable to schedule this report</h2>
            <p>If this error persists please create a ticket or contact us at support@thisisbeacon.com</p>
        </div>
      }
    </div>
    </ThemeProvider>
  );

}

const mapStateToProps = (state) => {
	return {
        website: state.websites.selectedWebsite,
	}
}

const mapDispatchToProps = dispatch => {
	return {
      addNotification: (payload) => dispatch(addNotification(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledReport)
