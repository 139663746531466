import BeaconRequest from '../BeaconRequest';

import {  
	FETCH_SINGLE_VISIT_REQUEST,
	FETCH_SINGLE_VISIT_SUCCESS,
	FETCH_VISITS,
	FETCH_VISITS_BY_LINK,
	FETCH_VISITS_BY_CAMPAIGN,
	FETCH_VISITS_BY_CHANNEL
} from './actionTypes';

export const fetchVisits = (sortBy, direction, filterStars, datetime) => {

	return (dispatch, getState) => {

		let query = `${getState().global.filter.type}/${getState().global.filter.payload.id}/visits`
		
		if(datetime) {
			query = query + '/' + datetime
		}

		let options = { order: "score:desc" }
		if(sortBy && direction){
			options = { ...options, order: sortBy + direction }
		}

		if(filterStars){
			options =  { ...options, filterBy: 'star_rating:' + filterStars }
		}

		return BeaconRequest.get(query, options)
		.then(responseObj => {
			dispatch({
				type: FETCH_VISITS,
				responseObj
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchSingleVisit = visit_id => {
	return (dispatch) => {
		dispatch({
			type: FETCH_SINGLE_VISIT_REQUEST,
		})
		return BeaconRequest.get('visit/' + visit_id)
		.then(responseObj => {
			dispatch({
				type: FETCH_SINGLE_VISIT_SUCCESS,
				responseObj
			})
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchVisitsByPaging = (link) => {
	return (dispatch) => {
		return BeaconRequest.paging(link)
		.then(responseObj => {
			dispatch({
				type: FETCH_VISITS,
				responseObj
			})
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchVisitsByLink = (linkId, startDate, endDate) => {

	return (dispatch, getState) => {
		console.log(startDate, endDate)
		let query = 'link/' + linkId + `/visits/${startDate}:${endDate}`	
		

		// return BeaconRequest.get(query, {order: "score:desc"})
		return BeaconRequest.get(query, { limit: '100' })
		.then(responseObj => {
			dispatch({
				type: FETCH_VISITS_BY_LINK,
				responseObj
			})
		})
		.catch(error => { console.log(error) })
	}
}
