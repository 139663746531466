import React, {useState, useEffect} from 'react'
import produce, {setAutoFreeze} from 'immer'
import { connect } from 'react-redux'

import { addNotification } from '../../actions/ui'
// import { setSelectedWebsite } from '../../actions/websites'

import ToolTip from '../../components/general/ToolTip'
import Toggle from '../../components/general/Toggle'
import MediaUpload from '../../components/general/MediaUpload'
import BeaconRequest from '../../BeaconRequest'

import Modal from  '../../components/general/Modal'

import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Collapse from '@material-ui/core/Collapse' 

export const HelpDialog = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState('bottom');

    const hand_open_help = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => !prev);
        // setPlacement(newPlacement);
    };

    const variables = [
        {
            name: 'Impressions',
            syntax: '{{impressions}}',
        },
        {
            name: 'Clicks',
            syntax: '{{clicks}}'
        },
        {
            name: 'Bots percentage',
            syntax: '{{bots_percent}}'
        },
        {
            name: 'Bots',
            syntax: '{{bots}}'
        },
        {
            name: 'Bounce rate',
            syntax: '{{bounce_rate}}'
        },
        {
            name: 'Visits',
            syntax: '{{visits}}'
        },
        {
            name: 'Average time on site',
            syntax: '{{avg_time_on_site}}'
        },
        {
            name: 'Engaged visitors',
            syntax: '{{engaged_visitors}}'
        },
        {
            name: 'Average page views',
            syntax: '{{avg_page_views}}'
        },
        {
            name: 'Leads',
            syntax: '{{leads}}'
        },
        {
            name: 'Cost per click',
            syntax: '{{cpc}}'
        },
        {
            name: 'Wasted spend',
            syntax: '{{wasted_spend}}'
        },
        {
            name: 'Cost per visit',
            syntax: '{{cpv}}'
        },
        {
            name: 'Cost per engaged visit',
            syntax: '{{cpev}}'
        },
        {
            name: 'Cost per lead',
            syntax: '{{cpl}}'
        },
        {
            name: 'Visits average',
            syntax: '{{visits_average}}'
        },
        {
            name: 'Engaged visitor percentage',
            syntax: '{{engaged_visitor_percent}}'
        }
    ]


    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div >
            <button className="button text-small" style={{...props.style}} onClick={hand_open_help}>Syntax Help</button>
            <Popper 
                open={open} 
                anchorEl={anchorEl} 
                placement={props.placement} 
                transition
                modifiers={{
                    flip: {
                    enabled: false,
                    },
                    preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window',
                    },
                    arrow: {
                    enabled: false,
                    //   element: arrowRef,
                    },
                }}
                style={{zIndex: 1500}}
                >
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={150}>
                    <Paper>
                        <div style={{maxWidth: '300px', padding: '10px'}}>
                            <div className="maring-medium" style={{textAlign: 'center', }}>
                                Syntax Help
                                <i className="fas fa-times-circle text-small" style={{float: 'right', cursor: 'pointer'}} onClick={e => setOpen(false)} ></i>
                            </div>
                            <Divider/>
                            <label className="margin-medium text-small" style={{marginTop: '10px'}}>
                                Beacon uses 'Variables' for reporting, which gets replaced by the actual value on report creation. See <a href="https://www.thisisbeacon.com/guides/report-variables/" target="_blank">term definitions</a>
                            </label>
                            
                            <Divider/>
                            {variables.map(vari => {
                                return <div className="maring-medium" style={{textAlign: 'left', }}>
                                    <div className="margin-small text-small" style={{marginTop: '10px'}}>
                                        {vari.name}: 
                                        <span className="margin-medium text-small" style={{padding: '5px'}}><code>{vari.syntax}</code></span>
                                    </div>
                                    {vari.description && <div className="margin-small text-small" style={{}}>{vari.description}</div>}
                                    <Divider/>
                                </div>
                            })

                            }
                        </div>
                    </Paper>
                </Fade>
                )}
            </Popper>
        </div>
    </ClickAwayListener>
}

export const Expandable = (props) => {
    const [checked, setChecked] = useState(false)

    return <div style={{margin: '10px'}}> 
        <Collapse in={checked} collapsedHeight={45} className="card" >
            <div onClick={e => setChecked(!checked)} className="text-medium " style={{margin: '10px', cursor: 'pointer'}}>
                {props.title} 
                <i className={`fas fa-chevron-${checked ? 'up' : 'down'}`} style={{float: 'right',}}></i>
            </div>
            {props.content}
        </Collapse>
    </div>
}

//unsaved changes check, or we could check a flag when changing state?
function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
        areObjects && !deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
        ) {
        return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}



const overview_desc = `This campaign has generated {{visits}} real human visits from {{clicks}} natural clicks; this is a ratio of approximately 1 visitor for every {{visits_average}} clicks. Beacon has determined that {{engaged_visitor_percent}}% of your visitors are engaged (meaning that they have spent some time looking at your site). On average, visitors viewed {{avg_page_views}} pages and stayed on the site for {{avg_time_on_site}} seconds. The last visit from a link in this campaign was on {{campaign_last_visit}}.  The average star rating for visits to this campaign is {{campaign_score}}. `
const spend_desc = `You have spent GBP {{campaign_spend}} in this campaign. Your average CPV (cost per visit) was GBP {{cpv}}.   You paid for {{clicks}} clicks, but received {{visits}} visits. {{bots_percent}}% of your paid clicks were not from real humans.  You wasted GBP {{wasted_spend}} on bots. `
const no_star_rating = `This campaign has generated {{visits}} real human visits from {{clicks}} natural clicks; this is a ratio of approximately 1 visitor for every {{visits_average}} clicks. Beacon has determined that {{engaged_visitor_percent}}% of your visitors are engaged (meaning that they have spent some time looking at your site). On average, visitors viewed {{avg_page_views}} pages and stayed on the site for {{avg_time_on_site}} seconds. The last visit from a link in this campaign was on {{campaign_last_visit}}.`
const no_wastage = `You have spent GBP {{campaign_spend}} in this campaign. Your average CPV (cost per visit) was GBP {{cpc}}.   You paid for {{clicks}} clicks, but received {{visits}} visits. {{bots_percent }}% of your paid clicks were not from real humans.`

const Section = (props) => {
    // const expandableTextArea = useRef(null)
    const [editConfig, setEditConfig] = useState(false)
    const {section, setPresets, presets, selectedConfig} = props

    const selected_config = presets[selectedConfig].config
    const selected_name =  presets[selectedConfig].name

    
    const capitalStr = section.replace(/^\w/, c => c.toUpperCase());
    return <div>
        
        <div style={{margin: '10px'}}>
            {/* <div>
                <label className="form__label">
                    {capitalStr} section
                </label>
            </div> */}
            <div className="margin-small">
                <Toggle 
                    checked={selected_config.template.sections[section].included}
                    text="Include this section in the report" 
                    handleToggle={e => {
                        const val = e.target.checked; 
                        setPresets(produce(draft => {draft[selectedConfig].config.template.sections[section].included = val}))
                    }}
                />
            </div>
            {selected_config.template.sections[section].included && 
                <div>
                    <TextareaAutosize
                        rowsMin={1}
                        rowsMax={2}
                        className="form__input margin-small" style={{resize: 'vertical', minHeight: '100px', maxHeight: '300px'}}
                        value={selected_config.template.sections[section].description}
                        onChange={e => {
                            const val = e.target.value; 
                            setPresets(produce(draft => {draft[selectedConfig].config.template.sections[section].description = val}))
                        }}
                    />
                    <div style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                        <div>
                            {/* <span onClick={e => {setEditConfig(!editConfig)}} style={{textDecoration: 'underline', cursor: 'pointer', }}>{editConfig ? "Collapse" : "Edit description"}</span> */}

                        </div>
                        <div style={{textAlign: 'right'}}>
                            <HelpDialog placement='right'/>
                        </div>
                    </div>
                    
                </div>
            }
            
        </div>
    </div>

}


const ReportingPresets = (props) => {
    
    const config = {
        
        template: {
          reportType: null, // multi_campaign, multi_period, single
        //   campaigns: [],
        //   title: "My Report",
          hasWastage: true,
          hasStarRating: true,
          executiveSummary: "",
          images: { banner: "", bannerFit: "cover", website: "", client: "" }, //banner aws s3 path
          notify: [],
          displayLinks: false,
          sections: {
              overview: {
                  description: overview_desc,
                  included: true,
              },
              spend: {
                  description: spend_desc,
                  included: true,
              },
              leads: {
                  description: "",
                  included: true,
              },
              channels: {
                  description: "",
                  included: true,
              }
          },
          password: ""
        //   password: Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2) // https://stackoverflow.com/a/29770068/874691
        }
    }
    const [presets, setPresets] = useState([
        {name: 'Default', config}
    ])
    const [selectedConfig, setSelectedConfig] = useState(0)

    // const [changes, setChanges] = useState([])

    useEffect(() => {
        get_presets()
        return () => {
        }
       
    }, [])


    const get_presets = async () => {
        await BeaconRequest.getNoChache(`reporttemplate/all/${props.website.website_id}`)
        .then(e => {
            if (e.response.length > 0) {
                setPresets(produce(draft => {
                    let all = e.response.map(i => {
                        const template = i.values
                        const conf = JSON.parse(template.content)[0]
                        
                        return {
                            template_id: template.template_id, 
                            name: conf.name,
                            config: conf.config
                        }
                    })

                    if (all.filter(t => t.name == "Default").length == 0) {
                        all.unshift({name: 'Default', config})
                    } else { //move to top
                        const index = all.map(e => e.name).indexOf('Default')
                        const element = all[index];
                        all.splice(index, 1);
                        all.splice(0, 0, element)
                    }

                    return all
                }))
                
            }
            
        })
        .catch(e => {
            console.log(e)
        })
    }

    const get_preset = (template_id, selectedConfig) => { //single
        BeaconRequest.getNoChache(`reporttemplate/${template_id}`)
            .then(e => {
                console.log(e)
                const template = e.response.values
                const conf = JSON.parse(template.content)[0]
                
                setPresets(produce(draft => {
                    draft[selectedConfig] = {
                        template_id: template.template_id, 
                        name: conf.name,
                        config: conf.config
                    }
                }))
            })
            .catch(e => {
                console.log(e)
            })
    }

    const save_all = (override_preset) => {
        BeaconRequest.post(
            'website/' + props.website.website_id + '/meta', {}, 
            [
                { name: "presets", value: JSON.stringify(override_preset || presets)},
            ],
        )
        .then(e => {
            BeaconRequest.get('website/' + props.website.website_id)
                .then(responseObj => {
                    props.setSelectedWebsite(responseObj)

                    const payload = {
                        title: `Updated`,
                        theme: "positive"
                    }
                    props.addNotification(payload)
                })
            .catch(error => { console.log(error) })
            
        })
        .catch(error => {
            throw error; //handle error
        })
    }

    const save_meta =  (conf, selectedConfig) => {
        if (conf.name == "") {
            return
        }
        
        if (conf.name == "Default" && presets.filter(t => t.name == "Default")[0].template_id == null) {
            // let copy = Object.assign({}, config);

            BeaconRequest.post(`reporttemplate/${props.website.website_id}/new`, {}, {
                website_id: "" + props.website.website_id,
                content: JSON.stringify([{name: "Default", config: conf.config}])
            })
            .then(e => {
                props.addNotification({
                    title: `Preset ${"Default"} Saved`,
                    theme: "positive"
                })
    
                get_presets()
                setSelectedConfig(0)
            })
            .catch(e => {
                props.addNotification({
                    title: `Unable to save preset ${"Default"}`,
                    theme: "negative"
                })
    
            })
            
        } else {
            BeaconRequest.post(`reporttemplate/${conf.template_id}`, {}, {
                website_id: "" + props.website.website_id,
                content: JSON.stringify([{name: conf.name, config: conf.config}])
            })
            .then(e => {
                props.addNotification({
                    title: `Preset ${conf.name} Saved`,
                    theme: "positive"
                })
    
                get_preset(conf.template_id, selectedConfig)
            })
            .catch(e => {
                props.addNotification({
                    title: `Unable to save preset ${conf.name}`,
                    theme: "negative"
                })
    
            })
        }

        
    }

    // const restore_to_default = (selectedConfig) => {
    //     const new_obj = {name: presets[selectedConfig].name, config}
    //     save_meta(new_obj, selectedConfig)

    //     setPresets(produce(draft => {
    //         draft[selectedConfig].config = config
    //     }))
    // }

    const name_increment = (name, count) => {
        if (presets.filter(preset => (count > 0 ? `${name} (${count})` : `${name}`) === preset.name).length > 0) {
            return name_increment(name, count + 1)
        } else {
            return  (count > 0 ? `${name} (${count})` : `${name}`)
        }
    }

    const delete_template = (index) => {
        if (window.confirm("Are you sure you want to delete this preset?")) {
            const delete_name = presets[index].name
            BeaconRequest.delete(`reporttemplate/${presets[index].template_id}`)
            .then(e => {
                props.addNotification({
                    title: `Preset ${delete_name} deleted`,
                    theme: "positive"
                })

                get_presets()
                setSelectedConfig(index - 1)
            })
            .catch(e => {
                props.addNotification({
                    title: `Unable to delete preset ${delete_name}`,
                    theme: "positive"
                })
            })
        }
    }

    const create_new_template = () => {
        let copy = Object.assign({}, config);

        const name = name_increment("untitled", 0)

        BeaconRequest.post(`reporttemplate/${props.website.website_id}/new`, {}, {
            website_id: "" + props.website.website_id,
            content: JSON.stringify([{name: name, config: copy}])
        })
        .then(async e => {
            await get_presets()
            setSelectedConfig(presets.length)
        })
        .catch(console.log)
    }

    const edit_preset_name = (index, name) => {
        setPresets(produce(draft => {
            draft[index].name = name
        }))
        setSelectedConfig(index)
    }

    

    const selected_config = presets[selectedConfig].config
    const selected_name =  presets[selectedConfig].name
    
    return <div>
                <h1 className="text-large margin-small">Reporting - Preset Manager</h1>
                <h3 className="margin-large">
                    Configure "presets" that can be used to customise reports easily.
                </h3>
                
                <div style={{display: 'grid', gridTemplateColumns: 'max-content auto', gridGap: '20px'}}>
                <div>
                    <ul className="dropdown__menu" style={{position: 'relative', backgroundColor: '#5E6172', marginTop: 0, minHeight: '250px'}}>
                        {presets.map((preset,index) => {
                            return <li className={`dropdown__item`} style={index === selectedConfig ?  {backgroundColor: '#474956'} : {}} onClick={e => setSelectedConfig(index)}>
                                {preset.name}
                            </li>
                        })}
                        <li className="dropdown__item dropdown__item--add" style={{color: '#FFD200'}} onClick={create_new_template}>
                            <i class="fas fa-plus-circle"></i>Create new preset
                        </li>
                    </ul>
                    {/* <div className="margin-small" style={{textAlign: 'right'}}>
                        <button className="button  button--green" onClick={e => save_all()}>Save all presets</button>
                    </div> */}
                </div>

                <div className="card" style={{padding: '10px'}}> 
                    <div style={{textAlign: 'right'}}>
                        {/* <span style={{fontStyle: 'italic', marginRight: '5px'}}>(unsaved changes)</span> */}

                        {selected_name != "Default" &&
                            <button className="button  button--red" 
                                style={{marginRight: '5px'}} 
                                onClick={e => delete_template(selectedConfig)}>Delete</button>
                        }
                        
                        <button className="button  button--green" onClick={e => save_meta(presets[selectedConfig], selectedConfig)}>Save</button>
                    </div>
                    <div className="margin-large"  style={{margin: '10px', marginBottom: '20px'}}>
                        <label className="form__label">
                        Preset Name: 
                        </label>
                        <div>
                        {selected_name !== "Default" ? 
                            <input className="form__input" value={selected_name} onChange={e => edit_preset_name(selectedConfig, e.target.value)}/> : 
                            <label className="form__label text-medium">Default</label>
                        }
                        </div>
                       
                    </div>


                    <div style={{margin: '10px'}}>
                        <div >
                            <Toggle
                                checked={selected_config.template.hasWastage}
                                text="Include wasted spend in report" 
                                handleToggle={e => {
                                    const val = e.target.checked; //define here, so that synthetic event doesnt get lost on editing draft
                                    if (window.confirm("Toggling 'Include wasted spend in report' will replace the spend description. are you sure?")) {
                                        setPresets(produce(draft => {
                                            draft[selectedConfig].config.template.hasWastage = val
                                            draft[selectedConfig].config.template.sections.spend.description = val ? spend_desc : no_wastage
                                        }))
                                    }
                                    
                                }}
                            />
                        </div>
                        <div>
                            <Toggle 
                                checked={selected_config.template.hasStarRating}
                                text="Include star ratings in report" 
                                handleToggle={e => {
                                    const val = e.target.checked; 
                                    if (window.confirm("Toggling 'Include star ratings in report' will replace the overview description. are you sure?")) {
                                        setPresets(produce(draft => {
                                            draft[selectedConfig].config.template.hasStarRating = val
                                            draft[selectedConfig].config.template.sections.overview.description = val ? overview_desc : no_star_rating
                                        }))
                                    }
                                    
                                }}
                            />
                        </div>
                        <div>
                            <Toggle 
                                checked={selected_config.template.displayLinks}
                                text="Include active links in report" 
                                handleToggle={e => {
                                    const val = e.target.checked; 
                                    setPresets(produce(draft => {
                                        draft[selectedConfig].config.template.displayLinks = val
                                    }))
                                }}
                            />
                        </div>
                    </div>
                    <div className="form__item margin-large" style={{width: '400px', margin: '10px'}}>
                        <label className="form__label">
                            Custom banner (minimum size <ToolTip text="400px x 200px" tip="and an aspect ratio of 2:1" hint={true} />)
                        </label>
                        { selected_config.template.images.banner !== ""? (<>
                            <span className="report__custom-banner" style={{width: '100%'}}>
                                
                                <img 
                                    src={selected_config.template.images.banner} 
                                    alt="Report Banner" 
                                    style={{
                                        objectFit: selected_config.template.images.bannerFit || "cover", 
                                        maxHeight: 'none', 
                                        width: '100%',
                                        height: '200px'
                                    }}
                                />
                                <button 
                                    className="report__custom-banner-remove button button--small button--red" 
                                    onClick={e => setPresets(produce(draft => {draft[selectedConfig].config.template.images.banner = ""}))}
                                >
                                    <i className="fas fa-times"></i>
                                </button>
                            </span>

                            <div style={{ 'marginBottom': '10px'}}>
                                <li style={{display: 'inline-block'}}>
                                    <div style={{marginLeft: '10px'}}>
                                        <h2 style={{display: 'inline-block', marginRight: '5px'}}>Cover</h2>
                                        <label className="radio-container">
                                            <input type="radio" name="radio" checked={selected_config.template.images.bannerFit == "cover"} onChange={(e) => {
                                                setPresets(produce(draft => {draft[selectedConfig].config.template.images.bannerFit = "cover"}))
                                            }}/>
                                            <span className="checkmark"></span>
                                        </label>
                                        <h2 style={{display: 'inline-block', marginRight: '5px'}}>Contain</h2>
                                        <label className="radio-container">
                                            <input type="radio" name="radio" checked={selected_config.template.images.bannerFit == "contain"} onChange={(e) => {
                                                setPresets(produce(draft => {draft[selectedConfig].config.template.images.bannerFit = "contain"}))
                                            }}/>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </li>
                            </div>
                        </>) : (<>
                            
                            <img src={"https://s3.eu-west-1.amazonaws.com/beacon.assets/summary_banner.png"} alt="Report Banner"  width="100%" height="100%"/>
                            
                            <MediaUpload aspectRatio={true} aspectRatioWidth={600} aspectRatioHeight={300} minWidth={360} 
                                handleUploadMedia={e => {
                                    setPresets(produce(draft => {draft[selectedConfig].config.template.images.banner = e}))
                                }
                            } />
                        </>) }
                    </div>
                    <div  style={{margin: '10px'}}>
                        <div  style={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
                            <label className="form__label">
                            Executive Summary
                            </label>
                            <div style={{textAlign: 'right'}}>
                                <HelpDialog placement='right'/>
                            </div>
                        </div>
                        <div>
                            <textarea className="form__input" 
                                value={selected_config.template.executiveSummary}
                                onChange={e => {
                                    const val = e.target.value; 
                                    setPresets(produce(draft => {draft[selectedConfig].config.template.executiveSummary = val}))
                                }}

                            />
                        </div>
                    </div>

                    <Expandable title="Overview" style={{margin:'10px'}} content={
                        <Section section="overview" {...{presets, setPresets, selectedConfig}}/>
                    }/>
                    <Expandable title="Spend" style={{margin:'10px'}} content={
                        <Section section="spend" {...{presets, setPresets, selectedConfig}}/>
                    }/>
                    <Expandable title="Channels" style={{margin:'10px'}} content={
                        <Section section="channels" {...{presets, setPresets, selectedConfig}}/>
                    }/>
                    <Expandable title="Leads" style={{margin:'10px'}} content={
                        <Section section="leads" {...{presets, setPresets, selectedConfig}}/>
                    }/>
                    
                    <div className="form__two-column">
                        {/* <div>
                            {(props.website.__meta.presets && JSON.parse(props.website.__meta.presets)[selectedConfig]) && 
                                <span onClick={e => restore_to_default(selectedConfig)} style={{textDecoration: 'underline', color: 'red', cursor: 'pointer'}}>Restore default settings</span>
                            }
                        </div> */}
                        <div></div>
                        <div style={{textAlign: 'right'}}>
                            {selected_name != "Default" &&
                                <button className="button  button--red" 
                                    style={{marginRight: '5px'}} 
                                    onClick={e => delete_template(selectedConfig)}>Delete</button>
                            }
                        
                            <button className="button  button--green" onClick={e => save_meta(presets[selectedConfig], selectedConfig)}>Save</button>
                        </div>
                    </div>
                    
                </div>
            </div>
    </div>

}

const mapStateToProps = (state) => {
	return {
        website: state.websites.selectedWebsite,
	}
}

const mapDispatchToProps = dispatch => {
	return {
        // setSelectedWebsite: (website) => dispatch(setSelectedWebsite(website)),
        addNotification: (payload) => dispatch(addNotification(payload)),
        setSelectedWebsite: (responseObj) => dispatch({
            type: 'SET_SELECTED_WEBSITE_SUCCESS',
            responseObj // send the complete response to the reducer
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportingPresets)