// Import action types
import { 
	FETCH_ACTIVE_CAMPAIGNS_REQUEST,
	FETCH_ACTIVE_CAMPAIGNS_SUCCESS,
	FETCH_ALL_CAMPAIGNS_REQUEST,
	FETCH_ALL_CAMPAIGNS_SUCCESS,
	FETCH_SINGLE_CAMPAIGN_REQUEST,
	FETCH_SINGLE_CAMPAIGN_SUCCESS,
	SET_SELECTED_CAMPAIGN,
	UNSET_SELECTED_CAMPAIGN,
	SET_SELECTED_CAMPAIGN_NAME,
	CLEAR_ACTIVE_CAMPAIGNS
} from '../actions/actionTypes';

export default function (state = {}, action) {
	switch(action.type){
		case FETCH_ACTIVE_CAMPAIGNS_REQUEST:
			return {
				...state,
				isFetchingActiveCampaigns: true
			}
		case FETCH_ACTIVE_CAMPAIGNS_SUCCESS:
			return {
				...state,
				activeCampaigns: action.activeCampaigns,
				isFetchingActiveCampaigns: false
			}
		case CLEAR_ACTIVE_CAMPAIGNS:
			return {
				...state,
				activeCampaigns: [],
			}
		case FETCH_ALL_CAMPAIGNS_REQUEST:
			return {
				...state,
				isFetchingAllCampaigns: true
			}
		case FETCH_ALL_CAMPAIGNS_SUCCESS:
			return {
				...state,
				allCampaigns: action.allCampaigns,
				isFetchingAllCampaigns: false
			}
		case FETCH_SINGLE_CAMPAIGN_REQUEST:
			return {
				...state,
				isFetchingSingleCampaign: true
			}
		case FETCH_SINGLE_CAMPAIGN_SUCCESS:
			return {
				...state,
				isFetchingSingleCampaign: false,
				singleCampaign: action.responseObj.response
			}
		case SET_SELECTED_CAMPAIGN:
			return {
				...state,
				selectedCampaignId: action.payload.campaign_id,
				selectedCampaignName: action.payload.title
			}
		case SET_SELECTED_CAMPAIGN_NAME:
			return {
				...state,
				selectedCampaignName: action.payload.title
			}
		case UNSET_SELECTED_CAMPAIGN:
			return {
				...state,
				selectedCampaignId: null,
				selectedCampaignName: undefined
			}
		default:
			return state;
	}
}