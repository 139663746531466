  
import { actionTypes } from "./constants";
import {createResourceFromQueries} from '../utils'
import axios from "axios";

export const createVisitorMetricsResource = ({filter, timePeriod, paging, disableCache, cancelToken}) => {
  
    return {
        metrics: [`${filter.type}/${filter.payload.id}/visitor-metrics/${timePeriod}`, null, {cancelToken: cancelToken.token}],
    }
}

export const setVisitorMetricsResource = (args) => {
  return (dispatch, getState) => {
        dispatch({
            type: actionTypes.IS_VISITOR_METRICS_FETCHING,
            fetching: true
        })

        const previousToken = getState().visitorMetrics.previousRequestSource
        if (previousToken && previousToken.cancel) {
            previousToken.cancel("media spend request cancelled");
        } 

        const source = axios.CancelToken.source();
        dispatch({
            type: actionTypes.SET_VISITOR_METRICS_CANCEL_TOKEN,
            previousRequestSource: source
        })

        createResourceFromQueries(createVisitorMetricsResource({...args, cancelToken: source}), args.disableCache)
            .then(payload => {
                dispatch({
                    type: actionTypes.SET_VISITOR_METRICS_DATA,
                    payload: payload
                })

                dispatch({
                    type: actionTypes.IS_VISITOR_METRICS_FETCHING,
                    fetching: false
                })
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                } else {
                    dispatch({
                        type: actionTypes.IS_VISITOR_METRICS_FETCHING,
                        fetching: false
                    })
                }
            })
  }
}