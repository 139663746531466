import React, { Component } from 'react'
import FontAwesomePicker from '../general/FontAwesomePicker'
import ColourPicker from '../general/ColourPicker';
import * as colours from '../../colours';
import BeaconRequest from '../../BeaconRequest'
import LoaderAlt from '../general/LoaderAlt'
import {beaconColours} from '../../colours'
import produce from 'immer'

class CreateChannel extends Component {
    state = {
        title: "",
        icon: "",
        colour: colours.beaconColours[Math.floor(Math.random()*colours.beaconColours.length)], // generate a random colour from the palette

        error: null,
        usedColours: null,
        usedError: false,

    }

    componentDidMount() {
        this.setState({usedError: false})
		BeaconRequest.get(`campaign/${this.props.campaign}/channels/coloursInUse`)
			.then(res => this.setState({ 
                usedColours: res.response,
                colour: beaconColours.filter(color => !res.response.map(colour => colour.toUpperCase()).includes(color.toUpperCase()))[0]
            }))
			.catch(err => this.setState({usedError: true}))
    }

    handleChannelNameChange(e){
        this.setState({
            title: e.target.value
        })

        if(e.target.value) { 
            this.props.isAdding(true)
        } else {
            this.props.isAdding(false)
        }
    }

    addChannel(){
        this.setState({error: null})
        if(!this.state.title){
            this.setState({error: 'Please enter a name for your channel'})
            return
        }

        if (this.props.channels.filter(channel => channel.title === this.state.title).length > 0) {
            this.setState({error: 'You already have a channel added with the same title'})
            return
        }

        this.props.isAdding(false)
        this.props.handleAddChannel({
            title: this.state.title,
            icon: this.state.icon,
            colour: this.state.colour,
        })
        
        this.setState(produce(state => {
            state.title = ""
            state.icon = ""

            const newUsed = state.usedColours.concat([state.colour])
            state.usedColours = newUsed
            state.color =  beaconColours.filter(color => !newUsed.map(colour => colour.toUpperCase()).includes(color.toUpperCase()))[0]
        }))
        
    }

    handleGetSelectedIcon(icon){
        this.setState({
            icon: icon
        })
    }

    handleGetSelectedColour(colour){
		this.setState({ colour: colour })
	}

    render(){
        return (
            <React.Fragment>
                <h2 className="margin-small"><strong>Create a custom channel</strong></h2>

                <div className="margin-large">
                    <div 
                    className="margin-small"
                    style={{
                        display:"grid", 
                        gridTemplateColumns:"auto max-content",
                        gridGap:8,
                        alignItems:"end"
                        
                    }}
                    >
                        <div style={{position:"relative"}}>
                            <label 
                            htmlFor="channel_name"
                            className="form__label">
                            Channel name
                            </label>
                            <input 
                            autoFocus
                            value={this.state.title}
                            onChange={this.handleChannelNameChange.bind(this)}
                            id="channel_name"
                            className="form__input"
                            type="text"
                            style={{paddingLeft:58, borderColor: this.state.error ? 'red' : '#E6EBED' }}
                            />

                            <div 
                            style={{
                                position:"absolute",
                                right:0,
                                bottom:0,
                            }}>
                                <FontAwesomePicker 
                                    disabled={this.props.channels.map(c => c.icon)} 
                                    getSelectedIcon={this.handleGetSelectedIcon.bind(this)} 
                                    selectedIcon={this.state.icon}
                                    controlled
                                />
                            </div>

                            <div 
                            style={{position:'absolute', 
                            left:4, 
                            bottom:"-1px" }}
                            >
                                {(this.state.usedColours == null || this.state.usedError) ? 
                                    <div style={{marginLeft: '15px'}}><LoaderAlt noHints /></div>
                                    :
                                    <ColourPicker 
                                        usedColours={this.state.usedColours} 
                                        defaultColour={this.state.colour} 
                                        selectedColour={this.handleGetSelectedColour.bind(this)} 
                                    />
                                }
                            </div>
                        </div>

                        <div>
                            <button
                            className="button button--dark-grey"
                            onClick={this.addChannel.bind(this)}
                            >
                            Add
                            </button>
                        </div>
                    </div>

                    {this.state.error && 
                        <p className="message message--warning">{this.state.error}</p>
                    }
                </div>
            </React.Fragment>
        )
    }
}

export default CreateChannel;