import React, {useState} from 'react';
import moment from 'moment-timezone';
import { PieChart, Pie, Tooltip, Label } from 'recharts';
import * as colours from '../../colours'
import ToolTip from './ToolTip'
import StarRating from './StarRating'
var _ = require('lodash')

const InnerPieLabel = (props) => {
    return (
      <g>
        <text x={props.viewBox.cx} y={props.viewBox.cy} textAnchor="middle" dominantBaseline="middle" fontSize="26px" font="Lato" fill="#5E6172">
          {props.total}
        </text>
        <text x={props.viewBox.cx} y={props.viewBox.cy + 25} textAnchor="middle" dominantBaseline="middle" fontSize="13px" fill='#5E6172'>
          Bounce Rate
        </text>
      </g>
    );
  };

  const InnerPieLabelViews = (props) => {
    return (<>
      <g className="primary">
        <text x={props.viewBox.cx} y={props.viewBox.cy} textAnchor="middle" dominantBaseline="middle" fontSize="26px" font="Lato" fill="#5E6172">
          {props.primary}
        </text>
        <text x={props.viewBox.cx} y={props.viewBox.cy + 25} textAnchor="middle" dominantBaseline="middle" fontSize="13px" fill='#5E6172'>
			Visits
        </text>
      </g>
	  <g className="secondary">
        <text x={props.viewBox.cx} y={props.viewBox.cy} textAnchor="middle" dominantBaseline="middle" fontSize="26px" font="Lato" fill="#5E6172">
          {props.secondary}
        </text>
        <text x={props.viewBox.cx} y={props.viewBox.cy + 25} textAnchor="middle" dominantBaseline="middle" fontSize="13px" fill='#5E6172'>
			Page Views
        </text>
      </g>
    </>);
  };


  const InnerPieLabelPage = (props) => {
	return (
		<g>
		<text x={props.viewBox.cx} y={props.viewBox.cy} textAnchor="middle" dominantBaseline="middle" fontSize="30px" font="Lato" fill="#5E6172">
			{props.total}
		</text>
		{/* <text x={props.viewBox.cx} y={props.viewBox.cy + 25} textAnchor="middle" dominantBaseline="middle" fontSize="13px" fill='#989898'>
			Total link clicks
		</text> */}
		</g>
	);
};


const LabelledPieChart = (props) => {
	return <PieChart width={180} height={180} style={{margin: 'auto'}}>
		<Pie 
		isAnimationActive={false}
		dataKey="value" 
		data={props.data} 
		allowDecimals={true}
		innerRadius='60%'
		stroke="none"
		too
		>
			<Label content={<InnerPieLabelPage total={props.total || 0}/>}/>
		</Pie>
		{/* <Tooltip/> */}
	</PieChart>
}

const SingleBar = (props) => {
	return <div className="star-bar__item">
		<span className="star-bar__title">
			{props.title} <ToolTip text={<i class="fas fa-question-circle"/>} tip={props.tip} />
		</span>
		<span className="star-bar__bar" style={{overflow: 'hidden'}}>
			<span className="star-bar__fill" style={{width: (props.percentage)+"%", backgroundColor: props.colour}}>
				<span className="star-bar__percent">{props.percentage}%</span>
			</span>
		</span>
	</div>
}


const Stats = props => {
	const [showGoogleStats, setShowGoogleStats] = useState(false)
	if (!props.data.visits.has_data) return null
	
	const {
		averagePagesPerVisit,
		average_dwell_time,
		newVisitorsToSite,
		repeatVisitorsToSite,
		visits,
		average_page_views,
		engaged_visitors,
		identified_visitors,
		new_to_site,
		repeat_to_site
	} = props.data.visits

	const {
		hard_bounce_rate,
		multi_page_rate,
		soft_bounce_rate,
		total_bounce_rate
	} = props.data.visits.bounce_rates

	let visitsData = [
		{ name:"New visitors", value: new_to_site, fill: colours.pink },
		{ name:"Repeat visitors", value: repeat_to_site, fill: colours.blue_6 }
	]

	let bounceData = [
		{ name:"Hard %", value: parseFloat(hard_bounce_rate), fill: colours.red },
		{ name:"Soft %", value: parseFloat(soft_bounce_rate), fill: colours.yellow },
		{ name:"Multi-page %", value: parseFloat(multi_page_rate), fill: colours.green }
	]

	const getColour = (percentage) => {
		if (percentage >= .9) return '#25CCCF'
		else if (percentage >= .5 && percentage < .9) return '#E28413'
		else return '#FF3190'
	}
	
	if(!_.isEmpty(props.data)){
		return (<><div style={{
				display: 'grid',
				gridTemplateColumns: 'max-content auto',
				gridColumnGap: '10px',
				marginBottom: '15px',
				
			}}>
				<div>
					{props.screenshot == null ? 
						<div style={{
							display:'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '285px',
							width: '290px',
							gridTemplateColumns: 'auto auto', 
							boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', 
							border: '1px solid #E6EBED'
						}}>
							<div style={{textAlign: 'center'}}>
								<div className="text-large"><i class="fas fa-camera"></i></div>
								<div className="">No screenshot available</div>
							</div>
						</div>
					:
						<img 
							style={{
								height: '285px',
								objectFit: 'cover',
								border: '1px solid #ddd',
								padding: '5px',
							}} 
							src={props.screenshot} 
							alt="Screenshot"
						/>
					}
					
				</div>
			<div 
			>
				<div className="card"  style={{display: 'grid', gridTemplateColumns: 'auto auto',  marginBottom: '10px'}}>
					<article 
					className="top-level-stats__item"
					style={{gridTemplateColumns:"auto auto"}}
					>
						<header className="top-level-stats__header">
							<PieChart width={180} height={180}>
								<Pie 
								isAnimationActive={false}
								dataKey="value" 
								data={visitsData} 
								allowDecimals={true}
								innerRadius='60%'
								stroke="none"
								>
									<Label content={<InnerPieLabelViews primary={visits} secondary={average_page_views}/>}/>
								</Pie>
								<Tooltip/>
							</PieChart>
						</header>
						<div>
							<ul>
								<li><span className="key-dot pink"></span>{new_to_site} new visitors</li>
								<li><span className="key-dot blue"></span>{repeat_to_site} repeat visitors</li>
								<li><br /></li>
								<li>Avg. {average_page_views} pages per visit</li>
							</ul>
						</div>

						
					</article>
					
					<article 
					className="top-level-stats__item"
					style={{gridTemplateColumns:"auto auto"}}
					>
						<header className="top-level-stats__header">
							<PieChart width={180} height={180}>
								<Pie 
								isAnimationActive={false}
								dataKey="value" 
								data={bounceData} 
								allowDecimals={true}
								innerRadius='60%'
								stroke="none"
								>
								<Label content={<InnerPieLabel total={hard_bounce_rate}/>}/>
								</Pie>
								<Tooltip/>
							</PieChart>
						</header>
						<div>
							<ul>
								<li><span className="key-dot red"></span>{hard_bounce_rate}% hard</li>
								<li><span className="key-dot yellow"></span>{soft_bounce_rate}% soft</li>
								<li><span className="key-dot green"></span>{multi_page_rate}% multi-page</li>
							</ul>
						</div>
						
						
					</article>
				</div>
				<div  className="card" style={{display: 'grid', gridTemplateColumns: 'auto auto auto',  }}>
					<article className="top-level-stats__item top-level-stats__item--full">
						<header>
							<span className="top-level-stats__headline">
								{
									average_dwell_time > 60 ?
									<span>{moment.utc(average_dwell_time*1000).format('mm:ss')} mins</span>
									:
									<span>{moment.utc(average_dwell_time*1000).format('ss')} secs</span>
								}
								
							</span>
							<span>Avg. time on site</span>
						</header>
					</article>
					<article className="top-level-stats__item top-level-stats__item--full">
						<header>
							<span className="top-level-stats__headline">
								
									<span>{moment.utc(props.pageReadySpeed*1000).format('mm:ss')} mins</span>
								
								
							</span>
							<span>Page Ready Time</span>
						</header>
					</article>
					<article className="top-level-stats__item top-level-stats__item--full">
						<header>
							
							<span className="top-level-stats__headline" style={{fontSize: '20px'}}>
								<StarRating stars={((props.pageStats.totalScoreDesktop*10)/2).toFixed(0)} tipText=""/>
							</span>
							<span>Page Lighthouse Score</span>
						</header>
					</article>
				</div>
			</div>
		
		</div>

		<div className="margin-large card" style={{padding: '15px'}}>
			<div className="margin-small" style={{textAlign: 'right'}}>
				<i>Last checked by Veracity - {moment(props.pageDataCached).format('D/M/Y hh:mm:ss')}</i>
			</div>
			<div style={{display: 'grid', gridTemplateColumns: 'max-content auto', gridColumnGap: '30px'}}>
				<div  style={{paddingLeft: '20px', paddingRight: '20px'}}>
					<div className="text-large" style={{textAlign: 'center'}}>Google Lighthouse Score</div>
						<div style={{textAlign: 'center', marginTop: '10px'}}>
							<span style={{marginRight: '10px'}}><span className="key-dot" style={{backgroundColor: '#FF3190'}}/>0-49</span>
							<span style={{marginRight: '10px'}}><span className="key-dot" style={{backgroundColor: '#E28413'}}/>50-89</span>
							<span><span className="key-dot" style={{backgroundColor: '#25CCCF'}}/>90-100</span>
						</div>
						<LabelledPieChart 
							data={[
								{ name:"", value: props.pageStats.totalScoreDesktop*100, fill: getColour(props.pageStats.totalScoreDesktop) },
								{ name:"", value: 100-(props.pageStats.totalScoreDesktop*100), fill: '#E6EBED' }
							]}
							total={(props.pageStats.totalScoreDesktop*100).toFixed(1) + "%"}
					/>
			
				</div>
				<div>
					<div style={{display: 'grid', gridTemplateColumns: 'auto auto', gridAutoRows: '1fr 1fr 1fr', gridColumnGap: '20px'}}>
						<SingleBar title="First Contentful Paint Score" percentage={(props.pageStats.firstContentfulPaintScore*100).toFixed(1)} colour={getColour(props.pageStats.firstContentfulPaintScore)} tip={"First Contentful Paint marks the time at which the first text or image is painted"}/>
						<SingleBar title="Speed Index Score" percentage={(props.pageStats.speedIndexScore*100).toFixed(1)}  colour={getColour(props.pageStats.speedIndexScore)} tip={"Speed Index shows how quickly the contents of a page are visibly populated"}/>
						<SingleBar title="Largest Contentful Paint Score" percentage={(props.pageStats.largestContentfulPaintScore*100).toFixed(1)}  colour={getColour(props.pageStats.largestContentfulPaintScore)} tip={"Time to interactive is the amount of time it takes for the page to become fully interactive"}/>
						
						<SingleBar title="Time To Interactive Score" percentage={(props.pageStats.timeToInteractiveScore*100).toFixed(1)}  colour={getColour(props.pageStats.timeToInteractiveScore)}  tip={"First Meaningful Paint measures when the primary content of a page is visible"}/>
						<SingleBar title="Total Blocking Time Score" percentage={(props.pageStats.totalBlockingTimeScore*100).toFixed(1)}  colour={getColour(props.pageStats.totalBlockingTimeScore)} tip={"First CPU idle marks the first time at which the page's main thread is quit enough to handle output"}/>
						<SingleBar title="Cumulative Layout Shift Score" percentage={(props.pageStats.cumulativeLayoutShiftScore*100).toFixed(1)}  colour={getColour(props.pageStats.cumulativeLayoutShiftScore)} tip={"The maximum potential First Input Delay that your users could experience is the duration, in milliseconds, of the longest task"}/>
					</div>
					<div >For more information visit <a href="https://developers.google.com/speed/docs/insights/v5/about">https://developers.google.com/speed/docs/insights/v5/about</a></div>
				</div>
			</div> 	
		</div>


		</>
		)
	}
	return null
}

export default Stats;