import React, { Component } from 'react';
import BeaconRequest from '../../BeaconRequest'

class Logout extends Component {
	handleLogout(){
		BeaconRequest.logout();
	}
	render(){
		return <span onClick={this.handleLogout.bind(this)}>Logout</span>
	}
}

export default Logout;