import moment from 'moment-timezone'

import { 
	DO_LOGIN_SUCCESS,
	DO_LOGIN_REFRESH,
	SET_FIRST_DASHBOARD,
	SET_USER_META,
	SET_CURRENT_USER,
	SET_DASHBOARD
} from '../actions/actionTypes';

// Respond to logion actions
// If login is successful, pass the information to the state as
// Otherwise set an error message and pass this to the state as login.errorMessage
const login = (state = {}, action) => {
	switch(action.type){
		case DO_LOGIN_SUCCESS:
			return {
				bearer: action.userDetails.response.bearer.bearer,
				expires: moment.unix(action.userDetails.response.bearer.expires)._d,
				refreshToken: action.userDetails.response.refreshToken,
				user_id: action.userDetails.response.bearer.user_id
			}
		case DO_LOGIN_REFRESH:
			return {
				bearer: action.userDetails.response.bearer.bearer,
				expires: moment.unix(action.userDetails.response.bearer.expires)._d,
				refreshToken: action.userDetails.response.refreshToken,
				user_id: action.userDetails.response.bearer.user_id
			}
		case SET_CURRENT_USER:
			return {
				currentUser: action.payload
			}
		case SET_USER_META: 
			return {
				...state,
				userMeta: action.meta.response
			}
		case SET_FIRST_DASHBOARD:
			return {
				...state,
				firstDashboard: action.flag
			}
		case SET_DASHBOARD: 
			return {
				...state,
				selectedDashboard: action.tab
			}
		default:
			return state
	}
}

export default login;