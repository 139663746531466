import { jsx as _jsx } from "react/jsx-runtime";
import moment from "moment";
import BeaconRequest from "../../BeaconRequest";
import FormatNumber from "../../components/general/FormatNumber";
import { Tab } from "./Tab";
var WholeNumberFormatter = new Intl.NumberFormat(undefined, {
    style: "decimal",
    maximumFractionDigits: 0
});
export var ColumnDefinitions = {
    id: {
        key: "id",
        title: "ID"
    },
    resourceName: {
        key: "resource_name",
        title: "Resource Name"
    },
    name: {
        key: "name",
        title: "Name"
    },
    campaignName: {
        key: "campaign_name",
        title: "Campaign Name"
    },
    adGroupName: {
        key: "ad_group_name",
        title: "Ad Group Name"
    },
    searchTerm: {
        key: "search_term",
        title: "Search Term"
    },
    status: {
        key: "status",
        title: "Status"
    },
    type: {
        key: "type",
        title: "Type"
    },
    matchType: {
        key: "match_type",
        title: "Match Type"
    },
    date: {
        key: "date",
        title: "Date",
        formatter: function (value, collation, timePeriod) {
            if (collation.id === "date")
                return moment(value).format("ll");
            if (collation.id === "summary")
                return "-";
            var date = collation.id === "year" && typeof value === "number"
                ? moment()
                    .year(value)
                    .month(1)
                    .day(1)
                    .hour(0)
                    .minute(0)
                    .second(0)
                : moment(value);
            var tpParts = BeaconRequest.tz_conversion(timePeriod).split(":");
            var tpStart = moment.unix(Number(tpParts[0]));
            var tpEnd = moment.unix(Number(tpParts[1]));
            var start = moment.max(date.clone(), tpStart).format("ll");
            var end = moment
                .min(date
                .clone()
                .add(1, collation.id)
                .subtract(1, "second"), tpEnd)
                .format("ll");
            return "".concat(start, " - ").concat(end);
        }
    },
    clicks: {
        key: "clicks",
        title: "Clicks",
        formatter: function (value) { return WholeNumberFormatter.format(value); }
    },
    cost: {
        key: "cost_micros",
        title: "Cost",
        formatter: function (value) { return _jsx(FormatNumber, { money: true, val: value / 1000000 }); }
    },
    firstPageCpc: {
        key: "first_page_cpc_micros",
        title: "First Page CPC",
        formatter: function (value) { return _jsx(FormatNumber, { money: true, val: value / 1000000 }); }
    },
    firstPositionCpc: {
        key: "first_position_cpc_micros",
        title: "First Position CPC",
        formatter: function (value) { return _jsx(FormatNumber, { money: true, val: value / 1000000 }); }
    },
    impressions: {
        key: "impressions",
        title: "Impressions",
        formatter: function (value) { return WholeNumberFormatter.format(value); }
    },
    conversions: {
        key: "conversions",
        title: "Conversions",
        formatter: function (value) { return WholeNumberFormatter.format(value); }
    },
    allConversions: {
        key: "all_conversions",
        title: "All Conversions",
        formatter: function (value) { return WholeNumberFormatter.format(value); }
    },
    costPerConversion: {
        key: "cost_per_conversion",
        title: "Cost per Conversion",
        formatter: function (value) { return _jsx(FormatNumber, { money: true, val: value / 1000000 }); }
    },
    averageCpc: {
        key: "average_cpc",
        title: "Average CPC",
        formatter: function (value) { return _jsx(FormatNumber, { money: true, val: value / 1000000 }); }
    }
};
export var Columns = new Map([
    [
        Tab.Customer,
        [
            ColumnDefinitions.date,
            ColumnDefinitions.impressions,
            ColumnDefinitions.clicks,
            ColumnDefinitions.conversions,
            ColumnDefinitions.allConversions,
            ColumnDefinitions.cost
        ]
    ],
    [
        Tab.Campaigns,
        [
            ColumnDefinitions.id,
            ColumnDefinitions.name,
            ColumnDefinitions.date,
            ColumnDefinitions.status,
            ColumnDefinitions.impressions,
            ColumnDefinitions.clicks,
            ColumnDefinitions.conversions,
            ColumnDefinitions.allConversions,
            ColumnDefinitions.cost
        ]
    ],
    [
        Tab.Ads,
        [
            ColumnDefinitions.id,
            ColumnDefinitions.campaignName,
            ColumnDefinitions.adGroupName,
            ColumnDefinitions.date,
            ColumnDefinitions.status,
            ColumnDefinitions.impressions,
            ColumnDefinitions.clicks,
            ColumnDefinitions.conversions,
            ColumnDefinitions.cost
        ]
    ],
    [
        Tab.Keywords,
        [
            ColumnDefinitions.id,
            ColumnDefinitions.name,
            ColumnDefinitions.campaignName,
            ColumnDefinitions.adGroupName,
            ColumnDefinitions.date,
            ColumnDefinitions.status,
            ColumnDefinitions.type,
            ColumnDefinitions.impressions,
            ColumnDefinitions.clicks,
            ColumnDefinitions.conversions,
            ColumnDefinitions.cost,
            ColumnDefinitions.firstPageCpc,
            ColumnDefinitions.firstPositionCpc
        ]
    ],
    [
        Tab.SearchTerms,
        [
            ColumnDefinitions.name,
            ColumnDefinitions.campaignName,
            ColumnDefinitions.adGroupName,
            ColumnDefinitions.date,
            ColumnDefinitions.status,
            ColumnDefinitions.matchType,
            ColumnDefinitions.impressions,
            ColumnDefinitions.clicks,
            ColumnDefinitions.cost
        ]
    ],
    [
        Tab.DynamicSearchTerms,
        [
            ColumnDefinitions.name,
            ColumnDefinitions.searchTerm,
            ColumnDefinitions.campaignName,
            ColumnDefinitions.adGroupName,
            ColumnDefinitions.date,
            ColumnDefinitions.impressions,
            ColumnDefinitions.clicks,
            ColumnDefinitions.conversions,
            ColumnDefinitions.cost
        ]
    ],
    [
        Tab.BiddingStrategies,
        [
            ColumnDefinitions.id,
            ColumnDefinitions.name,
            ColumnDefinitions.type,
            ColumnDefinitions.date,
            ColumnDefinitions.status,
            ColumnDefinitions.impressions,
            ColumnDefinitions.clicks,
            ColumnDefinitions.conversions,
            ColumnDefinitions.cost,
            ColumnDefinitions.costPerConversion,
            ColumnDefinitions.averageCpc
        ]
    ]
]);
