import React from 'react'

const NewChannels = props => {

    const channels = props.channels.map( (channel, i) => {
        return (
            <React.Fragment 
            key={i}
            >
                <input 
                type="checkbox"
                id={channel.title}
                style={{display:"none"}}
                defaultChecked={true}
                onChange={() => props.handleToggleChannel(channel)}
                />
                <label 
                className="channels__item"
                htmlFor={channel.title}
                >
                    <div className="channels__title">
                        <div className="channels__icon">
                            <span className="key-dot key-dot--over-icon" style={{backgroundColor:channel.colour}}></span>
                            { channel.icon ? <i className={channel.icon}></i> : <i>{channel.title.charAt(0)}</i> }
                        </div>
                        {channel.title}
                    </div>
                </label>
            </React.Fragment>
        )
    })
    return (
        <div className="channels margin-large">
            {channels}
        </div>
    )
}

export default NewChannels