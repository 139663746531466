import { DEV, BeaconRequest_ver, BeaconRequest_environment, BeaconRequest_publickey, BeaconRequest_client, appVer } from './API'
import axios from 'axios';
import memoize from 'memoizee'
import moment from 'moment-timezone'
import {store} from '.'

export const BeaconRequestPHP8 = (new (function BeaconRequest(){

	// Check the app version - if it is different to the previous one, log the user out
	const savedAppVersion = localStorage.getItem("version");
	if (savedAppVersion && savedAppVersion === appVer) {
		// Nowt
	} else {
	    localStorage.clear();
	    localStorage.setItem("version", appVer);
	}

	if(DEV){
		console.log("Beacon Request Initializing");
	}
	let self = this;
	// holds the data required for refesh
	let RefreshData = "";
	// holds the current bearer token
	let BearerToken = {};
	// holds the default headers
	let DefaultHeaders = {};
	// holds the default querystring
	let DefaultQueryString = {"oa_clientKey":BeaconRequest_client};
	// holds the server addredd to use
	let ServerAddress = "";
	// are we connecting to a dev system
	let isDevSystem = false;
	// are we waiting for and authorized 
	let pendingAuth = true;
	// holdsLastRefreshToken
	let lastRefreshJSON = {};
	// holds the last API test
	let APITest = false;
	
	// has the login been called before IE this is a refresh
	let SavedData = localStorage.getItem('BeaconRequest');
	
	if(SavedData){
		let json = JSON.parse(SavedData);
		RefreshData = json.RefreshData;
		BearerToken = json.BearerToken;
		
		// construct variables needed to operate from saved data;
		switch(BeaconRequest_environment){
			case "INSIGHT_API":
                ServerAddress = "v6api.thisisbeacon.com"; // PHP 8 Prod API
                isDevSystem = false;
            	break;
			case "STEW_API":
				ServerAddress = "stewapi.thisisbeacon.com";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "MARTIN_API":
				ServerAddress = "martinapi.thisisbeacon.com";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "ASPEN_API":
				ServerAddress = "aspen.api.beaconsoft.ltd";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "QA_API":
				ServerAddress = "kieran-api.beaconsoft.ltd"; // PHP 8 Dev API
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
				break;
			default:
				case "DEMO_API":
                ServerAddress = "demoapi.thisisbeacon.com";
                isDevSystem = false;
            break;
			case "LIVE_API":
				ServerAddress = "v4api.thisisbeacon.com"
				DefaultHeaders = {};
				isDevSystem = false;
			break;
		}
		
		if(isDevSystem){
			axios.defaults.headers.common["Dev-Authorization"] = "Bearer "+BearerToken.bearer;
		}else{
			axios.defaults.headers.common["Authorization"] = "Bearer "+BearerToken.bearer;
		}
		
		pendingAuth = false;
	}

	this.getServerAddress = function(){
		switch(BeaconRequest_environment){
			case "INSIGHT_API":
                ServerAddress = "v6api.thisisbeacon.com"; // PHP 8 Prod API
                isDevSystem = false;
            break;
			case "STEW_API":
				ServerAddress = "stewapi.thisisbeacon.com";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "MARTIN_API":
				ServerAddress = "martinapi.thisisbeacon.com";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "ASPEN_API":
				ServerAddress = "aspen.api.beaconsoft.ltd";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "QA_API":
				ServerAddress = "kieran-api.beaconsoft.ltd"; // PHP 8 Dev API
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "DEMO_API":
                ServerAddress = "demoapi.thisisbeacon.com";
                isDevSystem = false;
            break;
			default:
			case "LIVE_API":
				ServerAddress = "v4api.thisisbeacon.com"
				DefaultHeaders = {};
				isDevSystem = false;
			break;
		}
		return 'https://' + ServerAddress + '/' + BeaconRequest_ver + '/';

	}
			
	function sleep(ms){
		return new Promise(resolve=>{
			setTimeout(resolve,ms)
		})
	}
	
	function encryptPayload(jsonObject){
		let sodium = window.sodium;

		let nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
		let ServerPublicKey = sodium.from_hex(BeaconRequest_publickey);
		let ourKey = sodium.crypto_box_keypair();
		
		var str = JSON.stringify(jsonObject);
		var message = sodium.crypto_box_easy(str, nonce, ServerPublicKey, ourKey.privateKey);

		let postData = {
			"nonce":sodium.to_hex(nonce),
			"str":sodium.to_hex(message),
			"publicKey":sodium.to_hex(ourKey.publicKey)
		};
		
		return postData;
	}

	this.encryptPayload = encryptPayload

	function serializeQS(obj, prefix) {
		var str = [],
		  p;
		for (p in obj) {
		  if (obj.hasOwnProperty(p)) {
			var k = prefix ? prefix + "[" + p + "]" : p,
			  v = obj[p];
			str.push((v !== null && typeof v === "object") ?
			  serializeQS(v, k) :
			  encodeURIComponent(k) + "=" + encodeURIComponent(v));
		  }
		}
		return str.join("&");
	  }
	
	function QueryString(obj){
		if(typeof obj === "undefined"){ obj = {}; }
		let str = "";
		let DefaultQueryStr = serializeQS(DefaultQueryString);
		let QueryStr = serializeQS(obj);
		
		if(DefaultQueryStr !== ""){
			str += DefaultQueryStr;
		}
		
		if(str !== "" && QueryStr !== ""){
			str += "&";
		}
		
		str += QueryStr+"&!anticache="+(new Date()).getTime()
		return str;
	}
	
	function parsePage(link){
		const regex = /(https?:\/\/)([a-zA-Z0-9\-.]*)\/([0-9\.]{3})\/([a-zA-Z0-9\/:]*)\?(.*)/gm;
		let m = regex.exec(link);
		
		return m;
	}
	
	this.isLoggedIn = function(){
		if(typeof(BearerToken.expires) === 'undefined'){
			return false
		}
		if((new Date(BearerToken.expires * 1000)) > (new Date())){
			return true;
		}else{
			return this.refresh().then(function(){
				return true;
			}).catch(function(){
				return false;
			});
		}
	}
	
	this.isLoggedInPromise = function(){
		return new Promise(function(resolve, reject){
			if(typeof(BearerToken.expires) === 'undefined'){
				reject();
			}
			if((new Date(BearerToken.expires * 1000)) > (new Date())){
				resolve(BearerToken, RefreshData);
			}else{
				this.refresh().then(function(){
					resolve(BearerToken, RefreshData);
				}).catch(function(){
					reject();
				});
			}
		});
	}
	
	this.login = async function(email, password, recapture, ip){		
		switch(BeaconRequest_environment){
			case "INSIGHT_API":
                ServerAddress = "v6api.thisisbeacon.com"; // PHP 8 Prod API
                isDevSystem = false;
            break;
			case "STEW_API":
				ServerAddress = "stewapi.thisisbeacon.com";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "MARTIN_API":
				ServerAddress = "martinapi.thisisbeacon.com";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "ASPEN_API":
				ServerAddress = "aspen.api.beaconsoft.ltd";
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "QA_API":
				ServerAddress = "kieran-api.beaconsoft.ltd"; // PHP 8 Dev API
				axios.defaults.headers.common["Authorization"] = "Basic YmVhY29uOlBvd1dvd0NhdCE=";
				isDevSystem = true;
			break;
			case "DEMO_API":
                ServerAddress = "demoapi.thisisbeacon.com";
                isDevSystem = false;
            break;
			default:
			case "LIVE_API":
				ServerAddress = "v4api.thisisbeacon.com"
				DefaultHeaders = {};
				isDevSystem = false;
			break;
		}
		
		await this.testAPI();
		
		let sendHeaders = [];
		for(let h in DefaultHeaders){
			if(DefaultHeaders.hasOwnProperty(h)){
				sendHeaders[h] = DefaultHeaders[h]
			}
		}
		sendHeaders["Content-Type"] = "application/json";
		
		let loginObject = { "email": email, "password": password, "capture":recapture, "remip":ip };
		return new Promise(function(resolve, reject){
			axios.post("https://" + ServerAddress + "/" + BeaconRequest_ver + "/auth/login?" + QueryString(), encryptPayload(loginObject))
			.then(function(resp){
				let json = resp.data;
				if(json.responseType === "success"){
					RefreshData = json.response.refreshToken;
					BearerToken = json.response.bearer;
					if(isDevSystem){
						axios.defaults.headers.common["Dev-Authorization"] = "Bearer "+BearerToken.bearer;
					}else{
						axios.defaults.headers.common["Authorization"] = "Bearer "+BearerToken.bearer;
					}
					localStorage.setItem('BeaconRequest', JSON.stringify({
						RefreshData : json.response.refreshToken,
						BearerToken : json.response.bearer
					}));
					pendingAuth = false;
					resolve(json);
				}else{
					reject(json);
				}
			}).catch(error => reject(error));
		});
	}

	this.logout = function(){
		RefreshData = "";
		BearerToken = {};
		lastRefreshJSON = {};
		pendingAuth = true;
		localStorage.clear(); // clear local storage
		window.location.replace("/"); // redirect
		return true;
	}
	
	this.getBearer = function(){
		return BearerToken;
	}
	
	this.refresh = async function(){
		if(DEV){
			console.log("Refreshing Token");
		}
	
		if(pendingAuth){
			while(pendingAuth){
				await sleep(200);
			}
			return new Promise((resolve, reject) => {
				if(lastRefreshJSON.responseType === "success"){
					resolve(lastRefreshJSON); 
				}else{
					reject(lastRefreshJSON); 
				}
			});
		}
		pendingAuth = true;
		
		let sendHeaders = [];
		for(let h in DefaultHeaders){
			if(DefaultHeaders.hasOwnProperty(h)){
				sendHeaders[h] = DefaultHeaders[h]
			}
		}
		sendHeaders["Content-Type"] = "application/json";
		
		
		return new Promise(function(resolve, reject){
			axios.post("https://" + ServerAddress + "/" + BeaconRequest_ver + "/auth/refresh?" + QueryString(), { 'refreshToken': RefreshData}).then(
			function(resp){
				let json = resp.data;
				lastRefreshJSON = json;
				if(json.responseType === "success"){
					if(DEV){
						console.log("New Tokens Obtained");
					}
					RefreshData = json.response.refreshToken;
					BearerToken = json.response.bearer;
					if(isDevSystem){
						axios.defaults.headers.common["Dev-Authorization"] = "Bearer "+BearerToken.bearer;
					}else{
						axios.defaults.headers.common["Authorization"] = "Bearer "+BearerToken.bearer;
					}
					localStorage.setItem('BeaconRequest', JSON.stringify({
						RefreshData : json.response.refreshToken,
						BearerToken : json.response.bearer
					}));
					pendingAuth = false;
					resolve(json);
				}else{
					reject(json);
				}
			}).catch(function(error){
				self.logout();
				reject(error);
				console.log("Logout occurred");
			});
		});
	}
	
	this.testAPI = async function (){
		let serverAddr = this.getServerAddress();
		
		if(DEV){
			console.log("Checking API is online.");
		}
		
		if(APITest){
			if( ((new Date) - APITest) < 60*1000){
				return new Promise(function(resolve){
					resolve();
				});
			}else{
				while( ((new Date) - APITest) < 60*1000){
					await sleep(200);
				}
				return new Promise(function(resolve){
					resolve();
				})
			}
		}
		APITest = (new Date) - 60*1000;
		
		let sendHeaders = [];
		sendHeaders["Content-Type"] = "application/json";
		
		if(DEV){
			console.log({"axis.get":{
				"url":"https://" + ServerAddress + "/" + BeaconRequest_ver + "/status",				
				headers: sendHeaders
			}});
		}
		
		return new Promise(function(resolve, reject){
			axios.get("https://" + ServerAddress + "/" + BeaconRequest_ver + "/status")
			.then(async function(resp){
				let json = resp.data;
				if(json.response === "online"){
					APITest = new Date;
					resolve(json);
				}else{
					document.location = "https://www.thisisbeacon.com/upgrading/";
				}
			}).catch(async function(error){
				document.location = "https://www.thisisbeacon.com/upgrading/";
			});
		});
	}

	this.startof_day = (time) => {
		return moment.unix(time).startOf('day').unix()
	}
	
	this.endof_day = (time) => {
		return moment.unix(time).endOf('day').unix()
	}

	//last x days changed to previous x days, but using same key cause cba
	this.tz_conversion_map = (tz) => { 
		// console.log( convert_to_utc_unix(moment().tz(tz).startOf('month').subtract(1, 'm'))  + ":" + convert_to_utc_unix(moment().tz(tz).endOf('month').subtract(1, 'm')),)
		return { 
			"today": convert_to_utc_unix(startOf(tz, 0)) + ":" + convert_to_utc_unix(endOf(tz, 0)),
			"yesterday": convert_to_utc_unix(startOf(tz, 1)) + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last2days": convert_to_utc_unix(startOf(tz, 2)) + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last3days": convert_to_utc_unix(startOf(tz, 3))  + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last7days": convert_to_utc_unix(startOf(tz, 7))  + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last14days": convert_to_utc_unix(startOf(tz, 14))  + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last30days": convert_to_utc_unix(startOf(tz, 30))  + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last45days": convert_to_utc_unix(startOf(tz, 45))  + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last60days": convert_to_utc_unix(startOf(tz, 60))  + ":" + convert_to_utc_unix(endOf(tz, 1)),

			"this_month": convert_to_utc_unix(moment().tz(tz).startOf('month'))  + ":" + convert_to_utc_unix(endOf(tz, 1)),
			"last_month": convert_to_utc_unix(moment().tz(tz).subtract(1, 'months').startOf('month')) + ":" + convert_to_utc_unix(moment().tz(tz).subtract(1, 'months').endOf('month')),
		}
	}

	const convert_to_utc_unix = (date) => {
		return moment(date).utc().unix()
	}

	const startOf = (tz, day) => {
		return moment().tz(tz).subtract(day, 'day').startOf('day')
	}

	const endOf = (tz, day) => {
		return moment().tz(tz).subtract(day, 'day').endOf('day')
	}

	this.getTimezone = () => {
		const state = store.getState(), website = state.websites.selectedWebsite
		
		if (website && website.timezone) {
			return website.timezone	
		}

		return moment.tz.guess()
	}

	this.tz_conversion = (endpoint) => {
		let timezone = this.getTimezone()
		moment.tz.setDefault(timezone) //for parsing time zones

		const tz = this.tz_conversion_map(timezone)
		const endpoint_time_period =  Object.keys(tz).filter(key => endpoint.indexOf(key) !== -1)[0]
		
		if (endpoint_time_period != null) {
			endpoint = endpoint.replace(endpoint_time_period , tz[endpoint_time_period] )
		} 
		console.log(timezone, endpoint)
		
		return endpoint
	}

	let get = async (endpoint, querystring, headers, requireAuth) => {
		endpoint = this.tz_conversion(endpoint)
		
		if(typeof requireAuth === "undefined"){
			requireAuth = true;
		}

		if(requireAuth){
			while(pendingAuth){
				await sleep(200);
			}
		}
		
		await this.testAPI();
		
		// setup default content type for a post
		let sendHeaders = [];
		for(let h in DefaultHeaders){
			if(DefaultHeaders.hasOwnProperty(h)){
				sendHeaders[h] = DefaultHeaders[h]
			}
		}

		sendHeaders["Content-Type"] = "application/json";
		
		// go though given haders and add them to the array of headers
		if(typeof headers !== "undefined"){
			for(let header in headers){
				if(headers.hasOwnProperty(header)){
					sendHeaders[header] = headers[header];
				}
			}
		}
		if(DEV){
			console.log({"axis.get":{
				"url":"https://" + ServerAddress + "/" + BeaconRequest_ver + "/" + endpoint + "?" + QueryString(querystring),				
				headers: sendHeaders
			}});
		}
		
		return new Promise(function(resolve, reject){
			axios.get("https://" + ServerAddress + "/" + BeaconRequest_ver + "/"+ endpoint + "?" + QueryString(querystring), headers)
			.then(async function(resp){
				let json = resp.data;
				if(json.responseType === "success"){
					resolve(json);
				}else{
					reject(json);
				}
			}).catch(async function(error){
				if (typeof error?.response?.data === "object") {
					let json = error.response.data;
					// console.log(json);
					if(json.type === "AuthenticationException" && (json.code === 1006 || json.code === 1005)){
						if(DEV){
							console.log("AuthException we need to refresh.");
						}
						await self.refresh();
						axios.get("https://" + ServerAddress + "/" + BeaconRequest_ver + "/"+ endpoint + "?" + QueryString(querystring), headers)
						.then(function(resp){
							let json = resp.data;
							if(json.responseType === "success"){
								resolve(json);
							}else{
								reject(json);
							}
						}).catch(error => reject(error));
					}else{
						reject(json); //request timed out
					}
				} else {
					reject(error)
				}
				
			});
		});
	}


	this.getNoChache = get

	this.get = memoize(get, {
		maxAge: 10000,
		length: 3,
		// async: true
		// normalizer: function (args) {
		// 	return JSON.stringify(args[0])
		// }
	})
	
	this.post = async function(endpoint, querystring, postData, headers, encrypted, requireAuth){
		endpoint = this.tz_conversion(endpoint)

		await this.testAPI();
		
		// setup default content type for a post
		let sendHeaders = [];
		for(let h in DefaultHeaders){
			if(DefaultHeaders.hasOwnProperty(h)){
				sendHeaders[h] = DefaultHeaders[h]
			}
		}
		sendHeaders["Content-Type"] = "application/json";

		if(typeof requireAuth === "undefined"){
			requireAuth = true;
		}
		
		// go though given haders and add them to the array of headers
		if(typeof headers !== "undefined"){
			for(let header in headers){
				if(headers.hasOwnProperty(header)){
					sendHeaders[header] = headers[header];
				}
			}
		}
		
		if(typeof encrypted !== "undefined" && encrypted){
			postData = encryptPayload(postData);
		}
		
		if(requireAuth){
			while(pendingAuth){
				await sleep(200);
			}
		}
		
		if(DEV){
			console.log({"axios.post":{
				"url":"https://" + ServerAddress + "/" + BeaconRequest_ver + "/" + endpoint + "?" + QueryString(querystring),				
				body: JSON.stringify(postData), 
				headers: sendHeaders
			}});
		}
		
		return new Promise(function(resolve, reject){
			axios.post("https://" + ServerAddress + "/" + BeaconRequest_ver + "/" + endpoint + "?" + QueryString(querystring), postData)
			.then(async function(resp){
				let json = resp.data;
				if(json.responseType === "success"){
					resolve(json);
				}else{
					reject(json)
				}
			}).catch(async function(error){
				let json = error.response.data;
				if(json.type === "AuthenticationException" && json.code === 1006){
					await self.refresh();
					axios.post("https://" + ServerAddress + "/" + BeaconRequest_ver + "/" + endpoint + "?" + QueryString(querystring), postData)
					.then(function(resp){
						let json = resp.data;
						if(json.responseType === "success"){
							resolve(json);
						}else{
							reject(json);
						}
					}).catch(error => reject(error));
				}else{
					reject(json);
				}
			});
		});
	}
	
	this.encryptedPost = async function(endpoint, querystring, postData, headers){
		return this.post(endpoint, querystring, postData, headers, true);
	}
	
	// support for DELETE Calls
	this.delete = async function(endpoint, querystring, postData, headers){
			
		await this.testAPI();
		
		// setup default content type for a post
		let sendHeaders = [];
		for(let h in DefaultHeaders){
			if(DefaultHeaders.hasOwnProperty(h)){
				sendHeaders[h] = DefaultHeaders[h]
			}
		}
		sendHeaders["Content-Type"] = "application/json";
		
		// go though given haders and add them to the array of headers
		if(typeof headers !== "undefined"){
			for(let header in headers){
				if(headers.hasOwnProperty(header)){
					sendHeaders[header] = headers[header];
				}
			}
		}
		
		while(pendingAuth){
			await sleep(200);
		}
		
		if(DEV){
			console.log({"axios.post":{
				"url":"https://" + ServerAddress + "/" + BeaconRequest_ver + "/" + endpoint + "?" + QueryString(querystring),				
				body: JSON.stringify(postData), 
				headers: sendHeaders
			}});
		}
		
		return new Promise(function(resolve, reject){
			axios.delete("https://" + ServerAddress + "/" + BeaconRequest_ver + "/" + endpoint + "?" + QueryString(querystring))
			.then(async function(resp){
				let json = resp.data;
				if(json.responseType === "success"){
					resolve(json);
				}else{
					reject(json)
				}
			}).catch(async function(error){
				let json = error.response.data;
				if(json.type === "AuthenticationException" && json.code === 1006){
					await self.refresh();
					axios.delete("https://" + ServerAddress + "/" + BeaconRequest_ver + "/" + endpoint + "?" + QueryString(querystring))
					.then(function(resp){
						let json = resp.data;
						if(json.responseType === "success"){
							resolve(json);
						}else{
							reject(json);
						}
					}).catch(error => reject(error));
				}else{
					reject(json);
				}
			});
		});
	}
	
	// Add support for pasing a next url and making the request.
	this.paging = async function(link){
		let parts = parsePage(link);
		
		let endpoint = parts[4];
		let qs = parts[5];
		
		let QueryString = [];
		let qsParts = qs.split('&');
		for(let k in qsParts){
			let kv = qsParts[k].split('=');
			QueryString[kv[0]] = kv[1];
		}
		
		return this.getNoChache(endpoint, QueryString);
	}
	


	// const CancelToken = axios.CancelToken;

	// const cancelTokenHandler = {};

	// this.createCancelTokenHandler = (apiObject) => {
	// 	// initializing the cancel token handler object
		

	// 	// for each property in apiObject, i.e. for each request
	// 	// Object
	// 	// 	.getOwnPropertyNames(apiObject)
	// 	// 	.forEach(propertyName => {
	// 			// initializing the cancel token of the request
	// 			const cancelTokenRequestHandler = {
	// 				cancelToken: undefined
	// 			}

	// 			// associating the cancel token handler to the request name
	// 			cancelTokenHandler[propertyName] = {
	// 				handleRequestCancellation: () => {
	// 					// if a previous cancel token exists,
	// 					// cancel the request
	// 					cancelTokenRequestHandler.cancelToken && cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`)

	// 					// creating a new cancel token
	// 					cancelTokenRequestHandler.cancelToken = CancelToken.source();

	// 					// returning the new cancel token
	// 					return cancelTokenRequestHandler.cancelToken;
	// 				}
	// 			}
	// 		// })

	// 	return cancelTokenHandler;
	// }

	if(DEV){
		console.log("Beacon Request Initialized");
	}
})());

export default BeaconRequestPHP8;
