import BeaconRequest from '../BeaconRequest';
import { addNotification } from './ui'
import { fetchCampaignChannels, unsetSelectedChannel, clearCampaignChannels } from './channels'
import moment from 'moment-timezone';

// Import action types
import {
	FETCH_ACTIVE_CAMPAIGNS_REQUEST,
	FETCH_ACTIVE_CAMPAIGNS_SUCCESS,
	FETCH_ALL_CAMPAIGNS_REQUEST,
	FETCH_ALL_CAMPAIGNS_SUCCESS,
	FETCH_SINGLE_CAMPAIGN_REQUEST,
	FETCH_SINGLE_CAMPAIGN_SUCCESS,
	SET_SELECTED_CAMPAIGN,
	UNSET_SELECTED_CAMPAIGN,
	SET_SELECTED_CAMPAIGN_NAME,
	CLEAR_ACTIVE_CAMPAIGNS
} from './actionTypes';
import {oneOneEightID} from "../constants";

// Get all active campaigns and return the result
export const fetchActiveCampaigns = () => {

	return (dispatch, getState) => {
		dispatch({
			type: FETCH_ACTIVE_CAMPAIGNS_REQUEST
		})
		const userId = getState().login.currentUser.user_id;
		return BeaconRequest.get('website/'+ getState().websites.selectedWebsiteId +'/campaigns/active')
		.then(responseObj => {
			if (userId === oneOneEightID) { // 118 HACK
				responseObj.response = responseObj.response.filter(({campaign_id}) => campaign_id === 1753)
			}
			dispatch({
				type: FETCH_ACTIVE_CAMPAIGNS_SUCCESS,
				activeCampaigns: responseObj.response
			})
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}

export const clearActiveCampaigns = () => {
	return (dispatch, getState) => {
		dispatch({
			type: CLEAR_ACTIVE_CAMPAIGNS,
		})
	}
}

export const fetchAllCampaigns = () => {

	return (dispatch, getState) => {
		dispatch({
			type: FETCH_ALL_CAMPAIGNS_REQUEST
		})
		const userId = getState().login.currentUser.user_id;
		return BeaconRequest.get( 'website/'+ getState().websites.selectedWebsiteId +'/campaigns/all')
		.then(responseObj => {
			if (userId === oneOneEightID) { // 118 HACK
				responseObj.response = responseObj.response.filter(({campaign_id}) => campaign_id === 1753)
			}
			dispatch({
				type: FETCH_ALL_CAMPAIGNS_SUCCESS,
				allCampaigns: responseObj.response
			})
			return responseObj
		})
		.catch(error => { console.log(error) })
	}
}

export const fetchBotsByCampaign = (campaignId) => {
	return (dispatch, getState) => {
		const query = 'campaign/'+ campaignId+'/bots/allTime'
		return BeaconRequest.get(query, {})
		.then(responseObj => {
			return responseObj
		})
	}
}

export const fetchSingleCampaign = (campaignId,  nocache) => {
	return (dispatch, getState) => {
		dispatch({
			type: FETCH_SINGLE_CAMPAIGN_REQUEST,
		})
		
		const lifecycle_query = 'campaign/' + campaignId 
		const call = (query, qs) => nocache ? BeaconRequest.getNoChache(query, qs) : BeaconRequest.getNoChache(query, qs)

		return call(lifecycle_query, {"__onlyCore": 1})
			.then(responseObj => {
				const campaign = responseObj.response, start = BeaconRequest.startof_day(campaign.__lifecycle.first.timestamp);
				let end = BeaconRequest.endof_day(campaign.__lifecycle.last.timestamp)

				if (end == moment().endOf('day').unix() && moment.unix(campaign.__lifecycle.first.timestamp).isBefore(moment().startOf('day')) )  {
					end = moment().subtract(1, 'd').endOf('day').unix()
				}
				return call(`campaign/${campaignId}/channel/analytics/${start}:${end}/stack`).then(res => {
					dispatch({
						type: FETCH_SINGLE_CAMPAIGN_SUCCESS,
						responseObj: res
					})
					return res
				})
			})
			.catch(error => { console.log(error) })
	}
}

// Shortcut method to toggle whether a campaign is archived or not
export const toggleCampaign = (campaign, toggle) => {
	return (dispatch, getState) => {
		const websiteId = getState().websites.selectedWebsiteId
		const toggleCampaign = toggle === 1 ? false : true

		return BeaconRequest.post(
			'campaign/' + campaign.campaign_id, {},
			{
				title:campaign.title,
				website_id:websiteId,
				archived:toggleCampaign,
				colour:campaign.colour
			}
		)
		.then(responseObj => {
			const toggled = toggle === 1 ? "unarchived" : "archived"
			const payload = {
				title: "Campaign has been " + toggled,
				theme: "positive"
			}
			dispatch(addNotification(payload))
			dispatch(fetchActiveCampaigns())
			dispatch(fetchSingleCampaign(responseObj.response.campaign_id, null, null, true))
		})
		.catch(error => { console.log(error) })
	}
}

export const updateCampaign = (data) => {

	return (dispatch, getState) => {
		const websiteId = getState().websites.selectedWebsiteId
		let post = {
			title:data.campaignName,
			website_id:websiteId,
			description:data.campaignDescription ? data.campaignDescription : "",
			colour:data.campaignColour,
			adChannels:data.adChannels,
		}
		if(data.startDate){
			let startDate = moment(data.startDate).unix()
			post = {
				...post,
				startDate: startDate,
			}
		}
		if(!data.startDate){
			post = {...post, startDate: 0}
		}
		if(data.endDate){
			let endDate = moment(data.endDate).add(1, "days").subtract(1, "second").unix()
			post = {
				...post,
				endDate: endDate,
			}
		}
		if(!data.endDate){
			post = {...post, endDate: 0}
		}

		return BeaconRequest.post(
			'campaign/' + data.campaignId, {},
			post
		)
		.then(responseObj => {
			BeaconRequest.post(
				'campaign/' + data.campaignId+'/meta', {},
				[
					{name: "lead_definition", value: (() => {
						switch (data.selectedType) {
							case 'identified_visitor':
							default:
								return data.selectedType;
							case 'shopify_thank_you':
								return JSON.stringify([{"type":"shopify_thank_you","settings":["*/checkouts/*/thank_you"]}]);
							case 'url_match':
							case 'external_link':
							case 'preg_match':
								return JSON.stringify([{"type": data.selectedType, "settings": data.settingsLinks}]);
						}
					})()},
					{name: "show_identified_data", value: data.showIdentifiedData}
				]
			).then(res => {
				const payload = {
					title: "Campaign has been updated",
					theme: "positive"
				}
				dispatch(addNotification(payload))
				dispatch(fetchAllCampaigns())
				dispatch(fetchActiveCampaigns())
			})

			return responseObj
		})
		.catch(error => { return error })
	}
}

export const createCampaign = (data) => {

	return (dispatch, getState) => {
		const websiteId = getState().websites.selectedWebsiteId

		let post = {
			title:data.campaignName,
			description:data.campaignDescription,
			website_id:websiteId,
			colour:data.campaignColour,
		}

		if(data.endDate){
			let endDate = moment(data.endDate).add(1, "days").subtract(1, "second").unix()
			post = {
				...post,
				endDate: endDate
			}
		}

		if(data.startDate){
			let startDate = moment(data.startDate).unix()
			post = {
				...post,
				startDate: startDate
			}
		}
	
		return BeaconRequest.post(
			'campaign/', {}, 
			post
		)
		.then(responseObj => {
			BeaconRequest.post(
				'campaign/' + responseObj.response.campaign_id +'/meta', {},
				[
					{name: "lead_definition", value: (() => {
						switch (data.selectedType) {
							case 'identified_visitor':
							default:
								return data.selectedType;
							case 'shopify_thank_you':
								return JSON.stringify([{"type":"shopify_thank_you","settings":["*/checkouts/*/thank_you"]}]);
							case 'url_match':
							case 'external_link':
							case 'preg_match':
								return JSON.stringify([{"type": data.selectedType, "settings": data.settingsLinks}]);
						}
					})()},
					{name: "show_identified_data", value: data.showIdentifiedData}
				]
			)
			const payload = {
				title: "Campaign has been created",
				theme: "positive"
			}
			dispatch(addNotification(payload))
			dispatch(fetchActiveCampaigns())
			dispatch(setSelectedCampaign(responseObj.response.campaign_id))
			return responseObj
		})
		.catch(error => { return error })
	}
}

export const setSelectedCampaign = (campaign_id, timeperiod) => {
	return (dispatch, getState) => {
		// return BeaconRequest.get('campaign/' + campaign_id)
		// .then(responseObj => {
		dispatch({
			type: SET_SELECTED_CAMPAIGN,
			payload: {campaign_id: campaign_id, title: ''}
			// responseObj.response // send the complete response to the reducer
		})
		dispatch(unsetSelectedChannel())
		dispatch(clearCampaignChannels())
		dispatch(fetchCampaignChannels(campaign_id, timeperiod))
		BeaconRequest.get('campaign/' + campaign_id)
			.then(responseObj => {dispatch({type: SET_SELECTED_CAMPAIGN_NAME, payload: responseObj.response})})
		// })
		// .catch(error => { console.log(error) })
	}
}

export const unsetSelectedCampaign = () => {
	return {
		type: UNSET_SELECTED_CAMPAIGN
	}
}
