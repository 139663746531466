const initialState = {
	pages: {
		pagesType: 'all'
	},
	ui: {
		notifications: [],
		showLinksOnlyVisits: true,
		campaignLinkView: 0,
		showReportLessons: true
	},
	dates: {
		selectedDateValue: 'last7days',
		selectedDateName: 'Previous 7 days',
	},
	customers: {
		selectedCustomer: {
			__subscription: {
				currently_active: 0,
				is_trial: 0
			},
			__user_role: {
				role_id: 0
			}
		}
	},
	login: {
	}
}

export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('state');
		if(serializedState === null){
			return initialState
		}

		const serialised = JSON.parse(serializedState)
		return serialised;
	} catch(err) {
		return undefined;
	}
};

export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch(err){

	}
}
