import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createWebsite, fetchSupportedCurrencies } from '../../actions/websites';
import CreateWebsite from '../../components/forms/CreateWebsite';
import Modal from '../../components/general/Modal';
import PropTypes from 'prop-types';

class CreateWebsiteContainer extends Component {
	constructor(){
		super()
		this.state = {
			alertOnClose: false,
			websiteUrl: '',
			websiteName: '',
			websiteCurrency: '',
			supportedCurrencies: [],
			leadsText: 'Leads',
			timezone: moment.tz.guess(),
			

			error: null,
		}
		this.handleChangeWebsiteUrl = this.handleChangeWebsiteUrl.bind(this)
		this.handleChangeWebsiteName = this.handleChangeWebsiteName.bind(this)
		this.handleCreateWebsite = this.handleCreateWebsite.bind(this)
		this.handleChangeWebsiteCurrency = this.handleChangeWebsiteCurrency.bind(this)
		this.handleChangeLeadsText = this.handleChangeLeadsText.bind(this)
		this.handleTimezoneChange = this.handleTimezoneChange.bind(this)
		this.handleEnableTimezone = this.handleEnableTimezone.bind(this)
	}

    handleCreateWebsite(e){
    	e.preventDefault();
    	if(this.state.isValid){
			this.setState({error: null})
    		this.props.createWebsite(this.state)
				.then(response => {
					if (response) {
						this.props.handleOpen()
					} else {
						this.setState({error: response})
					}
				})
				.catch(err => {
					this.setState({error: err.message})
				})
    		
    	} else {
    		alert("Please ensure the URL has been entered correctly. You may be missing http:// or https:// from your website address.")
    	}
	}
	
	componentDidMount(){
		this.props.fetchSupportedCurrencies()
		.then((responseObj) => {
			// On mounting get the supported currencies and pass to the state object
			// Also set the default website currency to the first one in the array
			this.setState({ 
				supportedCurrencies: responseObj, 
				websiteCurrency: responseObj[0].code 
			})
		})
	}

    handleChangeWebsiteUrl(e){
    	this.setState({ websiteUrl: e.target.value, alertOnClose: true, isValid: e.target.validity.valid })
	}
	
	handleChangeWebsiteName(e){
    	this.setState({ websiteName: e.target.value, alertOnClose: true })
	}
	
	handleChangeWebsiteCurrency(e){
		this.setState({ websiteCurrency: e.target.value, alertOnClose: true  })
	
	}

	handleChangeLeadsText(e){
		this.setState({ leadsText: e.target.value, alertOnClose: true  })
	}

	handleTimezoneChange(e){
		this.setState({ timezone: e})
	}

	handleEnableTimezone(e){
		this.setState({ enableTimezone: e })
	}


	render() {
		let number_of_websites = this.props.customerWebsites.length
		let website_limit = this.props.customer.__subscription.__package_details.websites

		const user_role = this.props.customer.__user_role_id

		if(number_of_websites < website_limit){
			return (
				<Modal 
				title="Add Website"
				closeLabel="Cancel"
				saveLabel="Add Website"
				closeAction={this.props.handleOpen}
				saveAction={this.handleCreateWebsite}
				alertOnClose={this.state.alertOnClose}
				>
				{this.state.error && <div className="message message--warning margin-small">{this.state.error}</div>}
				<CreateWebsite
				websiteUrl={this.state.websiteUrl}
				websiteName={this.state.websiteName}
				leadsText={this.state.leadsText}
				supportedCurrencies={this.state.supportedCurrencies}
				handleChangeWebsiteCurrency={this.handleChangeWebsiteCurrency}
				handleChangeWebsiteUrl={this.handleChangeWebsiteUrl}
				handleChangeWebsiteName={this.handleChangeWebsiteName}
				handleChangeLeadsText={this.handleChangeLeadsText}
				timezone={this.state.timezone}
				enableTimezone={this.state.enableTimezone}
				handleTimezoneChange={this.handleTimezoneChange}
				handleEnableTimezone={this.handleEnableTimezone}
				/>
				</Modal>
			)
		} 

		return (
			<Modal 
			title="Add Website"
			closeAction={this.props.handleOpen}
			disableSave={true}
			alertOnClose={this.state.alertOnClose}
			>
			<div className="message message--warning">
				<h2 className="message__title">Plan limit hit!</h2>
				<p className="margin-small">You've reached the maximum number of websites you can add on your current plan. You'll need to upgrade your plan to add more websites.</p>
				{ 
					(user_role === 1 || user_role === 2) && 
					<p>To start or upgrade your plan, visit your <a href="/company">account page here</a></p>
				}
			</div>
			</Modal>
		)
			
	}
}

CreateWebsiteContainer.propTypes = {
	handleOpen: PropTypes.func,
};

const mapStateToProps = (state) => {
	return {
		customer: state.customers.selectedCustomer,
		customerWebsites: state.websites.customerWebsites
	}
}

const mapDispatchToProps = dispatch => {
	return {
		createWebsite: (payload) => dispatch(createWebsite(payload)),
		fetchSupportedCurrencies: () => dispatch(fetchSupportedCurrencies())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWebsiteContainer);
