import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import BeaconRequest from '../../BeaconRequest';
import { fetchCampaignChannels } from '../../actions/channels';
import DropdownList from '../general/DropdownList'
import { addNotification } from '../../actions/ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import LoaderAlt from '../general/LoaderAlt';
import { fetchLinks } from '../../actions/links';
import produce from 'immer';
var _ = require('lodash')

const api = {
    saveLink: (link, channel, description, campaign, website) => {
        return BeaconRequest.post('link/' + link.link_id, {}, {
            "url":"",
            "website_id": website,
            "campaign_id": campaign.id,
            "channel_id": channel != null ? channel.id : 0,
            "notes": description
        })
    },
}

const CardLink = (props) => {
    const [description, setdescription] = useState("")
    const [channel, setchannel] = useState(props.selectedChannel)
    const [changed, setchanged] = useState(false)
    const [saving, setsaving] = useState(false)
    const [saved, setsaved] = useState(false)
    const [error, seterror] = useState(false)
    const [campaign, setcampaign] = useState(props.selectedCampaign)
    const [channels, setchannels] = useState(props.channels)
    const [loadingChannels, setloadingChannels] = useState(false)

    useEffect(() => { //useContext
        props.addToChanged({index: props.index, changed})
    }, [changed])

    
    const save = () => {
        setsaving(true)
        api.saveLink(props.link, channel, description, campaign, props.website).then(res => {
            setsaved(true)

            const title = channel != null ? "Saved link to channel " + channel.title : "Link saved"
            props.addNotification({
                title,
                theme:"positive"
            })
            props.fetchLinks()

            setchanged(false)
            setsaving(false)
        }).catch(err => {
            console.log(err)
            seterror(true)
            alert("Could not save, please try again.")
            
        })
    }

    const handleCopy = () => {
		props.addNotification({
			title:"Copied to clipboard",
			theme:"positive"
		})
	}

    return (
        <div key={props.index} className="card margin-medium">
                <div className="card__header">
                <h2 className="card__title">#{props.index}</h2>
            </div>
            <ul className="card__content">
                <div className="form__two-column">
                    <li className="form__item">
                        <label className="form__label">Short URL</label>
                        <div style={{display:"grid", gridTemplateColumns:"max-content max-content", alignItems:"center"}}>
                            <div>
                                https://bcn.to/{props.link.shortCode}
                            </div>
                            <CopyToClipboard text={'https://bcn.to/'+(props.link.shortCode)} style={{marginLeft:15, justifySelf:"end"}}>
                                <button 
                                type="button" 
                                title="Copy to clipboard" 
                                className="button button--small button--light-grey" 
                                onClick={handleCopy}>
                                    <i className="fas fa-copy"></i>
                                </button>
                            </CopyToClipboard>
                        </div>
                    </li>
                    <li className="form__item">
                        <label className="form__label">Campaigns</label>
                        <DropdownList 
                            selectItem={(campaign) => {
                                setcampaign(campaign)
                                setchannel(null)
                                setchanged(true)
                                setsaving(false)
                                setsaved(false)
                                seterror(false)
                                setloadingChannels(true)
                                props.fetchCampaignChannels(campaign.id).then(responseObj => {
                                    setchannels(responseObj)
                                    setloadingChannels(false)
                                })
                            }} 
                            menu={{
                                title: campaign.title ? campaign.title : "Select a Campaign" ,
                                items: props.activeCampaigns.map(campaign => {
                                    return { id: campaign.campaign_id, title: campaign.title, colour: campaign.colour }
                                })
                            }} 
                            sort
                        />

                        <label className="form__label" style={{marginTop: '10px'}}>Channels</label>

                        <DropdownList 
                            selectItem={(channel) => {
                                setchannel(channel)
                                if (channel != "Select a Channel") {
                                    setchanged(true)
                                    setsaving(false)
                                    setsaved(false)
                                    seterror(false)
                                }
                            }} 
                            menu={{
                                title: channel ? channel.title : "Select a Channel",
                                items: channels.map(channel => {
                                    return { id: channel.channel_id, title: channel.title, channel_icon: channel.icon, colour: channel.colour}
                                })
                            }} 
                            isDisabled={loadingChannels}
                            sort
                        />
                    </li>
                </div>
                <li style={{position:"relative"}}>
                    <label className="form__label">Description</label>
                    <input 
                    type="text" 
                    className="form__input"
                    value={description}
                    onChange={e => {
                        setdescription(e.target.value)
                        if (e.target.value != "") {
                            setchanged(true)
                            setsaving(false)
                            setsaved(false)
                            seterror(false)
                        }
                    }}
                    />
                </li>
                <div className="form__two-column">
                    <li> 
                        <button 
                            className="button button--no-border button--green" 
                            style={{right: 0,  marginTop: "5px"}} 
                            onClick={e => save()}
                            disabled={(saving|| !changed || channel == null)}
                        >
                            Save
                        </button>
                    </li>
                    <li>
                        <div style={{ textAlign: 'right', fontSize: '16px'}}>
                            {(() => {
                                if (saving){
                                    return "saving ..."
                                } 
                                if (error) {
                                    return "error"
                                }
                                if (saved) {
                                    return "saved"
                                } 

                                return ""
                            })()}
                        </div>
                    </li>
                </div>
            </ul>
        </div>
    )
}

class MultiLinkCreator extends Component {
    constructor(){
        super();
        this.state = {
            linksToCreate: 3,
            isCreating: false, // to show a loader
            createdLinks: [],
            campaignChannels: [],
            changed: [],
            campaign: {
                title: ""
            },
            selectedChannel: null,
            fetchingChannels: false,
        }
        this.handleChangeLinksToCreate = this.handleChangeLinksToCreate.bind(this)
        this.handleSetCampaign = this.handleSetCampaign.bind(this)
        this.handleLinkInput = this.handleLinkInput.bind(this)
        this.handleCreateLinks = this.handleCreateLinks.bind(this)
        this.handleCopy = this.handleCopy.bind(this)
        this.addToChanged = this.addToChanged.bind(this)
    }

    componentDidUpdate(prevProps){
        if (this.props.isClosing !== prevProps.isClosing) {
            if (this.props.isClosing === true) {
                if (this.state.changed.filter(i => i === true).length > 0) {
                    if(!window.confirm("You have unsaved changes, are you sure you want to leave without saving?")) {
                        this.props.cancel()
                    } else {
                        this.props.close()
                    }
                } else {
                    this.props.close()
                }
               
            }
        }
    }
    
    addToChanged({index, changed}){
        this.setState(produce(prev => {prev.changed[index] = changed}))
    }

    handleChangeLinksToCreate(e){
        if(e.target.value > 20){
            alert("You can only create a maximum of 20 Veracity Links at a time");
            this.setState({
                linksToCreate: 20
            })
        } else if(e.target.value < 1){
            alert("You can't create less than one link");
            this.setState({
                linksToCreate: 1
            })
        } else {
            this.setState({
                linksToCreate: parseInt(e.target.value, 10)
            })
        }
    }

    handleSetCampaign(campaign){
        this.setState({
            campaignId: campaign.id,
            campaignTitle: campaign.title,
            campaign: campaign,
            selectedChannel: null,
            fetchingChannels: true,
        }, () => {
            BeaconRequest.get('campaign/'+campaign.id+'/channels/active')
			.then(responseObj => {
				this.setState({
                    campaignChannels: responseObj.response,
                    selectedChannels: [],
                    selectedChannelsDescriptons: [],
                    fetchingChannels: false,
                })
			})
			.catch(error => { console.log(error) })
        
            // this.props.fetchCampaignChannels(this.state.campaignId)
            // .then(responseObj => {
            //     this.setState({
            //         campaignChannels: responseObj,
            //         selectedChannels: [],
            //         selectedChannelsDescriptons: []
            //     })
            // })
        })
    }

    // async saveAll () {
    //     this.props.addNotification({
    //         title:"Saving ...",
    //         theme:"positive"
    //     })
    //     await Promise.all(Object.keys(this.state.changed).map(link_id => {
    //         return BeaconRequest.post(
    //             'link/' + link_id, {},
    //             {
    //                 url:"",
    //                 notes: this.state.changed[link_id]
    //             }
    //         )
    //     })).then(res => { 
    //         this.props.addNotification({
    //             title:"Saved",
    //             theme:"positive"
    //         })
    //         this.props.fetchLinks()
    //     })
    // }

    handleLinkInput(e){
        this.setState({
            originalUrl:e.target.value
        })
    }

    handleCreateLinks(){
        this.setState({
            isCreating: true
        })
        return BeaconRequest.post(
			'link/', {},
			{
			    "url":this.state.originalUrl,
			    "website_id":this.props.website,
                "campaign_id": this.state.campaignId,
                "channel_id": this.state.selectedChannel != null ? this.state.selectedChannel.id : 0,
                "links_to_create":this.state.linksToCreate
			}
        )
        .then(responseObj => {
            this.setState({
                isCreating: false,
                createdLinks: responseObj.response.links
            })
            this.props.fetchLinks()
        })
        .catch(error => {
            if(error.code === 4001){
				alert(error.message + ". Please try again.")
            }
            this.setState({
                isCreating: false
            })
        })
    }

    handleCopy(){
		const payload = {
			title:"Copied to clipboard",
			theme:"positive"
		}
		this.props.addNotification(payload)
	}

    render(){
        if(this.props.selectedCustomer.__user_role.role_id === 4){
            return (
                <div className="message message--warning">
                    <h2 className="message__title">You don't have permission to do that</h2>
                    <p>You don't have admin permissions for this company so cannot create Veracity Links.</p>
                </div>
            )
        }
        let j = 0
        const links = this.state.createdLinks.map( (link, i) => {
            j++
            return <CardLink 
                        key={j}
                        index={j} 
                        campaignId={this.state.campaignId}
                        website={this.props.website}
                        link={link}
                        campaignChannels={this.state.campaignChannels}
                        addNotification={(payload) => this.props.addNotification(payload)}
                        fetchLinks={() => this.props.fetchLinks}
                        addToChanged={(payload) => this.addToChanged(payload)}
                        activeCampaigns={this.props.activeCampaigns}
                        fetchCampaignChannels={(campaignid) => {
                            return BeaconRequest.get('campaign/'+campaignid+'/channels/active')
                                .then(responseObj => {
                                    return responseObj.response
                                })
                                .catch(error => { console.log(error) })
                        }}
                        selectedCampaign={this.state.campaign}
                        channels={this.state.campaignChannels}
                        selectedChannel={this.state.selectedChannel}

                    />
        })

        return (
            <ul>
                {_.isEmpty(this.state.createdLinks) &&
                    <li className="form__item">
                        <label className="form__label" htmlFor="link">URL to shorten</label>
                        <input 
                        id="link"
                        autoFocus
                        type="text" 
                        className="form__input" 
                        placeholder="Paste in a URL"
                        onChange={this.handleLinkInput} 
                        required
                        />
                    </li>

                }
                
                {_.isEmpty(this.state.createdLinks) &&
                    <div className={_.isEmpty(this.state.createdLinks) ? "form__two-column" : ""}>
                        <li className="form__item">
                            <label className="form__label">Select a Campaign</label>
                            <DropdownList 
                                selectItem={this.handleSetCampaign} 
                                menu={{
                                    title: this.state.campaignTitle ? this.state.campaignTitle : "Select a Campaign" ,
                                    items: this.props.activeCampaigns.map(campaign => {
                                        return { id: campaign.campaign_id, title: campaign.title, colour: campaign.colour }
                                    })
                                }} 
                                sort
                            />


                            {(this.state.campaignTitle != null) && <>
                                <label className="form__label" style={{marginTop: '10px', display: this.state.campaignTitle ? null : 'none' }}>Select a Channel</label>
                                {this.state.fetchingChannels === false ? <>
                                    <DropdownList 
                                        selectItem={(channel) => this.setState({selectedChannel: channel})} 
                                        menu={{
                                            title: this.state.selectedChannel ? this.state.selectedChannel.title : "Select a Channel",
                                            items: this.state.campaignChannels.map(channel => {
                                                return { id: channel.channel_id, title: channel.title, channel_icon: channel.icon, colour: channel.colour}
                                            })
                                        }} 
                                        sort
                                    />
                                </>
                                :
                                    <>
                                    <LoaderAlt />
                                    </>
                                }
                                
                                </>
                            }
                            
                        </li>

                        <li className="form__item">
                            <label className="form__label" htmlFor="number">Number of links to create</label>
                            <input 
                            style={{width:75}}
                            id="number"
                            type="number" 
                            className="form__input" 
                            value={this.state.linksToCreate}
                            onChange={this.handleChangeLinksToCreate}
                            />
                        </li>
                    </div>
                }
                {
                    (this.state.campaignTitle && _.isEmpty(this.state.createdLinks)) &&
                    <>
                        <div className="margin-medium message message--notice">
                            <p>You are about to create <strong>{this.state.linksToCreate}</strong> Veracity Links for the campaign <strong>{this.state.campaignTitle}</strong> {this.state.selectedChannel != null && <> and channel <strong>{this.state.selectedChannel.title}</strong></>}. You will be able to change individual link details after creation.</p>
                        </div>
                        <li className="form__item">
                            <button 
                            type="submit" 
                            className="button button--green"
                            disabled={!this.state.campaignTitle || !this.state.originalUrl || !this.state.selectedChannel}
                            onClick={this.handleCreateLinks}>
                            Create Veracity Links
                            </button>
                        </li>
                    </>
                }

                {
                    this.state.isCreating &&
                    <LoaderAlt />
                }

                {!_.isEmpty(this.state.createdLinks) &&
                    <>
                        <div style={{marginBottom:65}}>
                            <div className="margin-medium message message--notice">
                                <h3 className="message__title">Success!</h3>
                                <p>You have created <strong>{this.state.linksToCreate}</strong> Veracity Links for the campaign <strong>{this.state.campaignTitle}</strong> {this.state.selectedChannel != null && <> and channel <strong>{this.state.selectedChannel.title}</strong></>}.</p>
                            </div>
                            {links}
                        </div>
                        {/* <div className="fixed-tools">
                            <button className="button button--no-border button--green" onClick={e => this.saveAll(e, links)}>
                                Save all links
                            </button>
                        </div> */}
                    </>
                }
            </ul>
        )
    }
}

const mapStateToProps = state => {
	return {
        activeCampaigns: state.campaigns.activeCampaigns,
        website: state.websites.selectedWebsiteId,
        selectedCustomer: state.customers.selectedCustomer
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
        fetchCampaignChannels: (campaignId) => dispatch(fetchCampaignChannels(campaignId)),
        addNotification: (payload) => dispatch(addNotification(payload)),
        fetchLinks: () => dispatch(fetchLinks())
    } 
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiLinkCreator)