import React, { useState, useEffect, useMemo, useRef } from "react";

import DropdownList from "../../components/general/DropdownList";
import BeaconRequest from "../../BeaconRequest";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { connect } from "react-redux";

import {
  setSelectedCampaign,
  fetchActiveCampaigns,
  unsetSelectedCampaign
} from "../../actions/campaigns";
import {
  unsetSelectedChannel,
  clearCampaignChannels,
  setSelectedChannel,
  fetchCampaignChannels
} from "../../actions/channels";
import { unsetSelectedLink, setSelectedLink } from "../../actions/links";
import { oneOneEightID } from "../../constants";

import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Toggle from "../../components/general/Toggle";

import { setFilter } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from "moment";

const DropdownContainer = props => {
  return (
    <div>
      <DropdownContainerChild {...props} />
    </div>
  );
};

export const Popup = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  // const [placement, setPlacement] = React.useState('bottom');

  const hand_open_help = event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => !prev);
    // setPlacement(newPlacement);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div style={{ display: "inline-block" }}>
        <span style={{ ...props.style }} onClick={hand_open_help}>
          {props.children}
        </span>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={props.placement}
          transition
          modifiers={{
            flip: {
              enabled: false
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "window"
            },
            arrow: {
              enabled: false
              //   element: arrowRef,
            }
          }}
          style={{ zIndex: 15 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={150}>
              <div className="card" style={{ padding: "10px" }}>
                {props.content}
              </div>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const jsonToQueryString = json => {
  return (
    "?" +
    Object.keys(json)
      .map(function(key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
      })
      .join("&")
  );
};
const parseParams = querystring => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...parseParams(location.search),
        ...params
      },
      match,
      location,
      history
    };
  }, [params, match, location, history]);
};

export const redirectRouter = Element => props => {
  const router = useRouter();

  useEffect(() => {
    if (router.location.pathname == "/") redirect();
  }, []);

  const datetime = useSelector(state => state.dates.selectedDateValue);
  const filter = useSelector(state => state.global.filter);
  const website = useSelector(state => state.websites.selectedWebsite);

  //change params to object
  const redirect = (modelArg, idArg, periodArg, pathname, viewArg) => {
    const urlParams = new URLSearchParams(window.location.search);
    const model = urlParams.get("model");
    const id = urlParams.get("id");
    const period = urlParams.get("period");
    const view = urlParams.get("view");

    const querystring = {};

    if (viewArg || view) {
      querystring["view"] = viewArg || view;
    } else if (
      typeof website?.lastAdDataFor === "string" &&
      moment.tz(website.lastAdDataFor, BeaconRequest.getTimezone()).unix() >=
        moment()
          .tz(BeaconRequest.getTimezone())
          .subtract(7, "day")
          .startOf("day")
          .unix()
    ) {
      querystring["view"] = "performance";
    } else {
      querystring["view"] = "analytics";
    }

    if (modelArg || model) {
      querystring["model"] = modelArg || model;
    } else {
      if (filter && filter.type && filter.type != "website") {
        querystring["model"] = filter.type;
      }
    }

    if (idArg || id) {
      querystring["id"] = idArg || id;
    } else {
      if (filter && filter.payload.id && filter.type != "website") {
        querystring["id"] = filter.payload.id;
      }
    }

    if (periodArg || period) {
      querystring["period"] = periodArg || period;
    } else {
      if (datetime != null) {
        querystring["period"] = datetime;
      }
    }

    if (modelArg == "website") {
      delete querystring["model"];
      delete querystring["id"];
    }

    router.history.push(
      (pathname || window.location.pathname) + jsonToQueryString(querystring)
    );

    return;
  };

  return <Element {...{ ...props, ...router }} redirect={redirect} />;
};

const DropdownContainerChild = props => {
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [campaignChannels, setCampaignChannels] = useState([]);
  const [channelLinks, setChannelLinks] = useState([]);

  const [loadingCampaigns, setLoadingCampaigns] = useState(false);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [loadingLinks, setLoadingLinks] = useState(false);

  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);

  const [activeCampaignFilter, setActiveCampaignFilter] = useState(true);

  const [activeChannelFilter, setActiveChannelFilter] = useState(true);

  const [activeLinkFilter, setActiveLinkFilter] = useState(true);

  const prevDatetime = usePrevious(props.datetime);
  const prevFilter = usePrevious(props.filter);

  const dispatch = useDispatch();

  const handleSetCampaign = campaign => {
    setSelectedCampaign(campaign);
  };

  const handleSetChannel = channel => {
    setSelectedChannel(channel);
  };

  const handleSetLink = link => {
    setSelectedLink(link);
  };

  const fetchChannelsForCampaign = campaign_id => {
    setLoadingChannels(true);

    const query = `campaign/${campaign_id}/channels/dashboard/${
      activeChannelFilter ? "active" : "all"
    }/${props.datetime}`;

    BeaconRequest.get(query, { __onlyCore: 1 })
      .then(responseObj => {
        setCampaignChannels(responseObj.response);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoadingChannels(false));
  };

  const fetchLinksForChannel = channel_id => {
    setLoadingLinks(true);

    BeaconRequest.get(
      `channel/${channel_id}/links/dashboard/${
        activeLinkFilter ? "active" : "all"
      }/${props.datetime}`
    )
      .then(responseObj => {
        setChannelLinks(responseObj.response);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoadingLinks(false));
  };

  const fetchCampaignsForWebsite = website_id => {
    setLoadingCampaigns(true);

    const query = `website/${website_id}/campaigns/dashboard/${
      activeCampaignFilter ? "active" : "all"
    }/${props.datetime}`;

    BeaconRequest.get(query, { __onlyCore: 1 })
      .then(responseObj => {
        setActiveCampaigns(responseObj.response);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoadingCampaigns(false));
  };

  const request = (type, payload) => {
    //TODO cancelling requests
    if (type == "link") {
      if (
        payload.channel == null ||
        payload.campaign == null ||
        payload.title == null
      ) {
        BeaconRequest.get(`link/${payload.id}`).then(e => {
          fetchCampaignsForWebsite(props.websiteId);
          handleSetCampaign({
            id: e.response.campaign_id,
            title: e.response.__campaign.title
          });
          fetchChannelsForCampaign(e.response.campaign_id);

          handleSetChannel({
            id: e.response.channel_id,
            title: e.response.__channel.title
          });
          fetchLinksForChannel(e.response.channel_id);

          handleSetLink({
            ...payload,
            title: e.response.shortCode + ": " + e.response.notes
          });
        });
      } else {
        if (activeCampaigns.length == 0)
          fetchCampaignsForWebsite(props.websiteId);
        handleSetCampaign(payload.campaign);
        if (campaignChannels.length == 0)
          fetchChannelsForCampaign(payload.campaign.id);
        handleSetChannel(payload.channel);
        if (channelLinks.length == 0) fetchLinksForChannel(payload.channel.id);
        handleSetLink(payload);
      }
    } else if (type == "channel") {
      handleSetLink(null);

      if (payload.campaign == null || payload.title == null) {
        // , {__onlyCore: 1}
        BeaconRequest.get(`channel/${payload.id}`).then(c => {
          // BeaconRequest.get(`campaign/${c.response.campaign_id}`, {__onlyCore: 1}).then(e => {
          const e = c.response.__campaign;
          fetchCampaignsForWebsite(props.websiteId);
          handleSetCampaign({ id: e.campaign_id, title: e.title });
          fetchChannelsForCampaign(e.campaign_id);

          handleSetChannel({ ...payload, title: c.response.title });
          fetchLinksForChannel(c.response.channel_id);
          // })
        });
      } else {
        if (activeCampaigns.length == 0)
          fetchCampaignsForWebsite(props.websiteId);
        handleSetCampaign(payload.campaign);
        if (campaignChannels.length == 0)
          fetchChannelsForCampaign(payload.campaign.id);
        setSelectedChannel(payload);
        fetchLinksForChannel(payload.id);
      }
    } else if (type == "campaign") {
      handleSetLink(null);
      handleSetChannel(null);

      if (payload.title == null) {
        BeaconRequest.get(`campaign/${payload.id}`, { __onlyCore: 1 }).then(
          e => {
            fetchCampaignsForWebsite(props.websiteId);
            handleSetCampaign({ ...payload, title: e.response.title });
            fetchChannelsForCampaign(payload.id);
          }
        );
      } else {
        if (activeCampaigns.length == 0)
          fetchCampaignsForWebsite(props.websiteId);
        handleSetCampaign(payload);
        fetchChannelsForCampaign(payload.id);
      }
    } else {
      fetchCampaignsForWebsite(props.websiteId);
    }
  };

  const clearCampaign = () => {
    setSelectedCampaign(null);
    setCampaignChannels([]);
    setSelectedChannel(null);
    setChannelLinks([]);
    setSelectedLink(null);
    props.unsetSelectedCampaign();
    props.unsetSelectedChannel();
  };

  const clearChannel = () => {
    setChannelLinks([]);
    setSelectedLink(null);
    setSelectedChannel(null);
    props.unsetSelectedChannel();
  };

  const clearLink = () => {
    setSelectedLink(null);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const model = urlParams.get("model");
    const id = urlParams.get("id");
    const period = urlParams.get("period");

    if (id == null || model == null || props.history.location.search == "") {
      if (
        prevFilter == null ||
        (prevFilter && prevFilter.payload.id != props.websiteId)
      ) {
        dispatch(
          setFilter({ type: "website", payload: { id: props.websiteId } })
        );
        request("website", { id: props.websiteId });
        clearCampaign();
      }
    } else {
      if (
        prevFilter == null ||
        (prevFilter &&
          (prevFilter.type != model || prevFilter.payload.id != id))
      ) {
        dispatch(setFilter({ type: model, payload: { id } }));
        request(model, { id });
        switch (model) {
          case "campaign":
          default:
            clearChannel();
            break;
          case "channel":
            clearLink();
            break;
          case "link":
            break;
        }
      }
    }
  }, [props.location]);

  // useEffect(() => {
  //     if (props.currentUser.user_id === oneOneEightID) { // 118 HACK
  //         handleSetCampaign({id: 1753, title: "118 Campaign", colour: "#fecf2f"})
  //     }
  // }, [props.currentUser.user_id])

  useEffect(() => {
    fetchCampaignsForWebsite(props.websiteId);
  }, [activeCampaignFilter]);

  useEffect(() => {
    fetchCampaignsForWebsite(props.websiteId);
  }, [activeCampaignFilter]);

  useEffect(() => {
    if (prevDatetime != props.datetime) {
      fetchCampaignsForWebsite(props.websiteId);
    }
  }, [props.datetime]);

  useEffect(() => {
    selectedCampaign && fetchChannelsForCampaign(selectedCampaign.id);
  }, [activeChannelFilter, selectedCampaign]);

  useEffect(() => {
    if (prevDatetime != props.datetime) {
      selectedCampaign && fetchChannelsForCampaign(selectedCampaign.id);
    }
  }, [props.datetime, activeChannelFilter, selectedCampaign]);

  useEffect(() => {
    selectedCampaign &&
      selectedChannel &&
      fetchLinksForChannel(selectedChannel.id);
  }, [activeLinkFilter, selectedCampaign, selectedChannel]);

  useEffect(() => {
    if (prevDatetime != props.datetime) {
      selectedCampaign &&
        selectedChannel &&
        fetchLinksForChannel(selectedChannel.id);
    }
  }, [props.datetime, activeLinkFilter, selectedCampaign, selectedChannel]);

  return (
    <div
      style={{
        width: "100%",
        fontSize: "9.5pt"
        // marginBottom: '10px'
      }}
    >
      {/* {activeCampaigns != null && activeCampaigns.length > 0 && props.currentUser.user_id !== oneOneEightID ? // 118 HACK */}
      <span
        style={{ marginRight: "20px", maxWidth: "33%", display: "inline-flex" }}
      >
        <DropdownList
          isDisabled={props.query.view === "google"}
          selectItem={campaign => {
            if (campaign.hasSpend == false) {
              props.redirect("campaign", campaign.id, null, null, "analytics");
            } else {
              props.redirect("campaign", campaign.id);
            }
          }}
          selectedItem={selectedCampaign != null ? selectedCampaign.id : ""}
          autocomplete
          clearSelectedItem={() => {
            props.redirect("website");
          }}
          maxWidth
          isClearable={true}
          theme="light"
          loading={loadingCampaigns}
          menu={{
            title: selectedCampaign
              ? selectedCampaign.title
              : "Filter by campaign",
            items:
              activeCampaigns == null
                ? []
                : activeCampaigns.map(campaign => ({
                    id: campaign.campaign_id,
                    title: campaign.title,
                    colour: campaign.colour,
                    archived: campaign.archived == 1,
                    hasSpend: campaign.hasSpend == 1,
                    hasVisits: campaign.hasVisits == 1
                  }))
          }}
          sort
          tabs={[
            {
              name: "Active",
              onClick: e => {
                setActiveCampaignFilter(true);
              },
              isSelected: activeCampaignFilter
            },
            {
              name: "All",
              onClick: e => {
                setActiveCampaignFilter(false);
              },
              isSelected: !activeCampaignFilter
            }
          ]}
        />
      </span>
      {/* :
            <></>
        } */}

      {selectedCampaign != null && (
        <span
          style={{
            marginRight: "20px",
            maxWidth: "33%",
            display: "inline-flex"
          }}
        >
          <DropdownList
            isDisabled={props.query.view === "google"}
            selectItem={channel => {
              if (channel.hasSpend == false) {
                props.redirect("channel", channel.id, null, null, "analytics");
              } else {
                props.redirect("channel", channel.id);
              }
            }}
            selectedItem={selectedChannel != null ? selectedChannel.id : ""}
            autocomplete
            clearSelectedItem={() => {
              props.redirect("campaign", selectedCampaign.id);
            }}
            isClearable={true}
            theme="light"
            loading={loadingChannels}
            menu={{
              title: selectedChannel
                ? selectedChannel.title
                : "Filter by channel",
              items: campaignChannels.map(channel => ({
                id: channel.channel_id,
                title: channel.title,
                channel_icon: channel.icon,
                colour: channel.colour,
                hasSpend: channel.hasSpend == 1,
                hasVisits: channel.hasVisits == 1
              }))
            }}
            sort
            tabs={[
              {
                name: "Active",
                onClick: e => {
                  setActiveChannelFilter(true);
                },
                isSelected: activeChannelFilter
              },
              {
                name: "All",
                onClick: e => {
                  setActiveChannelFilter(false);
                },
                isSelected: !activeChannelFilter
              }
            ]}
          />
        </span>
      )}

      {selectedCampaign != null && selectedChannel != null && (
        <div
          style={{
            marginRight: "20px",
            maxWidth: "33%",
            display: "inline-flex"
          }}
        >
          <DropdownList
            isDisabled={props.query.view === "google"}
            selectItem={link => {
              if (link.hasSpend == false) {
                props.redirect("link", link.id, null, null, "analytics");
              } else {
                props.redirect("link", link.id);
              }
            }}
            autocomplete
            selectedItem={selectedLink != null ? selectedLink.id : ""}
            clearSelectedItem={() => {
              props.redirect("channel", selectedChannel.id);
            }}
            isClearable={true}
            theme="light"
            loading={loadingLinks}
            menu={{
              title: selectedLink ? selectedLink.title : "Filter by link",
              items: channelLinks.map(link => ({
                id: link.link_id,
                title: `${link.shortCode}: ${link.notes || "No description"}`,
                hasSpend: link.hasSpend == 1,
                hasVisits: link.hasVisits == 1
              }))
            }}
            sort
            tabs={[
              {
                name: "Active",
                onClick: e => {
                  setActiveLinkFilter(true);
                },
                isSelected: activeLinkFilter
              },
              {
                name: "All",
                onClick: e => {
                  setActiveLinkFilter(false);
                },
                isSelected: !activeLinkFilter
              }
            ]}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selectedCampaignName: state.campaigns.selectedCampaignName,
    selectedCampaignId: state.campaigns.selectedCampaignId,
    selectedChannelName: state.channels.selectedChannelName,
    selectedChannelId: state.channels.selectedChannelId,
    selectedLinkName: state.links.selectedLinkName,
    selectedLinkId: state.links.selectedLinkId,
    currentUser: state.login.currentUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // fetchActiveCampaigns: () => dispatch(fetchActiveCampaigns()),
    setSelectedCampaign: (campaign, timeperiod) =>
      dispatch(setSelectedCampaign(campaign, timeperiod)),
    unsetSelectedCampaign: () => dispatch(unsetSelectedCampaign()),
    unsetSelectedChannel: () => dispatch(unsetSelectedChannel()),
    clearCampaignChannels: () => dispatch(clearCampaignChannels()),
    // fetch_website_by_period: () => dispatch(fetch_website_by_period())

    setSelectedChannel: channel => dispatch(setSelectedChannel(channel)),
    setSelectedLink: link => dispatch(setSelectedLink(link)),
    unsetSelectedLink: () => dispatch(unsetSelectedLink())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(redirectRouter(DropdownContainer));
