var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Columns } from "./Column";
function Table(_a) {
    var _b;
    var activeTab = _a.activeTab, loadedData = _a.loadedData, collation = _a.collation, timePeriod = _a.timePeriod;
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "table" }, { children: [_jsx("div", __assign({ className: "table__row" }, { children: (_b = Columns.get(activeTab)) === null || _b === void 0 ? void 0 : _b.map(function (column) {
                        return collation.id === "summary" && column.key === "date" ? null : (_jsx("div", __assign({ style: { color: "rgb(76, 76, 76)" }, className: "table__heading" }, { children: column.title }), column.key));
                    }) })), loadedData.get(activeTab).rows.map(function (row, index) {
                    var _a;
                    return (_jsx("div", __assign({ className: "table__row" }, { children: (_a = Columns.get(activeTab)) === null || _a === void 0 ? void 0 : _a.map(function (column) {
                            var _a, _b;
                            return collation.id === "summary" && column.key === "date" ? null : (_jsx("div", __assign({ className: "table__cell" }, { children: (_b = (_a = column.formatter) === null || _a === void 0 ? void 0 : _a.call(column, row[column.key], collation, timePeriod)) !== null && _b !== void 0 ? _b : row[column.key] }), column.key));
                        }) }), index));
                })] })) }));
}
export default Table;
